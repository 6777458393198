import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Typography,
} from "@mui/material";
import React from "react";
import { CheckBoxInput } from "../../../types";
import Checkbox from "@mui/material/Checkbox";

type Props = {
  field: CheckBoxInput;
  fieldName: string;
  handleChange: (field: CheckBoxInput) => void;
  disabled: boolean;
};

const FormCheckBoxInput = ({
  field,
  fieldName,
  handleChange,
  disabled,
}: Props) => {
  function handleCheckChange(event: React.ChangeEvent<HTMLInputElement>) {
    //const checked = event.target.checked;
    let _field = { ...field };
    _field.value = !_field.value;
    handleChange(_field);
  }

  return (
    <FormControl>
      <FormControlLabel
        control={
          <Checkbox
            disabled={disabled}
            sx={{
              color: field.error ? "error.main" : "RGBA(0,0,0,0.6)",
            }}
            value={field.value}
            checked={field.value}
            onChange={handleCheckChange}
            name={fieldName}
            required={field.required}
          />
        }
        label={
          <Typography
            component={"label"}
            id={fieldName}
            sx={{
              marginBlock: "0.5em",
            }}
          >
            {field.label}
          </Typography>
        }
        labelPlacement={field.reversed ? "start" : "end"}
        color="RGBA(0,0,0,0.8)"
      />
      <FormHelperText error={!(field.error === "")} sx={{marginTop: 0}}>
        {field.error ? field.error : field.helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default FormCheckBoxInput;
