import { Box } from "@mui/material";
import React from "react";
import {
  DemandFilmingRow,
  DemandRow,
  DemandSpecialEventRow,
} from "../../../../data/Demand/models";
import UnusedDemandCard from "./UnusedDemandCard";
import DemandCard from "./DemandCard";

type Props = {
  demand: DemandSpecialEventRow | DemandFilmingRow;
  deactivateSubDemand: (demandid: string) => Promise<void>
};

const RegroupmentTab = ({ demand, deactivateSubDemand }: Props) => {
  return (
    <Box display="flex" flexDirection="column" gap="16px">
      {demand.regroupement.demandes.map((demand, idx) =>
        demand.statuscode === "Inutilisée" ? (
          <UnusedDemandCard key={idx} demand={demand} />
        ) : (
          <DemandCard key={idx} demand={demand} deactivate={() => deactivateSubDemand(demand.incidentid)}/>
        )
      )}
    </Box>
  );
};

export default RegroupmentTab;
