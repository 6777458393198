import { Box, BoxProps, Typography } from "@mui/material";
import React from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

type Props = {
  displayName: string;
  value: boolean;
  reversed?: boolean;
  fontSize?: string;
  color?: string;
  boxProps?: BoxProps
};

const DisplayCheckbox = ({
  displayName,
  value,
  fontSize,
  color,
  reversed,
  boxProps
}: Props) => {
  return (
    <Box
      flexDirection={reversed ? "row-reverse" : undefined}
      justifyContent={reversed ? "start" : undefined}
      alignItems="center"
      color={color ? color : undefined}
      display="flex"
      gap="10px"
      padding="2px 0 2px 0"
      {...boxProps}
    >
      <Typography
        fontWeight={"bold"}
        fontSize={fontSize && fontSize}
        color="inherit"
      >
        {displayName}
      </Typography>
      {value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
    </Box>
  );
};

export default DisplayCheckbox;
