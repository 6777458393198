import React from "react";
import { Divider, List } from "@mui/material";
//import Routes from "./links";
import AppDrawerLink from "./AppDrawerLink";
import { routes } from "../../../routes/routes";

const AppDrawerLinks = () => {
  return (
    <List sx={{ flexGrow: 1, height: "100%" }}>
      {Object.entries(routes).map(([key, value]) => {
        if (value.nav)
          return (
            <React.Fragment key={value.path}>
              <AppDrawerLink
                path={value.path}
                displayName={value.nav.label}
                icon={value.nav.icon}
              />
              <Divider />
            </React.Fragment>
          );
      })}
    </List>
  );
};

export default AppDrawerLinks;
