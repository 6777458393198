import React from "react";
import { TableFields } from "../../../../types";
import { Grid } from "@mui/material";
import CardTitle from "../../../common/cards/CardTitle";
import DisplayField from "../../../common/cards/DisplayField";

type Props = {
  title: string;
  emptyTextPlaceHolder?: string;
  adress1: TableFields;
};
const AccountAdressCardColumn1 = ({
  title,
  adress1,
  emptyTextPlaceHolder,
}: Props) => {
  return (
    <Grid
      container
      item
      xs={12}
      md={6}
      pr={2}
      sx={{ minWidth: { xs: "250px", md: "300px" } }}
    >
      <Grid item xs={12} minHeight={"45px"} paddingBottom={"5px"}>
        <CardTitle title={title} />
      </Grid>
      <Grid container spacing={"5px"}>
        {Object.entries(adress1).map(([key, value]) => (
          <Grid item xs={12} key={key}>
            <DisplayField
              emptyPlaceHolder={emptyTextPlaceHolder}
              displayName={value.displayName}
              value={value.value}
              fontSize="0.9rem"
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default AccountAdressCardColumn1;
