import React from "react";
import CenteredBox from "../boxes/CenteredBox";
import { CircularProgress, Typography } from "@mui/material";


const SectionLoading = () => {
  return (
    <CenteredBox sx={{height: "100%", width: "100%"}} padding={"20px"}>
      <CircularProgress id="circularProgress" color="primary" />
      <Typography
        variant="subtitle1"
        sx={{ margin: (theme) => theme.spacing(1) }}
      >
        Chargement...
      </Typography>
    </CenteredBox>
  );
};

export default SectionLoading;
