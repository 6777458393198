import React, { useState } from "react";
import {
  DemandAdmissibilityPatch,
  DemandAdmissibilityRow,
} from "../../../../data/Demand/models";
import { NewDemandFields } from "../../../../data/Demand/forms/dialog";
import { updateDemand } from "../../../../data/Demand/requests";
import EditDemandLayout from "../EditDemandLayout";
import SimpleFields from "../../../form/SimpleForm/SimpleFields";
import handleSimpleFormSubmit from "../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../hooks/useTabs";
import {
  EditDemandAdmissibilityFields,
  editDemandAdmissibilityFields,
  editDemandAdmissiblityRenouvellementSections,
  editDemandAdmissiblitySections,
} from "../../../../data/Demand/forms/admissibility";
import { validateDateString } from "../../../../data/common/validateDateString";
import buildForm from "../../../form/SimpleForm/buildForm";

type Props = {
  demand: DemandAdmissibilityRow;
  isNewDemand: boolean;
  fieldsGeneral: NewDemandFields;
  onUpdateSuccess: () => void;
  onUpdateFailure: (error?: string) => void;
  setFieldsGeneral: React.Dispatch<React.SetStateAction<NewDemandFields>>;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const Admissibility = ({
  demand,
  isNewDemand,
  fieldsGeneral,
  setFieldsGeneral,
  onUpdateSuccess,
  onUpdateFailure,
  setError,
}: Props) => {
  const details = demand.vdl_detailsdelademande;
  const [fields, setFields] = useState<EditDemandAdmissibilityFields>(
    buildForm(editDemandAdmissibilityFields, {
      vdl_dorganismeabutnonlucratif:
        details.vdl_dorganismeabutnonlucratif ?? false,
      vdl_dorganismedebienfaisanceenregistre:
        details.vdl_dorganismedebienfaisanceenregistre ?? false,
      vdl_etesvousunchapitrelavalloisduneinstancepro:
        details.vdl_etesvousunchapitrelavalloisduneinstancepro ?? false,
      vdl_siouiquelestlenometleneqdelorg:
        details.vdl_siouiquelestlenometleneqdelorg ?? "",
      vdl_datedeladeclarationaureq: validateDateString(
        details.vdl_datedeladeclarationaureq
      ),
      vdl_aucuneassurance: details.vdl_aucuneassurance ?? false,
      vdl_assurancedesadministrateurs:
        details.vdl_assurancedesadministrateurs ?? false,
      vdl_assurancedesbiens: details.vdl_assurancedesbiens ?? false,
      vdl_assuranceresponsabilitecivile:
        details.vdl_assuranceresponsabilitecivile ?? false,
      vdl_assuranceadministrateursetdirigeants:
        details.vdl_assuranceadministrateursetdirigeants ?? false,
      vdl_nomdelacompagniedassurance:
        details.vdl_nomdelacompagniedassurance ?? "",
      vdl_numerodelapolicedassurance:
        details.vdl_numerodelapolicedassurance ?? "",
      vdl_datedecheancedelapolicedassurance: validateDateString(
        details.vdl_datedecheancedelapolicedassurance
      ),
      vdl_missionetobjectif: details.vdl_missionetobjectif ?? "",
      vdl_realitebesoinsoutien: details.vdl_realitebesoinsoutien ?? "",
      vdl_pointsdeservicealaval: details.vdl_pointsdeservicealaval ?? false,
      vdl_siouiaquelendroit: details.vdl_siouiaquelendroit ?? "",
      vdl_dansqueldomainedactivitevotreorganismeinter: undefined,
      vdl_siautresprecisez: details.vdl_siautresprecisez ?? "",
      vdl_principauxserviceouactivites:
        details.vdl_principauxserviceouactivites ?? "",
      vdl_desregroupementsetoudesorganismes:
        details.vdl_desregroupementsetoudesorganismes ?? "",
      vdl_territoiresdesservisparlorganisme2:
        details.vdl_territoiresdesservisparlorganisme2 ?? [],
      vdl_siailleursprecisez: details.vdl_siailleursprecisez ?? "",
      vdl_nombredeparticipantsauxactivites:
        details.vdl_nombredeparticipantsauxactivites ?? "",
      vdl_typedeclientele: details.vdl_typedeclientele,
      vdl_prescolaire0a4ans: details.vdl_prescolaire0a4ans ?? "",
      vdl_enfants5a12ans: details.vdl_enfants5a12ans ?? "",
      vdl_adolescents13a17ans: details.vdl_adolescents13a17ans ?? "",
      vdl_adultes18a64ans: details.vdl_adultes18a64ans ?? "",
      vdl_aines65ansetplus65: details.vdl_aines65ansetplus65 ?? "",
      vdl_politiquedeverification: details.vdl_politiquedeverification ?? false,
      vdl_entente: details.vdl_entente ?? false,
      vdl_codedeconduite: details.vdl_codedeconduite ?? false,
      vdl_formationsurladoptiondecomportementsadequats:
        details.vdl_formationsurladoptiondecomportementsadequats ?? false,
      vdl_codedeconduitedelorganisation:
        details.vdl_codedeconduitedelorganisation ?? false,
      vdl_codeetsignatureduncontratethique:
        details.vdl_codeetsignatureduncontratethique ?? false,
      vdl_verificationantecedentjudiciaire:
        details.vdl_verificationantecedentjudiciaire ?? false,
      vdl_mecanismedegestionderisqueencasdedoute:
        details.vdl_mecanismedegestionderisqueencasdedoute ?? false,
      vdl_utilisationdelaplateformejebenevoleca:
        details.vdl_utilisationdelaplateformejebenevoleca ?? false,
      vdl_jeveuxplusdinformationacesujet:
        details.vdl_jeveuxplusdinformationacesujet ?? false,
      vdl_nombrederencontresduca: details.vdl_nombrederencontresduca ?? "",
      vdl_nombredepresencesalaga: details.vdl_nombredepresencesalaga ?? "",
      vdl_datedeladerniereassembleegeneraleannuelle: validateDateString(
        details.vdl_datedeladerniereassembleegeneraleannuelle
      ),
      vdl_nombredemembresactifsauseindelorganisme:
        details.vdl_nombredemembresactifsauseindelorganisme ?? "",
      vdl_duneconcertation: details.vdl_duneconcertation ?? "",
      vdl_dunregroupement: details.vdl_dunregroupement ?? "",
      vdl_dunefederation: details.vdl_dunefederation ?? "",
      vdl_produisezvousannuellementdesprevisionsbudgeta:
        details.vdl_produisezvousannuellementdesprevisionsbudgeta ?? false,
      vdl_vosderniersetatsfinanciersontilsfaitlobjet:
        details.vdl_vosderniersetatsfinanciersontilsfaitlobjet ?? false,
      vdl_datedefindevotreexercicefinancierannuel: validateDateString(
        details.vdl_datedefindevotreexercicefinancierannuel
      ),
      vdl_audit: details.vdl_audit ?? false,
      vdl_missiondexamen: details.vdl_missiondexamen ?? false,
      vdl_verificationcomptable: details.vdl_verificationcomptable ?? false,
      file1: undefined,
      file2: undefined,
      file3: undefined,
      file4: undefined,
      file5: undefined,
      file6: undefined,
      file7: undefined,
      file8: undefined,
    })
  );
  const { activeTab, changeTab } = useTabs();

  const onSubmit = async () => {
    const errors = handleSimpleFormSubmit(fields, setFields);

    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      const scrollTo = fields[errors[errors.length - 1]].fieldRef;
      if (scrollTo) {
        scrollTo.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
      return;
    }

    const _demand: DemandAdmissibilityPatch = {
      title: fieldsGeneral.title.value,
      vdl_contactprincipal: fieldsGeneral.vdl_contactprincipal.value,
      type: demand.type,
      vdl_detailsdelademande: {
        vdl_dorganismeabutnonlucratif:
          fields.vdl_dorganismeabutnonlucratif.value,
        vdl_dorganismedebienfaisanceenregistre:
          fields.vdl_dorganismedebienfaisanceenregistre.value,
        vdl_etesvousunchapitrelavalloisduneinstancepro:
          fields.vdl_etesvousunchapitrelavalloisduneinstancepro.value,
        vdl_siouiquelestlenometleneqdelorg:
          fields.vdl_siouiquelestlenometleneqdelorg.value,
        vdl_datedeladeclarationaureq:
          fields.vdl_datedeladeclarationaureq.value?.toISOString(),
        vdl_aucuneassurance: fields.vdl_aucuneassurance.value,
        vdl_assurancedesadministrateurs:
          fields.vdl_assurancedesadministrateurs.value,
        vdl_assurancedesbiens: fields.vdl_assurancedesbiens.value,
        vdl_assuranceresponsabilitecivile:
          fields.vdl_assuranceresponsabilitecivile.value,
        vdl_assuranceadministrateursetdirigeants:
          fields.vdl_assuranceadministrateursetdirigeants.value,
        vdl_nomdelacompagniedassurance:
          fields.vdl_nomdelacompagniedassurance.value,
        vdl_numerodelapolicedassurance:
          fields.vdl_numerodelapolicedassurance.value,
        vdl_datedecheancedelapolicedassurance:
          fields.vdl_datedecheancedelapolicedassurance.value?.toISOString(),

        vdl_missionetobjectif: fields.vdl_missionetobjectif.value,

        vdl_realitebesoinsoutien: fields.vdl_realitebesoinsoutien.value,

        vdl_pointsdeservicealaval: fields.vdl_pointsdeservicealaval.value,
        vdl_siouiaquelendroit: fields.vdl_siouiaquelendroit.value,
        vdl_dansqueldomainedactivitevotreorganismeinter:
          fields.vdl_dansqueldomainedactivitevotreorganismeinter.value,
        vdl_siautresprecisez: fields.vdl_siautresprecisez.value,
        vdl_principauxserviceouactivites:
          fields.vdl_principauxserviceouactivites.value,
        vdl_desregroupementsetoudesorganismes:
          fields.vdl_desregroupementsetoudesorganismes.value,
        vdl_territoiresdesservisparlorganisme2:
          fields.vdl_territoiresdesservisparlorganisme2.value,
        vdl_siailleursprecisez: fields.vdl_siailleursprecisez.value,

        vdl_nombredeparticipantsauxactivites:
          fields.vdl_nombredeparticipantsauxactivites.value,
        vdl_typedeclientele: fields.vdl_typedeclientele.value,

        vdl_prescolaire0a4ans: fields.vdl_prescolaire0a4ans.value,
        vdl_enfants5a12ans: fields.vdl_enfants5a12ans.value,
        vdl_adolescents13a17ans: fields.vdl_adolescents13a17ans.value,
        vdl_adultes18a64ans: fields.vdl_adultes18a64ans.value,
        vdl_aines65ansetplus65: fields.vdl_aines65ansetplus65.value,

        vdl_politiquedeverification: fields.vdl_politiquedeverification.value,
        vdl_entente: fields.vdl_entente.value,
        vdl_codedeconduite: fields.vdl_codedeconduite.value,
        vdl_formationsurladoptiondecomportementsadequats:
          fields.vdl_formationsurladoptiondecomportementsadequats.value,
        vdl_codedeconduitedelorganisation:
          fields.vdl_codedeconduitedelorganisation.value,
        vdl_codeetsignatureduncontratethique:
          fields.vdl_codeetsignatureduncontratethique.value,
        vdl_verificationantecedentjudiciaire:
          fields.vdl_verificationantecedentjudiciaire.value,
        vdl_mecanismedegestionderisqueencasdedoute:
          fields.vdl_mecanismedegestionderisqueencasdedoute.value,
        vdl_utilisationdelaplateformejebenevoleca:
          fields.vdl_utilisationdelaplateformejebenevoleca.value,
        vdl_jeveuxplusdinformationacesujet:
          fields.vdl_jeveuxplusdinformationacesujet.value,

        vdl_nombrederencontresduca: fields.vdl_nombrederencontresduca.value,
        vdl_nombredepresencesalaga: fields.vdl_nombredepresencesalaga.value,

        vdl_datedeladerniereassembleegeneraleannuelle:
          fields.vdl_datedeladerniereassembleegeneraleannuelle.value?.toISOString(),
        vdl_nombredemembresactifsauseindelorganisme:
          fields.vdl_nombredemembresactifsauseindelorganisme.value,
        vdl_duneconcertation: fields.vdl_duneconcertation.value,
        vdl_dunregroupement: fields.vdl_dunregroupement.value,
        vdl_dunefederation: fields.vdl_dunefederation.value,

        vdl_produisezvousannuellementdesprevisionsbudgeta:
          fields.vdl_produisezvousannuellementdesprevisionsbudgeta.value,
        vdl_vosderniersetatsfinanciersontilsfaitlobjet:
          fields.vdl_vosderniersetatsfinanciersontilsfaitlobjet.value,
        vdl_datedefindevotreexercicefinancierannuel:
          fields.vdl_datedefindevotreexercicefinancierannuel.value?.toISOString(),

        vdl_audit: fields.vdl_audit.value,
        vdl_missiondexamen: fields.vdl_missiondexamen.value,
        vdl_verificationcomptable: fields.vdl_verificationcomptable.value,
      },
    };

    if (demand.statuscode === "Inutilisée") {
      _demand.vdl_detailsdelademande.statuscode = "En création";
    }

    const { status, error } = await updateDemand(demand.incidentid, _demand);
    if (status === "success") {
      onUpdateSuccess();
    } else {
      onUpdateFailure(error);
    }
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_lienhautduformulaireportail}
    >
      <SimpleFields
        sectionsBackground="common.white"
        updateRefs
        fields={fields}
        setFields={setFields}
        sections={
          demand.vdl_detailsdelademande.vdl_admissibiliterenouvellement ===
          "Renouvellement"
            ? editDemandAdmissiblityRenouvellementSections
            : editDemandAdmissiblitySections
        }
      />
    </EditDemandLayout>
  );
};

export default Admissibility;
