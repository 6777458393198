/* import React, { useEffect } from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { IGeocodeResponse, geocode } from "@esri/arcgis-rest-geocoding";
import { ApiKeyManager } from "@esri/arcgis-rest-request";
import PlaceIcon from "@mui/icons-material/Place";
import {
  Address,
  ExistingAddress,
  ReturnAddress,
} from "../../../data/common/types";
import { AddressInput } from "../../../types";

type Props = {
  field: AddressInput;
  fieldName: string;
  handleChange: (field: AddressInput) => void;
  disabled: boolean;
};

const FormAddressInput = ({
  field,
  fieldName,
  handleChange,
  disabled,
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState<Address[]>([]);
  const [inputValue, setInputValue] = React.useState("");
  const [emptyText, setEmptyText] = React.useState("Compléter votre addresse");

  function handleValueChange(event: any, newValue: Address | null) {
    let _field = { ...field };
    _field.value = newValue === null ? undefined : newValue;
    if (newValue === null) {
      _field.value = undefined;
    } else {
      _field.value = newValue;
      _field.error = "";
    }
    handleChange(_field);
  }

  useEffect(() => {
    if (inputValue === "") setOptions([]);

    setEmptyText("Compléter votre addresse pour lancer la recherche...");

    const fetchData = async () => {
      try {
        setLoading(true);

        const allo: IGeocodeResponse = await geocode({
          singleLine: inputValue,
          countryCode: "CA",
          authentication: ApiKeyManager.fromKey(
            process.env.REACT_APP_ESRI_API_KEY ?? "AAPK7c5fa65817c147d9a7ab69f8ed0a8d756PnvGTJR8fKlrIzP8zDo_aNO88cRPYdCcCQiuS1d1rweL8K-EjEErAi5Jf2gtV_-"
          ),
          outFields: "*",
        });
        const candidates = allo.candidates as ReturnAddress[];
        setOptions(
          candidates.filter(
            (c) =>
              c.attributes.Addr_type === "PointAddress" ||
              c.attributes.Addr_type === "Subaddress"
          )
        );
        setLoading(false);
      } catch (e) {
        setEmptyText("Erreur dans la récupération des adresses");
      }
    };

    const delay = 1000;
    const debounce = setTimeout(() => {
      if (inputValue) fetchData();
    }, delay);

    return () => {
      clearTimeout(debounce);
    };
  }, [inputValue]);

  return (
    <Autocomplete
      open={open}
      value={field.value === undefined ? null : field.value}
      fullWidth
      filterOptions={(x: Address[]) => x}
      noOptionsText={emptyText}
      clearIcon={null}
      forcePopupIcon={false}
      loading={loading}
      id={fieldName}
      disabled={disabled}
      loadingText="Chargement..."
      isOptionEqualToValue={(
        option: ExistingAddress | ReturnAddress,
        value: ExistingAddress | ReturnAddress
      ) => option.address === value.address}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
        setOptions([]);
      }}
      onChange={handleValueChange}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      getOptionLabel={(option) => option.address}
      renderInput={(params) => (
        <TextField
          {...params}
          label={field.label}
          error={!(field.error === "")}
          helperText={field.error ? field.error : field.helperText}
          required={field.required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default FormAddressInput; */


import React, { useEffect } from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { IGeocodeResponse, geocode } from "@esri/arcgis-rest-geocoding";
import { ApiKeyManager } from "@esri/arcgis-rest-request";
import PlaceIcon from "@mui/icons-material/Place";
import {
  Address,
  ExistingAddress,
  ReturnAddress,
} from "../../../data/common/types";
import { AddressInput } from "../../../types";

type Props = {
  field: AddressInput;
  fieldName: string;
  handleChange: (field: AddressInput) => void;
  disabled: boolean;
};

const FormAddressInput = ({
  field,
  fieldName,
  handleChange,
  disabled,
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState<Address[]>([]);
  const [inputValue, setInputValue] = React.useState("");
  const [emptyText, setEmptyText] = React.useState("Compléter votre addresse");

  function handleValueChange(event: any, newValue: Address | null) {
    let _field = { ...field };
    _field.value = newValue === null ? undefined : newValue;
    if (newValue === null) {
      _field.value = undefined;
    } else {
      _field.value = newValue;
      _field.error = "";
    }
    handleChange(_field);
  }

  useEffect(() => {
    if (inputValue === "") setOptions([]);

    setEmptyText("Compléter votre addresse pour lancer la recherche...");

    const fetchData = async () => {
      try {
        setLoading(true);

        const allo: IGeocodeResponse = await geocode({
          singleLine: inputValue,
          countryCode: "CA",
          authentication: ApiKeyManager.fromKey(
            process.env.REACT_APP_ESRI_API_KEY ?? "AAPK7c5fa65817c147d9a7ab69f8ed0a8d756PnvGTJR8fKlrIzP8zDo_aNO88cRPYdCcCQiuS1d1rweL8K-EjEErAi5Jf2gtV_-"
          ),
          outFields: "*",
        });
        const candidates = allo.candidates as ReturnAddress[];
        setOptions(
          candidates.filter(
            (c) =>
              c.attributes.Addr_type === "PointAddress" ||
              c.attributes.Addr_type === "Subaddress"
          )
        );
        setLoading(false);
      } catch (e) {
        setEmptyText("Erreur dans la récupération des adresses");
      }
    };

    const delay = 1000;
    const debounce = setTimeout(() => {
      if (inputValue) fetchData();
    }, delay);

    return () => {
      clearTimeout(debounce);
    };
  }, [inputValue]);

  return (
    <Autocomplete
      open={open}
      value={field.value === undefined ? null : field.value}
      fullWidth
      filterOptions={(x: Address[]) => x}
      noOptionsText={emptyText}
      clearIcon={null}
      forcePopupIcon={false}
      loading={loading}
      id={fieldName}
      disabled={disabled}
      loadingText="Chargement..."
      isOptionEqualToValue={(
        option: ExistingAddress | ReturnAddress,
        value: ExistingAddress | ReturnAddress
      ) => option.address === value.address}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
        setOptions([]);
      }}
      onChange={handleValueChange}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      getOptionLabel={(option) => option.address}
      renderInput={(params) => (
        <TextField
          {...params}
          label={field.label}
          error={!(field.error === "")}
          helperText={field.error ? field.error : field.helperText}
          required={field.required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default FormAddressInput;