import React, { createContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { getDemandFiles } from "../data/Document/requests";
import { getActivities } from "../data/Activity/requests";
import { DocumentFile } from "../data/Document/models";
import { ActivityRow } from "../data/Activity/models";

type Props = {
  children: React.ReactNode;
};

type ErrorType = {
  files?: string;
  activitites?: string;
};

interface HistoricAttachmentContext {
  isLoading: boolean;
  error: ErrorType;
  demandFiles: DocumentFile[];
  activities: ActivityRow[];
  refreshData: (title: string) => void;
}

export const HistoricAttachmentContext =
  createContext<HistoricAttachmentContext>({
    isLoading: false,
    demandFiles: [],
    activities: [],
    refreshData: async () => {},
    error: {},
  });

const HistoricAttachmentProvider: React.FC<Props> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<ErrorType>({});
  const [activities, setActivities] = useState<ActivityRow[]>([]);
  const [demandFiles, setDemandFiles] = useState<DocumentFile[]>([]);
  const { id } = useParams();

  const fetchActivities = async (title: string) => {
    if (!id) {
      setError({
        files: "Id de la demande invalide",
        activitites: "Id de la demande invalide",
      });
      return;
    }

    setIsLoading(true);

    const [files, history] = await Promise.all([
      getDemandFiles(title),
      getActivities(id),
    ]);

    if (!files.data || files.error) {
      setError({
        ...error,
        files: files.error ?? "Erreur dans la récupération des fichiers",
      });
    } else {
      setDemandFiles(files.data);
    }

    if (!history.data || history.error) {
      setError({
        ...error,
        activitites:
          history.error ?? "Erreur dans la récupération de l'historique",
      });
      setIsLoading(false);
    } else {
      setActivities(history.data);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    //fetchActivities()
  }, []);

  return (
    <HistoricAttachmentContext.Provider
      value={{
        isLoading,
        error,
        demandFiles,
        activities,
        refreshData: fetchActivities,
      }}
    >
      {children}
    </HistoricAttachmentContext.Provider>
  );
};

export default HistoricAttachmentProvider;
