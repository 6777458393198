import React from "react";
import { CommentInput } from "../../../types";
import { Typography } from "@mui/material";

type Props = {
  field: CommentInput;
  fieldName: string;
};

const FormComment = ({ field, fieldName }: Props) => {
  return (
    <Typography marginTop={field.removeMarginTop ? undefined : "12px"}>
      {field.value}
    </Typography>
  );
};

export default FormComment;
