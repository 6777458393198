import { Dayjs } from "dayjs";
import {
  AutoCompleteInput,
  CheckBoxInput,
  CurrencyInput,
  DateInput,
  DateTimeInput,
  DropDownChoice,
  DropDownMultipleOptionSetInput,
  DropDownOptionSetInput,
  EmailInput,
  FileInput,
  FormFields,
  FormSection,
  MultilineInput,
  NumberInput,
  PhoneInput,
  RequiredCondition,
  TextInput,
} from "../../../types";
import { activitiesCalendarDisplayNames } from "../displayNames";

export type EditDemandActivitiesCalendar = {
  vdl_titredelevenement: TextInput;
  vdl_lieudelevenement: TextInput;
  vdl_adressecompletedulieudelevenement: TextInput;
  vdl_veuillezjoindreunephotofile: FileInput;
  vdl_dateetheurededebut: DateTimeInput;
  vdl_dateetheuredefin: DateTimeInput;
  vdl_commentairessurleshorairesdelevenement: MultilineInput;
  vdl_cout: DropDownOptionSetInput;
  vdl_adultes: CurrencyInput;
  vdl_retraites: CurrencyInput;
  vdl_etudiants: CurrencyInput;
  vdl_enfants: CurrencyInput;
  vdl_prixdefamille: CurrencyInput;
  vdl_prixdegroupe: CurrencyInput;
  vdl_autressvpdetaillez2: TextInput;
  vdl_stationnementpayant: CheckBoxInput;
  vdl_coutdestationnement: CurrencyInput;
  vdl_clientelesvisees: DropDownMultipleOptionSetInput;
  vdl_siteinternet: TextInput;
  vdl_pagefacebook: TextInput;
  vdl_courriel: EmailInput;
  vdl_telephonecalendrier: PhoneInput;
  vdl_descriptionsommairedelevenement: MultilineInput;
  vdl_autreinformationpertinente: MultilineInput;
};
const vdl_coutChoices: DropDownChoice[] = [
  {
    value: "Événement gratuit",
  },
  {
    value: "Événement payant",
  },
];
const vdl_clientelesviseesChoices: DropDownChoice[] = [
  {
    value: "Tous",
  },
  {
    value: "Familles",
  },
  {
    value: "Adolescents",
  },
  {
    value: "Aînés",
  },
  {
    value: "Personnes handicapées",
  },
];

const costsDisabled = (fields: FormFields) =>
  fields.vdl_cout.value !== "Événement payant";
const costsRequired: RequiredCondition = {
  validator: (fields: FormFields) =>
    fields.vdl_cout.value === "Événement payant",
  error: "Requis si l'événement est payant",
};

function isDateFinDisabled(fields: FormFields): boolean {
  try {
    const _fields2 = <EditDemandActivitiesCalendar>fields;
    return _fields2.vdl_dateetheurededebut.value === undefined;
  } catch (e) {
    return true;
  }
}

const editDemandActivitiesCalendarFields: EditDemandActivitiesCalendar = {
  vdl_titredelevenement: {
    type: "text",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    label: activitiesCalendarDisplayNames.vdl_titredelevenement,
  },
  vdl_lieudelevenement: {
    type: "text",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    label: activitiesCalendarDisplayNames.vdl_lieudelevenement,
  },
  vdl_adressecompletedulieudelevenement: {
    type: "text",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    maxLength: 300,
    label: activitiesCalendarDisplayNames.vdl_adressecompletedulieudelevenement,
  },
  vdl_veuillezjoindreunephotofile: {
    type: "file",
    error: "",
    value: undefined,
    required: true,
    fillLine: true,
    accept: [".gif", ".png", ".jpg", ".PNG", ".jpeg"],
    label: activitiesCalendarDisplayNames.vdl_veuillezjoindreunephotofile,
    helperText: "(ne peut pas être une affiche ou un logo)",
  },
  vdl_dateetheurededebut: {
    type: "datetime",
    error: "",
    value: undefined,
    required: false,
    disablePast: true,
    label: activitiesCalendarDisplayNames.vdl_dateetheurededebut,
    onChangeEvent: (fields: FormFields): FormFields | undefined => {
      try {
        const _fields2 = <EditDemandActivitiesCalendar>fields;
        return {
          vdl_dateetheuredefin: {
            ..._fields2.vdl_dateetheuredefin,
            value:
              _fields2.vdl_dateetheuredefin.value &&
              _fields2.vdl_dateetheuredefin.value.isBefore(
                _fields2.vdl_dateetheurededebut.value as Dayjs
              )
                ? undefined
                : _fields2.vdl_dateetheuredefin.value,
          },
        };
      } catch (e) {
        return undefined;
      }
    },
  },
  vdl_dateetheuredefin: {
    type: "datetime",
    error: "",
    value: undefined,
    required: false,
    disablePast: true,
    disabledCondition: isDateFinDisabled,
    label: activitiesCalendarDisplayNames.vdl_dateetheuredefin,
    minDate: (fields: FormFields) =>
      (fields.vdl_dateetheurededebut.value as Dayjs)
        ? (fields.vdl_dateetheurededebut.value as Dayjs)
        : undefined,
  },
  vdl_commentairessurleshorairesdelevenement: {
    type: "multiline",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    maxLength: 300,
    rows: {
      min: 2,
      max: 2,
    },
    label:
      activitiesCalendarDisplayNames.vdl_commentairessurleshorairesdelevenement,
  },
  vdl_cout: {
    type: "optionset",
    required: true,
    error: "",
    value: undefined,
    fillLine: true,
    choices: vdl_coutChoices,
    label: activitiesCalendarDisplayNames.vdl_cout,
  },
  vdl_adultes: {
    type: "currency",
    error: "",
    value: "",
    min: 0,
    max: 99999,
    disabledCondition: costsDisabled,
    requiredCondition: costsRequired,
    label: activitiesCalendarDisplayNames.vdl_adultes,
  },
  vdl_retraites: {
    type: "currency",
    error: "",
    value: "",
    min: 0,
    max: 99999,
    disabledCondition: costsDisabled,
    requiredCondition: costsRequired,
    label: activitiesCalendarDisplayNames.vdl_retraites,
  },
  vdl_etudiants: {
    type: "currency",
    error: "",
    value: "",
    min: 0,
    max: 99999,
    disabledCondition: costsDisabled,
    requiredCondition: costsRequired,
    label: activitiesCalendarDisplayNames.vdl_etudiants,
  },
  vdl_enfants: {
    type: "currency",
    error: "",
    value: "",
    min: 0,
    max: 99999,
    disabledCondition: costsDisabled,
    requiredCondition: costsRequired,
    label: activitiesCalendarDisplayNames.vdl_enfants,
  },
  vdl_prixdefamille: {
    type: "currency",
    error: "",
    value: "",
    min: 0,
    max: 99999,
    disabledCondition: costsDisabled,
    requiredCondition: costsRequired,
    label: activitiesCalendarDisplayNames.vdl_prixdefamille,
  },
  vdl_prixdegroupe: {
    type: "currency",
    error: "",
    value: "",
    min: 0,
    max: 99999,
    disabledCondition: costsDisabled,
    requiredCondition: costsRequired,
    label: activitiesCalendarDisplayNames.vdl_prixdegroupe,
  },
  vdl_autressvpdetaillez2: {
    type: "text",
    error: "",
    value: "",
    fillLine: true,
    label: activitiesCalendarDisplayNames.vdl_autressvpdetaillez2,
  },
  vdl_stationnementpayant: {
    type: "checkbox",
    error: "",
    value: false,
    reversed: true,
    onChangeEvent: onStationnementPayantChange,
    label: activitiesCalendarDisplayNames.vdl_stationnementpayant,
  },
  vdl_coutdestationnement: {
    type: "currency",
    error: "",
    value: "",
    disabledCondition: (fields) =>
      fields.vdl_stationnementpayant.value === false,
    min: 0,
    max: 99999,
    requiredCondition: {
      validator: (fields: FormFields) => fields.vdl_stationnementpayant.value,
      error: "Requis si le stationnement est payant",
    },
    label: activitiesCalendarDisplayNames.vdl_coutdestationnement,
  },
  vdl_clientelesvisees: {
    type: "multipleoptionset",
    error: "",
    value: [],
    fillLine: true,
    required: true,
    choices: vdl_clientelesviseesChoices,
    label: activitiesCalendarDisplayNames.vdl_clientelesvisees,
  },
  vdl_siteinternet: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    label: activitiesCalendarDisplayNames.vdl_siteinternet,
  },
  vdl_pagefacebook: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    label: activitiesCalendarDisplayNames.vdl_pagefacebook,
  },
  vdl_courriel: {
    type: "email",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    label: activitiesCalendarDisplayNames.vdl_courriel,
  },
  vdl_telephonecalendrier: {
    type: "phone",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    label: activitiesCalendarDisplayNames.vdl_telephonecalendrier,
  },
  vdl_descriptionsommairedelevenement: {
    type: "multiline",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    maxLength: 500,
    rows: {
      min: 3,
      max: 3,
    },
    label: activitiesCalendarDisplayNames.vdl_descriptionsommairedelevenement,
  },
  vdl_autreinformationpertinente: {
    type: "multiline",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    maxLength: 500,
    rows: {
      min: 3,
      max: 3,
    },
    label: activitiesCalendarDisplayNames.vdl_autreinformationpertinente,
  },
};

function onStationnementPayantChange(
  fields: FormFields
): FormFields | undefined {
  try {
    const _fields2 = <EditDemandActivitiesCalendar>fields;
    return {
      vdl_coutdestationnement: {
        ..._fields2.vdl_coutdestationnement,
        value: "",
        error: "",
      },
    };
  } catch (e) {
    return undefined;
  }
}

const editDemandActivitiesCalendarInfoSection: FormSection<EditDemandActivitiesCalendar> =
  {
    fields: [
      "vdl_titredelevenement",
      "vdl_lieudelevenement",
      "vdl_adressecompletedulieudelevenement",
    ],
    title: "Informations générales",
  };
const editDemandActivitiesCalendarPhotoSection: FormSection<EditDemandActivitiesCalendar> =
  {
    fields: ["vdl_veuillezjoindreunephotofile"],
    title: "Image",
  };
const editDemandActivitiesCalendarDateSection: FormSection<EditDemandActivitiesCalendar> =
  {
    fields: [
      "vdl_dateetheurededebut",
      "vdl_dateetheuredefin",
      "vdl_commentairessurleshorairesdelevenement",
    ],
    title: "Date(s) de l'évènement",
  };
const editDemandeActivitiesCalendarCostSection: FormSection<EditDemandActivitiesCalendar> =
  {
    fields: [
      "vdl_clientelesvisees",
      "vdl_cout",
      "vdl_adultes",
      "vdl_retraites",
      "vdl_etudiants",
      "vdl_enfants",
      "vdl_prixdefamille",
      "vdl_prixdegroupe",
      "vdl_autressvpdetaillez2",
      "vdl_stationnementpayant",
      "vdl_coutdestationnement",
    ],
    title: "Détail des tarifs offerts",
  };
const editDemandeActivitiesCalendarMoreInfoSection: FormSection<EditDemandActivitiesCalendar> =
  {
    fields: [
      "vdl_siteinternet",
      "vdl_pagefacebook",
      "vdl_courriel",
      "vdl_telephonecalendrier",
      "vdl_descriptionsommairedelevenement",
      "vdl_autreinformationpertinente",
    ],
    title: "Pour information sur l'événement ou l'organisme",
  };
const editDemandActivitiesCalendarSections: FormSection<EditDemandActivitiesCalendar>[] =
  [
    editDemandActivitiesCalendarInfoSection,
    editDemandActivitiesCalendarPhotoSection,
    editDemandActivitiesCalendarDateSection,
    editDemandeActivitiesCalendarCostSection,
    editDemandeActivitiesCalendarMoreInfoSection,
  ];

export {
  editDemandActivitiesCalendarFields,
  editDemandActivitiesCalendarSections,
};
