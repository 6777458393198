import React, { useState } from "react";
import {
  DemandEventSupportPatch,
  DemandEventSupportRow,
} from "../../../../data/Demand/models";
import { NewDemandFields } from "../../../../data/Demand/forms/dialog";
import { updateDemand } from "../../../../data/Demand/requests";
import EditDemandLayout from "../EditDemandLayout";
import SimpleFields from "../../../form/SimpleForm/SimpleFields";
import handleSimpleFormSubmit from "../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../hooks/useTabs";
import {
  EditDemandEventSupportFields,
  editDemandEventSupportFields,
  editDemandEventSupportSections,
} from "../../../../data/Demand/forms/eventSupport";

type Props = {
  demand: DemandEventSupportRow;
  isNewDemand: boolean;
  fieldsGeneral: NewDemandFields;
  onUpdateSuccess: () => void;
  onUpdateFailure: (error?: string) => void;
  setFieldsGeneral: React.Dispatch<React.SetStateAction<NewDemandFields>>;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const EventSupport = ({
  demand,
  isNewDemand,
  fieldsGeneral,
  setFieldsGeneral,
  onUpdateSuccess,
  onUpdateFailure,
  setError,
}: Props) => {
  const details = demand.vdl_detailsdelademande;
  const [fields, setFields] = useState<EditDemandEventSupportFields>(
    buildform(editDemandEventSupportFields, {
      vdl_amenagementelectriquetemporaire:
        details.vdl_amenagementelectriquetemporaire ?? false,
      vdl_typedappareil: details.vdl_typedappareil ?? "",
      vdl_nombredeprise: details.vdl_nombredeprise ?? "",
      vdl_intensiteetvoltage: details.vdl_intensiteetvoltage ?? "",
      vdl_typedappareil2: details.vdl_typedappareil2 ?? "",
      vdl_nombredeprise2: details.vdl_nombredeprise2 ?? "",
      vdl_intensiteetvoltage2: details.vdl_intensiteetvoltage2 ?? "",
      vdl_typedappareil3: details.vdl_typedappareil3 ?? "",
      vdl_nombredeprise3: details.vdl_nombredeprise3 ?? "",
      vdl_intensiteetvoltage3: details.vdl_intensiteetvoltage3 ?? "",
      vdl_descriptionadditionneldamenagementelectrique:
        details.vdl_descriptionadditionneldamenagementelectrique ?? "",
    })
  );
  const { activeTab, changeTab } = useTabs();

  const onSubmit = async () => {
    const errors = handleSimpleFormSubmit(fields, setFields);

    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      const scrollTo = fields[errors[errors.length - 1]].fieldRef;
      if (scrollTo) {
        scrollTo.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
      return;
    }

    const _demand: DemandEventSupportPatch = {
      title: fieldsGeneral.title.value,
      vdl_contactprincipal: fieldsGeneral.vdl_contactprincipal.value,
      type: demand.type,
      vdl_detailsdelademande: {
        vdl_amenagementelectriquetemporaire:
          fields.vdl_amenagementelectriquetemporaire.value,

        vdl_typedappareil: fields.vdl_typedappareil.value,
        vdl_nombredeprise: fields.vdl_nombredeprise.value,
        vdl_intensiteetvoltage: fields.vdl_intensiteetvoltage.value,

        vdl_typedappareil2: fields.vdl_typedappareil2.value,
        vdl_nombredeprise2: fields.vdl_nombredeprise2.value,
        vdl_intensiteetvoltage2: fields.vdl_intensiteetvoltage2.value,

        vdl_typedappareil3: fields.vdl_typedappareil3.value,
        vdl_nombredeprise3: fields.vdl_nombredeprise3.value,
        vdl_intensiteetvoltage3: fields.vdl_intensiteetvoltage3.value,

        vdl_descriptionadditionneldamenagementelectrique:
          fields.vdl_descriptionadditionneldamenagementelectrique.value,
      },
    };

    if (demand.statuscode === "Inutilisée") {
      _demand.vdl_detailsdelademande.statuscode = "En création";
    }

    const { status, error } = await updateDemand(demand.incidentid, _demand);
    if (status === "success") {
      onUpdateSuccess();
    } else {
      onUpdateFailure(error);
    }
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_lienhautduformulaireportail}
    >
      <SimpleFields
        updateRefs
        fields={fields}
        setFields={setFields}
        columns={{ minWidth: 260, count: 3 }}
        sections={editDemandEventSupportSections}
        sectionsBackground="common.white"
      />
    </EditDemandLayout>
  );
};

export default EventSupport;
function buildform(
  editDemandEventSupportFields: EditDemandEventSupportFields,
  arg1: {
    vdl_amenagementelectriquetemporaire: boolean;
    vdl_typedappareil: string;
    vdl_nombredeprise: string;
    vdl_intensiteetvoltage: string;
    vdl_typedappareil2: string;
    vdl_nombredeprise2: string;
    vdl_intensiteetvoltage2: string;
    vdl_typedappareil3: string;
    vdl_nombredeprise3: string;
    vdl_intensiteetvoltage3: string;
    vdl_descriptionadditionneldamenagementelectrique: string;
  }
): EditDemandEventSupportFields | (() => EditDemandEventSupportFields) {
  throw new Error("Function not implemented.");
}
