import { Box, CircularProgress, Fade, styled } from "@mui/material";
import React from "react";

type Props = {
    loading: boolean
}

const LoadingBox = styled(Box)(({ theme }) => ({
  zIndex: "5",
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "grid",
  justifyContent: "center",
  alignItems: "center",
  background: "radial-gradient(ellipse at center, rgba(0,0,0,0.2) -100%, rgba(0,0,0,0) 100%);",
  backgroundSize: "100% 100%"
}));

const SimpleFormLoading = ({loading} : Props) => {
  return (
    <Fade in={loading} timeout={600}>
      <LoadingBox>
        <CircularProgress />
      </LoadingBox>
    </Fade>
  );
};

export default SimpleFormLoading;
