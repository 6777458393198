export default function convertIsoNumberToNumber(iso: string) : number | undefined {
    if(iso.trim() === "") return;

    const numericString = iso.replace(/[^\d.,]/g, '');
  
    const normalizedString = numericString.replace(',', '.');
  
    const numberValue = parseFloat(normalizedString);
  
    return isNaN(numberValue) ? undefined : numberValue;
}