import React from "react";
import { MultipleFilesInput } from "../../../types";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Delete, FileUploadOutlined } from "@mui/icons-material";
import ClickableIcon from "../../common/buttons/icons/ClickableIcon";

type Props = {
  field: MultipleFilesInput;
  fieldName: string;
  handleChange: (field: MultipleFilesInput) => void;
  disabled: boolean;
};

const FormMultipleFilesInput = ({
  field,
  fieldName,
  handleChange,
  disabled,
}: Props) => {
  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {

    const files = event.target.files;
    let _field = { ...field };
    const _oldFiles = [..._field.value];
    if (files !== null) {
      if (field.maxLength === 1) {
        const file = files.item(0);
        if (file) {
          _oldFiles[0] = file;
          _field.error = "";
          _field.changed = true;
        }
      } else {
        for (var i = 0; i < files.length; i++) {
          const file = files.item(i);
          if (!file) continue;

          if(field.accept && !field.accept.some(ext => file.name.toLowerCase().endsWith(ext.toLowerCase()))) {
            continue;
          }

          if (_oldFiles.length >= field.maxLength) {
            _field.error = "Nombre maximum de fichiers atteint";
            break;
          }

          var found = false;
          for (var a = 0; a < _oldFiles.length; a++) {
            if (file.name === _oldFiles[a].name) {
              found = true;
              break;
            }
          }

          if (found) {
            _field.error = "Les fichies doivent être uniques";
            break;
          }
          _oldFiles.push(file);
          _field.error = "";
          _field.changed = true;
        }
      }
    }
    _field.value = _oldFiles;
    handleChange(_field);
  }

  function handleDelete(idx: number) {
    let _field = { ...field };
    _field.value = [];
    handleChange(_field);
  }

  return (
    <FormControl error={!(field.error == "")}>
      <Box display="flex" gap="16px" alignItems="center">
        {field.labelComponent ? (
          field.labelComponent({ label: field.label })
        ) : (
          <FormLabel required={field.required}>{field.label}</FormLabel>
        )}
        <IconButton component="label" sx={{ pointerEvents: "auto" }}>
          <FileUploadOutlined />
          <input
            type="file"
            hidden
            multiple={true}
            accept={field.accept ? field.accept.join(", ") : undefined}
            name={fieldName + "_file"}
            onChange={handleInputChange}
          />
        </IconButton>
      </Box>

      {field.error || field.helperText ? (
        <FormHelperText>
          {field.error ? field.error : field.helperText}
        </FormHelperText>
      ) : null}
      <Box>
        {field.value.map((file, idx) => (
          <Box
            display="grid"
            gridAutoFlow="column"
            justifyContent="start"
            alignItems="center"
            gap="8px"
            padding="4px"
            key={file.name}
          >
            <ClickableIcon
              icon={<Delete />}
              size="small"
              handleClick={() => {
                handleDelete(idx);
              }}
            />
            <Typography lineHeight={1}>{file.name}</Typography>
          </Box>
        ))}
      </Box>
    </FormControl>
  );
};

export default FormMultipleFilesInput;
