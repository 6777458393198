import React from "react";
import { Role } from "../../../types"
import useUser from "../../../hooks/useUserData";

type Props = {
  roleMinimum: Role;
  children: React.ReactNode
};
const PrivateComponent = ({roleMinimum, children} : Props) => {
    const {userHasRole} = useUser()
  const hasAccess = userHasRole(roleMinimum)
  return <>{hasAccess ? children : null}</>;
};

export default PrivateComponent;
