import { Box } from "@mui/material";
import React from "react";
import InfoRequest from "./InfoRequest/InfoRequest";
import { DemandRow } from "../../../../data/Demand/models";
import StandardEquipment from "./StandardEquipment/StandardEquipment";
import Fundraiser from "./Fundraiser/Fundraiser";
import Prisme from "./Prisme/Prisme";
import WebsiteDisplay from "./WebsiteDisplay/WebsiteDisplay";
import TelevisionDisplay from "./TelevisionDisplay/TelevisionDisplay";
import ElectronicPanel from "./ElectronicPanel/ElectronicPanel";
import ActivitiesCalendar from "./ActivitiesCalendar/ActivitiesCalendar";
import PublicSpace from "./PublicSpace/PublicSpace";
import Admissibility from "./Admissibility/Admissibility";
import PublicRoad from "./PublicRoad/PublicRoad";
import SpecialEvent from "./SpecialEvents/SpecialEvent/SpecialEvent";
import EcoSupport from "./EcoSupport/EcoSupport";
import Permit from "./Permit/Permit";
import SecurityPrevention from "./SecurityPrevention/SecurityPrevention";
import Filming from "./SpecialEvents/Filming/Filming";
import EventSupport from "./EventSupport/EventSupport";

type Props = {
  demand: DemandRow;
};

const SpecificTab = ({ demand }: Props) => {
  function renderDemandType() {
    switch (demand.type) {
      case "Demande d'information":
        return <InfoRequest demand={demand} />;
      case "Prisme":
        return <Prisme demand={demand} />;
      case "Équipement spécialisé":
      case "Équipement standard":
        return <StandardEquipment demand={demand} />;
      case "Collecte d'argent":
        return <Fundraiser demand={demand} />;
      case "Calendrier des activités":
        return <ActivitiesCalendar demand={demand} />;
      case "Panneaux électroniques":
        return <ElectronicPanel demand={demand} />;
      case "Télévision communautaire":
        return <TelevisionDisplay demand={demand} />;
      case "Site web de la ville":
        return <WebsiteDisplay demand={demand} />;
      case "Déposer une demande d'admissibilité":
      case "Demande de renouvellement":
        return <Admissibility demand={demand} />;
      case "Location ou prêt d'espaces municipaux":
        return <PublicSpace demand={demand} />;
      case "Voie publique":
        return <PublicRoad demand={demand} />;
      case "Sécurité prévention":
        return <SecurityPrevention demand={demand} />;
      case "Permis":
        return <Permit demand={demand} />;
      case "Soutien Éco-Responsable":
        return <EcoSupport demand={demand} />;
      case "Soutien événement":
        return <EventSupport demand={demand} />;
      case "Évènement spécial":
        return <SpecialEvent demand={demand} />;
      case "Tournage":
        return <Filming demand={demand} />;
    }
  }

  return <Box>{renderDemandType()}</Box>;
};

export default SpecificTab;
