import { Dialog } from "@mui/material";
import React, { useState } from "react";
import handleSimpleFormSubmit from "../../../../../../form/SimpleForm/handleSimpleFormSubmit";
import {
  NewActivityFields,
  newActivityFields,
} from "../../../../../../../data/Activity/forms/new";
import useUser from "../../../../../../../hooks/useUserData";
import { useSnackbar } from "../../../../../../../hooks/useSnackbar";
import CustomDialogHeader from "../../../../../../dialog/CustomDialogHeader/CustomDialogHeader";
import clone from "just-clone";
import { fileToBase64 } from "../../../../../../../data/common/fileToBase64";
import { Base64FilePost } from "../../../../../../../data/common/types";
import { createActivity } from "../../../../../../../data/Activity/requests";
import SimpleFormWithSectionsWithState from "../../../../../../form/SimpleForm/SimpleFormWithSectionsWithState";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleAddActivity: () => Promise<void>;
  demandid: string;
};

const NewMessageDialog = ({
  open,
  setOpen,
  handleAddActivity,
  demandid,
}: Props) => {
  const [fields, setFields] = useState<NewActivityFields>(
    clone(newActivityFields)
  );
  const [isFormLoading, setIsFormLoading] = useState(false);
  const { userData } = useUser();
  const { showSnackbar } = useSnackbar();

  async function handleSubmit() {
    try {
      setIsFormLoading(true);
      const errors = handleSimpleFormSubmit(fields, setFields);

      if (errors.length < 1 && fields.vdl_typedinformation.value) {
        const tempFiles: Base64FilePost[] = [];
        fields.files.value.forEach(async (file) => {
          const pieceJointeToBase = await fileToBase64(file);
          if (pieceJointeToBase) {
            tempFiles.push({
              filename: file.name,
              content: pieceJointeToBase,
            });
          }
        });
        const activityResponse = await createActivity(demandid, {
          subject: fields.subject.value,
          description: fields.description.value,
          vdl_expediteur: userData.contactid,
          vdl_typedinformation: fields.vdl_typedinformation.value,
          files: tempFiles,
        });

        if (activityResponse.status === "success") {
          await handleAddActivity();
        } else {
          throw new Error("Erreur dans la requête");
        }
      } else {
        showSnackbar("Vous avez des champs en erreur", "error");
      }
    } catch (e) {
      let message = "Erreur inconnue";
      if (e instanceof Error) message = e.message;
      showSnackbar(message, "error");
    } finally {
      setIsFormLoading(false);
    }
  }
  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <CustomDialogHeader title="Ajouter une information" setOpen={setOpen} />
        <SimpleFormWithSectionsWithState
          fields={fields}
          setFields={setFields}
          handleSuccess={handleSubmit}
          isFormLoading={isFormLoading}
          submitText="Envoyer"
        />
    </Dialog>
  );
};

export default NewMessageDialog;
