import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  useTheme,
  Skeleton,
  styled,
  CircularProgress,
} from "@mui/material";
import SectionLoading from "../../common/loading/SectionLoading";
import CenteredBox from "../../common/boxes/CenteredBox";

const TopSection = styled(Box)((theme) => ({
  display: "flex",
  flexDirection: "column",
  padding: "10px 10px 20px 30px",
  justifyContent: "center",
  gap: "6px",
  "@media (max-width:600px)": {
    padding: "10px 15px 20px 26px",
  },
}));

type Props = {
  children?: React.ReactNode;
  isLoading?: boolean;
  title: string;
  loadingSpinner?: Boolean;
  globalLoading?: Boolean;
  underTitleComponent?: JSX.Element;
  sideTitleComponent?: JSX.Element;
};

const PageTitleLayout = ({
  children,
  title,
  isLoading,
  underTitleComponent,
  sideTitleComponent,
  loadingSpinner,
  globalLoading,
}: Props) => {
  const theme = useTheme();
  const [isSticky, setIsSticky] = useState(false);
  const [boxHeight, setBoxHeight] = useState("40px");
  const [initialBoxHeight, setInitialBoxHeight] = useState("0px");
  const boxRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    const topSection = document.getElementById("top-section");
    if (topSection) {
      const newIsSticky = window.scrollY > 70;
      setIsSticky(newIsSticky);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (boxRef.current) {
      setInitialBoxHeight(`${boxRef.current.offsetHeight}px`);
    }
  }, []);

  useEffect(() => {
    if (isSticky) {
      setBoxHeight("80px");
    } else {
      setBoxHeight(initialBoxHeight);
    }
  }, [isSticky, initialBoxHeight]);

  return (
    <Box sx={{ backgroundColor: theme.custom.appLayout.backgroundColor }}>
      <TopSection
        id="top-section"
        theme={theme}
        sx={{
          ...theme.custom.pageLayoutWithTitle,
          position: "sticky",
          top: "0",
          zIndex: 10,
          transition: "height 0.1s ease-in-out",
          ...(isSticky && { overflow: "hidden" }),
        }}
      >
        <Box
          ref={boxRef}
          sx={{
            height: boxHeight,
            transition: "height 0.1s ease-in-out",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            padding: 0,
            alignItems: "center",
            rowGap: "6px",
            columnGap: "20px",
          }}
        >
          <Typography variant="h4">
            {isLoading ? <Skeleton width={"325px"} height={"60px"} /> : title}
          </Typography>
          {sideTitleComponent ? sideTitleComponent : null}
        </Box>
        {isSticky ? null : (
          <Box padding={0} minHeight={"20px"}>
            {underTitleComponent ? underTitleComponent : null}
          </Box>
        )}
      </TopSection>
      {loadingSpinner ? (
        <Box paddingTop={"1rem"}>
          <SectionLoading />
        </Box>
      ) : (
        <Box maxWidth={2000}>{children}</Box>
      )}
      {globalLoading ? (
        <Box
          position="fixed"
          top={0}
          left={0}
          height="100%"
          width="100%"
          bgcolor="RGBA(255,255,255,0.4)"
          zIndex={1000}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            padding="32px"
            borderRadius="50%"
            minHeight="180px"
            minWidth="180px"
          >
            <CircularProgress id="circularProgress" color="primary" />
            <Typography
              sx={{
                padding: "8px",
                background: "linear-gradient(transparent 0%, white 20%, white 80%, transparent 100%);",
                borderRadius: "20px",
              }}
            >
              Chargement...
            </Typography>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default PageTitleLayout;
