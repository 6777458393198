import { Box, Typography, IconButton, useTheme } from "@mui/material";
import { SocialIcon } from "react-social-icons";
import Logo from "../../common/images/laval-logo-renverse.svg";
import SimpleExternalLink from "../../common/links/SimpleExternalLink";

const socialMediaLinks = [
  { url: "https://www.facebook.com/villedelavalpageofficielle" },
  { url: "https://x.com/villedelavalqc" },
  { url: "https://www.youtube.com/villedelavalqc" },
  { url: "https://www.instagram.com/villedelavalqc/" },
  { url: "https://ca.linkedin.com/company/ville-de-laval" },
];

const Footer = () => {
  const theme = useTheme();
  const currentYear = new Date().getFullYear();

  const renderSocialIcons = () => {
    return socialMediaLinks.map((link, index) => (
      <IconButton
        key={index}
        color="inherit"
        sx={{ padding: 0, marginRight: 0.5 }}
      >
        <SocialIcon
          style={{ height: 35, width: 35 }}
          bgColor="white"
          fgColor="black"
          url={link.url}
          target="_blank"
        />
      </IconButton>
    ));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        minHeight: "80px",
        gap: "8px",
        justifyContent: "space-between",
        backgroundColor: "black",
        color: "white",
        "& a": {
          color: "#8db7e1",
          textDecoration: "none",
          marginLeft: theme.spacing(1),
        },
        alignItems: "center",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box
          component="img"
          marginTop="-16px"
          width={70}
          alt="Logo"
          src={Logo}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Typography variant="body2" ml={1} textAlign="left">
            {currentYear} Ville de Laval. Tous droits réservés.{" "}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <SimpleExternalLink to="https://google.com" text="Accessibilité" />
            <SimpleExternalLink
              to="https://google.com"
              text="Politique de confidentialité"
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        {renderSocialIcons()}
      </Box>
    </Box>
  );
};

export default Footer;
