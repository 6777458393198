import { Box, Collapse, Typography } from "@mui/material";
import React, { useState } from "react";
import { DocumentFolder } from "../../../data/Document/models";
import { ChevronRight, ExpandMore, Folder } from "@mui/icons-material";
import DownloadbleFile from "./DownloadableFile";

type Props = {
  folder: DocumentFolder;
  level: number;
};

const styles = {
  button: {
    cursor: "pointer",
    background: "#f1f1f1",
    userSelect: "none",
    "&:hover": {
      background: "lightgray",
    },
  },
};

const ExpandableFolder = ({ folder, level }: Props) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("");

  return (
    <Box display="grid" gap="2px">
      <Box
        display="flex"
        flexDirection="row"
        gap="8px"
        onClick={() => setOpen(!open)}
        sx={{ ...styles.button }}
      >
        {open ? <ExpandMore /> : <ChevronRight />}
        <Folder />
        <Typography>{folder.name}</Typography>
      </Box>
      <Collapse
        in={open}
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: `${level + 1 * 16}px`,
        }}
      >
        {folder.files.map((file, index) => {
          if (file.type === "folder") {
            return (
              <ExpandableFolder folder={file} level={level + 1} key={index} />
            );
          } else {
            return <DownloadbleFile file={file} key={index}/>;
          }
        })}
      </Collapse>
    </Box>
  );
};

export default ExpandableFolder;
