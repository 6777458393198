import accountDisplayNames from "../../data/Account/displayNames";
import { vdl_secteurChoices } from "../../data/Account/forms/optionSetChoices";
import {
  AddressInput,
  CheckBoxInput,
  DropDownLookUpInput,
  DropDownOptionSetInput,
  EmailInput,
  FormFields,
  FormSection,
  PhoneInput,
  TextInput,
} from "../../types";

export type NewAccountDisplayFields = {
  name: TextInput;
  vdl_autresnomorganisme: TextInput;
  vdl_typedorganisation: DropDownLookUpInput;
  vdl_neq: TextInput;
  telephone1: PhoneInput;
  emailaddress1: EmailInput;
  websiteurl: TextInput;
  vdl_pagefacebook: TextInput;

  vdl_secteursiegesocial: DropDownOptionSetInput;
  registeredAddressSearch: AddressInput; //TextInput;

  vdl_adresseidentique: CheckBoxInput;
  mailingAddressSearch: AddressInput; //TextInput;
};

const sectionGeneralInformations: FormSection<NewAccountDisplayFields> = {
  fields: [
    "name",
    "vdl_autresnomorganisme",
    "vdl_typedorganisation",
    "vdl_neq",
    "telephone1",
    "emailaddress1",
    "websiteurl",
    "vdl_pagefacebook",
  ],
  title: "Informations générales",
};

const sectionRegisteredAddress: FormSection<NewAccountDisplayFields> = {
  fields: ["vdl_secteursiegesocial", "registeredAddressSearch"],
  title: "Adresse du siège social",
};

const sectionMailingAddress: FormSection<NewAccountDisplayFields> = {
  fields: ["vdl_adresseidentique", "mailingAddressSearch"],
  title: "Adresse de correspondance",
};

const newAccountSections: FormSection<NewAccountDisplayFields>[] = [
  sectionGeneralInformations,
  sectionRegisteredAddress,
  sectionMailingAddress,
];

const newAccountFields: NewAccountDisplayFields = {
  name: {
    type: "text",
    value: "",
    error: "",
    required: true,
    label: accountDisplayNames.name,
  },
  vdl_autresnomorganisme: {
    type: "text",
    value: "",
    error: "",
    required: false,
    label: accountDisplayNames.vdl_autresnomorganisme,
  },
  vdl_typedorganisation: {
    type: "lookup",
    value: "",
    error: "",
    choices: [],
    required: true,
    label: accountDisplayNames.vdl_typedorganisation,
  },
  vdl_neq: {
    type: "text",
    value: "",
    error: "",
    required: false,
    label: accountDisplayNames.vdl_neq,
  },
  telephone1: {
    type: "phone",
    value: "",
    error: "",
    required: true,
    label: accountDisplayNames.telephone1,
  },
  emailaddress1: {
    type: "email",
    value: "",
    error: "",
    required: true,
    label: accountDisplayNames.emailaddress1,
  },
  websiteurl: {
    type: "text",
    value: "",
    error: "",
    required: false,
    label: accountDisplayNames.websiteurl,
  },
  vdl_pagefacebook: {
    type: "text",
    value: "",
    error: "",
    required: false,
    label: accountDisplayNames.vdl_pagefacebook,
  },

  vdl_secteursiegesocial: {
    type: "optionset",
    error: "",
    value: "",
    choices: vdl_secteurChoices,
    required: true,
    fillLine: true,
    label: accountDisplayNames.vdl_secteursiegesocial,
  },
  registeredAddressSearch: {
    type: "address", //"text",
    value: undefined, //"",
    error: "",
    required: true,
    fillLine: true,
    label: "Recherche d'adresse",
    onChangeEvent: updateMailingAddress,
  },
  vdl_adresseidentique: {
    type: "checkbox",
    value: false,
    error: "",
    required: false,
    fillLine: true,
    label: accountDisplayNames.vdl_adresseidentique,
    onChangeEvent: onSameAddressChange,
  },
  mailingAddressSearch: {
    type: "address", //"text",
    value: undefined, //"",
    error: "",
    required: true,
    fillLine: true,
    label: "Recherche d'adresse",
    disabledCondition: isSameAddress,
  },
};

function isSameAddress(fields: FormFields): boolean {
  const editFields = fields as NewAccountDisplayFields;
  return editFields.vdl_adresseidentique.value;
}

function onSameAddressChange(fields: FormFields): FormFields {
  try {
    const addressFields = <NewAccountDisplayFields>fields;
    let _fields: Partial<NewAccountDisplayFields> = {};
    _fields.mailingAddressSearch = {
      ...addressFields.mailingAddressSearch,
      error: "",
      value: addressFields.registeredAddressSearch.value,
    };

    return _fields;
  } catch (e) {
    return {};
  }
}

function updateMailingAddress(fields: FormFields): FormFields {
  try {
    const addressFields = <NewAccountDisplayFields>fields;
    let _fields: Partial<NewAccountDisplayFields> = {};
    if (addressFields.vdl_adresseidentique.value) {
      _fields.mailingAddressSearch = {
        ...addressFields.mailingAddressSearch,
        error: "",
        value: addressFields.registeredAddressSearch.value,
      };
    }
    return _fields;
  } catch (e) {
    return {};
  }
}

const newAccountForm = {
  fields: newAccountFields,
  sections: newAccountSections,
};

export { newAccountSections, newAccountFields, newAccountForm };
