import { Box, Typography } from "@mui/material";
import { specialEventsGeneralDisplayNames } from "../../../../../data/Demand/displayNames";
import DisplayFieldCard from "../../../../common/cards/DisplayFieldCard";
import { DemandSpecialEventsDetailsGeneralRow } from "../../../../../data/Demand/types/specialEvents/specialEventsGeneral";
import { dateStringToShortString } from "../../../../../data/common/fieldToString";
import BoxResponsivePadding from "../../../../common/boxes/BoxResponsivePadding";
import DateCard from "./DateCard";

type Props = {
  details: DemandSpecialEventsDetailsGeneralRow;
};

const SpecialEvents = ({ details }: Props) => {
  return (
    <Box display="grid" gap="16px">
      <DisplayFieldCard
        title="Commun"
        fields={[
          {
            label: specialEventsGeneralDisplayNames.vdl_lieudelevenement,
            value: details.vdl_lieudelevenement,
            type: "string",
          },
          {
            label: specialEventsGeneralDisplayNames.vdl_precisionsurlelieu,
            value: details.vdl_precisionsurlelieu,
            type: "string",
          },
          {
            label: specialEventsGeneralDisplayNames.vdl_siautreprecisezlieu,
            value: details.vdl_siautreprecisezlieu,
            type: "string",
          },
          {
            label:
              specialEventsGeneralDisplayNames.vdl_encasdintemperieslevenementest,
            value: details.vdl_encasdintemperieslevenementest?.join(", "),
            type: "string",
          },
          {
            label: specialEventsGeneralDisplayNames.vdl_siautreprecisezlieu,
            value: dateStringToShortString(details.vdl_siautreprecisezlieu),
            type: "string",
          },
        ]}
        columns={1}
        minColWidth={300}
      />
      <Box>
        <BoxResponsivePadding
          bgcolor="background.paper"
          display="grid"
          gap="16px"
        >
          <Typography
            variant="h6"
            fontWeight="bold"
            color="primary"
            paddingBottom="2px"
            flexBasis="100%"
          >
            Dates
          </Typography>

          {details.dates.length > 0 ? (
            details.dates.map((date) => (
              <DateCard date={date} key={date.vdl_datesid} />
            ))
          ) : (
            <Box bgcolor="background.default" padding="16px">
              <Typography>Il n'y a aucune date dans la demande</Typography>
            </Box>
          )}
        </BoxResponsivePadding>
      </Box>
    </Box>
  );
};

export default SpecialEvents;
