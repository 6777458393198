import { Card, CardContent, Grid, Skeleton } from "@mui/material";

type Props = {
  contactsCount: number;
  icon?: JSX.Element;
};

const AccountContactsCardSkeleton = ({ icon, contactsCount }: Props) => {
  return (
    <Card elevation={0} sx={{ marginBottom: 2 }}>
      <CardContent>
        <Grid container>
          <Grid item container xs={12} minHeight={"45px"}>
            <Grid item xs>
              <Skeleton
                variant="text"
                width={"50%"}
                sx={{ fontSize: "1.8rem" }}
              />
            </Grid>
            {icon ? (
              <Grid
                item
                width={"50px"}
                maxHeight={"40px"}
                paddingRight={"10px"}
              >
                {icon}
              </Grid>
            ) : null}
          </Grid>
          <Grid item container xs={12} rowGap={1}>
            {Array(contactsCount)
              .fill(0)
              .map((contact, i) => (
                <Grid item xs={12} key={i}>
                  <Skeleton
                    variant="rounded"
                    height={"52px"}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AccountContactsCardSkeleton;
