import React from "react";
import {
  Box,
  Card,
  CardContent,
  Collapse,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";

const ProgramCardSkeletton = () => {
  const theme = useTheme();

  return (
    <Card elevation={0} sx={{ bgcolor: "white" }}>
      <CardContent>
        <Box display="flex" flexDirection="column" gap="16px">
          <Box
            display="grid"
            gridAutoFlow="column"
            justifyContent="flex-start"
            alignItems="center"
            rowGap="4px"
            columnGap="8px"
            width="100%"
            sx={{
                gridTemplateColumns: {
                    xs: "30px 9fr 1fr",
                    sm: "30px 400px ",
                    lg: "30px 600px"
                }
            }}
            gridTemplateColumns="30px minmax(100px, 1fr) 1fr"
          >
            <Skeleton variant="circular" width="30px" height="30px" />
            <Box gridColumn="2" gridRow="1" >
              <Skeleton variant="text" sx={{ fontSize: "1.5rem" }}/>
            </Box>

            <Box gridColumn="2" gridRow="2" width="75%">
              <Skeleton variant="text" sx={{ fontSize: "0.9rem" }} />
            </Box>
          </Box>

          <Box
            height="2px"
            bgcolor={theme.custom.appLayout.backgroundColor}
            width="100%"
          />
          <Box/>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProgramCardSkeletton;
