import React, { useEffect, useState } from "react";
import { FormFields } from "../../../types";
import SimpleFormBase, { BaseFormProps } from "./SimpleFormBase";

type Props<T extends FormFields> = BaseFormProps<T> & {
  handleSuccess: (fields: T) => Promise<void> | void;
};

function SimpleFormWithSections<T extends FormFields>({
  fields,
  submitText,
  cancelText,
  handleCancel,
  handleSuccess,
  title,
  maxWidth,
  columns,
  sections,
  isFormLoading,
  sectionsBackground,
  removeFormPadding,
  updateRefs
}: Props<T>) {
  const [formData, setFormData] = useState<T>(() => fields);

  return (
    <SimpleFormBase
      fields={formData}
      setFields={setFormData}
      submitText={submitText}
      handleSuccess={handleSuccess}
      cancelText={cancelText}
      handleCancel={handleCancel}
      title={title}
      maxWidth={maxWidth}
      columns={columns}
      sections={sections}
      isFormLoading={isFormLoading}
      sectionsBackground={sectionsBackground}
      removeFormPadding={removeFormPadding}
      updateRefs={updateRefs}
    />
  );
}

export default SimpleFormWithSections;
