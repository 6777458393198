import { Dayjs } from "dayjs";
import {
  CheckBoxInput,
  DateInput,
  FileInput,
  FormFields,
  FormSection,
  TextInput,
} from "../../../types";
import { televisionDisplayDisplayNames } from "../displayNames";

export type EditDemandTelevisionDisplayFields = {
  vdl_imagedepromotionfile: FileInput;
  vdl_debutdediffusion: DateInput;
  vdl_findediffusion: DateInput;
  vdl_secteur1: CheckBoxInput;
  vdl_secteur2: CheckBoxInput;
  vdl_secteur3: CheckBoxInput;
  vdl_secteur4: CheckBoxInput;
  vdl_secteur5: CheckBoxInput;
  vdl_secteur6: CheckBoxInput;
};

const editDemandTelevisionDisplayFields: EditDemandTelevisionDisplayFields = {
  vdl_imagedepromotionfile: {
    type: "file",
    error: "",
    required: true,
    fillLine: true,
    accept: [".gif", ".png", ".jpg", ".PNG", ".jpeg"],
    label: televisionDisplayDisplayNames.vdl_imagedepromotionfile,
  },
  vdl_debutdediffusion: {
    type: "date",
    error: "",
    value: undefined,
    required: true,
    disablePast: true,
    fillLine: true,
    label: televisionDisplayDisplayNames.vdl_debutdediffusion,
  },
  vdl_findediffusion: {
    type: "date",
    error: "",
    value: undefined,
    required: true,
    fillLine: true,
    disablePast: true,
    label: televisionDisplayDisplayNames.vdl_findediffusion,
    minDate: (fields: FormFields) => {
      //console.log(fields.vdl_debutdediffusion.value)
      return fields.vdl_debutdediffusion.value
        ? (fields.vdl_debutdediffusion.value as Dayjs)
        : undefined;
    },
  },
  vdl_secteur1: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: televisionDisplayDisplayNames.vdl_secteur1,
    helperText:
      "Bibliothèque Germaine-Guèvremont, Bibliothèque Marius-Barbeau, Pavillon du Bois Papineau, Centre communautaire Boileau, Centre communautaire Groulx",
  },
  vdl_secteur2: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: televisionDisplayDisplayNames.vdl_secteur2,
    helperText:
      "Centre communautaire Laval-des-Rapides, Centre communautaire Raymond-Fortin, Centre communautaire St-Louis-de-Montfort, Centre communautaire Argenteuil, Bibliothèque Emile-Nelligan",
  },
  vdl_secteur3: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: televisionDisplayDisplayNames.vdl_secteur3,
    helperText:
      "Bibliothèque Multiculturelle, Centre communautaire Saint-Joseph, Centre communautaire Jean-Paul Campeau, Aréna Pierre-Creamer",
  },
  vdl_secteur4: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: televisionDisplayDisplayNames.vdl_secteur4,
    helperText:
      "Bibliothèque Yves-Thériault, Bibliothèque Philippe-Panneton, Centre communautaire Laval-Ouest; Centre Accès, Centre communautaire Sainte-Dorothée, Centre communautaire Jolibourg, Centre communautaire Bigras, Aréna Hartland-Monahan",
  },
  vdl_secteur5: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: televisionDisplayDisplayNames.vdl_secteur5,
    helperText:
      "Bibliothèque Gabrielle-Roy, Bibliothèque Sylvain-Garneau, Centre communautaire le Sorbier, Centre communautaire Montrougeau, Centre communautaire des Jeunes, Complexe Sportif Guimond, Centre Sportif Honoré-Mercier",
  },
  vdl_secteur6: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: televisionDisplayDisplayNames.vdl_secteur6,
    helperText:
      "Bibliothèque Laure-Conan, Centre Communautaire Auteuil, Centre communautaire Réjean-Martimbault, Centre communautaire Vimont, Aréna Lucerne, Aréna Mike-Bossy",
  },
};
const editDemandTelevisionDisplaySections: FormSection<EditDemandTelevisionDisplayFields>[] =
  [
    {
      title: "Informations sur la diffusion",
      fields: [
        "vdl_imagedepromotionfile",
        "vdl_debutdediffusion",
        "vdl_findediffusion",
        "vdl_secteur1",
        "vdl_secteur2",
        "vdl_secteur3",
        "vdl_secteur4",
        "vdl_secteur5",
        "vdl_secteur6",
      ],
    },
  ];
export {
  editDemandTelevisionDisplayFields,
  editDemandTelevisionDisplaySections,
};
