import { Box, Typography } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useMsal } from "@azure/msal-react";
import CustomButton from "../buttons/CustomButton";

const ErrorMessageContactDisabled = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logout();
  };

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      padding="16px"
      boxSizing="border-box"
    >
      <LockIcon sx={{ fontSize: 64, color: "primary.main", mb: 2 }} />
      <Typography variant="h4" color="primary" fontWeight="bold" mb={2}>
        Vous n'avez pas accès au portail.
      </Typography>
      <Typography variant="h5" color="primary" mb={2}>
        Votre profil a été désactivé.
      </Typography>
      <Typography>
        Contactez l'administrateur de votre organisation pour obtenir de l'aide.
      </Typography>
      <CustomButton
        primary
        startIcon={<ExitToAppIcon />}
        onClick={handleLogout}
        sx={{ mt: 2 }}
      >
        Déconnexion
      </CustomButton>
    </Box>
  );
};

export default ErrorMessageContactDisabled;
