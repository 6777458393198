import { errorHandler } from "../../components/common/error/errorHandler";
import { GetResponse } from "../common/types";
import usefetchWithMsal from "../fetchWithMsal";
import { ProductRow } from "./models";

export const getProducts = async (
  typeId: string
): Promise<GetResponse<ProductRow[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/get_products/${typeId}`;
    const result = await usefetchWithMsal("GET", endpoint);
    if (result.StatusCode === 404)
      return { status: "success", data: undefined };
    if (!result.Value) throw new Error("Erreur dans la requête");
    const data = result.Value;
    const tempData: ProductRow[] = [];
    data.forEach((product: any) => {
      tempData.push({
        productid: product.productid,
        vdl_categorie: {
          value: product.vdl_categorie.Name,
          label: product.vdl_categorie.Id,
        },
        name: product.name,
        description: product.description,
        vdl_dimension: product.vdl_dimension,
        quantityonhand: product.quantityonhand,
        vdl_photo: product.vdl_photo,
        vdl_typedeproduit: product.vdl_typedeproduit,
      });
    });

    return { status: "success", data: tempData };
  } catch (e) {
    return errorHandler(e, "Erreur dans la récupération des produits");
  }
};
