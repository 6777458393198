import { Box } from "@mui/material";
import { DemandSecurityPreventionRow } from "../../../../../data/Demand/models";
import { securityPreventionDisplayNames } from "../../../../../data/Demand/displayNames";
import DisplayFieldCard from "../../../../common/cards/DisplayFieldCard";
import {
  dateTimeStringToString,
  timeStringToString,
} from "../../../../../data/common/fieldToString";

type Props = {
  demand: DemandSecurityPreventionRow;
};

const SecurityPrevention = ({ demand }: Props) => {
  const details = demand.vdl_detailsdelademande;

  const utilisationDunAppareilACombustion = (
    <DisplayFieldCard
      title={
        securityPreventionDisplayNames.vdl_utilisationdunappareilacombustion
      }
      fields={[
        {
          label: securityPreventionDisplayNames.vdl_generatrice,
          value: details.vdl_generatrice,
          type: "check",
        },
        {
          label: securityPreventionDisplayNames.vdl_appareildecuison,
          value: details.vdl_appareildecuison,
          type: "check",
        },
        {
          label: securityPreventionDisplayNames.vdl_barbecue,
          value: details.vdl_barbecue,
          type: "check",
        },
        {
          label: securityPreventionDisplayNames.vdl_chauffagedappoint,
          value: details.vdl_chauffagedappoint,
          type: "check",
        },
        {
          label: securityPreventionDisplayNames.vdl_autre4,
          value: details.vdl_autre4,
          type: "check",
          span: 2,
        },
        {
          label: securityPreventionDisplayNames.vdl_siautreprecisez3,
          value: details.vdl_siautreprecisez3,
          type: "string",
          span: 2,
        },
        {
          label: securityPreventionDisplayNames.vdl_gazpropane,
          value: details.vdl_gazpropane,
          type: "check",
        },
        {
          label: securityPreventionDisplayNames.vdl_essence,
          value: details.vdl_essence,
          type: "check",
        },
        {
          label: securityPreventionDisplayNames.vdl_charbon,
          value: details.vdl_charbon,
          type: "check",
        },
        {
          label: securityPreventionDisplayNames.vdl_autre1,
          value: details.vdl_autre1,
          type: "check",
        },
        {
          label: securityPreventionDisplayNames.vdl_autre5,
          value: details.vdl_autre5,
          type: "string",
        },
      ]}
      columns={2}
      minColWidth={275}
      activeRule={{
        hide: true,
        value: details.vdl_utilisationdunappareilacombustion,
      }}
    />
  );

  const activiteDeNuit = (
    <DisplayFieldCard
      title={
        securityPreventionDisplayNames.vdl_activitedenuitsurunsitemunicipal
      }
      fields={[
        {
          label: securityPreventionDisplayNames.vdl_interieur,
          value: details.vdl_interieur,
          type: "check",
        },
        {
          label: securityPreventionDisplayNames.vdl_exterieur,
          value: details.vdl_exterieur,
          type: "check",
        },
        {
          label:
            securityPreventionDisplayNames.vdl_heurededebutdutilisationdusite,
          value: timeStringToString(details.vdl_heurededebutdutilisationdusite),
          type: "string",
        },
        {
          label:
            securityPreventionDisplayNames.vdl_heuredefindutilisationdusite,
          value: timeStringToString(details.vdl_heuredefindutilisationdusite),
          type: "string",
        },
        {
          label: securityPreventionDisplayNames.vdl_nombredeparticipant,
          value: details.vdl_nombredeparticipant?.toString(),
          type: "string",
          span: 2,
        },
        {
          label: securityPreventionDisplayNames.vdl_enfants512ans,
          value: details.vdl_enfants512ans?.toString(),
          type: "string",
          span: 2,
        },
        {
          label: securityPreventionDisplayNames.vdl_adolescents1317ans,
          value: details.vdl_adolescents1317ans?.toString(),
          type: "string",
          span: 2,
        },
        {
          label: securityPreventionDisplayNames.vdl_adultes,
          value: details.vdl_adultes?.toString(),
          type: "string",
          span: 2,
        },
      ]}
      columns={2}
      minColWidth={275}
      activeRule={{
        hide: true,
        value: details.vdl_activitedenuitsurunsitemunicipal,
      }}
    />
  );

  return (
    <Box display="grid" gap="16px">
      {details.vdl_typedactivite === "Évènement" ? (
        <>
          <DisplayFieldCard
            title={securityPreventionDisplayNames.vdl_feuacielouvert}
            fields={[
              {
                label: securityPreventionDisplayNames.vdl_feudecamp1mdediametre,
                value: details.vdl_feudecamp1mdediametre,
                type: "check",
              },
              {
                label: securityPreventionDisplayNames.vdl_feudejoie1mdediametre,
                value: details.vdl_feudejoie1mdediametre,
                type: "check",
              },
              {
                label: securityPreventionDisplayNames.vdl_foyerbrasero,
                value: details.vdl_foyerbrasero,
                type: "check",
              },
            ]}
            columns={3}
            minColWidth={275}
            activeRule={{ hide: true, value: details.vdl_feuacielouvert }}
          />

          <DisplayFieldCard
            title={securityPreventionDisplayNames.vdl_artistedefeu}
            fields={[
              {
                label: securityPreventionDisplayNames.vdl_jongleur,
                value: details.vdl_jongleur,
                type: "check",
              },
              {
                label: securityPreventionDisplayNames.vdl_cracheurdefeu,
                value: details.vdl_cracheurdefeu,
                type: "check",
                span: 2,
              },
              {
                label: securityPreventionDisplayNames.vdl_autre,
                value: details.vdl_autre,
                type: "check",
              },
              {
                label: securityPreventionDisplayNames.vdl_siautreprecisez,
                value: details.vdl_siautreprecisez,
                type: "string",
                span: 2,
              },
            ]}
            columns={3}
            minColWidth={275}
            activeRule={{ hide: true, value: details.vdl_artistedefeu }}
          />

          <DisplayFieldCard
            title={securityPreventionDisplayNames.vdl_accessoireavecfeu}
            fields={[
              {
                label: securityPreventionDisplayNames.vdl_flambeaux,
                value: details.vdl_flambeaux,
                type: "check",
              },
              {
                label: securityPreventionDisplayNames.vdl_lampions,
                value: details.vdl_lampions,
                type: "check",
              },
              {
                label: securityPreventionDisplayNames.vdl_lanternes,
                value: details.vdl_lanternes,
                type: "check",
              },
              {
                label: securityPreventionDisplayNames.vdl_autre2,
                value: details.vdl_autre2,
                type: "check",
              },
              {
                label: securityPreventionDisplayNames.vdl_siautreprecisez1,
                value: details.vdl_siautreprecisez1,
                type: "string",
                span: 2,
              },
            ]}
            columns={3}
            minColWidth={275}
            activeRule={{ hide: true, value: details.vdl_accessoireavecfeu }}
          />

          <DisplayFieldCard
            title={
              securityPreventionDisplayNames.vdl_utilisationdepiecespyrotechniqueseteffetsspe
            }
            fields={[
              {
                label:
                  securityPreventionDisplayNames.vdl_feudartificedomestique,
                value: details.vdl_feudartificedomestique,
                type: "check",
              },
              {
                label:
                  securityPreventionDisplayNames.vdl_feudartificeagrandeploiement,
                value: details.vdl_feudartificeagrandeploiement,
                type: "check",
              },
              {
                label:
                  securityPreventionDisplayNames.vdl_effetspeciauxpyrotechniques,
                value: details.vdl_effetspeciauxpyrotechniques,
                type: "check",
                span: 2,
              },
              {
                label: securityPreventionDisplayNames.vdl_precisez,
                value: details.vdl_precisez,
                type: "string",
                span: 2,
              },
              {
                label: securityPreventionDisplayNames.vdl_effetsspeciaux,
                value: details.vdl_effetsspeciaux,
                type: "check",
                span: 2,
              },
              {
                label: securityPreventionDisplayNames.vdl_precisez1,
                value: details.vdl_precisez1,
                type: "string",
                span: 2,
              },
            ]}
            columns={2}
            minColWidth={275}
            activeRule={{
              hide: true,
              value: details.vdl_utilisationdepiecespyrotechniqueseteffetsspe,
            }}
          />
          {utilisationDunAppareilACombustion}
          {activiteDeNuit}
          <DisplayFieldCard
            title={
              securityPreventionDisplayNames.vdl_attentionparticuliereduservicedepolice
            }
            fields={[]}
            columns={1}
            minColWidth={275}
            activeRule={{
              hide: true,
              value: details.vdl_attentionparticuliereduservicedepolice,
            }}
          />
          <DisplayFieldCard
            title={
              securityPreventionDisplayNames.vdl_securite500persbenevolesagentsdesecurite
            }
            fields={[]}
            columns={2}
            minColWidth={275}
            activeRule={{
              hide: true,
              value: details.vdl_securite500persbenevolesagentsdesecurite,
            }}
          />
          <DisplayFieldCard
            title={securityPreventionDisplayNames.vdl_casernemobile}
            fields={[]}
            columns={2}
            minColWidth={275}
            activeRule={{ hide: true, value: details.vdl_casernemobile }}
          />
          <DisplayFieldCard
            title={securityPreventionDisplayNames.vdl_kiosquedepreventionpolice}
            fields={[]}
            columns={2}
            minColWidth={275}
            activeRule={{
              hide: true,
              value: details.vdl_kiosquedepreventionpolice,
            }}
          />
          <DisplayFieldCard
            title={
              securityPreventionDisplayNames.vdl_kiosquedepreventionincendies
            }
            fields={[]}
            columns={2}
            minColWidth={275}
            activeRule={{
              hide: true,
              value: details.vdl_kiosquedepreventionincendies,
            }}
          />
        </>
      ) : (
        <>
          <DisplayFieldCard
            title={
              securityPreventionDisplayNames.vdl_utilisationdepiecespyrotechniqueseteffetsspe
            }
            fields={[
              {
                label:
                  securityPreventionDisplayNames.vdl_feudartificedomestique,
                value: details.vdl_feudartificedomestique,
                type: "check",
              },
              {
                label:
                  securityPreventionDisplayNames.vdl_feudartificeagrandeploiement,
                value: details.vdl_feudartificeagrandeploiement,
                type: "check",
              },
              {
                label:
                  securityPreventionDisplayNames.vdl_effetspeciauxpyrotechniques,
                value: details.vdl_effetspeciauxpyrotechniques,
                type: "check",
                span: 2,
              },
              {
                label: securityPreventionDisplayNames.vdl_precisez,
                value: details.vdl_precisez,
                type: "string",
                span: 2,
              },
              {
                label: securityPreventionDisplayNames.vdl_effetsspeciaux,
                value: details.vdl_effetsspeciaux,
                type: "check",
                span: 2,
              },
              {
                label: securityPreventionDisplayNames.vdl_precisez1,
                value: details.vdl_precisez1,
                type: "string",
                span: 2,
              },
              {
                label: securityPreventionDisplayNames.vdl_cascade,
                value: details.vdl_cascade,
                type: "check",
              },
              {
                label: securityPreventionDisplayNames.vdl_simulationdevol,
                value: details.vdl_simulationdevol,
                type: "check",
              },
              {
                label: securityPreventionDisplayNames.vdl_simulationdebagarre,
                value: details.vdl_simulationdebagarre,
                type: "check",
              },
              {
                label: securityPreventionDisplayNames.vdl_simulationdenlevement,
                value: details.vdl_simulationdenlevement,
                type: "check",
              },
              {
                label: securityPreventionDisplayNames.vdl_autre3,
                value: details.vdl_autre3,
                type: "check",
                span: 2,
              },
              {
                label: securityPreventionDisplayNames.vdl_siautreprecisez2,
                value: details.vdl_siautreprecisez2,
                type: "string",
                span: 2,
              },
            ]}
            columns={2}
            minColWidth={275}
            activeRule={{
              hide: true,
              value: details.vdl_utilisationdepiecespyrotechniqueseteffetsspe,
            }}
          />
          {utilisationDunAppareilACombustion}
          {activiteDeNuit}
          <DisplayFieldCard
            title={securityPreventionDisplayNames.vdl_survolabasealtitude}
            fields={[
              {
                label:
                  securityPreventionDisplayNames.vdl_utilisationdappareildetypeaviation,
                value: details.vdl_utilisationdappareildetypeaviation,
                type: "check",
              },
              {
                label: securityPreventionDisplayNames.vdl_precisez2,
                value: details.vdl_precisez2,
                type: "string",
              },
            ]}
            columns={2}
            minColWidth={275}
            activeRule={{ hide: true, value: details.vdl_survolabasealtitude }}
          />
        </>
      )}
    </Box>
  );
};

export default SecurityPrevention;
