import { GetResponse } from "../common/types";
import usefetchWithMsal from "../fetchWithMsal";
import { ProgramRow } from "./models";
import { ServiceRow } from "../Service/models";
import { errorHandler } from "../../components/common/error/errorHandler";

export const getPrograms = async (): Promise<GetResponse<ProgramRow[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/get_programs`;
    const result = await usefetchWithMsal("GET", endpoint);
    if (result.StatusCode === 404)
      return { status: "success", data: undefined };
    if (!result.Value) throw new Error("Erreur dans la requête");

    const data = result.Value;
    const tempData: ProgramRow[] = [];

    data.forEach((program: any) => {
      tempData.push({
        vdl_name: program.vdl_name,
        vdl_programmeid: program.vdl_programmeid,
        services: program.services ? program.services : [],
      });
    });
    return { status: "success", data: tempData };
  } catch (e) {
    return errorHandler(e, "Erreur dans la récupération des programmes");
  }
};
