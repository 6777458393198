import React from "react";
import {
  Box,
  Divider,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import LaunchSharpIcon from "@mui/icons-material/LaunchSharp";
import Logo from "../../common/images/laval-logo.svg";
import AppDrawerLinks from "./AppDrawerLinks";
import SimpleLink from "../../common/links/SimpleLink";
import SimpleExternalLink from "../../common/links/SimpleExternalLink";

const AppDrawerInner = () => {
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Link component={RouterLink} p={7} to={"/"}>
        <img src={Logo} style={{ width: 143 }} alt="Ville de Laval" />
      </Link>
      <AppDrawerLinks />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "5px",
          pl: "20px",
          pb: "30px",
        }}
      >
        <SimpleLink to="/" text="Foire aux questions" />
        <SimpleLink to="/" text="Services en ligne" />
        <SimpleExternalLink
          to="https://www.laval.ca/Pages/Fr/Activites/informations-pour-les-organismes-lavallois.aspx"
          text="Nous joindre"
          externalIcon
          targetBlank
        />
      </Box>
    </Box>
  );
};

export default AppDrawerInner;
