import { GetResponse } from "../common/types";
import usefetchWithMsal from "../fetchWithMsal";
import { RoleRow } from "./models";
import { errorHandler } from "../../components/common/error/errorHandler";

const getRoles = async (): Promise<GetResponse<RoleRow[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/get_roles`;
    const result = await usefetchWithMsal("GET", endpoint);
    if (result.StatusCode === 404)
      return { status: "success", data: undefined };
    if (!result.Value) throw new Error("Erreur dans la requête");

    const data = result.Value;
    const tempData: RoleRow[] = [];

    data.forEach((role: { vdl_roleid: string; vdl_nomdurole: string }) => {
      tempData.push({
        value: role.vdl_roleid,
        label: role.vdl_nomdurole,
      });
    });

    return { status: "success", data: tempData };
  } catch (e) {
    return errorHandler(e, "Erreur dans la récupération des rôles");
  }
};

export { getRoles };
