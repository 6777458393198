import { Box, Card, CardContent, Link, Typography } from "@mui/material";
import { useContext } from "react";
import { HistoricAttachmentContext } from "../../../../../providers/HistoricAttachmentProvider";
import ErrorMessage from "../../../../common/error/ErrorMessage";
import AttachmentCardSkeleton from "./AttachmentCardSkeleton";
import { useParams } from "react-router-dom";

const AttachmentCard = () => {
  const { demandFiles, isLoading, error } = useContext(
    HistoricAttachmentContext
  );
  const { id } = useParams();

  if (isLoading)
    return (
      <AttachmentCardSkeleton/>
    );

  if (!demandFiles || error.files || !id)
    return (
      <Card elevation={0} sx={{ bgcolor: "white" }}>
        <CardContent>
          <Box display="flex" flexDirection="column" gap="10px">
            <Typography variant="h6" fontWeight="bold" color="primary">
              Pièces jointes
            </Typography>
            <Box display="grid" rowGap="6px">
              <ErrorMessage message={error.activitites} />
            </Box>
          </Box>
        </CardContent>
      </Card>
    );

  return (
    <Card elevation={0} sx={{ bgcolor: "white" }}>
      <CardContent>
        <Box display="flex" flexDirection="column" gap="10px">
          <Typography variant="h6" fontWeight="bold" color="primary">
            Pièces jointes
          </Typography>
          <Box display="grid" rowGap="6px">
            {demandFiles.length > 0 ? (
              demandFiles.map((demandFile, index) => (
                <Link
                  key={index}
                  sx={{
                    color: "inherit",
                    textDecorationColor: "currentcolor",
                    lineHeight: 1.3,
                  }}
                  href={demandFile.downloadUrl}
                >
                  {demandFile.name}
                </Link>
              ))
            ) : (
              <Typography>Aucune pièce jointe.</Typography>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AttachmentCard;
