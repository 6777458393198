import React, { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

type Props = {
  children: React.ReactNode;
};

interface TabsContext {
  activeTab: number;
  isTabsRendered: boolean;
  changeTab: (newTab: number) => void;
  changeIsTabRendered: (value: boolean) => void;
}

export const TabsContext = createContext<TabsContext | undefined>(undefined);

const TabsProvider = ({ children }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(0);
  const [isTabsRendered, setIsTabsRendered] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) return;

    const queryParams = new URLSearchParams(searchParams);
    queryParams.set("tab", activeTab.toString());
    navigate(
      {
        pathname: location.pathname,
        search: queryParams.toString(),
      },
      { replace: true }
    );
  }, [activeTab]);

  useEffect(() => {
    const defaultTabString = searchParams.get("tab");
    const defaultTab =
      defaultTabString === null ? 0 : parseInt(defaultTabString) ?? 0;
    const queryParams = new URLSearchParams(searchParams);
    if (isTabsRendered) {
      setActiveTab(defaultTab);
      queryParams.set("tab", defaultTab.toString());
    } else if (!isFirstRender && defaultTabString === null) {
      queryParams.delete("tab");
    } else {
      setIsFirstRender(false);
      return;
    }
    navigate(
      {
        pathname: location.pathname,
        search: queryParams.toString(),
      },
      { replace: true }
    );
  }, [isTabsRendered]);

  const changeTab = (newTab: number) => {
    setActiveTab(newTab);
  };
  const changeIsTabRendered = (value: boolean) => {
    setIsTabsRendered(value);
  };
  return (
    <TabsContext.Provider
      value={{ activeTab, changeTab, isTabsRendered, changeIsTabRendered }}
    >
      {children}
    </TabsContext.Provider>
  );
};

export default TabsProvider;
