import React from "react";
import { DisplayFieldWithSpan } from "../../../types";
import { Box, Card, CardContent, Typography } from "@mui/material";
import DisplayField from "./DisplayField";
import DisplayCheckbox from "./DisplayCheckbox";
import BoxResponsivePadding from "../boxes/BoxResponsivePadding";
import DisplayFile from "./DisplayFile";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";

type Props = {
  title?: string;
  activeRule?: {
    hide: boolean;
    value?: boolean;
  };
  fields: DisplayFieldWithSpan[];
  columns: number;
  minColWidth: number;
  mgBottom?: string;
};

const DisplayFieldCard = ({
  title,
  activeRule,
  fields,
  columns,
  minColWidth,
  mgBottom,
}: Props) => {
  const gap = 10;
  return (
    <BoxResponsivePadding
      bgcolor="background.paper"
      marginBottom={mgBottom ? mgBottom : undefined}
    >
      <Box display="flex" flexWrap="wrap" gap={gap + "px"}>
        {title && (
          <Box display="flex" alignItems="center" gap="8px" flexBasis="100%">
            <Typography
              variant="h6"
              fontWeight="bold"
              color="primary"
              paddingBottom="2px"
            >
              {title}
            </Typography>
            {activeRule ? (
              activeRule.value ? (
                <CheckBox />
              ) : (
                <CheckBoxOutlineBlank />
              )
            ) : null}
          </Box>
        )}

        {(!activeRule || activeRule.value) &&
          fields.map((field, idx) => (
            <Box
              key={field.label + idx}
              flexBasis={`calc((100% / ${columns} - ${gap}px / ${columns} * ${
                columns - 1
              }) * ${field.span ? field.span : 1})`}
              flexGrow={1}
              minWidth={minColWidth}
              paddingTop={
                field.paddingTop ? field.paddingTop + "px" : undefined
              }
            >
              {field.type === "string" ? (
                <DisplayField
                  fontSize="0.95rem"
                  displayName={field.label}
                  emptyPlaceHolder="---"
                  value={field.value ?? ""}
                />
              ) : field.type === "check" ? (
                <DisplayCheckbox
                  reversed
                  fontSize="0.95rem"
                  displayName={field.label}
                  value={field.value ?? false}
                />
              ) : field.type === "file" ? (
                <DisplayFile
                  fontSize="0.95rem"
                  displayName={field.label}
                  emptyPlaceHolder="---"
                  value={field.value}
                />
              ) : (
                <Typography
                  fontWeight="bold"
                  color="inherit"
                  fontSize="1.1rem"
                  lineHeight={1.1}
                >
                  {field.label}
                </Typography>
              )}
            </Box>
          ))}
      </Box>
    </BoxResponsivePadding>
  );
};

export default DisplayFieldCard;
