import { useContext } from "react";
import { TabsContext } from "../providers/TabsProvider";

export default function useTabs() {
  const tabData = useContext(TabsContext);
  if (!tabData) {
    return {
      activeTab: 0,
      changeTab: () => {},
      isTabsRendered: false,
      changeIsTabRendered: () => {},
    };
  }

  return tabData;
}
