import dayjs, { Dayjs } from "dayjs";

export function validateDateString(dateString?: string): Dayjs | undefined {
  try {
    if (!dateString) return undefined;
    const dayJsDate = dayjs(dateString);
    return dayJsDate.isValid() ? dayJsDate : undefined;
  } catch (e) {
    return undefined;
  }
}
