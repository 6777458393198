import React, { useState } from "react";
import {
  TextField,
  TextFieldProps,
  InputAdornment,
  IconButton,
  Grid,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

type CustomTextFieldProps = TextFieldProps & {
  onQtyChange?: (newQty: number) => void;
};

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  onQtyChange,
  inputProps,
  ...props
}) => {
  const max = inputProps?.max ? Number(inputProps.max) : undefined;
  const min = inputProps?.min ? Number(inputProps.min) : undefined;
  const [error, setError] = useState<string | undefined>(undefined);

  const handleChange = (newValue: number) => {
    let newError: string | undefined = undefined;
    let validatedValue: number = newValue;
    switch (true) {
      case isNaN(newValue) || props.value === newValue:
        newError = "Veuillez entrer une valeur numérique.";
        validatedValue = props.value !== "" ? Number(props.value) : NaN;
        break;
      case max !== undefined && newValue > max:
        newError = `Veuillez entrer une valeur inférieure ou égale à ${max}.`;
        validatedValue = max ?? newValue;
        break;
      case min !== undefined && newValue < min:
        newError = `Veuillez entrer une valeur supérieure ou égale à ${min}.`;
        validatedValue = min ?? newValue;
        break;
    }

    setError(newError);
    onQtyChange && onQtyChange(validatedValue);
  };

  const handleIncrement = () => {
    const currentValue = props.value !== undefined ? Number(props.value) : 0;
    handleChange(currentValue + 1);
  };

  const handleDecrement = () => {
    const currentValue = props.value !== undefined ? Number(props.value) : 0;
    handleChange(currentValue - 1);
  };

  return (
    <TextField
      type="text"
      inputProps={inputProps}
      {...props}
      error={!!error || props.error}
      helperText={error || props.helperText}
      onChange={(event) => handleChange(Number(event.target.value))}
      InputProps={{
        style: { backgroundColor: "white" },
        endAdornment: (
          <InputAdornment position="end">
            <Grid container direction="column">
              <IconButton onClick={handleIncrement} size="small">
                <KeyboardArrowUpIcon
                  fontSize="small"
                  style={{ fontSize: "12px" }}
                />
              </IconButton>
              <IconButton onClick={handleDecrement} size="small">
                <KeyboardArrowDownIcon
                  fontSize="small"
                  style={{ fontSize: "12px" }}
                />
              </IconButton>
            </Grid>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CustomTextField;
