import { Typography } from "@mui/material";
import React from "react";

type Props = {
  title: string;
  color?: string;
};

const CardTitle = ({ title, color }: Props) => {
  return (
    <Typography variant="h5" color={color ? color : "primary"}>
      {title}
    </Typography>
  );
};

export default CardTitle;
