import React, { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Button,
  Dialog,
  IconButton,
  InputAdornment,
  InputBase,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import useUser from "../hooks/useUserData";
import ProductDialog from "../components/pageSpecific/EditDemand/StandardEquipment/Products/ProductsDialog/ProductDialog";
import fetchWithMsal from "../data/fetchWithMsal";
import CustomChip from "../components/common/misc/CustomChip";
import { getProducts } from "../data/Product/requests";
import SimpleFormWithSections from "../components/form/SimpleForm/SimpleFormWithSections";
import { FormFields, MultilineInput, TabContent, TextInput } from "../types";

import {
  EditDemandPrismeFields,
  editDemandPrismeFields,
  editDemandPrismeSections,
} from "../data/Demand/forms/prisme";
import {
  EditDemandTelevisionDisplayFields,
  editDemandTelevisionDisplayFields,
} from "../data/Demand/forms/televisionDisplay";
import BoxResponsivePadding from "../components/common/boxes/BoxResponsivePadding";
import {
  EditDemandWebsiteDisplayFields,
  editDemandWebsiteDisplayFields,
} from "../data/Demand/forms/websiteDisplay";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import BasicTabs from "../components/layouts/AppTabs/AppTabs";
import {
  EditDemandAdmissibilityFields,
  editDemandAdmissibilityFields,
  editDemandAdmissiblitySections,
} from "../data/Demand/forms/admissibility";

const Test = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { userData } = useUser();
  const theme = useTheme();

  useEffect(() => {
    //const querry = async () => {
    //const { data, error } = await getProducts();
    //};
    //querry();
  }, []);

  const tabData: TabContent[] = [
    {
      label: "Informations générales",
      component: null,
    },
    {
      label: "Voie publique",
      component: null,
    },
    {
      label: "Sécurité prévention",
      component: null,
    },
    {
      label: "Permis",
      component: null,
    },
    {
      label: "Soutient événement",
      component: null,
    },
    {
      label: "Soutien Eco-Responsable",
      component: null,
    },
    {
      label: "Affichage sur le calendrier",
      component: null,
    },
    {
      label: "Affichage sur panneaux électronique",
      component: null,
    },
    {
      label: "Affichage sur Prisme",
      component: null,
    },
    {
      label: "Affichage télévision",
      component: null,
    },
    {
      label: "Demande information",
      component: null,
    },
  ];
  const [open, setOpen] = useState(false);
  const contentRef = useRef<HTMLParagraphElement>(null);

  const shouldDisplayButton = () => {
    if (contentRef.current) {
      const contentElement = contentRef.current;
      console.log("scroll" + contentElement.scrollWidth);
      console.log("Width" + contentElement.clientWidth);
      return contentElement.scrollWidth > contentElement.clientWidth;
    }
  };
  if (0 > 1)
    return (
      <PageTitleLayout title={"Test"}>
        <BasicTabs tabs={tabData} scrollable />
      </PageTitleLayout>
    );

  return (
    <Box>
      <Box>
        <Typography variant="h6">Item Title</Typography>
      </Box>
      <Box sx={{ position: 'relative', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
      <Typography variant="body1" sx={{ maxWidth: '100vw', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          Need "Read More" if it is not don't Need "Read More" if it is not
          don'tvNeed "Read More" if it is not don'tNeed "Read More" if it is not
          don'tNeed "Read More" if it is not don'tNeed "Read More" if it is not
          don'tNeed "Read More" if it is not don'tNeed "Read More" if it is not
          don't
        </Typography>
        <Button
          className="text-size"
          onClick={() => setOpen(!open)}
          sx={{
            display: open ? "none" : "block",
            textAlign: "right",
            color: "blue",
            cursor: "pointer",
          }}
        >
          Read more...
        </Button>
      </Box>
    </Box>
  );
};

export default Test;
