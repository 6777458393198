import React, { useState } from "react";
import { DemandListRow } from "../../../../data/Demand/models";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import DemandCard from "../DemandCard/DemandCard";

type Props = {
  demands: DemandListRow[];
  regroupmentName: string;
};

const DemandRegroupment = ({ demands, regroupmentName }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <Accordion
      disableGutters
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      sx={{
        backgroundColor: "background.default",
        boxShadow: "none",
        borderRadius: "3px",
        "&:before": { display: "none" },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h6" fontWeight="bold">
          {regroupmentName}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ display: "grid", gap: "16px" }}>
        {demands.map((demand, index) => (
          <DemandCard key={index} demand={demand} redirectHome/>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default DemandRegroupment;
