import React from "react";
import {
  Drawer,
  IconButton,
  Toolbar,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { createContext, Dispatch, useContext, useEffect } from "react";
import MuiDrawer from "@mui/material/Drawer";
import { useLocation } from "react-router-dom";
import AppDrawerInner from "./AppDrawerInner";

interface AppDrawerContextType {
  width: number;
  desktop: {
    isOpen: boolean;
    setIsOpen: Dispatch<boolean>;
  };
  mobile: {
    isOpen: boolean;
    setIsOpen: Dispatch<boolean>;
  };
}

export const AppDrawerContext = createContext<AppDrawerContextType | undefined>(
  undefined
);

const AppDrawer = () => {
  const drawerContext = useContext(AppDrawerContext);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();

  useEffect(() => {
    drawerContext?.mobile.setIsOpen(false);
  }, [location.pathname]);

  const drawerWidth = drawerContext?.width;
  const CustomDrawer = styled(MuiDrawer)({
    "& .MuiDrawer-paper": {
      whiteSpace: "nowrap",
      height: "99.99vh",
      width: drawerWidth,
      transition: theme["transitions"].create("width", {
        easing: theme["transitions"].easing.sharp,
        duration: theme["transitions"].duration.enteringScreen,
      }),
      boxSizing: "border-box",
    },
  });

  const container =
    window !== undefined ? () => window.document.body : undefined;

  const handleDrawerToggle = () => {
    if (sm) {
      drawerContext?.mobile.setIsOpen(!drawerContext?.mobile.isOpen);
    } else {
      drawerContext?.desktop.setIsOpen(!drawerContext?.desktop.isOpen);
    }
  };

  return (
    <>
      <Drawer
        component="nav"
        variant="temporary"
        container={container}
        open={drawerContext?.mobile.isOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { sm: "block", md: "none" },
          displayPrint: "none",
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            color: theme => theme.custom.drawer.color,
          },
        }}
      >
        <AppDrawerInner />
      </Drawer>
      <CustomDrawer
        component="nav"
        variant="permanent"
        sx={{
          display: { xs: 'none', md: "block" },
          displayPrint: "none",
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            color: theme => theme.custom.drawer.color,
          },
        }}
        theme={theme}
        open
      >
        <AppDrawerInner />
      </CustomDrawer>
    </>
  );
};

export default AppDrawer;
