import { Box, styled } from "@mui/material";

const CenteredBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection:'column',
  height: '100%',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center', 
}));

export default CenteredBox