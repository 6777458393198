import React, { useEffect, useState } from "react";
import { TabContent } from "../types";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import BasicTabs from "../components/layouts/AppTabs/AppTabs";
import GeneralTab from "../components/pageSpecific/Demand/GeneralTab/GeneralTab";
import { Link, useNavigate, useParams } from "react-router-dom";
import { deleteDemand, getDemand, updateDemand } from "../data/Demand/requests";
import { DemandGeneralPatch, DemandRow } from "../data/Demand/models";
import ErrorMessage from "../components/common/error/ErrorMessage";
import { getAccountNoContacts } from "../data/Account/requests";
import useUser from "../hooks/useUserData";
import { AccountRowNoContacts } from "../data/Account/models";
import { Box, Grid, Typography } from "@mui/material";
import SpecificTab from "../components/pageSpecific/Demand/SpecificTab/SpecificTab";
import CustomLinkButton from "../components/common/buttons/CustomLinkButton";
import { RouterRoutes, routes } from "../routes/routes";
import RegroupmentTab from "../components/pageSpecific/Demand/RegroupmentTab/RegroupmentTab";
import useTabs from "../hooks/useTabs";
import CustomButton from "../components/common/buttons/CustomButton";
import { useSnackbar } from "../hooks/useSnackbar";
import { useConfirm } from "../hooks/useConfirmation";
import {
  canUserDeleteDemand,
  canUserEditDemand,
  demandEditableStatus,
  isDemandDeletable,
  isDemandEditable,
  isDemandOfTypeEvent,
  isDemandPrincipalIncident,
} from "../data/Demand/rules";
import { televisionDisplayDisplayNames } from "../data/Demand/displayNames";
import { editDemandTelevisionDisplayFields } from "../data/Demand/forms/televisionDisplay";
import HistoricAttachmentProvider from "../providers/HistoricAttachmentProvider";
import AppTabsSkeleton from "../components/layouts/AppTabs/AppTabsSkeleton";
import AccountCardSkeleton from "../components/pageSpecific/Demand/GeneralTab/AccountCard/AccountCardSkeleton";
import BoxResponsivePadding from "../components/common/boxes/BoxResponsivePadding";
import AttachmentCardSkeleton from "../components/pageSpecific/Demand/GeneralTab/AttachmentCard/AttachmentCardSkeleton";

const Demand = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const [account, setAccount] = useState<AccountRowNoContacts>();
  const [demand, setDemand] = useState<DemandRow>();

  const { id } = useParams();
  const { userData } = useUser();
  const { activeTab } = useTabs();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const confirm = useConfirm();

  const getData = async () => {
    if (!id) {
      setError("Url invalide");
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    const [demand, account] = await Promise.all([
      getDemand(id),
      getAccountNoContacts(userData.parentcustomerid.value),
    ]);

    if (!demand.data || demand.error) {
      setError(demand.error);
      setIsLoading(false);
      return;
    }
    if (!account.data || account.error) {
      setError(account.error);
      setIsLoading(false);
      return;
    }
    setAccount(account.data);
    setDemand(demand.data);
    setIsLoading(false);
  };

  const deactivateSubDemand = async (demandid: string) => {
    setIsLoading(true);
    const unusedDemand: DemandGeneralPatch = {
      vdl_detailsdelademande: {
        statuscode: "Inutilisée",
      },
    };

    const demandRes = await updateDemand(demandid, unusedDemand);

    if (demandRes.status === "error") {
    } else {
      await getData();
      showSnackbar("La demande a été modifiée avec succès", "success");
    }
  };

  useEffect(() => {
    getData();
  }, [id]);

  if (isLoading)
    return (
      // <PageTitleLayout title="Modifier demande" isLoading>
      //   <SectionLoading />
      // </PageTitleLayout>
      <DemandPageLoading activeTab={activeTab} />
    );

  if (error || !demand || !account) return <ErrorMessage message={error} />;

  const getTabs = (): TabContent[] => {
    const tabData: TabContent[] = [
      {
        label: "Informations générales",
        component: <GeneralTab demand={demand} account={account} />,
      },
      {
        label: "Informations spécifiques",
        component: <SpecificTab demand={demand} />,
      },
    ];
    isDemandOfTypeEvent(demand) &&
      tabData.push({
        label: `Demandes associées (${demand.regroupement.demandes.length})`,
        component: (
          <RegroupmentTab
            demand={demand}
            deactivateSubDemand={deactivateSubDemand}
          />
        ),
      });
    return tabData;
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    if (isDemandOfTypeEvent(demand)) {
      const unusedDemands: string[] = [];
      const usedDemands: string[] = [];
      demand.regroupement.demandes.map((subDemand) => {
        if (subDemand.statuscode === "Inutilisée")
          unusedDemands.push(subDemand.title);
        if (subDemand.statuscode === "En création") {
          usedDemands.push(subDemand.title);
        }
      });

      const usedMessage =
        usedDemands.length > 0
          ? `${
              usedDemands.length
            } sous-demandes seront soumises. \n -${usedDemands.join(",\n-")} `
          : undefined;

      const unusedMessage =
        unusedDemands.length > 0
          ? `\n\n${
              unusedDemands.length
            } sous-demandes s seront supprimées. \n -${unusedDemands.join(
              ",\n-"
            )}`
          : undefined;

      const confirmResult = await confirm({
        title: `Cette opération affectera les sous-demandes associées.`,
        message: `${usedMessage}${unusedMessage} \n\b`,
        confirmText: "Continuer",
        cancelText: "Annuler",
      });

      if (!confirmResult) {
        setIsLoading(false);
        return;
      }
    }

    try {
      let errorFields: string[] = [];
      if (1 < 0 && demand.type === "Télévision communautaire") {
        const details = demand.vdl_detailsdelademande;

        Object.keys(details).forEach((_key) => {
          const key = _key as keyof typeof details;
          if (
            Object.prototype.hasOwnProperty.call(
              editDemandTelevisionDisplayFields,
              key
            )
          ) {
            if (
              editDemandTelevisionDisplayFields[
                key as keyof typeof editDemandTelevisionDisplayFields
              ].required ??
              false
            ) {
              switch (typeof details[key]) {
                case "undefined":
                  errorFields.push(televisionDisplayDisplayNames[key]);
                  break;
                case "number":
                  break;
                default:
                  break;
              }
            }
          }
        });
        if (errorFields.length > 0) {
          await confirm({
            title: "Cette demande n'est pas complète",
            message: `Certains champs requis sont vides, veuillez les remplir avant de soumettre la demande \n${errorFields.join(
              "\n"
            )}`,
            confirmText: "Ok",
          });
          setIsLoading(false);
          return;
        }
      }
    } catch (error) {
      setIsLoading(false);
    }

    const submittedDemand: DemandGeneralPatch = {
      vdl_detailsdelademande: {
        statuscode: "Soumise",
      },
    };

    const demandRes = await updateDemand(demand.incidentid, submittedDemand);

    if (demandRes.status === "error") {
      if (demandRes.statuscode === 490) {
        await confirm({
          title: "Erreur dans la soumission",
          message: demandRes.error,
          confirmText: "Ok",
        });
      } else {
        showSnackbar(demandRes.error, "error");
      }
    } else {
      navigate( `${routes.demands.path}/${
        demand.regroupement && !isDemandPrincipalIncident(demand)
          ? demand.regroupement.principalIncidentId + "?tab=2"
          : ""
      }`);
      showSnackbar("La demande a été soumise avec succès", "success");
    }
    setIsLoading(false);
  };

  const handleDelete = async () => {
    const confirmResult = await confirm({
      title: "Êtes-vous certain?",
      message: "Une fois supprimée, la demande ne pourra pas être récupérée.",
      confirmText: "Continuer",
      cancelText: "Annuler",
    });
    if (!confirmResult) return;

    setIsLoading(true);

    const demandRes = await deleteDemand(demand.incidentid);

    if (demandRes.status === "error") {
      showSnackbar(demandRes.error, "error");
    } else {
      showSnackbar("La demande a été supprimé avec succès", "success");
      navigate(routes.demands.path);
    }
    setIsLoading(false);
  };
  return (
    <PageTitleLayout
      title={demand.ticketnumber}
      sideTitleComponent={
        isDemandEditable(demand) &&
        canUserEditDemand(userData, demand.vdl_contactprincipal?.contactid) ? (
          <Box display="flex" gap="8px" flexWrap="wrap" justifyContent="center">
            {activeTab < 2 ? (
              <CustomLinkButton
                to={`${RouterRoutes.Edit}?tab=${activeTab}`}
                secondary
              >
                Modifier
              </CustomLinkButton>
            ) : null}
            {activeTab < 2 &&
              canUserDeleteDemand(
                userData,
                demand.vdl_contactprincipal?.contactid
              ) &&
              isDemandDeletable(demand) && (
                <CustomButton onClick={handleDelete} secondary>
                  Supprimer
                </CustomButton>
              )}
            <CustomButton onClick={handleSubmit} secondary>
              Soumettre
            </CustomButton>
          </Box>
        ) : undefined
      }
      underTitleComponent={
        demand.regroupement && !isDemandPrincipalIncident(demand) ? (
          <Typography
            color="white"
            fontWeight="bold"
            component={Link}
            to={`${routes.demands.path}/${demand.regroupement.principalIncidentId}/?tab=0`}
          >
            Demande parent
          </Typography>
        ) : undefined
      }
    >
      <HistoricAttachmentProvider>
        <BasicTabs tabs={getTabs()} />
      </HistoricAttachmentProvider>
    </PageTitleLayout>
  );
};

export default Demand;

const DemandPageLoading = ({ activeTab }: { activeTab: number }) => {
  return (
    <PageTitleLayout title="Modifier demande" isLoading>
      <AppTabsSkeleton tabsNumber={2} activeTabIndex={activeTab} />
      <BoxResponsivePadding gap="16px">
        <Grid container columnGap={"1rem"} rowGap={"1rem"}>
          <Grid item xs={12} height={"100%"} xl>
            <AccountCardSkeleton />
          </Grid>
          <Grid item xs={12} xl={4.5}>
            <AccountCardSkeleton />
            <Grid style={{ marginTop: "1rem" }}>
              <AttachmentCardSkeleton />
            </Grid>
          </Grid>
        </Grid>
      </BoxResponsivePadding>
    </PageTitleLayout>
  );
};
