import { Box } from "@mui/material";
import { DemandEventSupportRow } from "../../../../../data/Demand/models";
import DisplayFieldCard from "../../../../common/cards/DisplayFieldCard";
import { eventSupportDisplayNames } from "../../../../../data/Demand/displayNames";

type Props = {
  demand: DemandEventSupportRow;
};

const EventSupport = ({ demand }: Props) => {
  const details = demand.vdl_detailsdelademande;
  return (
    <Box display="grid" gap="16px">
      <DisplayFieldCard
        title=""
        fields={[
          {
            label: eventSupportDisplayNames.vdl_amenagementelectriquetemporaire,
            value: details.vdl_amenagementelectriquetemporaire,
            type: "check",
          },
        ]}
        columns={2}
        minColWidth={300}
      />
      <DisplayFieldCard
        title=""
        fields={[
          {
            label: eventSupportDisplayNames.vdl_typedappareil,
            value: details.vdl_typedappareil,
            type: "string",
          },
          {
            label: eventSupportDisplayNames.vdl_nombredeprise,
            value: details.vdl_nombredeprise,
            type: "string",
          },
          {
            label: eventSupportDisplayNames.vdl_intensiteetvoltage,
            value: details.vdl_intensiteetvoltage,
            type: "string",
          },

          {
            label: eventSupportDisplayNames.vdl_typedappareil2,
            value: details.vdl_typedappareil2,
            type: "string",
          },
          {
            label: eventSupportDisplayNames.vdl_nombredeprise2,
            value: details.vdl_nombredeprise2,
            type: "string",
          },
          {
            label: eventSupportDisplayNames.vdl_intensiteetvoltage2,
            value: details.vdl_intensiteetvoltage2,
            type: "string",
          },

          {
            label: eventSupportDisplayNames.vdl_typedappareil3,
            value: details.vdl_typedappareil3,
            type: "string",
          },
          {
            label: eventSupportDisplayNames.vdl_nombredeprise3,
            value: details.vdl_nombredeprise3,
            type: "string",
          },
          {
            label: eventSupportDisplayNames.vdl_intensiteetvoltage3,
            value: details.vdl_intensiteetvoltage3,
            type: "string",
          },
        ]}
        columns={2}
        minColWidth={300}
      />

      <DisplayFieldCard
        title=""
        fields={[
          {
            label:
              eventSupportDisplayNames.vdl_descriptionadditionneldamenagementelectrique,
            value: details.vdl_descriptionadditionneldamenagementelectrique,
            type: "string",
          },
        ]}
        columns={2}
        minColWidth={300}
      />
    </Box>
  );
};

export default EventSupport;
