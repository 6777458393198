import { Box } from "@mui/material";
import { DemandPrismeRow } from "../../../../../data/Demand/models";
import { prismeDisplayNames } from "../../../../../data/Demand/displayNames";
import DisplayFieldCard from "../../../../common/cards/DisplayFieldCard";
import { dateStringToShortString } from "../../../../../data/common/fieldToString";

type Props = {
  demand: DemandPrismeRow;
};

const Prisme = ({ demand }: Props) => {
  const details = demand.vdl_detailsdelademande;
  return (
    <Box display="grid" gap="16px">
      <DisplayFieldCard
        title="Activité"
        fields={[
          {
            label: prismeDisplayNames.vdl_activiteapromouvoir,
            value: details.vdl_activiteapromouvoir,
            type: "string",
          },
          {
            label: prismeDisplayNames.vdl_datedelactivite,
            value: dateStringToShortString(details.vdl_datedelactivite),
            type: "string",
          },
        ]}
        columns={1}
        minColWidth={300}
      />
      <DisplayFieldCard
        title="Période d'affichage"
        fields={[
          {
            label: prismeDisplayNames.vdl_datededebutdelevenement,
            value: dateStringToShortString(details.vdl_datededebutdelevenement),
            type: "string",
          },
          {
            label: prismeDisplayNames.vdl_datedefindelevenement,
            value: dateStringToShortString(details.vdl_datedefindelevenement),
            type: "string",
          },
        ]}
        columns={2}
        minColWidth={300}
      />
      <DisplayFieldCard
        title="Période d'affichage"
        fields={[
          {
            label: prismeDisplayNames.vdl_esquissedelaffichefile,
            value: details.vdl_esquissedelaffichefile,
            type: "file",
            span: 2,
          },
          {
            label: prismeDisplayNames.vdl_nombredepanneaux,
            value: details.vdl_nombredepanneaux
              ? details.vdl_nombredepanneaux
              : "",
            type: "string",
            span: 2,
          },
          {
            label: prismeDisplayNames.vdl_autoroute25bouldelaconcordeestsite3,
            value: details.vdl_autoroute25bouldelaconcordeestsite3 ?? false,
            type: "check",
          },
          {
            label: prismeDisplayNames.vdl_monteedumoulinleparcdumoulinsite10,
            value: details.vdl_monteedumoulinleparcdumoulinsite10 ?? false,
            type: "check",
          },
          {
            label:
              prismeDisplayNames.vdl_bouldeslaurentidesboulsaintmartinsite1,
            value: details.vdl_bouldeslaurentidesboulsaintmartinsite1 ?? false,
            type: "check",
          },
          {
            label: prismeDisplayNames.vdl_bouldeslaurentidesboulcartiersite2,
            value: details.vdl_bouldeslaurentidesboulcartiersite2 ?? false,
            type: "check",
          },
          {
            label: prismeDisplayNames.vdl_boullecorbusierbouldusouvenirsite8,
            value: details.vdl_boullecorbusierbouldusouvenirsite8 ?? false,
            type: "check",
          },
          {
            label: prismeDisplayNames.vdl_bouldelaconcordebouldelavenirsite9,
            value: details.vdl_bouldelaconcordebouldelavenirsite9 ?? false,
            type: "check",
          },
          {
            label: prismeDisplayNames.vdl_boulcurelabelleboullevesquesite4,
            value: details.vdl_boulcurelabelleboullevesquesite4 ?? false,
            type: "check",
          },
          {
            label: prismeDisplayNames.vdl_boularthursauvebouldagenaisouestsite6,
            value: details.vdl_boularthursauvebouldagenaisouestsite6?? false,
            type: "check",
          },
          {
            label: prismeDisplayNames.vdl_boulsamsonavjosaphatdemerssite11,
            value: details.vdl_boulsamsonavjosaphatdemerssite11?? false,
            type: "check",
          },
          {
            label:
              prismeDisplayNames.vdl_bouldeslaurentidesbouldagenaisestsite5,
            value: details.vdl_bouldeslaurentidesbouldagenaisestsite5?? false,
            type: "check",
          },
        ]}
        columns={2}
        minColWidth={300}
      />
    </Box>
  );
};

export default Prisme;
