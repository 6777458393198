import {
  CheckBoxInput,
  FormFields,
  FormSection,
  PhoneInput,
  TextInput,
  DropDownOptionSetInput,
  DropDownLookUpInput,
  RadioInput,
} from "../../../types";
import {demandDisplayNames} from "../displayNames";

export type NewDemandFields = {
  title: TextInput;
  vdl_contactprincipal: DropDownLookUpInput;
};

const newDemandFiels: NewDemandFields = {
  title: {
    type: "text",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    label: demandDisplayNames.title,
  },
  vdl_contactprincipal: {
    type: "lookup",
    error: "",
    value: "",
    choices: [],
    required: true,
    fillLine: true,
    label: demandDisplayNames.vdl_contactprincipal,
  },
};

export { newDemandFiels };
