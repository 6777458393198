import {
  CheckBoxInput,
  FormFields,
  FormSection,
  PhoneInput,
  TextInput,
  DropDownOptionSetInput,
  EmailInput,
} from "../../../types";
import accountDisplayNames from "../displayNames";

import { vdl_secteurChoices } from "./optionSetChoices";

export type EditAccountFields = {
  // Step 1 Info générales
  name: TextInput;
  vdl_autresnomorganisme: TextInput;
  telephone1: PhoneInput;
  emailaddress1: EmailInput;
  websiteurl: TextInput;
  vdl_pagefacebook: TextInput;

  // Step 2 Info adresse du siege social
  vdl_secteursiegesocial: DropDownOptionSetInput;
  searchAddress: TextInput;
  address1_line1: TextInput;
  address1_line2: TextInput;
  address1_city: TextInput;
  address1_postalcode: TextInput;
  vdl_adresseidentique: CheckBoxInput;
  mailingAddressSearch: TextInput;
  address2_line1: TextInput;
  address2_line2: TextInput;
  address2_city: TextInput;
  address2_postalcode: TextInput;

  // Step 3 Consentement
  communicationConsent: CheckBoxInput;
};

const sectionGeneralInfo: FormSection<EditAccountFields> = {
  fields: [
    "name",
    "vdl_autresnomorganisme",
    "emailaddress1",
    "telephone1",
    "websiteurl",
    "vdl_pagefacebook",
  ],
  title: "Informations Générales",
};

const sectionSiegeSocialInfo: FormSection<EditAccountFields> = {
  fields: [
    "vdl_secteursiegesocial",
    "searchAddress",
    "address1_line1",
    "address1_line2",
    "address1_city",
    "address1_postalcode",
  ],
  title: "Adresse du Siège Social",
};

const sectionCorrespondanceInfo: FormSection<EditAccountFields> = {
  fields: [
    "vdl_adresseidentique",
    "mailingAddressSearch",
    "address2_line1",
    "address2_line2",
    "address2_city",
    "address2_postalcode",
  ],
  title: "Adresse de correspondance",
};

const sectionConsent: FormSection<EditAccountFields> = {
  fields: ["communicationConsent"],
  title: "Consentement à la communication",
};

const editAccountSections: FormSection<EditAccountFields>[] = [
  sectionGeneralInfo,
  sectionSiegeSocialInfo,
  sectionCorrespondanceInfo,
];

const editAccountFields: EditAccountFields = {
  // info generales

  name: {
    type: "text",
    value: "",
    error: "",
    required: true,
    label: accountDisplayNames.name,
  },

  vdl_autresnomorganisme: {
    type: "text",
    value: "",
    error: "",
    required: false,
    label: accountDisplayNames.vdl_autresnomorganisme,
  },

  telephone1: {
    type: "phone",
    value: "",
    error: "",
    required: true,
    label: accountDisplayNames.telephone1,
  },

  emailaddress1: {
    type: "email",
    value: "",
    error: "",
    required: true,
    label: accountDisplayNames.emailaddress1,
  },

  websiteurl: {
    type: "text",
    value: "",
    error: "",
    required: false,
    label: accountDisplayNames.websiteurl,
  },

  vdl_pagefacebook: {
    type: "text",
    value: "",
    error: "",
    required: false,
    label: accountDisplayNames.vdl_pagefacebook,
  },

  // info adresse du siege social

  vdl_secteursiegesocial: {
    type: "optionset",
    error: "",
    value: "",
    choices: vdl_secteurChoices,
    required: false,
    fillLine: true,
    label: accountDisplayNames.vdl_secteursiegesocial,
  },

  searchAddress: {
    type: "text",
    value: "",
    error: "",
    required: false,
    fillLine: true,
    label: "Recherche adresse",
  },
  address1_line1: {
    type: "text",
    maxLength: 300,
    value: "",
    error: "",
    required: true,
    label: accountDisplayNames.address1_line1,
  },
  address1_line2: {
    type: "text",
    maxLength: 300,
    value: "",
    error: "",
    required: false,
    label: accountDisplayNames.address1_line2,
  },
  address1_city: {
    type: "text",
    value: "",
    error: "",
    required: true,
    label: accountDisplayNames.address1_city,
  },
  address1_postalcode: {
    type: "text",
    value: "",
    error: "",
    required: true,
    label: accountDisplayNames.address1_postalcode,
  },
  vdl_adresseidentique: {
    type: "checkbox",
    value: false,
    error: "",
    required: false,
    fillLine: true,
    label: accountDisplayNames.vdl_adresseidentique,
    onChangeEvent: onSameAddressChange,
  },
  mailingAddressSearch: {
    type: "text",
    maxLength: 300,
    value: "",
    error: "",
    required: false,
    fillLine: true,
    label: "Recherche",
    disabledCondition: isAdresseIdentique,
  },
  address2_line1: {
    type: "text",
    maxLength: 300,
    value: "",
    error: "",
    required: true,
    label: accountDisplayNames.address2_line1,
    disabledCondition: isAdresseIdentique,
  },
  address2_line2: {
    type: "text",
    maxLength: 300,
    value: "",
    error: "",
    required: false,
    label: accountDisplayNames.address2_line2,
    disabledCondition: isAdresseIdentique,
  },
  address2_city: {
    type: "text",
    value: "",
    error: "",
    required: true,
    label: accountDisplayNames.address2_city,
    disabledCondition: isAdresseIdentique,
  },
  address2_postalcode: {
    type: "text",
    value: "",
    error: "",
    required: true,
    label: accountDisplayNames.address2_postalcode,
    disabledCondition: isAdresseIdentique,
  },

  // Consentement
  communicationConsent: {
    type: "checkbox",
    value: false,
    error: "",
    required: false,
    label: "Conscentement",
  },
};

function isAdresseIdentique(fields: FormFields): boolean {
  const editFields = fields as EditAccountFields;
  return editFields.vdl_adresseidentique.value;
}

function onSameAddressChange(fields2: FormFields): FormFields {
  try {
    const fields = <EditAccountFields>fields2;
    let _fields: Partial<EditAccountFields> = {};
    _fields.address2_line1 = {
      ...fields.address2_line1,
      value: fields.address1_line1.value,
    };
    _fields.address2_line2 = {
      ...fields.address2_line2,
      value: fields.address1_line2.value,
    };
    _fields.address2_city = {
      ...fields.address2_city,
      value: fields.address1_city.value,
    };
    _fields.address2_postalcode = {
      ...fields.address2_postalcode,
      value: fields.address1_postalcode.value,
    };

    return _fields;
  } catch (e) {
    return {};
  }
}

function roleDisabled(fields: FormFields): boolean {
  try {
    return fields.accessPortail.value === false;
  } catch (e) {
    return false;
  }
}

const editAccountForm = {
  fields: editAccountFields,
  sections: editAccountSections,
};

export { editAccountForm, editAccountSections, editAccountFields };
