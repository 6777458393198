import React from "react";
import { Box, Grid, Skeleton, Typography, styled } from "@mui/material";

type Props = {
  icon: boolean;
};

const CardBox = styled(Box)(({ theme }) => ({
  display: "grid",
  rowGap: "8px",
  columnGap: "16px",
  height: "100%",
  alignItems: "center",
  gridTemplateColumns: "auto 1fr",
  [theme.breakpoints.up("sm")]: {
    gridTemplateColumns: "auto auto auto",
    justifyContent: "space-between",
  },
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "auto auto auto auto",
  },
  [theme.breakpoints.up("lg")]: {
    gridTemplateColumns: "4fr 3rem 3fr auto 6.5rem",
    gridTemplateRows: "auto auto",
    gridAutoFlow: "column",
    rowGap: "0",
  },
}));

const DemandCardSkeleton = ({ icon }: Props) => {
  return (
    <Box padding="16px" bgcolor="background.paper" borderRadius="4px">
      <CardBox>
        <Box
          sx={{
            gridRow: {},
            gridColumn: {
              xs: "span 2",
              sm: "span 3",
              md: "span 4",
              lg: "span 1",
            },
          }}
        >
          <Skeleton
            variant="text"
            sx={{
              fontSize: "1.25rem",
              lineHeight: 1.4,
            }}
            width={"23.8%"}
          />
        </Box>
        <Box
          sx={{
            gridRow: { xs: "3", md: "2" },
            gridColumn: {
              xs: "span 2",
              sm: "span 1",
            },
          }}
        >
          <Skeleton
            sx={{
              fontSize: "0.9rem",
            }}
            width={"190px"}
          />
          <Box display="flex" flexDirection="row" gap={"0.4rem"}>
            <Skeleton
              sx={{
                fontSize: "0.9rem",
              }}
              width={"59%"}
            />
            <Skeleton
              sx={{
                fontSize: "0.9rem",
              }}
              width={"41%"}
            />
          </Box>
        </Box>
        <Box
          sx={{
            gridRow: {
              xs: "2",
              sm: "2/span 2",
              md: "2",
              lg: "1 / span 2",
            },
            gridColumn: {
              xs: "2",
            },
          }}
        >
          {icon && (
            <Skeleton
              variant="circular"
              width={"calc(1.5rem + 16px)"}
              height={"calc(1.5rem + 16px)"}
            />
          )}
        </Box>
        <Box
          sx={{
            gridRow: {
              xs: "4",
              md: "3",
              lg: "1/span 2",
            },
            gridColumn: {
              xs: "span 2",
              sm: "span 1",
              lg: "3",
            },
            paddingX: {
              lg: "16px",
            },
          }}
        >
          <Box display="flex" flexDirection="row" gap="0.4rem">
            <Skeleton
              sx={{
                fontSize: "1.1rem",
              }}
              width={"30%"}
            />
            <Skeleton
              sx={{
                fontSize: "1rem",
              }}
              width={"70%"}
            />
          </Box>
        </Box>
        <Box
          sx={{
            gridRow: {
              sm: "2",
              md: "2",
              lg: "span 2",
            },
            gridColumn: {
              md: "3",
              lg: "4",
            },
          }}
        >
          <Skeleton width={"95px"} height={"46px"} />
        </Box>
        <Box
          display="flex"
          gap="12px"
          alignItems="center"
          sx={{
            gridRow: {
              xs: "5",
              sm: "2/span 2",
              md: "2",
              lg: "span 2",
            },
            gridColumn: {
              xs: "span 2",
              sm: "3/span 1",
              md: "4",
              lg: "5",
            },
          }}
        >
          {icon && (
            <Skeleton
              variant="circular"
              width={"calc(1.5rem + 16px)"}
              height={"calc(1.5rem + 16px)"}
            />
          )}
          {/* icon && (
            <Skeleton
              variant="circular"
              width={"calc(1.5rem + 16px)"}
              height={"calc(1.5rem + 16px)"}
            />
          ) */}
        </Box>
      </CardBox>
    </Box>
  );
};

export default DemandCardSkeleton;
