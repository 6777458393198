import { Box, Typography } from "@mui/material";
import CenteredBox from "../boxes/CenteredBox";
import { RouterRoutes } from "../../../routes/routes";
import SimpleLink from "../links/SimpleLink";

export default function Unauthorized() {

  return (
    <CenteredBox>
      <Box
        sx={{
          borderColor: 'primary.main',
          borderStyle: 'solid', 
          borderRadius: 2,
          display: 'block',
          p: 2,
          m: 2
        }}
      >
        <Typography variant="h5" textAlign="center" sx={{margin: theme => theme.spacing(3)}}>
            Vous n'avez pas accès à ce contenu.
        </Typography>
        
        <SimpleLink
          to={RouterRoutes.Home}
          text="Retourner à l'accueil"
        />
      </Box>
    </CenteredBox>
  );
}