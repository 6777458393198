import { Box, Skeleton } from "@mui/material";

const DocumentSkeleton = () => {
  return (
    <Box>
      <Skeleton
        variant="rectangular"
        sx={{ borderRadius: "4px / 6.7px ", fontSize: "2.08rem" }}
      ></Skeleton>
      {Array(6)
        .fill(0)
        .map((i, idx) => (
          <Skeleton
            key={idx}
            variant="rectangular"
            sx={{ fontSize: " 1.25rem", marginTop: "8px" }}
          ></Skeleton>
        ))}
    </Box>
  );
};

export default DocumentSkeleton;
