import { AccountRow, AccountRowNoContacts } from "./models";

export const getFullAddress = (
  account: AccountRow | AccountRowNoContacts
): string =>
  [
    account.address1_line1,
    account.address2_city,
    account.address1_stateorprovince,
    account.address1_postalcode,
  ]
    .filter((part) => part !== undefined)
    .join(", ");
