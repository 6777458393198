import React, { useState } from "react";
import {
  DemandEcoSupportPatch,
  DemandEcoSupportRow,
} from "../../../../data/Demand/models";
import SimpleFields from "../../../form/SimpleForm/SimpleFields";
import { NewDemandFields } from "../../../../data/Demand/forms/dialog";
import { updateDemand } from "../../../../data/Demand/requests";
import EditDemandLayout from "../EditDemandLayout";
import handleSimpleFormSubmit from "../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../hooks/useTabs";
import {
  EditDemandEcoSupportFields,
  editDemandEcoSupportFields,
  editDemandEcoSupportSections,
} from "../../../../data/Demand/forms/ecoSupport";
import { validateDateString } from "../../../../data/common/validateDateString";
import buildForm from "../../../form/SimpleForm/buildForm";

type Props = {
  demand: DemandEcoSupportRow;
  isNewDemand: boolean;
  fieldsGeneral: NewDemandFields;
  onUpdateSuccess: () => void;
  onUpdateFailure: (error?: string) => void;
  setFieldsGeneral: React.Dispatch<React.SetStateAction<NewDemandFields>>;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const EcoSupport = ({
  demand,
  isNewDemand,
  fieldsGeneral,
  setFieldsGeneral,
  onUpdateSuccess,
  onUpdateFailure,
  setError,
}: Props) => {
  const details = demand.vdl_detailsdelademande;
  const [fields, setFields] = useState<EditDemandEcoSupportFields>(
    buildForm(editDemandEcoSupportFields, {
      vdl_duobacsroulants240ldechetrecyclage:
        details.vdl_duobacsroulants240ldechetrecyclage ?? false,
      vdl_qte2: details.vdl_qte2,
      vdl_duobarils205ldechetsrecyclage:
        details.vdl_duobarils205ldechetsrecyclage ?? false,
      vdl_qte4: details.vdl_qte4,
      vdl_bacroulantbrun240lcompost:
        details.vdl_bacroulantbrun240lcompost ?? false,
      vdl_qte5: details.vdl_qte5,
      vdl_bacslimjimnoir90lavecsacsdechet:
        details.vdl_bacslimjimnoir90lavecsacsdechet ?? false,
      vdl_qte6: details.vdl_qte6,
      vdl_bacslimjimbleu90lavecsacsrecyclage:
        details.vdl_bacslimjimbleu90lavecsacsrecyclage ?? false,
      vdl_qte: details.vdl_qte,
      vdl_bacslimjimbrun90lavecsacscompost:
        details.vdl_bacslimjimbrun90lavecsacscompost ?? false,
      vdl_qte1: details.vdl_qte1,
      vdl_supportaveloavecchainesetcadenas:
        details.vdl_supportaveloavecchainesetcadenas ?? false,
      vdl_addressedelivraison: details.vdl_addressedelivraison ?? "",
      vdl_datedelivraison: validateDateString(details.vdl_datedelivraison),
      vdl_datederecupperation: validateDateString(
        details.vdl_datederecupperation
      ),
      vdl_kiosqueenvironnementecocitoyennete:
        details.vdl_kiosqueenvironnementecocitoyennete ?? false,
      vdl_servicedeplanificationetdaccompagnement:
        details.vdl_servicedeplanificationetdaccompagnement ?? false,
    })
  );
  const { activeTab, changeTab } = useTabs();

  const onSubmit = async () => {
    const errors = handleSimpleFormSubmit(fields, setFields);

    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      const scrollTo = fields[errors[errors.length - 1]].fieldRef;
      if (scrollTo) {
        scrollTo.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
      return;
    }

    const _demand: DemandEcoSupportPatch = {
      title: fieldsGeneral.title.value,
      vdl_contactprincipal: fieldsGeneral.vdl_contactprincipal.value,
      type: demand.type,
      vdl_detailsdelademande: {
        vdl_duobacsroulants240ldechetrecyclage:
          fields.vdl_duobacsroulants240ldechetrecyclage.value,
        vdl_qte2: fields.vdl_qte2.value,
        vdl_duobarils205ldechetsrecyclage:
          fields.vdl_duobarils205ldechetsrecyclage.value,
        vdl_qte4: fields.vdl_qte4.value,
        vdl_bacroulantbrun240lcompost:
          fields.vdl_bacroulantbrun240lcompost.value,
        vdl_qte5: fields.vdl_qte5.value,
        vdl_bacslimjimnoir90lavecsacsdechet:
          fields.vdl_bacslimjimnoir90lavecsacsdechet.value,
        vdl_qte6: fields.vdl_qte6.value,
        vdl_bacslimjimbleu90lavecsacsrecyclage:
          fields.vdl_bacslimjimbleu90lavecsacsrecyclage.value,
        vdl_qte: fields.vdl_qte.value,
        vdl_bacslimjimbrun90lavecsacscompost:
          fields.vdl_bacslimjimbrun90lavecsacscompost.value,
        vdl_qte1: fields.vdl_qte1.value,
        vdl_supportaveloavecchainesetcadenas:
          fields.vdl_supportaveloavecchainesetcadenas.value,

        vdl_addressedelivraison: fields.vdl_addressedelivraison.value,
        vdl_datedelivraison: fields.vdl_datedelivraison.value?.toISOString(),
        vdl_datederecupperation:
          fields.vdl_datederecupperation.value?.toISOString(),

        vdl_kiosqueenvironnementecocitoyennete:
          fields.vdl_kiosqueenvironnementecocitoyennete.value,
        vdl_servicedeplanificationetdaccompagnement:
          fields.vdl_servicedeplanificationetdaccompagnement.value,
      },
    };

    if (demand.statuscode === "Inutilisée") {
      _demand.vdl_detailsdelademande.statuscode = "En création";
    }

    const { status, error } = await updateDemand(demand.incidentid, _demand);
    if (status === "success") {
      onUpdateSuccess();
    } else {
      onUpdateFailure(error);
    }
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_lienhautduformulaireportail}
    >
      <SimpleFields
        updateRefs
        fields={fields}
        setFields={setFields}
        sections={editDemandEcoSupportSections}
        sectionsBackground="common.white"
        columns={{ minWidth: 260, count: 2 }}
      />
    </EditDemandLayout>
  );
};

export default EcoSupport;
