import { Box, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import ActivityCard from "./ActivityCard";
import { ActivityRow } from "../../../../../../data/Activity/models";
import { useParams } from "react-router-dom";
import HistoricEventCardSkeletton from "./ActivityCardSkeletton";
import ErrorMessage from "../../../../../common/error/ErrorMessage";
import CustomButton from "../../../../../common/buttons/CustomButton";
import NewMessageDialog from "./NewMessageDialog/NewMessageDialog";
import { HistoricAttachmentContext } from "../../../../../../providers/HistoricAttachmentProvider";
import { DemandGeneralRow } from "../../../../../../data/Demand/models";

type Props = {
  demand: DemandGeneralRow;
};

const HistoricSection = ({ demand }: Props) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { refreshData, activities, isLoading, error } = useContext(
    HistoricAttachmentContext
  );
  const { id } = useParams();

  const handleAddActivity = async () => {
    refreshData(demand.title);
    setDialogOpen(false);
  };

  useEffect(() => {
    refreshData(demand.title);
  }, []);

  if (isLoading)
    return (
      <Box >
        <Typography
          variant="h6"
          fontWeight="bold"
          color="primary"
          paddingBottom="16px"
        >
          Historique
        </Typography>
        {Array(2)
          .fill(0)
          .map((_, idx) => (
            <HistoricEventCardSkeletton key={idx} />
          ))}
      </Box>
    );

  if (!activities || error.activitites || !id)
    return (
      <Box
        display="grid"
        gap="16px"
        paddingBottom="16px"
      >
        <Typography variant="h6" fontWeight="bold" color="primary">
          Historique
        </Typography>
        <ErrorMessage message={error.activitites} />
      </Box>
    );

  return (
    <Box id="historique">
      {dialogOpen && (
        <NewMessageDialog
          open={dialogOpen}
          setOpen={setDialogOpen}
          handleAddActivity={handleAddActivity}
          demandid={id}
        />
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        gap="16px"
        paddingBottom="16px"
      >
        <Typography variant="h6" fontWeight="bold" color="primary">
          Historique
        </Typography>
        <CustomButton primary onClick={() => setDialogOpen(true)}>
          Ajouter une information
        </CustomButton>
      </Box>

      {activities.map((activity) => (
        <ActivityCard activity={activity} key={activity.activityid} />
      ))}
    </Box>
  );
};

export default HistoricSection;
