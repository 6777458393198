import {
  AccountInfo,
} from "@azure/msal-common";

export const b2cPolicies = {
  names: {
    signUpSignIn: "b2c_1_susi_reset_v2",
    editProfile: "b2c_1_edit_profile_v2",
  },
  authorities: {
    signUpSignIn: {
      authority: process.env.REACT_APP_MSAL_SIGNIN_AUTHORITY ?? "",
    },
    signUp: {
      authority: process.env.REACT_APP_MSAL_SIGNUP_AUTHORITY ?? "",
    },
    forgotPassword: {
      authority: process.env.REACT_APP_MSAL_FORGOTPW_AUTHORITY ?? "",
    },
  },
  authorityDomain: process.env.REACT_APP_MSAL_AUTHORITY_DOMAIN ?? "",
};

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID ?? "",
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: "/auth",
    navigateToLoginRequestUrl: true,
  },
};

export const signUpRequestBuilder = (email?: string) => {
  return {
    authority: email ? b2cPolicies.authorities.signUp.authority : b2cPolicies.authorities.signUpSignIn.authority,
    scopes: [process.env.REACT_APP_MSAL_API_ACCESS_SCOPE ?? ""],
    extraQueryParameters: email ? { email: email, invite: "true" } : undefined,
    redirectUri: email ? "/auth" : undefined
  };
};

export const loginRequest = {
  scopes: [],
  
};

export const loginRequestWithAccount = (account: AccountInfo) => {
  return {
    scopes: [],
    authority: account.tenantId === "B2C_1_PortailOrg_SigninSignup" ? b2cPolicies.authorities.signUpSignIn.authority : b2cPolicies.authorities.signUp.authority,
  };
};
