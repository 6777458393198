import { FileInput, TextInput } from "../../../../types";
import { publicSpaceDisplayNames } from "../../displayNames";

export type EditDemandPublicSpaceFields = {
  vdl_name: TextInput;
};

const editDemandPublicSpaceFields: EditDemandPublicSpaceFields = {
  vdl_name: {
    type: "text",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    label: publicSpaceDisplayNames.vdl_name,
  },
};

export { editDemandPublicSpaceFields };
