import { Box } from "@mui/material";
import { DemandPermitRow } from "../../../../../data/Demand/models";
import { permitDisplayNames } from "../../../../../data/Demand/displayNames";
import DisplayFieldCard from "../../../../common/cards/DisplayFieldCard";

type Props = {
  demand: DemandPermitRow;
};

const Permit = ({ demand }: Props) => {
  const details = demand.vdl_detailsdelademande;

  const diffusionSonore = (
    <DisplayFieldCard
      title="Diffusion sonore"
      fields={[
        {
          label:
            permitDisplayNames.vdl_utilisationdunsystemedesonorisationamplifie,
          value: details.vdl_utilisationdunsystemedesonorisationamplifie,
          type: "check",
          span: 2
        },
        {
          label:
            permitDisplayNames.vdl_autreappareilsusceptibledenuireauvoisinage,
          value: details.vdl_autreappareilsusceptibledenuireauvoisinage,
          type: "check",
        },
        {
          label: permitDisplayNames.vdl_precisez8,
          value: details.vdl_precisez8,
          type: "string",
        },
      ]}
      columns={2}
      minColWidth={275}
      activeRule={{
        hide: true,
        value: details.vdl_diffusionsonore,
      }}
    />
  );

  return (
    <Box display="grid" gap="16px">
      {details.vdl_typedactivite === "Évènement" ? (
        <>
          <DisplayFieldCard
            title="Consommation de boissons alcoolisées"
            fields={[]}
            columns={1}
            minColWidth={275}
            activeRule={{
              hide: true,
              value: details.vdl_consommationdeboissonsalcooliques,
            }}
          />
          <DisplayFieldCard
            title="Vente de boissons alcoolisées"
            fields={[
              {
                label: permitDisplayNames.vdl_venteestgereeparledemandeur1,
                value: details.vdl_venteestgereeparledemandeur1,
                type: "check",
                span: 2,
              },
              {
                label: permitDisplayNames.vdl_ventegereeparuntiers1,
                value: details.vdl_ventegereeparuntiers1,
                type: "check",
              },
              {
                label: permitDisplayNames.vdl_precisez4,
                value: details.vdl_precisez4,
                type: "string",
              },
              {
                label: permitDisplayNames.vdl_profitsdelaventeremis1,
                value: details.vdl_profitsdelaventeremis1,
                type: "string",
              },
            ]}
            columns={2}
            minColWidth={275}
            activeRule={{
              hide: true,
              value: details.vdl_consommationdeboissonsalcooliques,
            }}
          />
          <DisplayFieldCard
            title="Vente d'aliments et breuvages"
            fields={[
              {
                label: permitDisplayNames.vdl_venteestgereeparledemandeur2,
                value: details.vdl_venteestgereeparledemandeur2,
                type: "check",
                span: 2,
              },
              {
                label: permitDisplayNames.vdl_ventegereeparuntiers2,
                value: details.vdl_ventegereeparuntiers2,
                type: "check",
              },
              {
                label: permitDisplayNames.vdl_precisez6,
                value: details.vdl_precisez6,
                type: "string",
              },
              {
                label: permitDisplayNames.vdl_nombredepointsdeventesurlesite1,
                value: details.vdl_nombredepointsdeventesurlesite1?.toString(),
                type: "string",
                span: 2,
              },
              {
                label: permitDisplayNames.vdl_profitsdelaventeremis2,
                value: details.vdl_profitsdelaventeremis2,
                type: "string",
                span: 2,
              },
            ]}
            columns={2}
            minColWidth={275}
            activeRule={{
              hide: true,
              value: details.vdl_ventealimentsetboissonsexcomptoirkiosque,
            }}
          />
          <DisplayFieldCard
            title="Camion-restaurant"
            fields={[]}
            columns={1}
            minColWidth={275}
            activeRule={{
              hide: true,
              value: details.vdl_camionrestaurant,
            }}
          />
          <DisplayFieldCard
            title="Vente de produits et/ou services"
            fields={[
              {
                label: permitDisplayNames.vdl_venteestgereeparledemandeur3,
                value: details.vdl_venteestgereeparledemandeur3,
                type: "check",
                span: 2,
              },
              {
                label: permitDisplayNames.vdl_ventegereeparuntiers3,
                value: details.vdl_ventegereeparuntiers3,
                type: "check",
              },
              {
                label: permitDisplayNames.vdl_precisez5,
                value: details.vdl_precisez5,
                type: "string",
              },
              {
                label: permitDisplayNames.vdl_nombredepointsdeventesurlesite2,
                value: details.vdl_nombredepointsdeventesurlesite2?.toString(),
                type: "string",
                span: 2,
              },
              {
                label: permitDisplayNames.vdl_profitsdelaventeremisa3,
                value: details.vdl_profitsdelaventeremisa3,
                type: "string",
                span: 2,
              },
            ]}
            columns={2}
            minColWidth={275}
            activeRule={{
              hide: true,
              value: details.vdl_ventedeproduitsservicesobjetsexpovente,
            }}
          />
          <DisplayFieldCard
            title="Tarification d'accès à un site municipal"
            fields={[
              {type: "comment", label: "Tarifs:", span: 2,},
              {
                label: permitDisplayNames.vdl_enfant,
                value: details.vdl_enfant?.toString(),
                type: "string",
              },
              {
                label: permitDisplayNames.vdl_adultes,
                value: details.vdl_adultes?.toString(),
                type: "string",
              },
              {
                label: permitDisplayNames.vdl_autres1,
                value: details.vdl_autres1,
                type: "check",
              },
              {
                label: permitDisplayNames.vdl_siautreprecisez,
                value: details.vdl_siautreprecisez,
                type: "string",
              },
              {
                label: permitDisplayNames.vdl_revenusdetarificationremisa,
                value: details.vdl_revenusdetarificationremisa,
                type: "string",
                span: 2,
              },
            ]}
            columns={2}
            minColWidth={275}
            activeRule={{
              hide: true,
              value: details.vdl_tarificationdaccesaunsitemunicipal,
            }}
          />
          <DisplayFieldCard
            title="Sollicitation porte-à-porte"
            fields={[
              {
                label: permitDisplayNames.vdl_collectedebouteille,
                value: details.vdl_collectedebouteille,
                type: "check",
              },
              {
                label: permitDisplayNames.vdl_ventedecalendrier,
                value: details.vdl_ventedecalendrier,
                type: "check",
              },
              {
                label: permitDisplayNames.vdl_autre,
                value: details.vdl_autre,
                type: "check",
              },
              {
                label: permitDisplayNames.vdl_precisez3,
                value: details.vdl_precisez3,
                type: "string",
              },
              {
                label: permitDisplayNames.vdl_revenusdelactiviteremisa,
                value: details.vdl_revenusdelactiviteremisa,
                type: "string",
                span: 2,
              },
            ]}
            columns={2}
            minColWidth={275}
            activeRule={{
              hide: true,
              value: details.vdl_sollicitationporteaporte,
            }}
          />
          <DisplayFieldCard
            title="Installation de structure temporaire"
            fields={[
              {
                label: permitDisplayNames.vdl_chapiteaumarquise,
                value: details.vdl_chapiteaumarquise,
                type: "check",
              },
              {
                label: permitDisplayNames.vdl_preciseznombreetdimension,
                value: details.vdl_preciseznombreetdimension,
                type: "string",
              },
              {
                label: permitDisplayNames.vdl_structuresgonflables,
                value: details.vdl_structuresgonflables,
                type: "check",
              },
              {
                label: permitDisplayNames.vdl_tyrolienne,
                value: details.vdl_tyrolienne,
                type: "check",
              },
              {
                label: permitDisplayNames.vdl_scenemobile,
                value: details.vdl_scenemobile,
                type: "check",
              },
              {
                label: permitDisplayNames.vdl_autres3,
                value: details.vdl_autres3,
                type: "check",
                span: 2
              },
              {
                label: permitDisplayNames.vdl_precisez2,
                value: details.vdl_precisez2,
                type: "string",
                span: 2,
              },
              {
                label:
                  permitDisplayNames.vdl_equipementconformeauxnormesetreglements,
                value: details.vdl_equipementconformeauxnormesetreglements,
                type: "check",
                span: 2,
              },
              {
                label:
                  permitDisplayNames.vdl_installateuretoperateursontqualifies,
                value: details.vdl_installateuretoperateursontqualifies,
                type: "check",
                span: 2,
              },
              {
                label: permitDisplayNames.vdl_nomdufournisseur,
                value: details.vdl_nomdufournisseur,
                type: "string",
                span: 2,
              },
              {
                label:
                  permitDisplayNames.vdl_requerantassurelaresponsabilitecivile,
                value: details.vdl_requerantassurelaresponsabilitecivile,
                type: "check",
                span: 2,
              },
            ]}
            columns={2}
            minColWidth={275}
            activeRule={{
              hide: true,
              value: details.vdl_installationdestructuretemporaire,
            }}
          />
          <DisplayFieldCard
            title="Affichage temporaire"
            fields={[
              {
                label: permitDisplayNames.vdl_panneauxdesignalisation,
                value: details.vdl_panneauxdesignalisation,
                type: "check",
              },
              {
                label: permitDisplayNames.vdl_precisezdimensionetmateriaux,
                value: details.vdl_precisezdimensionetmateriaux,
                type: "string",
              },
              {
                label: permitDisplayNames.vdl_bannieres,
                value: details.vdl_bannieres,
                type: "check",
                span: 2,
              },
              {
                label: permitDisplayNames.vdl_fanions,
                value: details.vdl_fanions,
                type: "check",
              },
              {
                label: permitDisplayNames.vdl_precisezdimensionetsupport,
                value: details.vdl_precisezdimensionetsupport,
                type: "string",
              },
              {
                label: permitDisplayNames.vdl_autres2,
                value: details.vdl_autres2,
                type: "check",
              },
              {
                label: permitDisplayNames.vdl_precisez1,
                value: details.vdl_precisez1,
                type: "string",
              },
            ]}
            columns={2}
            minColWidth={275}
            activeRule={{
              hide: true,
              value: details.vdl_affichagetemporairesignalisationpavoisement,
            }}
          />
          {diffusionSonore}
        </>
      ) : details.vdl_typedactivite === "Tournage" ? (
        <>
          {diffusionSonore}{" "}
          <DisplayFieldCard
            title="Éclairage"
            fields={[
              {
                label:
                  permitDisplayNames.vdl_utilisationdunsystemdeclairagenuisance,
                value: details.vdl_utilisationdunsystemdeclairagenuisance,
                type: "check",
              },
              {
                label: permitDisplayNames.vdl_precisez7,
                value: details.vdl_precisez7,
                type: "string",
              },
            ]}
            columns={2}
            minColWidth={275}
            activeRule={{
              hide: true,
              value: details.vdl_eclairage,
            }}
          />
        </>
      ) : null}
    </Box>
  );
};

export default Permit;
