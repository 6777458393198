import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import CustomButton from "../../common/buttons/CustomButton";

type Props = {
  open: boolean;
  handleConfirm: () => void;
  handleCancel: () => void;
  config: DialogConfig;
};
export type DialogConfig = {
  title?: string;
  message?: string;
  cancelText?: string;
  confirmText?: string;
};

const ConfirmationDialog = ({
  open,
  handleConfirm,
  handleCancel,
  config,
}: Props) => {
  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle color='primary' fontSize="1.9rem">{config.title}</DialogTitle>
      <DialogContent>
        <DialogContentText whiteSpace="pre-line">{config.message}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{paddingBottom: "16px"}}>
        {config.cancelText ? (
          <CustomButton secondary onClick={handleCancel}>{config.cancelText}</CustomButton>
        ) : null}
        <CustomButton primary onClick={handleConfirm}>{config.confirmText}</CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
