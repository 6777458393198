import { DisplayNames, TableFields } from "../../types";
import { BaseRow, Lookup, OptionSetString } from "./types";

export function getStringFromField(
  field: Lookup | OptionSetString | string | number | Boolean | undefined
): string {
  if (!field) return "";
  if (typeof field === "boolean") {
    return field ? "Oui" : "Non";
  } else if (typeof field === "string") {
    return field;
  } else if (typeof field === "number") {
    return field.toString();
  } else if ("label" in field) {
    return field.label;
  }

  return "";
}

export function getDisplayFieldsFromRow<T extends BaseRow>(
  obj: T,
  fields: (keyof T)[],
  displayNames: DisplayNames<T>
): TableFields {
  let _fields: TableFields = {};
  fields.forEach((fieldName) => {
    _fields[fieldName.toString()] = {
      displayName: displayNames[fieldName] ? displayNames[fieldName] : "",
      value: getStringFromField(obj[fieldName]),
    };
  });
  return _fields;
}

export function dateStringToShortString(dateString?: string): string {
  if(!dateString || dateString==="") return "";
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  if(isNaN(year) || isNaN(year) || isNaN(year)) {
    return ""
  }
  return `${year}-${month}-${day} `;
}

export function dateStringToYearString(dateString?: string): string {
  if(!dateString || dateString==="") return ""
  const date = new Date(dateString);
  const year = date.getFullYear();
  return isNaN(year) ? "" : year.toString();
}

export function dateTimeStringToString(dateString?: string): string {
  if(!dateString || dateString==="") return "";
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  if(isNaN(year)) {
    return ""
  }
  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export function timeStringToString(dateString?: string): string {
  if(!dateString || dateString==="") return "";
  const date = new Date(dateString);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${hours}:${minutes}`;
}

export function dateStringToLongWithDayString(dateString?: string): string {
  if(!dateString || dateString==="") return "";
  const date = new Date(dateString);
  const year = date.getFullYear();
  
  const dayNames = [
    "dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"
  ];
  const monthNames = [
    "janvier", "février", "mars", "avril", "mai", "juin",
    "juillet", "août", "septembre", "octobre", "novembre", "décembre"
  ];

  const dayName = dayNames[date.getDay()]; // Get day of the week in French
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${dayName} ${day} ${month} ${year} à ${hours}:${minutes}`;
}

export function dateStringToLongString(dateString?: string): string {
  if(!dateString || dateString==="") return "";
  const date = new Date(dateString);
  const year = date.getFullYear();
  
  const monthNames = [
    "janvier", "février", "mars", "avril", "mai", "juin",
    "juillet", "août", "septembre", "octobre", "novembre", "décembre"
  ];

  const month = monthNames[date.getMonth()];
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${day} ${month} ${year} à ${hours}:${minutes}`;
}