import React, { useState } from "react";
import BoxResponsivePadding from "../../../common/boxes/BoxResponsivePadding";
import {
  DemandPublicSpacePatch,
  DemandPublicSpaceRow,
} from "../../../../data/Demand/models";
import { NewDemandFields } from "../../../../data/Demand/forms/dialog";
import { updateDemand } from "../../../../data/Demand/requests";
import EditDemandLayout from "../EditDemandLayout";

import SimpleFields from "../../../form/SimpleForm/SimpleFields";
import handleSimpleFormSubmit from "../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../hooks/useTabs";
import {
  EditDemandPublicSpaceFields,
  editDemandPublicSpaceFields,
} from "../../../../data/Demand/forms/publicSpace/publicSpace";
import { Box, Link, Typography } from "@mui/material";
import ReservationsSection from "./Reservations/ReservationsSection";
import {
  DemandPublicSpaceReservationEditing,
  DemandPublicSpaceReservationPatch,
  DemandPublicSpaceReservationRow,
} from "../../../../data/Demand/types/publicSpace/reservation";
import buildForm from "../../../form/SimpleForm/buildForm";

type Props = {
  demand: DemandPublicSpaceRow;
  isNewDemand: boolean;
  fieldsGeneral: NewDemandFields;
  onUpdateSuccess: () => void;
  onUpdateFailure: (error?: string) => void;
  setFieldsGeneral: React.Dispatch<React.SetStateAction<NewDemandFields>>;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const PublicSpace = ({
  demand,
  isNewDemand,
  fieldsGeneral,
  setFieldsGeneral,
  onUpdateSuccess,
  onUpdateFailure,
  setError,
}: Props) => {
  const [fields, setFields] = useState<EditDemandPublicSpaceFields>(
    buildForm(editDemandPublicSpaceFields, {
      vdl_name: demand.vdl_detailsdelademande.vdl_name ?? "",
    })
  );
  const [demandReservations, setDemandReservations] = useState<
    (DemandPublicSpaceReservationRow | DemandPublicSpaceReservationEditing)[]
  >([]);
  const { activeTab, changeTab } = useTabs();

  const onSubmit = async () => {
    const errors = handleSimpleFormSubmit(fields, setFields);

    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      return;
    }

    const _demand: DemandPublicSpacePatch = {
      title: fieldsGeneral.title.value,
      vdl_contactprincipal: fieldsGeneral.vdl_contactprincipal.value,
      type: demand.type,
      vdl_detailsdelademande: {
        vdl_name: fields.vdl_name.value,
        reservations: [],
      },
    };

    if (demand.statuscode === "Inutilisée") {
      _demand.vdl_detailsdelademande.statuscode = "En création";
    }

    const reservationsUpdate: DemandPublicSpaceReservationPatch[] = [];

    demandReservations.forEach((reservation) => {
      if ("vdl_reservationid" in reservation) {
        if ("delete" in reservation) {
          reservationsUpdate.push({
            vdl_reservationid: reservation.vdl_reservationid,
            delete: true,
          });
        }
      } else {
        reservationsUpdate.push({
          ...reservation,
          vdl_nomdusiteareserver: reservation.vdl_nomdusiteareserver.value,
          vdl_sousplateau: reservation.vdl_sousplateau.value,
          vdl_titre: reservation.vdl_titre,
        });
      }
    });

    _demand.vdl_detailsdelademande.reservations = reservationsUpdate;
    const { status, error } = await updateDemand(demand.incidentid, _demand);
    if (status === "success") {
      onUpdateSuccess();
    } else {
      onUpdateFailure(error);
    }
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_lienhautduformulaireportail}
    >
      <Box display="grid" gap="16px">
        <BoxResponsivePadding bgcolor="common.white" display="grid" gap="16px">
          <SimpleFields
            title="Activité"
            fields={fields}
            setFields={setFields}
          />
          <Typography>
            Avant d'effectuer une réservation, veuillez consulter la
            disponibilité du site en vous redirigeant vers le lien
            suivant:&nbsp;
            <Link href="https://www.publicationsports.com/cal/index.php?o=36&t=169&p=1232&lg=fr">
              https://www.publicationsports.com/cal/index.php?o=36&t=169&p=1232&lg=fr
            </Link>
            .
          </Typography>
        </BoxResponsivePadding>
        <BoxResponsivePadding bgcolor="common.white">
          <ReservationsSection
            reservations={demandReservations}
            setDemandReservations={setDemandReservations}
          />
        </BoxResponsivePadding>
      </Box>
    </EditDemandLayout>
  );
};

export default PublicSpace;
