import { DisplayNames } from "../../types";
import { AccountRow } from "./models";

const accountDisplayNames: DisplayNames<AccountRow> = {
  name: "Nom officiel de l'organisation",
  vdl_autresnomorganisme: "Autres noms utilisés",
  vdl_typedorganisation: "Type d'organisation",
  vdl_neq: "NEQ ou UAI",
  telephone1: "N° de téléphone",
  emailaddress1: "Courriel",
  websiteurl: "Site internet",
  vdl_pagefacebook: "Page Facebook",
  vdl_secteursiegesocial: "Secteur où est situé le siège social à Laval",
  address1_line1: "Adresse ligne 1",
  address1_line2: "Adresse ligne 2",
  address1_city: "Ville",
  address1_country: "",
  address1_postalcode: "Code postal",
  address2_line1: "Adresse ligne 1",
  address2_line2: "Adresse ligne 2",
  address2_city: "Ville",
  address2_country: "",
  address2_postalcode: "Code postal",
  accountid: "",
  address1_fax: "",
  address1_line3: "",
  address1_stateorprovince: "",
  vdl_adresseidentique: "Utiliser l'adresse du siège social",
  address2_line3: "",
  address2_stateorprovince: "",
  primarycontactid: "",
  contacts: "",
  otherNames: "",
};

export default accountDisplayNames;
