import { Dayjs } from "dayjs";
import {
  CheckBoxInput,
  DateInput,
  FormFields,
  FormSection,
  NumberInput,
  TextInput,
} from "../../../types";
import { ecoSupportDisplayNames } from "../displayNames";

export type EditDemandEcoSupportFields = {
  //Prêt d'équipement
  vdl_duobacsroulants240ldechetrecyclage: CheckBoxInput;
  vdl_qte2: NumberInput;
  vdl_duobarils205ldechetsrecyclage: CheckBoxInput;
  vdl_qte4: NumberInput;
  vdl_bacroulantbrun240lcompost: CheckBoxInput;
  vdl_qte5: NumberInput;
  vdl_bacslimjimnoir90lavecsacsdechet: CheckBoxInput;
  vdl_qte6: NumberInput;
  vdl_bacslimjimbleu90lavecsacsrecyclage: CheckBoxInput;
  vdl_qte: NumberInput;
  vdl_bacslimjimbrun90lavecsacscompost: CheckBoxInput;
  vdl_qte1: NumberInput;
  vdl_supportaveloavecchainesetcadenas: CheckBoxInput;

  //Lieu de livraison
  vdl_addressedelivraison: TextInput;
  vdl_datedelivraison: DateInput;
  vdl_datederecupperation: DateInput;

  //Autres services offerts
  vdl_kiosqueenvironnementecocitoyennete: CheckBoxInput;
  vdl_servicedeplanificationetdaccompagnement: CheckBoxInput;
};

const editDemandEcoSupportFields: EditDemandEcoSupportFields = {
  vdl_duobacsroulants240ldechetrecyclage: {
    type: "checkbox",
    error: "",
    value: false,
    label: ecoSupportDisplayNames.vdl_duobacsroulants240ldechetrecyclage,
  },
  vdl_qte2: {
    type: "number",
    error: "",
    value: undefined,
    disabledCondition: (fields: FormFields) =>
      !fields.vdl_duobacsroulants240ldechetrecyclage.value,
    label: ecoSupportDisplayNames.vdl_qte2,
  },
  vdl_duobarils205ldechetsrecyclage: {
    type: "checkbox",
    error: "",
    value: false,
    label: ecoSupportDisplayNames.vdl_duobarils205ldechetsrecyclage,
  },
  vdl_qte4: {
    type: "number",
    error: "",
    value: undefined,
    disabledCondition: (fields: FormFields) =>
      !fields.vdl_duobarils205ldechetsrecyclage.value,
    label: ecoSupportDisplayNames.vdl_qte4,
  },
  vdl_bacroulantbrun240lcompost: {
    type: "checkbox",
    error: "",
    value: false,
    label: ecoSupportDisplayNames.vdl_bacroulantbrun240lcompost,
  },
  vdl_qte5: {
    type: "number",
    error: "",
    value: undefined,
    disabledCondition: (fields: FormFields) =>
      !fields.vdl_bacroulantbrun240lcompost.value,
    label: ecoSupportDisplayNames.vdl_qte5,
  },
  vdl_bacslimjimnoir90lavecsacsdechet: {
    type: "checkbox",
    error: "",
    value: false,
    label: ecoSupportDisplayNames.vdl_bacslimjimnoir90lavecsacsdechet,
  },
  vdl_qte6: {
    type: "number",
    error: "",
    value: undefined,
    disabledCondition: (fields: FormFields) =>
      !fields.vdl_bacslimjimnoir90lavecsacsdechet.value,
    label: ecoSupportDisplayNames.vdl_qte6,
  },
  vdl_bacslimjimbleu90lavecsacsrecyclage: {
    type: "checkbox",
    error: "",
    value: false,
    label: ecoSupportDisplayNames.vdl_bacslimjimbleu90lavecsacsrecyclage,
  },
  vdl_qte: {
    type: "number",
    error: "",
    value: undefined,
    disabledCondition: (fields: FormFields) =>
      !fields.vdl_bacslimjimbleu90lavecsacsrecyclage.value,
    label: ecoSupportDisplayNames.vdl_qte,
  },
  vdl_bacslimjimbrun90lavecsacscompost: {
    type: "checkbox",
    error: "",
    value: false,
    label: ecoSupportDisplayNames.vdl_bacslimjimbrun90lavecsacscompost,
  },
  vdl_qte1: {
    type: "number",
    error: "",
    value: undefined,
    disabledCondition: (fields: FormFields) =>
      !fields.vdl_bacslimjimbrun90lavecsacscompost.value,
    label: ecoSupportDisplayNames.vdl_qte1,
  },
  vdl_supportaveloavecchainesetcadenas: {
    type: "checkbox",
    error: "",
    value: false,
    label: ecoSupportDisplayNames.vdl_supportaveloavecchainesetcadenas,
  },

  vdl_addressedelivraison: {
    type: "text",
    error: "",
    value: "",
    fillLine: true,
    label: ecoSupportDisplayNames.vdl_addressedelivraison,
  },
  vdl_datedelivraison: {
    type: "date",
    error: "",
    value: undefined,
    disablePast: true,
    label: ecoSupportDisplayNames.vdl_datedelivraison,
  },
  vdl_datederecupperation: {
    type: "date",
    error: "",
    value: undefined,
    disablePast: true,
    label: ecoSupportDisplayNames.vdl_datederecupperation,
    minDate: (fields: FormFields) =>
      fields.vdl_datedelivraison.value
        ? (fields.vdl_datedelivraison.value as Dayjs)
        : undefined,
  },

  vdl_kiosqueenvironnementecocitoyennete: {
    type: "checkbox",
    error: "",
    value: false,
    label: ecoSupportDisplayNames.vdl_kiosqueenvironnementecocitoyennete,
  },
  vdl_servicedeplanificationetdaccompagnement: {
    type: "checkbox",
    error: "",
    value: false,
    label: ecoSupportDisplayNames.vdl_servicedeplanificationetdaccompagnement,
  },
};

const editDemandeEcoSuppoerPretSection: FormSection<EditDemandEcoSupportFields> =
  {
    fields: [
      "vdl_duobacsroulants240ldechetrecyclage",
      "vdl_qte2",
      "vdl_duobarils205ldechetsrecyclage",
      "vdl_qte4",
      "vdl_bacroulantbrun240lcompost",
      "vdl_qte5",
      "vdl_bacslimjimnoir90lavecsacsdechet",
      "vdl_qte6",
      "vdl_bacslimjimbleu90lavecsacsrecyclage",
      "vdl_qte",
      "vdl_bacslimjimbrun90lavecsacscompost",
      "vdl_qte1",
      "vdl_supportaveloavecchainesetcadenas",
    ],
    title: "Prêt d'équipement",
  };

const editDemandeEcoSuppoerLieuSection: FormSection<EditDemandEcoSupportFields> =
  {
    fields: [
      "vdl_addressedelivraison",
      "vdl_datedelivraison",
      "vdl_datederecupperation",
    ],
    title: "Lieu de livraison",
  };

const editDemandeEcoSuppoerAutresSection: FormSection<EditDemandEcoSupportFields> =
  {
    fields: [
      "vdl_kiosqueenvironnementecocitoyennete",
      "vdl_servicedeplanificationetdaccompagnement",
    ],
    title: "Autres services offerts",
  };

const editDemandEcoSupportSections: FormSection<EditDemandEcoSupportFields>[] =
  [
    editDemandeEcoSuppoerPretSection,
    editDemandeEcoSuppoerLieuSection,
    editDemandeEcoSuppoerAutresSection,
  ];

export { editDemandEcoSupportFields, editDemandEcoSupportSections };
