import { Dayjs } from "dayjs";
import {
  DateTimeInput,
  DropDownLookUpInput,
  DropDownOptionSetInput,
  FormFields,
  FormSection,
  NumberInput,
  PhoneInput,
  TextInput,
} from "../../../../types";
import { vdl_salutationsChoices } from "../../../Contact/forms/optionSetChoices";
import { publicSpaceReservationDisplayNames } from "../../displayNames";

export type EditDemandPublicSpaceReservationFields = {
  vdl_courriel: TextInput;
  vdl_debutdelareservation: DateTimeInput;
  vdl_findelareservation: DateTimeInput;
  vdl_ndetelephoneprincipal: PhoneInput;
  vdl_titre: DropDownOptionSetInput;
  vdl_prenom: TextInput;
  vdl_nom: TextInput;
  vdl_nombredeparticipants: NumberInput;
  vdl_nomdusiteareserver: DropDownLookUpInput;
  vdl_sousplateau: DropDownLookUpInput;
};

const editDemandPublicSpaceReservationFields: EditDemandPublicSpaceReservationFields =
  {
    vdl_nomdusiteareserver: {
      type: "lookup",
      error: "",
      value: undefined,
      choices: [],
      required: true,
      label: publicSpaceReservationDisplayNames.vdl_nomdusiteareserver,
    },
    vdl_sousplateau: {
      type: "lookup",
      error: "",
      value: undefined,
      choices: [],
      required: true,
      label: publicSpaceReservationDisplayNames.vdl_sousplateau,
    },
    vdl_debutdelareservation: {
      type: "datetime",
      error: "",
      value: undefined,
      required: true,
      disablePast: true,
      label: publicSpaceReservationDisplayNames.vdl_debutdelareservation,
      onChangeEvent: (fields: FormFields) => ({
        ...fields,
        vdl_findelareservation: {
          ...fields.vdl_findelareservation,
          value:
            (fields.vdl_findelareservation.value as Dayjs) &&
            fields.vdl_findelareservation.value.isBefore(
              fields.vdl_debutdelareservation.value as Dayjs
            )
              ? undefined
              : fields.vdl_findelareservation.value,
        },
      }),
    },
    vdl_findelareservation: {
      type: "datetime",
      error: "",
      value: undefined,
      required: true,
      disablePast: true,
      label: publicSpaceReservationDisplayNames.vdl_findelareservation,
      minDate: (fields: FormFields) =>
        (fields.vdl_debutdelareservation.value as Dayjs)
          ? (fields.vdl_debutdelareservation.value as Dayjs)
          : undefined,
    },
    vdl_nombredeparticipants: {
      type: "number",
      error: "",
      value: undefined,
      required: true,
      label: publicSpaceReservationDisplayNames.vdl_nombredeparticipants,
    },
    vdl_nom: {
      type: "text",
      error: "",
      value: "",
      required: true,
      label: publicSpaceReservationDisplayNames.vdl_nom,
    },
    vdl_courriel: {
      type: "text",
      error: "",
      value: "",
      required: false,
      label: publicSpaceReservationDisplayNames.vdl_courriel,
    },
    vdl_ndetelephoneprincipal: {
      type: "phone",
      error: "",
      value: "",
      required: true,
      label: publicSpaceReservationDisplayNames.vdl_ndetelephoneprincipal,
    },
    vdl_prenom: {
      type: "text",
      error: "",
      value: "",
      required: true,
      label: publicSpaceReservationDisplayNames.vdl_prenom,
    },
    vdl_titre: {
      type: "optionset",
      error: "",
      value: undefined,
      choices: vdl_salutationsChoices,
      fillLine: true,
      required: true,
      label: publicSpaceReservationDisplayNames.vdl_titre,
    },
  };
const editDemandRervationInfoSection: FormSection<EditDemandPublicSpaceReservationFields> =
  {
    fields: [
      "vdl_nomdusiteareserver",
      "vdl_sousplateau",
      "vdl_debutdelareservation",
      "vdl_findelareservation",
      "vdl_nombredeparticipants",
    ],
    title: "Informations réservation",
  };
const editDemandRervationContactSection: FormSection<EditDemandPublicSpaceReservationFields> =
  {
    fields: [
      "vdl_titre",
      "vdl_prenom",
      "vdl_nom",
      "vdl_courriel",
      "vdl_ndetelephoneprincipal",
    ],
    title: "Personne à contacter",
  };

const editDemandRervationSections: FormSection<EditDemandPublicSpaceReservationFields>[] =
  [editDemandRervationInfoSection, editDemandRervationContactSection];

export { editDemandPublicSpaceReservationFields, editDemandRervationSections };
