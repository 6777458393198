import React, { useState } from "react";
import {
  DemandPublicRoadPatch,
  DemandPublicRoadRow,
} from "../../../../data/Demand/models";
import { NewDemandFields } from "../../../../data/Demand/forms/dialog";
import { updateDemand } from "../../../../data/Demand/requests";
import EditDemandLayout from "../EditDemandLayout";
import SimpleFields from "../../../form/SimpleForm/SimpleFields";
import handleSimpleFormSubmit from "../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../hooks/useTabs";
import { validateDateString } from "../../../../data/common/validateDateString";
import {
  EditDemandPublicRoadFields,
  editDemandPublicRoadFields,
  editDemandPublicRoadSectionsEvent,
  editDemandPublicRoadSectionsFilming,
} from "../../../../data/Demand/forms/publicRoad";
import buildForm from "../../../form/SimpleForm/buildForm";

type Props = {
  demand: DemandPublicRoadRow;
  isNewDemand: boolean;
  fieldsGeneral: NewDemandFields;
  onUpdateSuccess: () => void;
  onUpdateFailure: (error?: string) => void;
  setFieldsGeneral: React.Dispatch<React.SetStateAction<NewDemandFields>>;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const PublicRoad = ({
  demand,
  isNewDemand,
  fieldsGeneral,
  setFieldsGeneral,
  onUpdateSuccess,
  onUpdateFailure,
  setError,
}: Props) => {
  const details = demand.vdl_detailsdelademande;
  const [fields, setFields] = useState<EditDemandPublicRoadFields>(
    buildForm(editDemandPublicRoadFields, {
      vdl_fermeturedelarue2: details.vdl_fermeturedelarue2 ?? false,
      vdl_fermeturedelarue: details.vdl_fermeturedelarue,
      vdl_troncon: details.vdl_troncon ?? false,
      vdl_nomdelarue: details.vdl_nomdelarue ?? "",
      vdl_nord: details.vdl_nord ?? false,
      vdl_sud: details.vdl_sud ?? false,
      vdl_est: details.vdl_est ?? false,
      vdl_ouest: details.vdl_ouest ?? false,
      vdl_entrerueouadresses: details.vdl_entrerueouadresses ?? "",
      vdl_circuit: details.vdl_circuit ?? false,
      vdl_trajetrueparrueavecdirection:
        details.vdl_trajetrueparrueavecdirection ?? "",
      vdl_dateetheuredelafermeture: validateDateString(
        details.vdl_dateetheuredelafermeture
      ),
      vdl_dateetheuredelareouverture: validateDateString(
        details.vdl_dateetheuredelareouverture
      ),
      vdl_renseignementadditionnels:
        details.vdl_renseignementadditionnels ?? "",

      vdl_fermeturedutrottoircasecocher:
        details.vdl_fermeturedutrottoircasecocher ?? false,

      vdl_fermeturedutrottoirliste: details.vdl_fermeturedutrottoirliste,
      vdl_nomdelarue2: details.vdl_nomdelarue2 ?? "",
      vdl_entrerueouadresses4: details.vdl_entrerueouadresses4 ?? "",
      vdl_nord2: details.vdl_nord2 ?? false,
      vdl_sud2: details.vdl_sud2 ?? false,
      vdl_est2: details.vdl_est2 ?? false,
      vdl_ouest2: details.vdl_ouest2 ?? false,
      vdl_dateetheuredelafermeture2: validateDateString(
        details.vdl_dateetheuredelafermeture2
      ),
      vdl_dateetheuredelareouverture2: validateDateString(
        details.vdl_dateetheuredelareouverture2
      ),
      vdl_renseignementadditionnels2:
        details.vdl_renseignementadditionnels2 ?? "",

      vdl_interdictiondestationnement:
        details.vdl_interdictiondestationnement ?? false,
      vdl_raisondelademande: details.vdl_raisondelademande ?? "",
      vdl_nomdelarue3: details.vdl_nomdelarue3 ?? "",
      vdl_nord3: details.vdl_nord3 ?? false,
      vdl_est3: details.vdl_est3 ?? false,
      vdl_sud3: details.vdl_sud3 ?? false,
      vdl_ouest3: details.vdl_ouest3 ?? false,
      vdl_dateetheuredelafermeture3: validateDateString(
        details.vdl_dateetheuredelafermeture3
      ),
      vdl_dateetheuredelareouverture3: validateDateString(
        details.vdl_dateetheuredelareouverture3
      ),
      vdl_renseignementadditionnels3:
        details.vdl_renseignementadditionnels3 ?? "",

      vdl_tolerancedestationnement:
        details.vdl_tolerancedestationnement ?? false,
      vdl_raisondelademande2: details.vdl_raisondelademande2 ?? "",
      vdl_nomdelarue4: details.vdl_nomdelarue4 ?? "",
      vdl_nord4: details.vdl_nord4 ?? false,
      vdl_est4: details.vdl_est4 ?? false,
      vdl_sud4: details.vdl_sud4 ?? false,
      vdl_ouest4: details.vdl_ouest4 ?? false,
      vdl_dateetheuredelafermeture4: validateDateString(
        details.vdl_dateetheuredelafermeture4
      ),
      vdl_dateetheuredelareouverture4: validateDateString(
        details.vdl_dateetheuredelareouverture4
      ),
      vdl_renseignementadditionnels4:
        details.vdl_renseignementadditionnels4 ?? "",

      vdl_redonneeavelo: details.vdl_redonneeavelo ?? false,
      vdl_defile: details.vdl_defile ?? false,
      vdl_marche: details.vdl_marche ?? false,
      vdl_processionreligieuse: details.vdl_processionreligieuse ?? false,
      vdl_vehiculehippomobilecaleche:
        details.vdl_vehiculehippomobilecaleche ?? false,
      vdl_rassemblementsansdeplacement:
        details.vdl_rassemblementsansdeplacement ?? false,
      vdl_nombredeparticipants: details.vdl_nombredeparticipants,
      vdl_enfants512ans: details.vdl_enfants512ans,
      vdl_adultes: details.vdl_adultes,
      vdl_trajetrueparrueavecdirection2:
        details.vdl_trajetrueparrueavecdirection2 ?? "",
      vdl_rue: details.vdl_rue ?? false,
      vdl_trottoir: details.vdl_trottoir ?? false,
      vdl_pistescyclables: details.vdl_pistescyclables ?? false,
      vdl_autre: details.vdl_autre ?? false,
      vdl_siautreprecisez: details.vdl_siautreprecisez ?? "",
    })
  );
  const { activeTab, changeTab } = useTabs();

  const onSubmit = async () => {
    const errors = handleSimpleFormSubmit(fields, setFields);

    console.log(errors);
    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      return;
    }

    const _demand: DemandPublicRoadPatch = {
      title: fieldsGeneral.title.value,
      vdl_contactprincipal: fieldsGeneral.vdl_contactprincipal.value,
      type: demand.type,
      vdl_detailsdelademande: {
        vdl_fermeturedelarue2: fields.vdl_fermeturedelarue2.value,
        vdl_fermeturedelarue: fields.vdl_fermeturedelarue.value,
        vdl_troncon: fields.vdl_troncon.value,
        vdl_nomdelarue: fields.vdl_nomdelarue.value,
        vdl_nord: fields.vdl_nord.value,
        vdl_sud: fields.vdl_sud.value,
        vdl_est: fields.vdl_est.value,
        vdl_ouest: fields.vdl_ouest.value,
        vdl_entrerueouadresses: fields.vdl_entrerueouadresses.value,
        vdl_circuit: fields.vdl_circuit.value,
        vdl_trajetrueparrueavecdirection:
          fields.vdl_trajetrueparrueavecdirection.value,
        vdl_dateetheuredelafermeture:
          fields.vdl_dateetheuredelafermeture.value?.toISOString(),
        vdl_dateetheuredelareouverture:
          fields.vdl_dateetheuredelareouverture.value?.toISOString(),
        vdl_renseignementadditionnels:
          fields.vdl_renseignementadditionnels.value,

        vdl_fermeturedutrottoircasecocher:
          fields.vdl_fermeturedutrottoircasecocher.value,
        vdl_fermeturedutrottoirliste: fields.vdl_fermeturedutrottoirliste.value,
        vdl_nomdelarue2: fields.vdl_nomdelarue2.value,
        vdl_entrerueouadresses4: fields.vdl_entrerueouadresses4.value,
        vdl_nord2: fields.vdl_nord2.value,
        vdl_sud2: fields.vdl_sud2.value,
        vdl_est2: fields.vdl_est2.value,
        vdl_ouest2: fields.vdl_ouest2.value,
        vdl_dateetheuredelafermeture2:
          fields.vdl_dateetheuredelafermeture2.value?.toISOString(),
        vdl_dateetheuredelareouverture2:
          fields.vdl_dateetheuredelareouverture2.value?.toISOString(),
        vdl_renseignementadditionnels2:
          fields.vdl_renseignementadditionnels2.value,

        vdl_interdictiondestationnement:
          fields.vdl_interdictiondestationnement.value,
        vdl_raisondelademande: fields.vdl_raisondelademande.value,
        vdl_nomdelarue3: fields.vdl_nomdelarue3.value,
        vdl_nord3: fields.vdl_nord3.value,
        vdl_est3: fields.vdl_est3.value,
        vdl_sud3: fields.vdl_sud3.value,
        vdl_ouest3: fields.vdl_ouest3.value,
        vdl_dateetheuredelafermeture3:
          fields.vdl_dateetheuredelafermeture3.value?.toISOString(),
        vdl_dateetheuredelareouverture3:
          fields.vdl_dateetheuredelareouverture3.value?.toISOString(),
        vdl_renseignementadditionnels3:
          fields.vdl_renseignementadditionnels3.value,

        vdl_tolerancedestationnement: fields.vdl_tolerancedestationnement.value,
        vdl_raisondelademande2: fields.vdl_raisondelademande2.value,
        vdl_nomdelarue4: fields.vdl_nomdelarue4.value,
        vdl_nord4: fields.vdl_nord4.value,
        vdl_est4: fields.vdl_est4.value,
        vdl_sud4: fields.vdl_sud4.value,
        vdl_ouest4: fields.vdl_ouest4.value,
        vdl_dateetheuredelafermeture4:
          fields.vdl_dateetheuredelafermeture4.value?.toISOString(),
        vdl_dateetheuredelareouverture4:
          fields.vdl_dateetheuredelareouverture4.value?.toISOString(),
        vdl_renseignementadditionnels4:
          fields.vdl_renseignementadditionnels4.value,

        vdl_redonneeavelo: fields.vdl_redonneeavelo.value,
        vdl_defile: fields.vdl_defile.value,
        vdl_marche: fields.vdl_marche.value,
        vdl_processionreligieuse: fields.vdl_processionreligieuse.value,
        vdl_vehiculehippomobilecaleche:
          fields.vdl_vehiculehippomobilecaleche.value,
        vdl_rassemblementsansdeplacement:
          fields.vdl_rassemblementsansdeplacement.value,
        vdl_nombredeparticipants: fields.vdl_nombredeparticipants.value,
        vdl_enfants512ans: fields.vdl_enfants512ans.value,
        vdl_adultes: fields.vdl_adultes.value,
        vdl_trajetrueparrueavecdirection2:
          fields.vdl_trajetrueparrueavecdirection2.value,
        vdl_rue: fields.vdl_rue.value,
        vdl_trottoir: fields.vdl_trottoir.value,
        vdl_pistescyclables: fields.vdl_pistescyclables.value,
        vdl_autre: fields.vdl_autre.value,
        vdl_siautreprecisez: fields.vdl_siautreprecisez.value,
      },
    };

    if (demand.statuscode === "Inutilisée") {
      _demand.vdl_detailsdelademande.statuscode = "En création";
    }

    const { status, error } = await updateDemand(demand.incidentid, _demand);
    if (status === "success") {
      onUpdateSuccess();
    } else {
      onUpdateFailure(error);
    }
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_lienhautduformulaireportail}
    >
      <SimpleFields
        fields={fields}
        setFields={setFields}
        sections={
          demand.vdl_detailsdelademande.vdl_typedactivite === "Évènement"
            ? editDemandPublicRoadSectionsEvent
            : editDemandPublicRoadSectionsFilming
        }
        columns={{ minWidth: 275, count: 2 }}
        sectionsBackground="background.paper"
      />
    </EditDemandLayout>
  );
};

export default PublicRoad;
