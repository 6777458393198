import { Box, Typography } from "@mui/material";
import BoxResponsivePadding from "../../../../common/boxes/BoxResponsivePadding";
import ClickableIcon from "../../../../common/buttons/icons/ClickableIcon";
import {
  DemandPublicSpaceReservationRowBase,
  DemandPublicSpaceReservationRow,
} from "../../../../../data/Demand/types/publicSpace/reservation";
import {
  dateStringToLongWithDayString,
} from "../../../../../data/common/fieldToString";
import { Delete } from "@mui/icons-material";

type Props = {
  reservation:
    | DemandPublicSpaceReservationRow //Existantes
    | DemandPublicSpaceReservationRowBase; //Nouvelles
  deleteReservation(): void;
};
const ReservationCard = ({ reservation, deleteReservation  }: Props) => {
  return (
    <BoxResponsivePadding
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="space-between"
      bgcolor="background.default"
      rowGap="16px"
      columnGap="24px"
    >
      <Typography fontWeight="bold">
        {reservation.vdl_nomdusiteareserver.label}
      </Typography>
      <Typography>{reservation.vdl_sousplateau.label}</Typography>

      <Box>
        <Typography fontWeight="bold">{`${dateStringToLongWithDayString(
          reservation.vdl_debutdelareservation
        )}`}</Typography>
        <Typography fontWeight="bold">{`au ${dateStringToLongWithDayString(
          reservation.vdl_findelareservation
        )}`}</Typography>
      </Box>
      <Box>
        <ClickableIcon
          backgroundColor="background.paper"
          icon={<Delete />}
          handleClick={deleteReservation}
        />
      </Box>
    </BoxResponsivePadding>
  );
};

export default ReservationCard;
