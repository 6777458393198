import { useMsal } from "@azure/msal-react";
import { Person } from "@mui/icons-material";
import { Box, Button, Divider, Link, Menu, MenuItem } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { Link as RouterLink } from "react-router-dom";
import React, { useState, MouseEvent } from "react";
import useUser from "../../../hooks/useUserData";

const AppHeaderUser = () => {
  const { instance } = useMsal();
  const {userData} = useUser();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleMenu = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    instance.logout();
  };

  return (
    <>
      <Button
        color="inherit"
        onClick={handleMenu}
        sx={{ textTransform: "none" }}
      >
        <Person sx={{ mr: 1 }} />
        {userData.firstname} {userData.lastname}
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleClose}>
          <Link
            component={RouterLink}
            to={"/profil"}
            color="inherit"
            underline="none"
          >
            Mes informations personnelles
          </Link>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <Box display="flex" alignItems="center">
            <PowerSettingsNewIcon sx={{ mr: 1, color: "red" }} />
            Déconnexion
          </Box>
        </MenuItem>
      </Menu>
    </>
  );
};

export default AppHeaderUser;
