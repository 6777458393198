import {
  CheckBoxInput,
  CommentInput,
  DateTimeInput,
  FormFields,
  FormSection,
  NumberInput,
  SectionCheckBoxInput,
  TextInput,
  TimeInput,
} from "../../../types";
import { securityPreventionDisplayNames } from "../displayNames";

export type EditDemandSecurityPreventionFields = {
  vdl_feuacielouvert: SectionCheckBoxInput;
  vdl_feudecamp1mdediametre: CheckBoxInput;
  vdl_feudejoie1mdediametre: CheckBoxInput;
  vdl_foyerbrasero: CheckBoxInput;

  //Artiste du feu
  vdl_artistedefeu: SectionCheckBoxInput;
  vdl_jongleur: CheckBoxInput;
  vdl_cracheurdefeu: CheckBoxInput;
  vdl_autre: CheckBoxInput;
  vdl_siautreprecisez: TextInput;

  //Accessoire avec feu
  vdl_accessoireavecfeu: SectionCheckBoxInput;
  vdl_flambeaux: CheckBoxInput;
  vdl_lampions: CheckBoxInput;
  vdl_lanternes: CheckBoxInput;
  vdl_autre2: CheckBoxInput;
  vdl_siautreprecisez1: TextInput;

  //Utilisation de pièces pyrotechniques et effets spéciaux
  vdl_utilisationdepiecespyrotechniqueseteffetsspe: SectionCheckBoxInput;
  vdl_feudartificedomestique: CheckBoxInput;
  vdl_feudartificeagrandeploiement: CheckBoxInput;
  vdl_effetspeciauxpyrotechniques: CheckBoxInput;
  vdl_precisez: TextInput;
  vdl_effetsspeciaux: CheckBoxInput;
  vdl_precisez1: TextInput;
  vdl_cascade: CheckBoxInput;
  vdl_simulationdevol: CheckBoxInput;
  vdl_simulationdebagarre: CheckBoxInput;
  vdl_simulationdenlevement: CheckBoxInput;
  vdl_autre3: CheckBoxInput;
  vdl_siautreprecisez2: TextInput;

  //Utilisation d'un appareil à combustion
  commentType: CommentInput;
  vdl_utilisationdunappareilacombustion: SectionCheckBoxInput;
  vdl_generatrice: CheckBoxInput;
  vdl_appareildecuison: CheckBoxInput;
  vdl_barbecue: CheckBoxInput;
  vdl_chauffagedappoint: CheckBoxInput;
  vdl_autre4: CheckBoxInput;
  vdl_siautreprecisez3: TextInput;
  commentCombustible: CommentInput;
  vdl_gazpropane: CheckBoxInput;
  vdl_essence: CheckBoxInput;
  vdl_charbon: CheckBoxInput;
  vdl_autre1: CheckBoxInput;
  vdl_autre5: TextInput;

  //Activité de nuit sur un site municipal
  vdl_activitedenuitsurunsitemunicipal: SectionCheckBoxInput;
  vdl_interieur: CheckBoxInput;
  vdl_exterieur: CheckBoxInput;
  vdl_heurededebutdutilisationdusite: TimeInput;
  vdl_heuredefindutilisationdusite: TimeInput;
  vdl_nombredeparticipant: NumberInput;
  vdl_enfants512ans: NumberInput;
  vdl_adolescents1317ans: NumberInput;
  vdl_adultes: NumberInput;

  //Survol à basse altitude – atterrissages/décollages – Hélicoptères – utilisation d’un drone
  vdl_survolabasealtitude: SectionCheckBoxInput;
  vdl_utilisationdappareildetypeaviation: CheckBoxInput;
  vdl_precisez2: TextInput;

  //Autres
  vdl_attentionparticuliereduservicedepolice: SectionCheckBoxInput;
  vdl_securite500persbenevolesagentsdesecurite: SectionCheckBoxInput;
  vdl_casernemobile: SectionCheckBoxInput;
  vdl_kiosquedepreventionpolice: SectionCheckBoxInput;
  vdl_kiosquedepreventionincendies: SectionCheckBoxInput;
};

const editDemandSecurityPreventionFields: EditDemandSecurityPreventionFields = {
  vdl_feuacielouvert: {
    type: "sectioncheckbox",
    value: false,
  },
  vdl_feudecamp1mdediametre: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_feudecamp1mdediametre,
  },
  vdl_feudejoie1mdediametre: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_feudejoie1mdediametre,
  },
  vdl_foyerbrasero: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_foyerbrasero,
  },

  vdl_artistedefeu: {
    type: "sectioncheckbox",
    value: false,
  },
  vdl_jongleur: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_jongleur,
  },
  vdl_cracheurdefeu: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_cracheurdefeu,
    span: 3,
  },
  vdl_autre: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_autre,
  },
  vdl_siautreprecisez: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: false,
    disabledCondition: (fields: FormFields) => !fields.vdl_autre.value,
    label: securityPreventionDisplayNames.vdl_siautreprecisez,
    span: 3,
  },

  vdl_accessoireavecfeu: {
    type: "sectioncheckbox",
    value: false,
  },
  vdl_flambeaux: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_flambeaux,
  },
  vdl_lampions: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_lampions,
  },
  vdl_lanternes: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_lanternes,
  },
  vdl_autre2: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_autre2,
  },
  vdl_siautreprecisez1: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: false,
    disabledCondition: (fields: FormFields) => !fields.vdl_autre2.value,
    label: securityPreventionDisplayNames.vdl_siautreprecisez1,
    span: 2,
  },

  vdl_utilisationdepiecespyrotechniqueseteffetsspe: {
    type: "sectioncheckbox",
    value: false,
  },
  vdl_feudartificedomestique: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_feudartificedomestique,
  },
  vdl_feudartificeagrandeploiement: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_feudartificeagrandeploiement,
  },
  vdl_effetspeciauxpyrotechniques: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: securityPreventionDisplayNames.vdl_effetspeciauxpyrotechniques,
  },
  vdl_precisez: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    maxLength: 300,
    disabledCondition: (fields: FormFields) =>
      !fields.vdl_effetspeciauxpyrotechniques.value,
    label: securityPreventionDisplayNames.vdl_precisez,
  },
  vdl_effetsspeciaux: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: securityPreventionDisplayNames.vdl_effetsspeciaux,
  },
  vdl_precisez1: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    maxLength: 300,
    disabledCondition: (fields: FormFields) => !fields.vdl_effetsspeciaux.value,
    label: securityPreventionDisplayNames.vdl_precisez1,
  },
  vdl_cascade: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_cascade,
  },
  vdl_simulationdevol: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_simulationdevol,
  },
  vdl_simulationdebagarre: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_simulationdebagarre,
  },
  vdl_simulationdenlevement: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_simulationdenlevement,
  },
  vdl_autre3: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_autre3,
  },
  vdl_siautreprecisez2: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    disabledCondition: (fields: FormFields) => !fields.vdl_autre3.value,
    label: securityPreventionDisplayNames.vdl_siautreprecisez2,
  },

  vdl_utilisationdunappareilacombustion: {
    type: "sectioncheckbox",
    value: false,
  },
  vdl_generatrice: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_generatrice,
  },

  commentType: {
    type: "comment",
    value: "Type:",
    fillLine: true,
  },
  vdl_appareildecuison: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_appareildecuison,
  },
  vdl_barbecue: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_barbecue,
  },
  vdl_chauffagedappoint: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_chauffagedappoint,
  },
  vdl_autre1: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_autre1,
  },
  vdl_siautreprecisez3: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    disabledCondition: (fields: FormFields) => !fields.vdl_autre1.value,
    label: securityPreventionDisplayNames.vdl_siautreprecisez3,
  },
  commentCombustible: {
    type: "comment",
    value: "Combustible:",
    fillLine: true,
  },
  vdl_gazpropane: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_gazpropane,
  },
  vdl_essence: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_essence,
  },
  vdl_charbon: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_charbon,
  },
  vdl_autre4: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: securityPreventionDisplayNames.vdl_autre4,
  },
  vdl_autre5: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    disabledCondition: (fields: FormFields) => !fields.vdl_autre4.value,
    label: securityPreventionDisplayNames.vdl_autre5,
  },

  vdl_activitedenuitsurunsitemunicipal: {
    type: "sectioncheckbox",
    value: false,
  },
  vdl_interieur: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_interieur,
  },
  vdl_exterieur: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_exterieur,
  },
  vdl_heurededebutdutilisationdusite: {
    type: "time",
    error: "",
    value: undefined,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_heurededebutdutilisationdusite,
  },
  vdl_heuredefindutilisationdusite: {
    type: "time",
    error: "",
    value: undefined,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_heuredefindutilisationdusite,
  },
  vdl_nombredeparticipant: {
    type: "number",
    error: "",
    value: undefined,
    required: false,
    fillLine: true,
    label: securityPreventionDisplayNames.vdl_nombredeparticipant,
  },
  vdl_enfants512ans: {
    type: "number",
    error: "",
    value: undefined,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_enfants512ans,
  },
  vdl_adolescents1317ans: {
    type: "number",
    error: "",
    value: undefined,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_adolescents1317ans,
  },
  vdl_adultes: {
    type: "number",
    error: "",
    value: undefined,
    required: false,
    fillLine: false,
    label: securityPreventionDisplayNames.vdl_adultes,
  },

  vdl_survolabasealtitude: {
    type: "sectioncheckbox",
    value: false,
  },
  vdl_utilisationdappareildetypeaviation: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: false,
    label:
      securityPreventionDisplayNames.vdl_utilisationdappareildetypeaviation,
  },
  vdl_precisez2: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: false,
    maxLength: 300,
    disabledCondition: (fields: FormFields) =>
      !fields.vdl_utilisationdappareildetypeaviation.value,
    label: securityPreventionDisplayNames.vdl_precisez2,
  },
  vdl_attentionparticuliereduservicedepolice: {
    type: "sectioncheckbox",
    value: false,
  },
  vdl_securite500persbenevolesagentsdesecurite: {
    type: "sectioncheckbox",
    value: false,
  },
  vdl_casernemobile: {
    type: "sectioncheckbox",
    value: false,
  },
  vdl_kiosquedepreventionpolice: {
    type: "sectioncheckbox",
    value: false,
  },
  vdl_kiosquedepreventionincendies: {
    type: "sectioncheckbox",
    value: false,
  },
};

const editDemandSecurityPreventionCielOuvertSection: FormSection<EditDemandSecurityPreventionFields> =
  {
    fields: [
      "vdl_feudecamp1mdediametre",
      "vdl_feudejoie1mdediametre",
      "vdl_foyerbrasero",
    ],
    title: securityPreventionDisplayNames.vdl_feuacielouvert,
    activeRule: {
      field: "vdl_feuacielouvert",
    },
    columns: {
      minWidth: 275,
      count: 3,
    },
  };
const editDemandSecurityPreventionArtisteFeuSection: FormSection<EditDemandSecurityPreventionFields> =
  {
    fields: [
      "vdl_jongleur",
      "vdl_cracheurdefeu",
      "vdl_autre",
      "vdl_siautreprecisez",
    ],
    title: securityPreventionDisplayNames.vdl_artistedefeu,
    activeRule: {
      field: "vdl_artistedefeu",
    },
    columns: {
      minWidth: 275,
      count: 4,
    },
  };

const editDemandSecurityPreventionAccessoireFeuSection: FormSection<EditDemandSecurityPreventionFields> =
  {
    fields: [
      "vdl_flambeaux",
      "vdl_lampions",
      "vdl_lanternes",
      "vdl_autre2",
      "vdl_siautreprecisez1",
    ],
    title: securityPreventionDisplayNames.vdl_accessoireavecfeu,
    activeRule: {
      field: "vdl_accessoireavecfeu",
    },
    columns: {
      minWidth: 275,
      count: 3,
    },
  };

const editDemandSecurityPreventionPyrotechniqueSection: FormSection<EditDemandSecurityPreventionFields> =
  {
    fields: [
      "vdl_feudartificedomestique",
      "vdl_feudartificeagrandeploiement",
      "vdl_effetspeciauxpyrotechniques",
      "vdl_precisez",
      "vdl_effetsspeciaux",
      "vdl_precisez1",
    ],
    title:
      securityPreventionDisplayNames.vdl_utilisationdepiecespyrotechniqueseteffetsspe,
    activeRule: {
      field: "vdl_utilisationdepiecespyrotechniqueseteffetsspe",
    },
    columns: {
      minWidth: 275,
      count: 2,
    },
  };
const editDemandSecurityPreventionPyrotechniqueFilmingSection: FormSection<EditDemandSecurityPreventionFields> =
  {
    fields: [
      "vdl_feudartificedomestique",
      "vdl_feudartificeagrandeploiement",
      "vdl_effetspeciauxpyrotechniques",
      "vdl_precisez",
      "vdl_effetsspeciaux",
      "vdl_precisez1",
      "vdl_cascade",
      "vdl_simulationdevol",
      "vdl_simulationdebagarre",
      "vdl_simulationdenlevement",
      "vdl_autre3",
      "vdl_siautreprecisez2",
    ],
    title:
      securityPreventionDisplayNames.vdl_utilisationdepiecespyrotechniqueseteffetsspe,
    activeRule: {
      field: "vdl_utilisationdepiecespyrotechniqueseteffetsspe",
    },
    columns: {
      minWidth: 275,
      count: 2,
    },
  };

const editDemandSecurityPreventionAppareilCombustionSection: FormSection<EditDemandSecurityPreventionFields> =
  {
    fields: [
      "commentType",
      "vdl_generatrice",
      "vdl_appareildecuison",
      "vdl_barbecue",
      "vdl_chauffagedappoint",
      "vdl_autre4",
      "vdl_autre5",
      "commentCombustible",
      "vdl_gazpropane",
      "vdl_essence",
      "vdl_charbon",
      "vdl_autre1",
      "vdl_siautreprecisez3",
    ],
    title: securityPreventionDisplayNames.vdl_utilisationdunappareilacombustion,
    activeRule: {
      field: "vdl_utilisationdunappareilacombustion",
    },
    columns: {
      minWidth: 275,
      count: 2,
    },
  };

const editDemandSecurityPreventionActiviteNuitSection: FormSection<EditDemandSecurityPreventionFields> =
  {
    fields: [
      "vdl_interieur",
      "vdl_exterieur",
      "vdl_heurededebutdutilisationdusite",
      "vdl_heuredefindutilisationdusite",
      "vdl_nombredeparticipant",
      "vdl_enfants512ans",
      "vdl_adolescents1317ans",
      "vdl_adultes",
    ],
    title: securityPreventionDisplayNames.vdl_activitedenuitsurunsitemunicipal,
    activeRule: {
      field: "vdl_activitedenuitsurunsitemunicipal",
    },
    columns: {
      minWidth: 275,
      count: 2,
    },
  };

const editDemandSecurityPreventionSurvolSection: FormSection<EditDemandSecurityPreventionFields> =
  {
    fields: ["vdl_utilisationdappareildetypeaviation", "vdl_precisez2"],
    title: securityPreventionDisplayNames.vdl_survolabasealtitude,
    activeRule: {
      field: "vdl_survolabasealtitude",
    },
  };

const editDemandSecurityPreventionSectionsSpecialEvent: FormSection<EditDemandSecurityPreventionFields>[] =
  [
    editDemandSecurityPreventionCielOuvertSection,
    editDemandSecurityPreventionArtisteFeuSection,
    editDemandSecurityPreventionAccessoireFeuSection,
    editDemandSecurityPreventionPyrotechniqueSection,
    editDemandSecurityPreventionAppareilCombustionSection,
    editDemandSecurityPreventionActiviteNuitSection,
    {
      fields: [],
      description:
        "(le service de police sera informé de la tenue de l'événement)",
      title:
        securityPreventionDisplayNames.vdl_attentionparticuliereduservicedepolice,
      activeRule: {
        field: "vdl_attentionparticuliereduservicedepolice",
      },
    },
    {
      fields: [],
      description:
        "Le requérant assure la sécurité des participants par la présence de bénévoles affectés au maintien de l'ordre ou par la présence d'une agence de sécurité reconnue.",
      title:
        securityPreventionDisplayNames.vdl_securite500persbenevolesagentsdesecurite,
      activeRule: {
        field: "vdl_securite500persbenevolesagentsdesecurite",
      },
    },
    {
      fields: [],
      title: securityPreventionDisplayNames.vdl_casernemobile,
      activeRule: {
        field: "vdl_casernemobile",
      },
    },
    {
      fields: [],
      title: securityPreventionDisplayNames.vdl_kiosquedepreventionpolice,
      activeRule: {
        field: "vdl_kiosquedepreventionpolice",
      },
    },
    {
      fields: [],
      title: securityPreventionDisplayNames.vdl_kiosquedepreventionincendies,
      activeRule: {
        field: "vdl_kiosquedepreventionincendies",
      },
    },
  ];

const editDemandSecurityPreventionSectionsShooting: FormSection<EditDemandSecurityPreventionFields>[] =
  [
    editDemandSecurityPreventionPyrotechniqueFilmingSection,
    editDemandSecurityPreventionAppareilCombustionSection,
    editDemandSecurityPreventionActiviteNuitSection,
    editDemandSecurityPreventionSurvolSection,
  ];

export {
  editDemandSecurityPreventionFields,
  editDemandSecurityPreventionSectionsSpecialEvent,
  editDemandSecurityPreventionSectionsShooting,
};
