import { Box, Dialog, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../../../../common/buttons/CustomButton";
import ReservationsDialog from "./ReservationsDialog/ReservationsDialog";
import { SiteRow } from "../../../../../data/Plateau/models.ts";
import { getSites } from "../../../../../data/Plateau/requests";
import {
  DemandPublicSpaceReservationRowBase,
  DemandPublicSpaceReservationEditing,
} from "../../../../../data/Demand/types/publicSpace/reservation";
import ReservationCard from "./ReservationCard";

type Props = {
  reservations: DemandPublicSpaceReservationEditing[];
  setDemandReservations: React.Dispatch<
    React.SetStateAction<DemandPublicSpaceReservationEditing[]>
  >;
};

const ReservationsSection = ({
  reservations,
  setDemandReservations,
}: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [sites, setSites] = useState<SiteRow[]>([]);
  const [error, setError] = useState<string>();

  useEffect(() => {
    const sitesQuery = async () => {
      const sitesResult = await getSites();
      if (!sitesResult.data || sitesResult.error) {
        setError(sitesResult.error);
        return;
      }
      setSites(sitesResult.data);
    };
    sitesQuery();
  }, []);

  function addReservation(newReservation: DemandPublicSpaceReservationRowBase) {
    setDemandReservations((prev) => [...prev, newReservation]);
    setDialogOpen(false);
  }
  function deleteReservation(idx: number) {
    setDemandReservations((prev) => {
      if ("vdl_reservationid" in prev[idx]) {
        return prev.map((item, index) =>
          index === idx ? { ...item, delete: true } : item
        );
      }
      return prev.filter((_, index) => {
        return index !== idx;
      });
    });
  }
  return (
    <>
      {dialogOpen ? (
        <ReservationsDialog
          sites={sites}
          open={dialogOpen}
          setOpen={setDialogOpen}
          addReservation={addReservation}
        />
      ) : null}
      <Box>
        <Box display="flex" justifyContent="space-between" paddingBottom="16px">
          <Typography variant="h5" color="primary" paddingBottom="4px">
            Réservations
          </Typography>
          <CustomButton primary onClick={() => setDialogOpen(true)}>
            Ajouter
          </CustomButton>
        </Box>
        <Box display="grid" gap="16px">
          {reservations.map((reservation, idx) => {
            if (!("delete" in reservation)) {
              let key: string;
              if ("vdl_reservationid" in reservation) {
                key =
                  reservation.vdl_reservationid +
                  reservation.vdl_sousplateau.value;
              } else {
                key =
                  reservation.vdl_sousplateau.value +
                  reservation.vdl_debutdelareservation +
                  reservation.vdl_findelareservation;
              }
              return (
                <ReservationCard
                  key={key}
                  reservation={reservation}
                  deleteReservation={() => deleteReservation(idx)}
                />
              );
            }
          })}
        </Box>
      </Box>
    </>
  );
};

export default ReservationsSection;
