import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import StepIndicator from "../multiStepForm/StepIndicator";
import { NewContactFields } from "../../data/Contact/forms/new";
import { NewAccountDisplayFields } from "./accountDisplay";

type Props = {
  accountFields: NewAccountDisplayFields;
  contactFields: NewContactFields;
};

const Step3Component = ({ contactFields, accountFields }: Props) => {
  return (
    <Grid container mt={2}>
      {/* Section - Mes informations */}
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: "#005EA6",
            color: "white",
            padding: "0.5rem",
          }}
        >
          <Typography variant="h6" gutterBottom style={{ margin: 0 }}>
            Mes informations
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ backgroundColor: "#F4F4F4", padding: "1rem" }}
        >
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            Titre
          </Typography>
          <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
            {contactFields.vdl_salutation.value}
          </Typography>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            Prénom
          </Typography>
          <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
            {contactFields.firstname.value}
          </Typography>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            Nom
          </Typography>
          <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
            {contactFields.lastname.value}
          </Typography>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            Fonction au sein de l'organisation
          </Typography>
          <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
            {contactFields.vdl_fonction.value}
          </Typography>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            Courriel
          </Typography>
          <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
            {contactFields.emailaddress1.value}
          </Typography>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            N° de téléphone principal
          </Typography>
          <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
            {contactFields.telephone1.value}
          </Typography>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            Autre téléphone
          </Typography>
          <Typography variant="body2">
            {contactFields.telephone2.value}
          </Typography>
        </Grid>
      </Grid>

      {/* Section - Mon organisation */}
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: "#005EA6",
            color: "white",
            padding: "0.5rem",
          }}
        >
          <Typography variant="h6" gutterBottom style={{ margin: 0 }}>
            Mon organisation
          </Typography>
        </Grid>

        {/* Informations générales */}
        <Grid item xs={12}>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{ backgroundColor: "#F4F4F4", padding: "1rem" }}
            >
              <Typography
                variant="h6"
                mb={-2}
                style={{ color: "#005EA6", fontWeight: "bold" }}
              >
                Informations générales
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{ backgroundColor: "#F4F4F4", padding: "1rem" }}
            >
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Nom officiel de l'organisation
              </Typography>
              <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
                {accountFields.name.value}
              </Typography>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Type d'organisation
              </Typography>
              <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
                {
                  accountFields.vdl_typedorganisation.choices.find(
                    (choice) =>
                      choice.value === accountFields.vdl_typedorganisation.value
                  )?.label
                }
              </Typography>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Numéro de téléphone
              </Typography>
              <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
                {accountFields.telephone1.value}
              </Typography>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Site internet
              </Typography>
              <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
                {accountFields.websiteurl.value}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{ backgroundColor: "#F4F4F4", padding: "1rem" }}
            >
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Autres noms utilisés au Québec
              </Typography>
              <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
                {accountFields.vdl_autresnomorganisme.value}
              </Typography>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                NEQ ou UAI
              </Typography>
              <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
                {accountFields.vdl_neq.value}
              </Typography>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Courriel
              </Typography>
              <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
                {accountFields.emailaddress1.value}
              </Typography>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Page Facebook
              </Typography>
              <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
                {accountFields.vdl_pagefacebook.value}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Section - Adresse du siège social */}
      <Grid
        item
        xs={12}
        md={6}
        style={{ backgroundColor: "#F4F4F4", padding: "1rem" }}
      >
        <Typography
          variant="h6"
          mb={2}
          style={{ color: "#005EA6", fontWeight: "bold" }}
          gutterBottom
        >
          Adresse du siège social
        </Typography>
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          Secteur où est situé le siège social
        </Typography>
        <Typography variant="body2">
          {accountFields.vdl_secteursiegesocial.value}
        </Typography>
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          Adresse
        </Typography>
        <Typography variant="body2">
          {accountFields.registeredAddressSearch.value?.address}
          {/* {accountFields.registeredAddressSearch.value} */}
        </Typography>
      </Grid>

      {/* Section - Adresse de correspondance */}
      <Grid
        item
        xs={12}
        md={6}
        style={{ backgroundColor: "#F4F4F4", padding: "1rem" }}
      >
        <Typography
          variant="h6"
          mb={2}
          style={{ color: "#005EA6", fontWeight: "bold" }}
          gutterBottom
        >
          Adresse de correspondance
        </Typography>

        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          Adresse
        </Typography>
        {accountFields.vdl_adresseidentique.value ? (
          <Typography variant="body2">
            Utilise l'adresse du siège social
          </Typography>
        ) : (
          <></>
        )}
        <Typography variant="body2">
          {accountFields.mailingAddressSearch.value?.address}
          {/* {accountFields.mailingAddressSearch.value} */}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Step3Component;
