import { Base64File } from "./types";

export const buildEmptyFile = (fileName: string) =>
  new File([], fileName, { type: "text/plain", lastModified: 0 });

export const dynamicsFileToFileType =  (file: Base64File): File => {
  return base64ToFileType(file.content, file.mimetype, file.filename)
};

export const base64ToFileType =  (
  base64: string,
  contentType: string,
  fileName: string
): File => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new File([byteArray], fileName, { type: contentType, lastModified: Date.now() });
};


