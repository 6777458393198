import { DateTimeInput } from "../../../types";
import {
  DateTimePicker,
  DateTimeValidationError,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";

type Props = {
  field: DateTimeInput;
  fieldName: string;
  handleChange: (field: DateTimeInput) => void;
  disabled: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
};

const FormDateTimeInput = ({
  field,
  fieldName,
  handleChange,
  disabled,
  minDate,
  maxDate,
}: Props) => {
  function handleInputChange(
    value: Dayjs | null,
    context: PickerChangeHandlerContext<DateTimeValidationError>
  ) {
    let _field = { ...field };
    if (value === null) {
      _field.value = undefined;
      _field.error = "Ce champ est requis";
    } else {
      _field.error = "";
      _field.value = value;
    }
    handleChange(_field);
  }

  function handleBlur() {
    if (field.required && !field.value) {
      let _field = { ...field };
      _field.error = "Ce champ est requis";
      handleChange(_field);
    }
  }

  return (
    <DateTimePicker
      name={fieldName}
      ampm={false}
      value={field.value !== undefined ? field.value : null}
      onChange={handleInputChange}
      onClose={handleBlur}
      minDateTime={minDate}
      maxDateTime={maxDate}
      format={field.format ? field.format : "DD/MM/YYYY HH:mm"}
      shouldDisableDate={field.shouldDisableDate}
      shouldDisableMonth={field.shouldDisabledMonth}
      disablePast={field.disablePast}
      disableFuture={field.disableFuture}
      disabled={disabled}
      views={field.views}
      label={field.label}
      slotProps={{
        field: {
          readOnly: true,
        },
        textField: {
          required: field.required,
          fullWidth: true,
          error: !(field.error === ""),
          helperText: field.error ? field.error : field.helperText,
        },
      }}
    />
  );
};

export default FormDateTimeInput;
