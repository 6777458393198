import React from "react";
import { FileInput } from "../../../types";
import { Box, Button, IconButton, TextField } from "@mui/material";
import { Delete, FileUploadOutlined } from "@mui/icons-material";
import ClickableIcon from "../../common/buttons/icons/ClickableIcon";

type Props = {
  field: FileInput;
  fieldName: string;
  handleChange: (field: FileInput) => void;
  disabled: boolean;
};

const FormFileInput = ({ field, fieldName, handleChange, disabled }: Props) => {
  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files ? event.target.files.item(0) : null;
    let _field = { ...field };
    if (file !== null) {
      const maxSize = field.maxSize ? field.maxSize : 32768000;
      if (file.size > maxSize) {
        _field.error = "Le fichier doit avoir une taille maximale de 32 Mo";
      } else if (
        field.accept &&
        field.accept.some((ext) =>
          file.name.toLowerCase().endsWith(ext.toLowerCase())
        )
      ) {
        _field.value = file;
        _field.changed = true;
        _field.error = "";
      }

      handleChange(_field);
    }
  }

  function handleDelete() {
    let _field = { ...field };
    _field.value = undefined;
    _field.changed = true;
    handleChange(_field);
  }

  return (
    <TextField
      value={field.value ? field.value.name : ""}
      name={fieldName}
      required={field.required}
      error={!(field.error === "")}
      helperText={field.error ? field.error : field.helperText}
      disabled={disabled}
      fullWidth
      label={field.label}
      InputProps={{
        sx: { cursor: "pointer", pointerEvents: "none" },
        endAdornment: (
          <Box display="flex">
            {field.value ? (
              <ClickableIcon handleClick={handleDelete} icon={<Delete />} />
            ) : null}
            <IconButton component="label" sx={{ pointerEvents: "auto" }}>
              <FileUploadOutlined />
              <input
                type="file"
                hidden
                multiple={false}
                accept={field.accept ? field.accept.join(", ") : undefined}
                name={fieldName + "_file"}
                onChange={handleInputChange}
              />
            </IconButton>
          </Box>
        ),
      }}
      InputLabelProps={{
        required: field.required,
      }}
    />
  );
};

export default FormFileInput;
