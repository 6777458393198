import React, { useState } from "react";
import {
  DemandTelevisionDisplayPatch,
  DemandTelevisionDisplayRow,
} from "../../../../data/Demand/models";
import { NewDemandFields } from "../../../../data/Demand/forms/dialog";
import { updateDemand } from "../../../../data/Demand/requests";
import EditDemandLayout from "../EditDemandLayout";
import {
  EditDemandTelevisionDisplayFields,
  editDemandTelevisionDisplayFields,
  editDemandTelevisionDisplaySections,
} from "../../../../data/Demand/forms/televisionDisplay";
import SimpleFields from "../../../form/SimpleForm/SimpleFields";
import handleSimpleFormSubmit from "../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../hooks/useTabs";
import { dynamicsFileToFileType } from "../../../../data/common/buildFile";
import { validateDateString } from "../../../../data/common/validateDateString";
import { fileToBase64 } from "../../../../data/common/fileToBase64";
import buildForm from "../../../form/SimpleForm/buildForm";

type Props = {
  demand: DemandTelevisionDisplayRow;
  isNewDemand: boolean;
  fieldsGeneral: NewDemandFields;
  onUpdateSuccess: () => void;
  onUpdateFailure: (error?: string) => void;
  setFieldsGeneral: React.Dispatch<React.SetStateAction<NewDemandFields>>;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const TelevisionDisplay = ({
  demand,
  isNewDemand,
  fieldsGeneral,
  setFieldsGeneral,
  onUpdateSuccess,
  onUpdateFailure,
  setError,
}: Props) => {
  const details = demand.vdl_detailsdelademande;
  const [fields, setFields] = useState<EditDemandTelevisionDisplayFields>(
    buildForm(editDemandTelevisionDisplayFields, {
      vdl_imagedepromotionfile: details.vdl_imagedepromotionfile
        ? dynamicsFileToFileType(details.vdl_imagedepromotionfile)
        : undefined,
      vdl_debutdediffusion: validateDateString(details.vdl_debutdediffusion),
      vdl_findediffusion: validateDateString(details.vdl_findediffusion),
      vdl_secteur1: details.vdl_secteur1 ?? false,
      vdl_secteur2: details.vdl_secteur2 ?? false,
      vdl_secteur3: details.vdl_secteur3 ?? false,
      vdl_secteur4: details.vdl_secteur4 ?? false,
      vdl_secteur5: details.vdl_secteur5 ?? false,
      vdl_secteur6: details.vdl_secteur6 ?? false,
    })
  );
  const { activeTab, changeTab } = useTabs();

  const onSubmit = async () => {
    const errors = handleSimpleFormSubmit(fields, setFields);

    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      const scrollTo = fields[errors[errors.length - 1]].fieldRef;
      if (scrollTo) {
        scrollTo.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
      return;
    }

    const _demand: DemandTelevisionDisplayPatch = {
      title: fieldsGeneral.title.value,
      vdl_contactprincipal: fieldsGeneral.vdl_contactprincipal.value,
      type: demand.type,
      vdl_detailsdelademande: {
        vdl_debutdediffusion: fields.vdl_debutdediffusion.value?.toISOString(),
        vdl_findediffusion: fields.vdl_findediffusion.value?.toISOString(),
        vdl_secteur1: fields.vdl_secteur1.value,
        vdl_secteur2: fields.vdl_secteur2.value,
        vdl_secteur3: fields.vdl_secteur3.value,
        vdl_secteur4: fields.vdl_secteur4.value,
        vdl_secteur5: fields.vdl_secteur5.value,
        vdl_secteur6: fields.vdl_secteur6.value,
      },
    };
    if (fields.vdl_imagedepromotionfile.changed) {
      if (fields.vdl_imagedepromotionfile.value) {
        const pieceJointeToBase = await fileToBase64(
          fields.vdl_imagedepromotionfile.value
        );
        if (pieceJointeToBase) {
          _demand.vdl_detailsdelademande.vdl_imagedepromotionfile = {
            filename: fields.vdl_imagedepromotionfile.value.name,
            content: pieceJointeToBase,
          };
        }
      }
    }

    if (demand.statuscode === "Inutilisée") {
      _demand.vdl_detailsdelademande.statuscode = "En création";
    }

    const { status, error } = await updateDemand(demand.incidentid, _demand);
    if (status === "success") {
      onUpdateSuccess();
    } else {
      onUpdateFailure(error);
    }
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_lienhautduformulaireportail}
    >
      <SimpleFields
        updateRefs
        fields={fields}
        setFields={setFields}
        sections={editDemandTelevisionDisplaySections}
        sectionsBackground="common.white"
      />
    </EditDemandLayout>
  );
};

export default TelevisionDisplay;
