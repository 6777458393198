import {
  CheckBoxInput,
  CommentInput,
  DateInput,
  DropDownChoice,
  DropDownMultipleOptionSetInput,
  DropDownOptionSetInput,
  FormFields,
  FormSection,
  MultilineInput,
  NumberInput,
  TextInput,
  TimeInput,
} from "../../../../../types";
import { filmingDisplayNames } from "../../../displayNames";
import {
  EditDemandSpecialEventsGeneralFields,
  editDemandSpecialEventsGeneralFields,
  editDemandSpecialEventsGeneralSections,
} from "../specialEventsGeneral";

export type EditDemandFilmingFields = {
  vdl_titredelaproduction: TextInput;
  vdl_naturedutournage: DropDownMultipleOptionSetInput;
  vdl_tournageetudiant: CheckBoxInput;
  vdl_resumeduscenarioenquelquesmots: MultilineInput;
  vdl_nombredevehiculesdeproductionetparticuliers: NumberInput;
  vdl_cubes: NumberInput;
  vdl_roulottes: NumberInput;
  vdl_voituresparticuliers: NumberInput;
  vdl_autresprecisez: TextInput;
  vdl_generatricerequise: CheckBoxInput;
  vdl_descriptiondesequipementsaetreinstalles: MultilineInput;
  vdl_cascadesoueffetsspeciaux: CheckBoxInput;
  vdl_descriptiondetailleedescascadeseffetsspeciaux: MultilineInput;

  //Engagements de la maison de production
  vdl_engagementloisetreglement: CheckBoxInput;
  vdl_respectdesconditionsdusoutienmunicipal: CheckBoxInput;
  vdl_envoidesdocuments2semmaximumavantletournage: CheckBoxInput;
  vdl_engagementapayerlafacture: CheckBoxInput;
  vdl_sengageafournirlesjustificatifssurdemande: CheckBoxInput;
};

const vdl_naturedutournageChoices: DropDownChoice[] = [
  {
    value: "Long métrage",
  },
  {
    value: "Documentaire",
  },
  {
    value: "Court métrage",
  },
  {
    value: "Corporatif",
  },
  {
    value: "Télésérie",
  },
  {
    value: "Émission télé",
  },
  {
    value: "Vidéoclip",
  },
  {
    value: "Publicité télé",
  },
  {
    value: "Websérie",
  },
  {
    value: "Session photo",
  },
];

const editDemandFilmingFields: EditDemandFilmingFields = {
  vdl_titredelaproduction: {
    type: "text",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    label: filmingDisplayNames.vdl_titredelaproduction,
  },
  vdl_naturedutournage: {
    type: "multipleoptionset",
    error: "",
    value: [],
    required: true,
    fillLine: true,
    choices: vdl_naturedutournageChoices,
    label: filmingDisplayNames.vdl_naturedutournage,
  },
  vdl_tournageetudiant: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: filmingDisplayNames.vdl_tournageetudiant,
  },
  vdl_resumeduscenarioenquelquesmots: {
    type: "multiline",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    maxLength: 3000,
    label: filmingDisplayNames.vdl_resumeduscenarioenquelquesmots,
  },
  vdl_nombredevehiculesdeproductionetparticuliers: {
    type: "number",
    error: "",
    value: undefined,
    required: false,
    fillLine: true,
    label: filmingDisplayNames.vdl_nombredevehiculesdeproductionetparticuliers,
  },
  vdl_cubes: {
    type: "number",
    error: "",
    value: undefined,
    required: false,
    fillLine: true,
    label: filmingDisplayNames.vdl_cubes,
  },
  vdl_roulottes: {
    type: "number",
    error: "",
    value: undefined,
    required: false,
    fillLine: true,
    label: filmingDisplayNames.vdl_roulottes,
  },
  vdl_voituresparticuliers: {
    type: "number",
    error: "",
    value: undefined,
    required: false,
    fillLine: true,
    label: filmingDisplayNames.vdl_voituresparticuliers,
  },
  vdl_autresprecisez: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    label: filmingDisplayNames.vdl_autresprecisez,
  },
  vdl_generatricerequise: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: filmingDisplayNames.vdl_generatricerequise,
  },
  vdl_descriptiondesequipementsaetreinstalles: {
    type: "multiline",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    maxLength: 3000,
    label: filmingDisplayNames.vdl_resumeduscenarioenquelquesmots,
  },
  vdl_cascadesoueffetsspeciaux: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: filmingDisplayNames.vdl_cascadesoueffetsspeciaux,
  },
  vdl_descriptiondetailleedescascadeseffetsspeciaux: {
    type: "multiline",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    maxLength: 3000,
    label: filmingDisplayNames.vdl_resumeduscenarioenquelquesmots,
  },

  vdl_engagementloisetreglement: {
    type: "checkbox",
    error: "",
    value: false,
    required: true,
    fillLine: true,
    label: filmingDisplayNames.vdl_engagementloisetreglement,
  },
  vdl_respectdesconditionsdusoutienmunicipal: {
    type: "checkbox",
    error: "",
    value: false,
    required: true,
    fillLine: true,
    label: filmingDisplayNames.vdl_respectdesconditionsdusoutienmunicipal,
  },
  vdl_envoidesdocuments2semmaximumavantletournage: {
    type: "checkbox",
    error: "",
    value: false,
    required: true,
    fillLine: true,
    label: filmingDisplayNames.vdl_envoidesdocuments2semmaximumavantletournage,
  },
  vdl_engagementapayerlafacture: {
    type: "checkbox",
    error: "",
    value: false,
    required: true,
    fillLine: true,
    label: filmingDisplayNames.vdl_engagementapayerlafacture,
  },
  vdl_sengageafournirlesjustificatifssurdemande: {
    type: "checkbox",
    error: "",
    value: false,
    required: true,
    fillLine: true,
    label: filmingDisplayNames.vdl_sengageafournirlesjustificatifssurdemande,
  },
};

const editDemandFilimingTournageSection: FormSection<EditDemandFilmingFields> =
  {
    fields: [
      "vdl_titredelaproduction",
      "vdl_naturedutournage",
      "vdl_tournageetudiant",
      "vdl_resumeduscenarioenquelquesmots",
      "vdl_nombredevehiculesdeproductionetparticuliers",
      "vdl_cubes",
      "vdl_roulottes",
      "vdl_voituresparticuliers",

      "vdl_autresprecisez",
      "vdl_generatricerequise",
      "vdl_descriptiondesequipementsaetreinstalles",
      "vdl_cascadesoueffetsspeciaux",
      "vdl_descriptiondetailleedescascadeseffetsspeciaux",
    ],
    title: "Tournage",
  };

const editDemandFilimingEngagementSection: FormSection<EditDemandFilmingFields> =
  {
    fields: [
      "vdl_engagementloisetreglement",
      "vdl_respectdesconditionsdusoutienmunicipal",
      "vdl_envoidesdocuments2semmaximumavantletournage",
      "vdl_engagementapayerlafacture",
      "vdl_sengageafournirlesjustificatifssurdemande",
    ],
    title: "Engagements de la maison de production",
  };

const editDemandFilmingSections: FormSection<EditDemandFilmingFields>[] = [
  editDemandFilimingTournageSection,
  editDemandFilimingEngagementSection,
];

export { editDemandFilmingFields, editDemandFilmingSections };
