import React, { useEffect, useState } from "react";
import SimpleFormWithSections from "../components/form/SimpleForm/SimpleFormWithSections";
import { Box } from "@mui/material";
import SectionLoading from "../components/common/loading/SectionLoading";
import { FormFields } from "../types";
import useUser from "../hooks/useUserData";
import { getContact, updateContact } from "../data/Contact/requests";
import { useSnackbar } from "../hooks/useSnackbar";
import { useNavigate, useParams } from "react-router-dom";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import { editContactForm, EditContactFields } from "../data/Contact/forms/edit";
import { ContactPatch } from "../data/Contact/models";
import { getRoles } from "../data/Role/requests";
import ErrorMessage from "../components/common/error/ErrorMessage";

const EditContact = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const [fields, setFields] = useState<EditContactFields>(
    editContactForm.fields
  );
  const { email } = useParams();
  const [contactId, setContactId] = useState<string | undefined>();

  const { userHasRole } = useUser();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  useEffect(() => {
    const querry = async () => {
      try {
        if (!email) throw Error();
        const rolesResult = await getRoles();

        const { data, error } = await getContact(email);
        if (!data || error) {
          throw Error();
        }
        setContactId(data.contactid);
        let _fields = fields;
        _fields.firstname.value = data.firstname;
        _fields.lastname.value = data.lastname;
        _fields.telephone1.value = data.telephone1;
        _fields.telephone2.value = data.telephone2 ?? "";
        _fields.firstname.value = data.firstname;
        _fields.vdl_salutation.value = data.vdl_salutation;
        _fields.vdl_fonction.value = data.vdl_fonction;
        _fields.vdl_accesauportail.value = data.vdl_accesauportail
          ? "Oui"
          : "Non";
        _fields.vdl_accesauportail.disabledCondition = () =>
          !userHasRole("Administrateur");
        _fields.vdl_roledesecurite = {
          ..._fields.vdl_roledesecurite,
          choices:
            rolesResult.status === "success" && rolesResult.data
              ? rolesResult.data
              : [],
          disabledCondition: roleDisabled,
          value: data.vdl_roledesecurite?.value,
        };

        setFields(_fields);
        setIsLoading(false);
      } catch (e) {
        setError(
          email ? "Erreur dans la récupération de données" : "URL invalide"
        );
      } finally {
        setIsLoading(false);
      }
    };

    querry();
  }, []);
  function roleDisabled(fields: FormFields): boolean {
    try {
      return !(
        fields.vdl_accesauportail.value === "Oui" &&
        userHasRole("Administrateur")
      );
    } catch (e) {
      return false;
    }
  }
  const handleSuccess = async (fields: FormFields) => {
    let _contact: ContactPatch = {
      firstname: fields.firstname.value,
      lastname: fields.lastname.value,
      telephone1: fields.telephone1.value,
      telephone2: fields.telephone2.value,
      vdl_salutation: fields.vdl_salutation.value,
      vdl_fonction: fields.vdl_fonction.value,
      vdl_accesauportail: fields.vdl_accesauportail.value === "Oui",
    };
    if (userHasRole("Administrateur")) {
      _contact.vdl_accesauportail = fields.vdl_accesauportail.value === "Oui";
      if (fields.vdl_roledesecurite.value) {
        _contact.vdl_roledesecurite = fields.vdl_roledesecurite.value;
      }
    }
    if (!contactId) return;

    const { status, error } = await updateContact(contactId, _contact);
    if (status === "success") {
      showSnackbar("Contact modifié", "success");
      navigate(`/contacts/${email}`);
    } else {
      showSnackbar(
        "Erreur dans la modification du contact. Réessayez ou contactez le support.",
        "error"
      );
    }
  };

  if (isLoading)
    return (
      <PageTitleLayout title="Modifier un contact">
        <SectionLoading />
      </PageTitleLayout>
    );
  if (error) return <ErrorMessage message={error} />;
  return (
    <PageTitleLayout title="Modifier un contact">
      <Box>
        <SimpleFormWithSections
          maxWidth={900}
          fields={fields}
          handleSuccess={handleSuccess}
          submitText={"Enregistrer"}
          sections={editContactForm.sections}
          columns={{ minWidth: 220, count: 2 }}
        />
      </Box>
    </PageTitleLayout>
  );
};

export default EditContact;
