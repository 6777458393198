import React from "react";
import { AutoCompleteInput, TextInput } from "../../../types";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from "@mui/material";

type Props = {
  field: AutoCompleteInput;
  fieldName: string;
  handleChange: (field: AutoCompleteInput) => void;
  disabled: boolean;
};

const FormAutoCompleteInput = ({
  field,
  fieldName,
  handleChange,
  disabled,
}: Props) => {
  
  function handleBlur(event: React.FocusEvent<HTMLDivElement>) {
    let _field = { ...field };
    _field.error = "";
    if (field.required && (!field.value || field.value === "")) {
      _field.error = "Ce champ est requis";
    }
    if (field.validator && field.value && field.value !== "") {
      const error = field.validator(field.value);
      if (error) {
        _field.error = error;
      }
    }
    handleChange(_field);
  }
  const handleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | null
  ) => {
    let _field = { ...field };
    if (value !== null) {
      _field.value = value;
      _field.error = "";
      console.log(_field);
      handleChange(_field);
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    let _field = { ...field };
    _field.value = value;
    _field.error = "";
    handleChange(_field);
  };

  return (
    <Autocomplete
      value={field.value}
      fullWidth
      freeSolo={field.freeSolo}
      options={field.choices.map((option) => option.value)}
      disabled={disabled}
      onChange={handleAutoCompleteChange}
      onBlur={handleBlur}
      renderInput={(params) => (
        <TextField
          {...params}
          label={field.label}
          name={fieldName}
          onChange={handleInputChange}
          inputProps={{ ...params.inputProps, maxLength: field.maxLength }}
          required={field.required}
          error={!(field.error === "")}
          helperText={field.error ? field.error : field.helperText}
          InputLabelProps={{
            required: field.required,
            sx: {
              ".MuiInputLabel-asterisk": field.required
                ? { color: "#A81B8D", fontSize: "20px" }
                : {},
            },
          }}
        />
      )}
    />
  );
};

export default FormAutoCompleteInput;
