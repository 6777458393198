import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Dialog, useMediaQuery, useTheme } from "@mui/material";

import {
  GroupedProductRows,
  ProductRow,
} from "../../../../../../data/Product/models";
import ProductsCategory from "./ProductsCategory";
import ProductDialogHeader from "./ProductDialogHeader";
import Transition from "../../../../../common/animation/DialogTransition";
import SectionLoading from "../../../../../common/loading/SectionLoading";
import { ProductDictionary } from "../ProductsSection";
import { DemandStandardEquipmentProductRow } from "../../../../../../data/Demand/types/standardEquipment";
import ErrorMessage from "../../../../../common/error/ErrorMessage";

type Props = {
  open: boolean;
  products: ProductRow[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  productDictionary: ProductDictionary;
  handleAddProduct: (product: DemandStandardEquipmentProductRow) => void;
  isLoading: boolean;
  error?: string;
};

const ProductDialog = ({
  products,
  open,
  setOpen,
  productDictionary,
  handleAddProduct,
  isLoading,
  error,
}: Props) => {
  const defaultCategory = "Toutes les catégories";
  const [category, setCategory] = useState<string>(defaultCategory);
  const [searchText, setSearchText] = useState<string>("");
  const [scrollY, setScrollY] = useState<number>();
  const [filterVisible, setFilterVisible] = useState<boolean>(true);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const groupedProducts = useMemo(
    () =>
      products.reduce((acc: GroupedProductRows, item) => {
        if (!acc[item.vdl_categorie.value]) {
          acc[item.vdl_categorie.value] = [];
        }
        acc[item.vdl_categorie.value].push(item);
        return acc;
      }, {}),
    [products]
  );

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (!sm) return;
    const scroll = event.currentTarget.scrollTop;
    if (!scrollY) {
      setScrollY(scroll);
      return;
    }
    if (scrollY - scroll > 300) {
      setFilterVisible(true);
      setScrollY(scroll);
    }
    if (scroll - scrollY > 10) {
      setFilterVisible(false);
      setScrollY(scroll);
    }
  };

  const filteredCategoryItems = (
    category: string,
    groupedProducts: GroupedProductRows
  ): ProductRow[] => {
    if (searchText === "") return groupedProducts[category];
    console.log(
      groupedProducts[category].filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      )
    );
    return groupedProducts[category].filter((item) =>
      item.name.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const renderSwtich = () => {
    if (isLoading) return <SectionLoading />;

    if (error)
      return (
        <ErrorMessage message={"Erreur dans la récupération des produits."} />
      );

    if (products.length === 0)
      return (
        <ErrorMessage
          message={
            "Il est possible que vous n'ayez accès à aucune catégorie de produits. Veuillez contacter la ville pour obtenir de l'aide."
          }
        />
      );

    if (category === defaultCategory) {
      return Object.keys(groupedProducts).map((category) => (
        <ProductsCategory
          key={category}
          category={category}
          products={filteredCategoryItems(category, groupedProducts)}
          productDictionary={productDictionary}
          handleAddProduct={handleAddProduct}
        />
      ));
    } else {
      return (
        <ProductsCategory
          category={category}
          products={filteredCategoryItems(category, groupedProducts)}
          productDictionary={productDictionary}
          handleAddProduct={handleAddProduct}
        />
      );
    }
  };
  return (
    <Dialog open={open} fullScreen TransitionComponent={Transition}>
      <Box display="flex" flexDirection="column" height="100%">
        <ProductDialogHeader
          defaultCategory={defaultCategory}
          category={category}
          categories={Object.keys(groupedProducts)}
          setCategory={setCategory}
          setOpen={setOpen}
          setSearchText={setSearchText}
          searchText={searchText}
          filterVisible={filterVisible}
        />
        <Box
          height="100%"
          sx={{ overflowY: "auto" }}
          bgcolor={theme.custom.appLayout.backgroundColor}
          component="div"
          onScroll={handleScroll}
        >
          <Box padding={"16px"}>{renderSwtich()}</Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          bgcolor={theme.palette.secondary.main}
          paddingY="16px"
        ></Box>
      </Box>
    </Dialog>
  );
};

export default ProductDialog;
