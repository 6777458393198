import { useEffect, useState, useContext } from "react";
import { Box, CssBaseline, useMediaQuery, useTheme } from "@mui/material";
import AppDrawer, { AppDrawerContext } from "../AppDrawer/AppDrawer";
import AppHeader from "../AppHeader/AppHeader";
import AppFooter from "../AppFooter/AppFooter";
import { AuthenticatedTemplate } from "@azure/msal-react";
import AppContent from "../AppContent/AppContent";

type Props = {
  children: React.ReactNode;
};

export default function AuthenticatedLayout({ children }: Props) {
  const theme = useTheme();
  const drawerContext = useContext(AppDrawerContext);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);
  const [isDrawerMobileOpen, setIsDrawerMobileOpen] = useState<boolean>(false);
  const sm = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <AuthenticatedTemplate>
      <AppDrawerContext.Provider
        value={{
          width: 272,
          desktop: { isOpen: isDrawerOpen, setIsOpen: setIsDrawerOpen },
          mobile: {
            isOpen: isDrawerMobileOpen,
            setIsOpen: setIsDrawerMobileOpen,
          },
        }}
      >
        <Box sx={{height: "100%"}}>
          <CssBaseline />
          <AppHeader />
          <AppDrawer />
          <AppContent footer={<AppFooter />}>{children}</AppContent>
        </Box>
      </AppDrawerContext.Provider>
    </AuthenticatedTemplate>
  );
}
