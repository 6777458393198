import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandableFolder from "../components/pageSpecific/Documents/ExpandableFolder";
import BoxResponsivePadding from "../components/common/boxes/BoxResponsivePadding";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import DownloadbleFile from "../components/pageSpecific/Documents/DownloadableFile";
import { getFiles } from "../data/Document/requests";
import { DocumentRow } from "../data/Document/models";
import DocumentSkeleton from "../components/pageSpecific/Documents/DocumentSkeleton";
import ErrorMessage from "../components/common/error/ErrorMessage";

function filterMatchingObjects(
  obj: DocumentRow | DocumentRow[],
  filterValue: string
): DocumentRow[] {
  if (Array.isArray(obj)) {
    const filteredArray: DocumentRow[] = obj.flatMap((child) =>
      filterMatchingObjects(child, filterValue)
    );
    return filteredArray;
  } else {
    if (obj.type === "folder") {
      const filteredObj: DocumentRow = {
        name: obj.name,
        type: obj.type,
        files: [],
      };
      if (obj.name.toLowerCase().includes(filterValue.toLowerCase())) {
        filteredObj.files = obj.files;
        return [filteredObj];
      } else {
        const filteredChildren: DocumentRow[] = obj.files.flatMap((file) =>
          filterMatchingObjects(file, filterValue)
        );
        if (filteredChildren.length > 0) {
          filteredObj.files = filteredChildren;
          return [filteredObj];
        }
      }
    } else if (obj.name.toLowerCase().includes(filterValue.toLowerCase())) {
      return [{ name: obj.name, type: obj.type, downloadUrl: obj.downloadUrl }];
    }
    return [];
  }
}

const Documents = () => {
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filesData, setFilesData] = useState<DocumentRow[]>([]);
  const [error, setError] = useState<string>();

  useEffect(() => {
    const querry = async () => {
      const { data, error } = await getFiles();
      if (data) {
        setFilesData(data);
      } else {
        setError("Erreur " + error);
      }
      setIsLoading(false);
    };
    querry();
  }, []);

  const handleSearchValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const handleClear = () => {
    setSearchValue("");
  };

  function handleFilter() {
    if (searchValue.trim() === "") {
      return filesData;
    }
    return filterMatchingObjects(filesData, searchValue);
  }

  if (isLoading) return <DocumentsPagesLoading />;

  if(error) return <ErrorMessage message={error}/>

  return (
    <PageTitleLayout title="Documents">
      <BoxResponsivePadding display="flex" flexDirection="column" gap="8px">
        <TextField
          size="small"
          variant="outlined"
          value={searchValue}
          onChange={handleSearchValue}
          placeholder="Rechercher"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ cursor: "pointer" }}>
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                style={{ display: searchValue === "" ? "none" : "flex" }}
                onClick={handleClear}
                sx={{ cursor: "pointer" }}
              >
                <ClearIcon />
              </InputAdornment>
            ),
          }}
        />

        {handleFilter().map((dossier, index) => {
          if (dossier.type === "folder") {
            return <ExpandableFolder folder={dossier} level={0} key={index} />;
          }
          if (dossier.type === "file") {
            return <DownloadbleFile file={dossier} key={index} />;
          }
        })}
      </BoxResponsivePadding>
    </PageTitleLayout>
  );
};

export default Documents;

const DocumentsPagesLoading = () => {
  return (
    <PageTitleLayout isLoading title="Documents">
      <BoxResponsivePadding display="flex" flexDirection="column">
        <DocumentSkeleton />
      </BoxResponsivePadding>
    </PageTitleLayout>
  );
};
