import { Box } from "@mui/material";
import {
  DemandAdmissibilityRow,
  DemandEcoSupportRow,
} from "../../../../../data/Demand/models";
import { ecoSupportDisplayNames } from "../../../../../data/Demand/displayNames";
import DisplayFieldCard from "../../../../common/cards/DisplayFieldCard";
import { dateStringToShortString } from "../../../../../data/common/fieldToString";

type Props = {
  demand: DemandEcoSupportRow;
};

const EcoSupport = ({ demand }: Props) => {
  const details = demand.vdl_detailsdelademande;
  return (
    <Box display="grid" gap="16px">
      <DisplayFieldCard
        title="Prêt d'équipement"
        fields={[
          {
            label:
              ecoSupportDisplayNames.vdl_duobacsroulants240ldechetrecyclage,
            value: details.vdl_duobacsroulants240ldechetrecyclage,
            type: "check",
          },
          {
            label: ecoSupportDisplayNames.vdl_qte2,
            value: details.vdl_qte2?.toString(),
            type: "string",
          },
          {
            label: ecoSupportDisplayNames.vdl_duobarils205ldechetsrecyclage,
            value: details.vdl_duobarils205ldechetsrecyclage,
            type: "check",
          },
          {
            label: ecoSupportDisplayNames.vdl_qte4,
            value: details.vdl_qte4?.toString(),
            type: "string",
          },
          {
            label: ecoSupportDisplayNames.vdl_bacroulantbrun240lcompost,
            value: details.vdl_bacroulantbrun240lcompost,
            type: "check",
          },
          {
            label: ecoSupportDisplayNames.vdl_qte5,
            value: details.vdl_qte5?.toString(),
            type: "string",
          },
          {
            label: ecoSupportDisplayNames.vdl_bacslimjimnoir90lavecsacsdechet,
            value: details.vdl_bacslimjimnoir90lavecsacsdechet,
            type: "check",
          },
          {
            label: ecoSupportDisplayNames.vdl_qte6,
            value: details.vdl_qte6?.toString(),
            type: "string",
          },
          {
            label:
              ecoSupportDisplayNames.vdl_bacslimjimbleu90lavecsacsrecyclage,
            value: details.vdl_bacslimjimbleu90lavecsacsrecyclage,
            type: "check",
          },
          {
            label: ecoSupportDisplayNames.vdl_qte,
            value: details.vdl_qte?.toString(),
            type: "string",
          },
          {
            label: ecoSupportDisplayNames.vdl_bacslimjimbrun90lavecsacscompost,
            value: details.vdl_bacslimjimbrun90lavecsacscompost,
            type: "check",
          },
          {
            label: ecoSupportDisplayNames.vdl_qte1,
            value: details.vdl_qte1?.toString(),
            type: "string",
          },

          {
            label: ecoSupportDisplayNames.vdl_supportaveloavecchainesetcadenas,
            value: details.vdl_supportaveloavecchainesetcadenas,
            type: "check",
          },
        ]}
        columns={2}
        minColWidth={300}
      />
      <DisplayFieldCard
        title="Lieu de livraison"
        fields={[
          {
            label: ecoSupportDisplayNames.vdl_addressedelivraison,
            value: details.vdl_addressedelivraison,
            type: "string",
          },
          {
            label: ecoSupportDisplayNames.vdl_datedelivraison,
            value: dateStringToShortString(details.vdl_datedelivraison),
            type: "string",
          },
          {
            label: ecoSupportDisplayNames.vdl_datederecupperation,
            value: dateStringToShortString(details.vdl_datederecupperation),
            type: "string",
          },
        ]}
        columns={1}
        minColWidth={300}
      />
      <DisplayFieldCard
        title="Autres services offerts"
        fields={[
          {
            label:
              ecoSupportDisplayNames.vdl_kiosqueenvironnementecocitoyennete,
            value: details.vdl_kiosqueenvironnementecocitoyennete,
            type: "check",
          },
          {
            label:
              ecoSupportDisplayNames.vdl_servicedeplanificationetdaccompagnement,
            value: details.vdl_servicedeplanificationetdaccompagnement,
            type: "check",
          },
        ]}
        columns={1}
        minColWidth={300}
      />
    </Box>
  );
};

export default EcoSupport;
