import { DisplayNames } from "../../types";
import { ActivityPost } from "./models";

const activityDisplayNames: DisplayNames<ActivityPost> = {
    subject: "Sujet",
    description: "Description",
    vdl_expediteur: "",
    vdl_typedinformation: "Type d'information",
    files: "Pièces jointes"
};

export default activityDisplayNames;
