import { Box, Card, CardContent, useTheme } from "@mui/material";
import React from "react";
import GeneralInfoSection from "./GeneralInfoSection/GeneralInfoSection";
import HistoricSection from "./HistoricSection/HistoricSection";
import { DemandGeneralRow } from "../../../../../data/Demand/models";

type Props = {
  demand: DemandGeneralRow;
};

const GeneralCard = ({demand} : Props) => {
  const theme = useTheme();
  return (
    <Card elevation={0} sx={{ bgcolor: "white" }}>
      <CardContent>
        <Box display="grid" gap="16px">
          <GeneralInfoSection demand={demand}/>
          <Box
            height="2px"
            bgcolor={theme.palette.grey[300]}
            width="100%"
          />
          <HistoricSection demand={demand}/>
        </Box>
      </CardContent>
    </Card>
  );
};

export default GeneralCard;
