import { errorHandler } from "../../components/common/error/errorHandler";
import { GetResponse } from "../common/types";
import usefetchWithMsal from "../fetchWithMsal";
import { DocumentFile, DocumentRow } from "./models";

export const getFiles = async (): Promise<GetResponse<DocumentRow[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/get_files`;
    const result = await usefetchWithMsal("GET", endpoint);
    if (result.StatusCode === 404)
      return { status: "success", data: undefined };
    if (!result) throw new Error("Réponse de la requête invalide");

    const data = result;

    return { status: "success", data: data };
  } catch (e) {
    return errorHandler(e, "Erreur dans la récupération des fichiers");
  }
};

export const getDemandFiles = async (
  incidentName: string
): Promise<GetResponse<DocumentFile[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/get_demand_files/${incidentName}`;
    const result = await usefetchWithMsal("GET", endpoint);
    if (result.StatusCode === 404)
      return { status: "success", data: undefined };
    if (!result) throw new Error("Réponse de la requête invalide");

    const data = result;

    return { status: "success", data: data };
  } catch (e) {
    return errorHandler(
      e,
      "Erreur dans la récupération des pièces jointes de la demande"
    );
  }
};
