import { useMsal } from "@azure/msal-react";
import React from "react";
import AuthenticatedLayout from "./AuthenticatedLayout";
import { Outlet } from "react-router-dom";
import AppLoading from "../../common/loading/AppLoading";
import AuthenticatedEmptyLayout from "./AuthenticatedEmptyLayout";

/*Will be useful if unauthenticated layout is needed*/
const AppLayout = () => {
  return (
    <>
      <AuthenticatedLayout children={<Outlet />} />
    </>
  );
};

const AppEmptyLayout = () => {
  return (
    <>
      <AuthenticatedEmptyLayout children={<Outlet />} />
    </>
  );
};

export { AppLayout, AppEmptyLayout };

/*Will be useful if unauthenticated layout is needed
const AppLayout = () => {
  const { inProgress } = useMsal();
  return (
    <>
      {inProgress ? (
        <AppLoading />
      ) : (
        <AuthenticatedLayout children={<Outlet />} />
      )}
    </>
  );
};

export default AppLayout;*/
