import {
  CommentInput,
  DateInput,
  DropDownChoice,
  DropDownMultipleOptionSetInput,
  DropDownOptionSetInput,
  FormFields,
  FormSection,
  MultilineInput,
  TextInput,
  TimeInput,
} from "../../../../types";
import { specialEventsGeneralDisplayNames } from "../../displayNames";

export type EditDemandSpecialEventsGeneralFields = {
  vdl_lieudelevenement: TextInput;
  vdl_precisionsurlelieu: DropDownOptionSetInput;
  vdl_siautreprecisezlieu: TextInput;
  vdl_encasdintemperieslevenementest: DropDownMultipleOptionSetInput;
  vdl_siremisle: DateInput;
};
const vdl_precisionsurlelieuChoices: DropDownChoice[] = [
  {
    value: "Plateau sportif extérieur",
  },
  {
    value: "Centre communautaire",
  },
  {
    value: "Aréna",
  },
  {
    value: "Stationnement",
  },
  {
    value: "Voie Publique",
  },
  {
    value: "Espace vert",
  },
  {
    value: "Autre",
  },
];

const vdl_encasdintemperieslevenementestChoices: DropDownChoice[] = [
  {
    value: "Maintenu",
  },
  {
    value: "Annulé",
  },
  {
    value: "Remis",
  },
];

const editDemandSpecialEventsGeneralFields: EditDemandSpecialEventsGeneralFields =
  {
    vdl_lieudelevenement: {
      type: "text",
      error: "",
      value: "",
      required: true,
      fillLine: true,
      maxLength: 300,
      label: specialEventsGeneralDisplayNames.vdl_lieudelevenement,
    },
    vdl_precisionsurlelieu: {
      type: "optionset",
      error: "",
      value: undefined,
      required: true,
      fillLine: true,
      choices: vdl_precisionsurlelieuChoices,
      label: specialEventsGeneralDisplayNames.vdl_precisionsurlelieu,
    },
    vdl_siautreprecisezlieu: {
      type: "text",
      error: "",
      value: "",
      required: false,
      fillLine: true,
      disabledCondition: (fields: FormFields) =>
        fields.vdl_precisionsurlelieu.value !== "Autre",
      label: specialEventsGeneralDisplayNames.vdl_siautreprecisezlieu,
    },
    vdl_encasdintemperieslevenementest: {
      type: "multipleoptionset",
      error: "",
      value: [],
      required: true,
      fillLine: true,
      choices: vdl_encasdintemperieslevenementestChoices,
      label:
        specialEventsGeneralDisplayNames.vdl_encasdintemperieslevenementest,
      onChangeEvent: (fields: FormFields) => {
        try {
          const fields2 = <EditDemandSpecialEventsGeneralFields>fields;
          if (
            !fields2.vdl_encasdintemperieslevenementest.value.includes("Remis")
          ) {
            return {
              vdl_siremisle: {
                ...fields2.vdl_siremisle,
                value: undefined,
              },
            };
          }
        } catch (error) {
          return undefined
        }
      },
    },
    vdl_siremisle: {
      type: "date",
      error: "",
      value: undefined,
      required: false,
      fillLine: true,
      disablePast: true,
      disabledCondition: (fields: FormFields) =>
        !(fields.vdl_encasdintemperieslevenementest.value as string[]).includes(
          "Remis"
        ),
      label: specialEventsGeneralDisplayNames.vdl_siremisle,
    },
  };

const editDemandSpecialEventsGeneralCommonSection: FormSection<EditDemandSpecialEventsGeneralFields> =
  {
    fields: [
      "vdl_lieudelevenement",
      "vdl_precisionsurlelieu",
      "vdl_siautreprecisezlieu",
      "vdl_encasdintemperieslevenementest",
      "vdl_siremisle",
    ],
    title: "Informations",
  };

const editDemandSpecialEventsGeneralSections: FormSection<EditDemandSpecialEventsGeneralFields>[] =
  [editDemandSpecialEventsGeneralCommonSection];

export {
  editDemandSpecialEventsGeneralFields,
  editDemandSpecialEventsGeneralSections,
};
