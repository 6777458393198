import { IconButton, styled, useMediaQuery, useTheme } from "@mui/material";
import { useContext } from "react";
import { AppDrawerContext } from "../AppDrawer/AppDrawer";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import AppHeaderUser from "./AppHeaderUser";

export default function AppHeader() {
  const theme = useTheme();
  const drawerContext = useContext(AppDrawerContext);
  const sm = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrawerToggle = () => {
    if (sm) {
      drawerContext?.mobile.setIsOpen(!drawerContext?.mobile.isOpen);
    }
  };

  const drawerWidth = drawerContext?.width;
  const AppBarStyled = styled(MuiAppBar)(({ theme }) => ({
    width: "100%",
    left: "0px",
    paddingLeft: `${drawerWidth}px`,
    height: theme.custom.header.height,
    marginTop: "0px",
    top: "0px",
    alignItems: "stretch",
    boxShadow: "none",
    transition: theme["transitions"].create(["width", "margin"], {
      easing: theme["transitions"].easing.sharp,
      duration: theme["transitions"].duration.leavingScreen,
    }),
    [theme.breakpoints.down("md")]: {
      width: "100%",
      left: "0px",
      paddingLeft: "0px",
      transition: theme["transitions"].create(["width", "margin"], {
        easing: theme["transitions"].easing.sharp,
        duration: theme["transitions"].duration.enteringScreen,
      }),
    },
  }));

  return (
    <>
      <AppBarStyled
        position="fixed"
        theme={theme}
        sx={{
          displayPrint: "none",
          backgroundColor: (theme) => theme.custom.header.backgroundColor,
          color: (theme) => theme.custom.header.color,
        }}
      >
        <Toolbar sx={{ height: "100%" }}>
          <IconButton
            id={"drawerToggleBtn"}
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => {
              handleDrawerToggle();
            }}
            sx={{ mr: 2, display: sm ? "default" : "none" }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}></Box>
          <AppHeaderUser />
        </Toolbar>
      </AppBarStyled>
    </>
  );
}
