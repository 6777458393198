import { Box, Typography } from "@mui/material";
import React from "react";
import { Close } from "@mui/icons-material";
import ClickableIcon from "../../common/buttons/icons/ClickableIcon";

type Props = {
    title: string;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomDialogHeader = ({title, setOpen} : Props) => {
  return (
    <Box
      padding="16px 16px 8px 16px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="h4" color="primary" fontSize="1.9rem">
       {title}
      </Typography>
      <ClickableIcon
        icon={<Close />}
        handleClick={() => {
          setOpen(false);
        }}
        size={30}
      />
    </Box>
  );
};

export default CustomDialogHeader;
