import { Box, styled } from "@mui/material";
import { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router";

import { useMsal } from "@azure/msal-react";
import handleSimpleFormSubmit from "../form/SimpleForm/handleSimpleFormSubmit";
import StepIndicator from "../multiStepForm/StepIndicator";
import CustomButton from "../common/buttons/CustomButton";
import Step3Component from "./Step3Component";
import { AccountPost } from "../../data/Account/models";
import { createAccount } from "../../data/Account/requests";
import { getAccounTypes } from "../../data/AccountType/requests";
import {
  NewContactFields,
  newContactFields,
  sectionSignUp,
} from "../../data/Contact/forms/new";
import { ContactPost } from "../../data/Contact/models";
import { createContact } from "../../data/Contact/requests";
import { RoleRow } from "../../data/Role/models";
import { UpdateUserContext } from "../../providers/UserProvider";
import { RouterRoutes } from "../../routes/routes";
import SimpleFields from "../form/SimpleForm/SimpleFields";
import {
  NewAccountDisplayFields,
  newAccountFields,
  newAccountSections,
} from "./accountDisplay";
import { getRoles } from "../../data/Role/requests";
import AppLoading from "../common/loading/AppLoading";
import ErrorMessage from "../common/error/ErrorMessage";
import { errorHandler } from "../common/error/errorHandler";

const buttonLabels = {
  previous: "Précédent",
  next: "Continuer",
  finish: "Terminer",
  cancel: "Annuler",
};

const Section = styled("div")(() => ({
  padding: "0px 34px",

  ["@media (max-width:1200px)"]: {
    padding: "0px 4px",
  },
}));

const Section3 = styled("div")(() => ({
  padding: "0px 50px",

  ["@media (max-width:1200px)"]: {
    padding: "0 20px",
  },
}));

interface SignUp {
  onStepChange: () => void;
}

const SignUp: React.FC<SignUp> = ({ onStepChange }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [error, setError] = useState<string>();
  const [accountFields, setAccountFields] =
    useState<NewAccountDisplayFields>(newAccountFields);
  const [contactFields, setContactFields] =
    useState<NewContactFields>(newContactFields);
  const [roles, setRoles] = useState<RoleRow[]>([]);
  const userContext = useContext(UpdateUserContext);
  const navigate = useNavigate();
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  useEffect(() => {
    const fetchTypesAndRoles = async () => {
      try {
        const [accountTypesResponse, rolesResponse] = await Promise.all([
          getAccounTypes(),
          getRoles(),
        ]);

        if (
          accountTypesResponse.status === "success" &&
          accountTypesResponse.data
        ) {
          console.log(accountTypesResponse.data);
          setAccountFields({
            ...accountFields,
            vdl_typedorganisation: {
              ...accountFields.vdl_typedorganisation,
              choices: accountTypesResponse.data,
            },
          });
        } else {
          setError(accountTypesResponse.error);
        }

        if (rolesResponse.status === "success" && rolesResponse.data) {
          setRoles(rolesResponse.data);
        } else {
          setError(rolesResponse.error);
        }
      } catch (error) {
        setError(
          errorHandler(
            error,
            "Erreur dans la récupération du type d'organisation ou des rôles"
          ).error
        );
      } finally {
        setIsLoading(false);
      }
    };

    setContactFields((prevContactFields) => ({
      ...prevContactFields,
      emailaddress1: {
        ...prevContactFields.emailaddress1,
        value:
          !prevContactFields.emailaddress1.value && activeAccount?.username
            ? activeAccount.username
            : prevContactFields.emailaddress1.value,
        disabledCondition() {
          return true;
        },
      },
      vdl_accesauportail: {
        ...prevContactFields.vdl_accesauportail,
        required: false,
      },
    }));
    fetchTypesAndRoles();
  }, []);

  const goToNextStep = () => {
    let errors = [];
    if (currentStep === 1) {
      errors = handleSimpleFormSubmit(contactFields, setContactFields);
    } else if (currentStep === 2) {
      errors = handleSimpleFormSubmit(accountFields, setAccountFields);
    }
    if (errors.length > 0) {
      return;
    }
    if (currentStep < 3) {
      onStepChange();
      setCurrentStep(currentStep + 1);
    }
  };

  const goToPreviousStep = () => {
    if (currentStep > 1) {
      onStepChange();
      setCurrentStep(currentStep - 1);
    }
  };

  const handleLogout = () => {
    instance.logout();
  };

  const handleSuccess = async (
    accountFields: NewAccountDisplayFields,
    contactFields: NewContactFields
  ) => {
    setIsLoading(true);
    let _account: AccountPost = {
      name: accountFields.name.value,
      otherNames: accountFields.vdl_autresnomorganisme.value,
      vdl_typedorganisation: accountFields.vdl_typedorganisation.value!,
      vdl_autresnomorganisme: accountFields.vdl_autresnomorganisme.value,
      telephone1: accountFields.telephone1.value,
      emailaddress1: accountFields.emailaddress1.value,
      websiteurl: accountFields.websiteurl.value,
      vdl_pagefacebook: accountFields.vdl_pagefacebook.value,
      vdl_neq: accountFields.vdl_neq.value,
      vdl_secteursiegesocial: accountFields.vdl_secteursiegesocial.value!,
      address1_city:
        accountFields.registeredAddressSearch.value?.attributes?.City!, //"",
      address1_country:
        accountFields.registeredAddressSearch.value?.attributes?.CntryName, //"",
      address1_line1: accountFields.registeredAddressSearch.value?.address!, //"",
      address1_postalcode:
        accountFields.registeredAddressSearch.value?.attributes?.Postal +
        " " +
        accountFields.registeredAddressSearch.value?.attributes?.PostalExt, //"",
      address1_stateorprovince: "",
      vdl_adresseidentique: accountFields.vdl_adresseidentique.value,
      address2_city:
        accountFields.mailingAddressSearch.value?.attributes?.City!, //"",
      address2_country:
        accountFields.mailingAddressSearch.value?.attributes?.CntryName!, //"",
      address2_line1: accountFields.mailingAddressSearch.value?.address!, //"",
      address2_postalcode:
        accountFields.mailingAddressSearch.value?.attributes?.Postal +
        " " +
        accountFields.mailingAddressSearch.value?.attributes?.PostalExt, //"",
      address2_stateorprovince: "",
      communicationConsent: true,
    };
    const { data, error } = await createAccount(_account);

    if (!data) throw new Error("Impossible de céer le compte : " + error);

    const roleAdminUID = roles.find(
      (role) => role.label === "Administrateur"
    )?.value;
    let _contact: ContactPost = {
      vdl_salutation: contactFields.vdl_salutation.value,
      parentcustomerid: data,
      lastname: contactFields.lastname.value,
      firstname: contactFields.firstname.value,
      vdl_fonction: contactFields.vdl_fonction.value!,
      vdl_roledesecurite: roleAdminUID ? roleAdminUID : "",
      emailaddress1: contactFields.emailaddress1.value,
      telephone1: contactFields.telephone1.value,
      vdl_accesauportail: true,
    };
    const contactResponse = await createContact(_contact);
    if (contactResponse.status === "error" && !contactResponse.data) {
      return;
    }
    await userContext?.updateUser();
    setIsLoading(false);
    navigate(`${RouterRoutes.Demands}`);
  };

  if (error) return <ErrorMessage message={error} />;

  if (isLoading) return <AppLoading />;

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" margin="auto" marginTop={3} marginBottom={1}>
        <StepIndicator currentStep={currentStep} />
      </Box>

      {currentStep === 1 && (
        <Section>
          <SimpleFields
            fields={contactFields}
            setFields={setContactFields}
            sections={[sectionSignUp]}
            columns={{ minWidth: 260, count: 2 }}
          />
          <Box display="flex" justifyContent="center" mt={3} gap={1}>
            <CustomButton secondary onClick={handleLogout}>
              {buttonLabels.cancel}
            </CustomButton>
            <CustomButton primary onClick={goToNextStep}>
              {buttonLabels.next}
            </CustomButton>
          </Box>
        </Section>
      )}

      {currentStep === 2 && (
        <Section>
          <SimpleFields
            fields={accountFields}
            setFields={setAccountFields}
            sections={newAccountSections}
            columns={{ minWidth: 260, count: 2 }}
          />
          <Box display="flex" justifyContent="center" mt={3} gap={1}>
            <CustomButton secondary onClick={goToPreviousStep}>
              {buttonLabels.previous}
            </CustomButton>
            <CustomButton primary onClick={goToNextStep}>
              {buttonLabels.next}
            </CustomButton>
          </Box>
        </Section>
      )}

      {currentStep === 3 && (
        <Section3>
          <Step3Component
            contactFields={contactFields}
            accountFields={accountFields}
          />
          <Box display="flex" justifyContent="center" mt={5} gap={1}>
            <CustomButton secondary onClick={goToPreviousStep}>
              {buttonLabels.previous}
            </CustomButton>
            <CustomButton
              primary
              onClick={(event) => handleSuccess(accountFields, contactFields)}
            >
              {buttonLabels.finish}
            </CustomButton>
          </Box>
        </Section3>
      )}
    </Box>
  );
};

export default SignUp;
