import React, { useEffect } from 'react'
import AppLoading from '../components/common/loading/AppLoading'
import { useNavigate } from 'react-router-dom'

const AuthRedirect = () => {
    const navigate = useNavigate()

    useEffect(() => {
        navigate("/", {replace: true})
    }, [])
    
  return (
    <AppLoading/>
  )
}

export default AuthRedirect