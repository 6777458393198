import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";

export default function useUser() {
  const userData = useContext(UserContext);
  if (!userData) {
    throw new Error("User data is not available.");
  }

  return userData;
}
