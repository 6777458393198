import { Box, Skeleton } from "@mui/material";
import React from "react";

interface AppTabsSkeletonProps {
  tabsNumber: number;
  activeTabIndex: number;
}

const AppTabsSkeleton = ({
  tabsNumber,
  activeTabIndex,
}: AppTabsSkeletonProps) => {
  const tabIndices = Array.from({ length: tabsNumber }, (_, index) => index);
  return (
    <Box bgcolor="common.white" display="flex" flexDirection="row">
      {tabIndices.map((index) => (
        <Box
          key={index}
          sx={{ margin: "0 10px", display: "flex", flexDirection: "column" }}
        >
          <Box sx={{ padding: "0 5px", margin: "10px 0" }}>
            <Skeleton width={149} height={25} />
          </Box>
          {index === activeTabIndex && (
            <Skeleton variant="rectangular" height={3} />
          )}
        </Box>
      ))}
    </Box>
  );
};

export default AppTabsSkeleton;
