import React, { useEffect, useState } from "react";
import {
  getContact,
  updateContact,
  sendInvite,
} from "../data/Contact/requests";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import { Box, Typography, useTheme } from "@mui/material";
import DataCard from "../components/common/cards/DataCard/DataCard";
import Edit from "@mui/icons-material/Edit";
import DataCardSkeleton from "../components/common/cards/DataCard/DataCardSkeleton";
import contactDisplayNames from "../data/Contact/displayNames";
import LinkIcon from "../components/common/buttons/icons/LinkIcon";
import SendIcon from "@mui/icons-material/Send";
import BlockIcon from "@mui/icons-material/Block";
import useUser from "../hooks/useUserData";
import { useParams } from "react-router-dom";
import { getDisplayFieldsFromRow } from "../data/common/fieldToString";
import { ContactRow } from "../data/Contact/models";
import PrivateComponent from "../components/common/auth/PrivateComponent";
import ClickableIcon from "../components/common/buttons/icons/ClickableIcon";
import { useSnackbar } from "../hooks/useSnackbar";
import CustomChip from "../components/common/misc/CustomChip";
import ErrorMessage from "../components/common/error/ErrorMessage";
import BoxResponsivePadding from "../components/common/boxes/BoxResponsivePadding";
import { RouterRoutes, basePaths } from "../routes/routes";

const fieldsToDisplay: (keyof ContactRow)[] = [
  "vdl_salutation",
  "emailaddress1",
  "firstname",
  "lastname",
  "telephone1",
  "telephone2",
  "vdl_fonction",
  "statecode",
];

const ContactPage = () => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [contactData, setContactData] = useState<ContactRow>();
  const [error, setError] = useState<string>();
  const { email } = useParams();

  const querry = async () => {
    try {
      if (!email) throw Error();

      const { data, error } = await getContact(email);
      if (!data || error) {
        throw Error();
      }
      setContactData(data);
    } catch (e) {
      setError(
        email ? "Erreur dans la récupération de données" : "URL invalide"
      );
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    querry();
  }, []);

  if (isLoading) return <ContactPageLoading />;

  if (error || !contactData) return <ErrorMessage message={error} />;

  return (
    <PageTitleLayout
      title={`${contactData.firstname} ${contactData.lastname}`}
      sideTitleComponent={
        <TitleIcons
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          contactData={contactData}
          updateOpenedContact={querry}
        />
      }
      underTitleComponent={
        <Typography fontWeight="bold">
          {contactData.vdl_roledesecurite?.label}
        </Typography>
      }
    >
      <Box
        sx={{
          ...theme.custom.pageCardContainer,
        }}
      >
        <DataCard
          emptyTextPlaceHolder="---"
          title="Informations personnelles"
          displayFields={getDisplayFieldsFromRow(
            contactData,
            fieldsToDisplay,
            contactDisplayNames
          )}
        />
      </Box>
    </PageTitleLayout>
  );
};

export default ContactPage;

const ContactPageLoading = () => {
  return (
    <PageTitleLayout isLoading title="Profil">
      <BoxResponsivePadding>
        <DataCardSkeleton icon displayFieldsCount={fieldsToDisplay.length} />
      </BoxResponsivePadding>
    </PageTitleLayout>
  );
};

type TitleIconsProps = {
  contactData: ContactRow;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  updateOpenedContact: () => Promise<void>;
};

const TitleIcons = ({
  contactData,
  isLoading,
  setIsLoading,
  updateOpenedContact,
}: TitleIconsProps) => {
  const theme = useTheme();
  const { showSnackbar } = useSnackbar();
  const { userData } = useUser();

  const handleSendInvite = async () => {
    setIsLoading(true);
    const result = await sendInvite(contactData.contactid);
    await updateOpenedContact();
    setIsLoading(false);
    if (result.status === "success") {
      showSnackbar("Invitation envoyée", "success");
    } else {
      showSnackbar("Erreur dans l'invitation", "error");
    }
  };

  const handleDeactivate = async () => {
    setIsLoading(true);
    const result = await updateContact(contactData.contactid, {
      vdl_accesauportail: false,
      statecode: "Inactif",
    });
    await updateOpenedContact();
    setIsLoading(false);
    if (result.status === "success") {
      showSnackbar("Contact désactivé", "success");
    } else {
      showSnackbar("Erreur dans la désactivation", "error");
    }
  };

  return (
    <Box
      display="flex"
      rowGap="1rem"
      columnGap="1.5rem"
      alignItems="center"
      flexWrap="wrap"
    >
      <CustomChip
        color={contactData.vdl_accesauportail ? "green" : "grey"}
        text={
          contactData.vdl_accesauportail
            ? "Accès au portail"
            : "Pas accès au portail"
        }
      />
      <PrivateComponent roleMinimum="Administrateur">
        <Box display="flex" gap="1rem">
          <LinkIcon
            size="1.3rem"
            tooltip="Modifier ce contact"
            to={basePaths.edit}
            icon={<Edit />}
            backgroundColor="background.default"
          />
          {contactData.vdl_accesauportail ? null : (
            <ClickableIcon
              size="1.3rem"
              tooltip="Envoyer une invitation"
              icon={<SendIcon />}
              handleClick={handleSendInvite}
              backgroundColor="background.default"
            />
          )}
          {contactData.vdl_accesauportail ? (
            <ClickableIcon
              size="1.3rem"
              tooltip="Désactiver l'accès portail"
              handleClick={handleDeactivate}
              icon={<BlockIcon />}
              backgroundColor="background.default"
            />
          ) : null}
        </Box>
      </PrivateComponent>
    </Box>
  );
};
