import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DisplayField from "../../../../common/cards/DisplayField";
import {
  AccountRow,
  AccountRowNoContacts,
} from "../../../../../data/Account/models";
import { ContactRow } from "../../../../../data/Contact/models";
import { getFullAddress } from "../../../../../data/Account/helpers";
import accountDisplayNames from "../../../../../data/Account/displayNames";
import contactDisplayNames from "../../../../../data/Contact/displayNames";
import { ContactDemandRow } from "../../../../../data/Demand/models";

type Props = {
  account: AccountRowNoContacts;
  primarycontact?: ContactDemandRow;
};

const AccountCard = ({ account, primarycontact }: Props) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card elevation={0} sx={{ bgcolor: "white" }}>
      <CardContent>
        <Box display="flex" flexDirection="column" gap="10px">
          <Typography variant="h6" fontWeight="bold" color="primary">
            Identification du requérant
          </Typography>
          <Box display="grid" rowGap="6px">
            <DisplayField
              displayName="Nom organisme"
              value={account.name}
              horizontal={!sm}
              horizontalLabelWidthPercentage={30}
            />
            <DisplayField
              displayName={accountDisplayNames.vdl_neq}
              value={account.vdl_neq}
              horizontal={!sm}
              horizontalLabelWidthPercentage={30}
            />
            <DisplayField
              displayName="Adresse"
              value={getFullAddress(account)}
              horizontal={!sm}
              horizontalLabelWidthPercentage={30}
            />
            <DisplayField
              displayName="Téléphone"
              value={account.telephone1}
              horizontal={!sm}
              horizontalLabelWidthPercentage={30}
            />
          </Box>

          <Typography
            variant="h6"
            fontWeight="bold"
            color="primary"
            paddingTop="8px"
          >
            Identification du responsable désigné
          </Typography>

          {primarycontact ? (
            <Box display="grid" rowGap="6px">
              <DisplayField
                displayName={contactDisplayNames.fullname}
                value={`${primarycontact.firstname} ${primarycontact.lastname}`}
                horizontal={!sm}
                horizontalLabelWidthPercentage={30}
              />
              <DisplayField
                displayName="Fonction"
                value={primarycontact.vdl_fonction}
                horizontal={!sm}
                horizontalLabelWidthPercentage={30}
              />
              <DisplayField
                displayName="Téléphone"
                value={primarycontact.telephone1}
                horizontal={!sm}
                horizontalLabelWidthPercentage={30}
              />
            </Box>
          ) : null}
        </Box>
      </CardContent>
    </Card>
  );
};

export default AccountCard;
