import React from "react";
import {
  Box,
  Divider,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";

type Props = {
    path: string;
    displayName: string;
    icon: JSX.Element;
};

const AppDrawerLink = ({path, displayName, icon} : Props) => {
    const location = useLocation();
    const theme = useTheme();
  return (
    <ListItem disablePadding>
      <Link
        component={RouterLink}
        to={path}
        width={"100%"}
        bgcolor={path === location.pathname ? theme.palette.action.hover : undefined}
        sx={{
          textDecoration: "none",
          color: (theme) => theme.custom.drawer.color,
        }}
      >
        <ListItemButton>
          <ListItemIcon
            sx={{
              width: "32px",
              minWidth: "32px",
              color: (theme) => theme.custom.drawer.iconColor,
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{fontSize: "0.9rem"}} primary={displayName} />
        </ListItemButton>
      </Link>
    </ListItem>
  );
};

export default AppDrawerLink;
