import { Box, Card, CardContent, Skeleton } from "@mui/material";
import React from "react";

const AccountCardSkeleton = () => {
  return (
    <Card elevation={0} sx={{ bgcolor: "white" }}>
      <CardContent>
        <Box display="flex" flexDirection="column" gap="10px">
          <Skeleton
            variant="rectangular"
            style={{
              borderRadius: "4px / 6.7px ",
              fontSize: "1.69rem",
            }}
          />
          <Box display="grid" rowGap="6px">
            {Array(4)
              .fill(0)
              .map((i, idx) => (
                <Box display="flex" gap={"8px"} key={idx}>
                  <Skeleton
                    variant="rectangular"
                    style={{
                      borderRadius: "4px / 6.7px",
                      width: "30%",
                      fontSize: "1.1rem",
                      paddingBottom:
                        idx === 1 || idx === 2 || idx === 3 ? "1.4rem" : "0",
                    }}
                  />
                  <Skeleton
                    variant="rectangular"
                    style={{
                      borderRadius: "4px / 6.7px",
                      width: "70%",
                      fontSize: "1.1rem",
                      paddingBottom:
                        idx === 1 || idx === 2 || idx === 3 ? "1.4rem" : "0",
                    }}
                  />
                </Box>
              ))}
          </Box>
          <Box paddingTop={"8px"}>
            <Skeleton
              variant="rectangular"
              style={{
                borderRadius: "4px / 6.7px ",
                fontSize: "1.73rem",
              }}
            />
          </Box>
          <Box display="grid" rowGap="6px">
            {Array(3)
              .fill(0)
              .map((i, idx) => (
                <Box display="flex" gap={"8px"} key={idx}>
                  <Skeleton
                    variant="rectangular"
                    style={{
                      borderRadius: "4px / 6.7px",
                      width: "30%",
                      fontSize: "1.16rem",
                    }}
                  />
                  <Skeleton
                    variant="rectangular"
                    style={{
                      borderRadius: "4px / 6.7px",
                      width: "70%",
                      fontSize: "1.16rem",
                    }}
                  />
                </Box>
              ))}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AccountCardSkeleton;
