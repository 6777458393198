import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import { Box} from "@mui/material";
import DataCard from "../components/common/cards/DataCard/DataCard";
import Edit from "@mui/icons-material/Edit";
import DataCardSkeleton from "../components/common/cards/DataCard/DataCardSkeleton";
import { TableFields } from "../types";
import contactDisplayNames from "../data/Contact/displayNames";
import LinkIcon from "../components/common/buttons/icons/LinkIcon";
import useUser from "../hooks/useUserData";
import { RouterRoutes } from "../routes/routes";
import { ContactRow } from "../data/Contact/models";
import { getStringFromField } from "../data/common/fieldToString";
import BoxResponsivePadding from "../components/common/boxes/BoxResponsivePadding";

const fieldsToDisplay: (keyof ContactRow)[] = [
  "vdl_salutation",
  "firstname",
  "lastname",
  "vdl_fonction",
  "emailaddress1",
  "telephone1",
  "telephone2"
];

const ProfilePage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [profileData, setProfileData] = useState<TableFields>();
  const [error, setError] = useState<string>();

  const { instance } = useMsal();
  const {userData} = useUser();

  useEffect(() => {
    
    if (userData) {
      let tempData: TableFields = {};
      fieldsToDisplay.forEach((fieldName) => {
        tempData[fieldName] = {
          displayName: contactDisplayNames[fieldName]
            ? contactDisplayNames[fieldName]
            : "",
          value: getStringFromField(userData[fieldName]),
        };
      });
      setProfileData(tempData);
      setIsLoading(false);
    }
  }, [userData]);

  if (isLoading) return <ProfilePageLoading />;

  if (error || !profileData) return null;

  return (
    <PageTitleLayout title="Mon profil">
      <BoxResponsivePadding>
        <DataCard
          title="Informations personnelles"
          displayFields={profileData}
          icon={
            <LinkIcon
              icon={<Edit />}
              to={RouterRoutes.EditProfile}
              backgroundColor="background.default"
            />
          }
        />
      </BoxResponsivePadding>
    </PageTitleLayout>
  );
};

export default ProfilePage;

const ProfilePageLoading = () => {
  return (
    <PageTitleLayout isLoading title="Mon profil">
      <Box padding="20px">
        <DataCardSkeleton
          icon
          displayFieldsCount={fieldsToDisplay.length}
        />
      </Box>
    </PageTitleLayout>
  );
};
