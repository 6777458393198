import { useState, useCallback } from "react";
import { useMsal } from "@azure/msal-react";
import { msalInstance } from "../providers/MsalProvider";
import { loginRequest } from "../auth/authConfig";

type FetchProps = {
  method: string;
  endpoint: string;
  data?: any;
};

const fetchWithMsal = async (method: string, endpoint: string, data?: any) => {
  try {
    const activeAccount = msalInstance.getActiveAccount();
    let token;
    try {
      token = (await msalInstance.acquireTokenSilent(loginRequest)).idToken;
    } catch (error) {
      await msalInstance.acquireTokenRedirect(loginRequest);
      return;
    }
    if (!activeAccount || !token) throw Error("Utilisateur non-authentifié");
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    const subscriptionKey = `${
      process.env.REACT_APP_APIM_SUBSCRIPTION_KEY ?? ""
    }`;
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", bearer);
    headers.append("Ocp-Apim-Subscription-Key", subscriptionKey);
    
    //const fullEndpoint = `${endpoint}?subscription-key=${subscriptionKey}`;

    if (data) headers.append("Content-Type", "application/json");

    let options = {
      method: method,
      headers: headers,
      body: data ? JSON.stringify(data) : null,
    };

    const response = await fetch(endpoint, options);

    if (!response.ok) {
      throw new Error("Réponse du serveur: Erreur");
    }
    const jsonData = await response.json();
    //console.log("endpoint:" + endpoint)
    //console.log(jsonData)
    return jsonData;
  } catch (e) {
    throw e;
  }
};

export default fetchWithMsal;
