import React, { useEffect, useState } from "react";
import { MsalAuthenticationResult, useMsal } from "@azure/msal-react";

const AuthenticationError = (result: MsalAuthenticationResult) => {
  const { instance } = useMsal();
  //Looks like there is no better way to handle user Cancel for now
  //https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/5800
  useEffect(() => {
    const handle = async () => {
      if (
        result.error &&
        result.error.errorMessage.indexOf("AADB2C90091") > -1
      ) {
       
        window.location.reload();
        return;
      }
      try {
        const promiseResult = await instance.handleRedirectPromise();
        if (promiseResult?.account === undefined) {
          instance.loginRedirect();
        }
      } catch (e) {}
    };
    handle();
  }, []);
  return (
    <div>
      Connexion annulée par l'utilisateur rafraichir la page pour reccommencer
    </div>
  );
};

export default AuthenticationError;
