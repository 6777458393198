import {
  CheckBoxInput,
  CommentInput,
  DateInput,
  DropDownChoice,
  DropDownMultipleOptionSetInput,
  DropDownOptionSetInput,
  FileInput,
  FormFields,
  FormSection,
  MultilineInput,
  TextInput,
} from "../../../types";
import { vdl_secteurChoices } from "../../Account/forms/optionSetChoices";
import { admissibilityDisplayNames } from "../displayNames";

export type EditDemandAdmissibilityFields = {
  comment_6: CommentInput;
  vdl_dorganismeabutnonlucratif: CheckBoxInput;
  vdl_dorganismedebienfaisanceenregistre: CheckBoxInput;
  vdl_etesvousunchapitrelavalloisduneinstancepro: CheckBoxInput;
  vdl_siouiquelestlenometleneqdelorg: TextInput;
  vdl_datedeladeclarationaureq: DateInput;
  comment_5: CommentInput;
  vdl_aucuneassurance: CheckBoxInput;
  vdl_assurancedesadministrateurs: CheckBoxInput;
  vdl_assurancedesbiens: CheckBoxInput;
  vdl_assuranceresponsabilitecivile: CheckBoxInput;
  vdl_assuranceadministrateursetdirigeants: CheckBoxInput;
  vdl_nomdelacompagniedassurance: TextInput;
  vdl_numerodelapolicedassurance: TextInput;
  vdl_datedecheancedelapolicedassurance: DateInput;

  vdl_missionetobjectif: MultilineInput;

  vdl_realitebesoinsoutien: MultilineInput;

  vdl_pointsdeservicealaval: CheckBoxInput;
  vdl_siouiaquelendroit: TextInput;
  vdl_dansqueldomainedactivitevotreorganismeinter: DropDownOptionSetInput;
  vdl_siautresprecisez: TextInput;
  comment_4: CommentInput;
  vdl_principauxserviceouactivites: MultilineInput;
  vdl_desregroupementsetoudesorganismes: MultilineInput;
  vdl_territoiresdesservisparlorganisme2: DropDownMultipleOptionSetInput;
  vdl_siailleursprecisez: TextInput;

  vdl_nombredeparticipantsauxactivites: TextInput;
  vdl_typedeclientele: DropDownOptionSetInput;

  comment_individus: CommentInput;
  vdl_prescolaire0a4ans: TextInput;
  vdl_enfants5a12ans: TextInput;
  vdl_adolescents13a17ans: TextInput;
  vdl_adultes18a64ans: TextInput;
  vdl_aines65ansetplus65: TextInput;

  comment_cliente: CommentInput;
  vdl_politiquedeverification: CheckBoxInput;
  vdl_entente: CheckBoxInput;
  vdl_codedeconduite: CheckBoxInput;
  vdl_formationsurladoptiondecomportementsadequats: CheckBoxInput;
  vdl_codedeconduitedelorganisation: CheckBoxInput;
  vdl_codeetsignatureduncontratethique: CheckBoxInput;
  vdl_verificationantecedentjudiciaire: CheckBoxInput;
  vdl_mecanismedegestionderisqueencasdedoute: CheckBoxInput;
  vdl_utilisationdelaplateformejebenevoleca: CheckBoxInput;
  vdl_jeveuxplusdinformationacesujet: CheckBoxInput;

  vdl_nombrederencontresduca: TextInput;
  vdl_nombredepresencesalaga: TextInput;

  vdl_datedeladerniereassembleegeneraleannuelle: DateInput;
  vdl_nombredemembresactifsauseindelorganisme: TextInput;
  comment_2: CommentInput;
  vdl_duneconcertation: TextInput;
  vdl_dunregroupement: TextInput;
  vdl_dunefederation: TextInput;

  vdl_produisezvousannuellementdesprevisionsbudgeta: CheckBoxInput;
  vdl_vosderniersetatsfinanciersontilsfaitlobjet: CheckBoxInput;
  vdl_datedefindevotreexercicefinancierannuel: DateInput;

  comment_3: CommentInput;
  vdl_audit: CheckBoxInput;
  vdl_missiondexamen: CheckBoxInput;
  vdl_verificationcomptable: CheckBoxInput;

  file1: FileInput;
  file2: FileInput;
  comment7: CommentInput;
  file3: FileInput;
  file4: FileInput;
  file5: FileInput;
  file6: FileInput;
  comment8: CommentInput;
  file7: FileInput;
  file8: FileInput;
};

const vdl_dansqueldomainedactivitevotreorganismeinterChoices: DropDownChoice[] =
  [
    {
      value: "Sport",
    },
    {
      value: "Loisirs",
    },
    {
      value: "Développement social",
    },
    {
      value: "Culture",
    },
    {
      value: "Plein-air et environnement",
    },
    {
      value: "Autre (précisez)",
    },
  ];
const vdl_territoiresdesservisparlorganismeChoices: DropDownChoice[] = [
  ...vdl_secteurChoices,
  { value: "Régional (Partout à Laval)" },
  { value: "Ailleurs qu'à Laval (spécifiez)" },
];

const vdl_typedeclienteleChoices: DropDownChoice[] = [
  { value: "Individus seulement" },
  { value: "Organismes seulement" },
  { value: "Individus et organismes" },
];

const disabledAssuranceFields = (fields: FormFields) =>
  fields.vdl_aucuneassurance.value;

const lastYear = (new Date().getFullYear() - 1).toString();

const previousLastYear = (new Date().getFullYear() - 2).toString();

const editDemandAdmissibilityFields: EditDemandAdmissibilityFields = {
  comment_6: {
    type: "comment",
    fillLine: true,
    value: "Avez-vous un statut juridique :",
  },
  vdl_dorganismeabutnonlucratif: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: admissibilityDisplayNames.vdl_dorganismeabutnonlucratif,
  },
  vdl_dorganismedebienfaisanceenregistre: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: admissibilityDisplayNames.vdl_dorganismedebienfaisanceenregistre,
  },
  vdl_etesvousunchapitrelavalloisduneinstancepro: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label:
      admissibilityDisplayNames.vdl_etesvousunchapitrelavalloisduneinstancepro,
  },
  vdl_siouiquelestlenometleneqdelorg: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    disabledCondition: (fields: FormFields) =>
      !fields.vdl_etesvousunchapitrelavalloisduneinstancepro.value,
    requiredCondition: {
      validator: (fields: FormFields) =>
        fields.vdl_etesvousunchapitrelavalloisduneinstancepro.value,
    },
    label: admissibilityDisplayNames.vdl_siouiquelestlenometleneqdelorg,
  },
  vdl_datedeladeclarationaureq: {
    type: "date",
    error: "",
    value: undefined,
    required: false,
    disableFuture: true,
    fillLine: true,
    label: admissibilityDisplayNames.vdl_datedeladeclarationaureq,
  },
  comment_5: {
    type: "comment",
    fillLine: true,
    value:
      "Possédez-vous une police d'assurance couvrant les aspects suivants :",
  },
  vdl_aucuneassurance: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: admissibilityDisplayNames.vdl_aucuneassurance,
  },
  vdl_assurancedesadministrateurs: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    disabledCondition: disabledAssuranceFields,
    label: admissibilityDisplayNames.vdl_assurancedesadministrateurs,
  },
  vdl_assurancedesbiens: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    disabledCondition: disabledAssuranceFields,
    label: admissibilityDisplayNames.vdl_assurancedesbiens,
  },
  vdl_assuranceresponsabilitecivile: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    disabledCondition: disabledAssuranceFields,
    label: admissibilityDisplayNames.vdl_assuranceresponsabilitecivile,
  },
  vdl_assuranceadministrateursetdirigeants: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    disabledCondition: disabledAssuranceFields,
    label: admissibilityDisplayNames.vdl_assuranceadministrateursetdirigeants,
  },
  vdl_nomdelacompagniedassurance: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    disabledCondition: disabledAssuranceFields,
    label: admissibilityDisplayNames.vdl_nomdelacompagniedassurance,
  },
  vdl_numerodelapolicedassurance: {
    type: "text",
    error: "",
    value: "",
    required: false,
    maxLength: 50,
    fillLine: true,
    disabledCondition: disabledAssuranceFields,
    label: admissibilityDisplayNames.vdl_numerodelapolicedassurance,
  },
  vdl_datedecheancedelapolicedassurance: {
    type: "date",
    error: "",
    value: undefined,
    required: false,
    fillLine: true,
    disablePast: true,
    disabledCondition: disabledAssuranceFields,
    label: admissibilityDisplayNames.vdl_datedecheancedelapolicedassurance,
  },

  vdl_missionetobjectif: {
    type: "multiline",
    error: "",
    value: "",
    required: true,
    maxLength: 4000,
    rows: {
      min: 4,
      max: 4,
    },
    label: admissibilityDisplayNames.vdl_missionetobjectif,
  },

  vdl_realitebesoinsoutien: {
    type: "multiline",
    error: "",
    value: "",
    required: true,
    maxLength: 2000,
    rows: {
      min: 4,
      max: 4,
    },
    label: admissibilityDisplayNames.vdl_realitebesoinsoutien,
  },
  vdl_pointsdeservicealaval: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: admissibilityDisplayNames.vdl_pointsdeservicealaval,
  },
  vdl_siouiaquelendroit: {
    type: "text",
    error: "",
    value: "",
    maxLength: 200,
    disabledCondition: (fields: FormFields) =>
      !fields.vdl_pointsdeservicealaval.value,
    label: admissibilityDisplayNames.vdl_siouiaquelendroit,
  },
  vdl_dansqueldomainedactivitevotreorganismeinter: {
    type: "optionset",
    error: "",
    value: undefined,
    required: false,
    choices: vdl_dansqueldomainedactivitevotreorganismeinterChoices,
    label:
      admissibilityDisplayNames.vdl_dansqueldomainedactivitevotreorganismeinter,
  },
  vdl_siautresprecisez: {
    type: "text",
    error: "",
    value: "",
    maxLength: 500,
    disabledCondition: (fields: FormFields) =>
      fields.vdl_dansqueldomainedactivitevotreorganismeinter.value !==
      "Autre (précisez)",
    label: admissibilityDisplayNames.vdl_siautresprecisez,
  },
  comment_4: {
    type: "comment",
    value: " ",
    fillLine: true,
  },
  vdl_principauxserviceouactivites: {
    type: "multiline",
    error: "",
    value: "",
    helperText:
      "Quels sont les principaux services ou activités offerts par votre organisme (Les classer par ordre d'importance.)",
    required: true,
    fillLine: true,
    maxLength: 4000,
    rows: {
      min: 4,
      max: 4,
    },
    label: admissibilityDisplayNames.vdl_principauxserviceouactivites,
  },
  vdl_desregroupementsetoudesorganismes: {
    type: "multiline",
    error: "",
    helperText:
      "Êtes-vous en lien avec des regroupements et/ou des organismes du même domaine, complémentaire ou par proximité de territoire (collaboration, échange de services ou de bonnes pratiques).",
    value: "",
    required: false,
    fillLine: true,
    maxLength: 4000,
    rows: {
      min: 4,
      max: 4,
    },
    label: admissibilityDisplayNames.vdl_desregroupementsetoudesorganismes,
  },
  vdl_territoiresdesservisparlorganisme2: {
    type: "multipleoptionset",
    error: "",
    value: [],
    required: false,
    choices: vdl_territoiresdesservisparlorganismeChoices,
    label: admissibilityDisplayNames.vdl_territoiresdesservisparlorganisme2,
  },
  vdl_siailleursprecisez: {
    type: "text",
    error: "",
    value: "",
    required: false,
    label: admissibilityDisplayNames.vdl_siailleursprecisez,
    disabledCondition: (fields: FormFields) =>
      !(
        fields.vdl_territoiresdesservisparlorganisme2.value as string[]
      ).includes("Ailleurs qu'à Laval (spécifiez)"),
    requiredCondition: {
      validator: (fields: FormFields) =>
        (
          fields.vdl_territoiresdesservisparlorganisme2.value as string[]
        ).includes("Ailleurs qu'à Laval (spécifiez)"),
    },
  },

  vdl_nombredeparticipantsauxactivites: {
    type: "text",
    error: "",
    value: "",
    required: true,
    maxLength: 250,
    label: admissibilityDisplayNames.vdl_nombredeparticipantsauxactivites,
    helperText:
      "Quel est le nombre de participants annuel aux acitivités de votre organisme?",
  },
  vdl_typedeclientele: {
    type: "optionset",
    error: "",
    value: "",
    required: true,
    choices: vdl_typedeclienteleChoices,
    label: admissibilityDisplayNames.vdl_typedeclientele,
  },

  comment_individus: {
    type: "comment",
    fillLine: true,
    value:
      "Dans le cas d'individus, quelle est la répartition en pourcentage de votre clientèle selon les groupes d'âge suivants ?",
  },
  vdl_prescolaire0a4ans: {
    type: "text",
    error: "",
    value: "",
    required: true,
    label: admissibilityDisplayNames.vdl_prescolaire0a4ans,
  },
  vdl_enfants5a12ans: {
    type: "text",
    error: "",
    value: "",
    required: true,
    label: admissibilityDisplayNames.vdl_enfants5a12ans,
  },
  vdl_adolescents13a17ans: {
    type: "text",
    error: "",
    value: "",
    required: true,
    label: admissibilityDisplayNames.vdl_adolescents13a17ans,
  },
  vdl_adultes18a64ans: {
    type: "text",
    error: "",
    value: "",
    required: true,
    label: admissibilityDisplayNames.vdl_adultes18a64ans,
  },
  vdl_aines65ansetplus65: {
    type: "text",
    error: "",
    value: "",
    required: true,
    label: admissibilityDisplayNames.vdl_aines65ansetplus65,
  },

  comment_cliente: {
    type: "comment",
    fillLine: true,
    value:
      "Dans la mesure où vous œuvrez avec une clientèle vulnérable, quelles sont les mesures préventives que votre organisation met en place pour protéger la clientèle?",
  },

  vdl_politiquedeverification: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: admissibilityDisplayNames.vdl_politiquedeverification,
  },
  vdl_entente: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: admissibilityDisplayNames.vdl_entente,
  },
  vdl_codedeconduite: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: admissibilityDisplayNames.vdl_codedeconduite,
  },
  vdl_formationsurladoptiondecomportementsadequats: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label:
      admissibilityDisplayNames.vdl_formationsurladoptiondecomportementsadequats,
  },
  vdl_codedeconduitedelorganisation: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: admissibilityDisplayNames.vdl_codedeconduitedelorganisation,
  },
  vdl_codeetsignatureduncontratethique: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: admissibilityDisplayNames.vdl_codeetsignatureduncontratethique,
  },
  vdl_verificationantecedentjudiciaire: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: admissibilityDisplayNames.vdl_verificationantecedentjudiciaire,
  },
  vdl_mecanismedegestionderisqueencasdedoute: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: admissibilityDisplayNames.vdl_mecanismedegestionderisqueencasdedoute,
  },
  vdl_utilisationdelaplateformejebenevoleca: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: admissibilityDisplayNames.vdl_utilisationdelaplateformejebenevoleca,
  },
  vdl_jeveuxplusdinformationacesujet: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: admissibilityDisplayNames.vdl_jeveuxplusdinformationacesujet,
  },
  vdl_nombrederencontresduca: {
    type: "text",
    error: "",
    value: "",
    required: true,
    label: admissibilityDisplayNames.vdl_nombrederencontresduca,
  },
  vdl_nombredepresencesalaga: {
    type: "text",
    error: "",
    value: "",
    required: true,
    label: admissibilityDisplayNames.vdl_nombredepresencesalaga,
  },
  vdl_datedeladerniereassembleegeneraleannuelle: {
    type: "date",
    error: "",
    value: undefined,
    required: true,
    fillLine: true,
    disableFuture: true,
    label:
      admissibilityDisplayNames.vdl_datedeladerniereassembleegeneraleannuelle,
  },
  vdl_nombredemembresactifsauseindelorganisme: {
    type: "text",
    error: "",
    value: "",
    fillLine: true,
    required: true,
    label:
      admissibilityDisplayNames.vdl_nombredemembresactifsauseindelorganisme,
  },
  comment_2: {
    type: "comment",
    value: "Votre organisme est-il membre?",
    fillLine: true,
  },
  vdl_duneconcertation: {
    type: "text",
    error: "",
    value: "",
    fillLine: true,
    required: false,
    maxLength: 150,
    label: admissibilityDisplayNames.vdl_duneconcertation,
  },
  vdl_dunregroupement: {
    type: "text",
    error: "",
    value: "",
    fillLine: true,
    required: false,
    maxLength: 150,
    label: admissibilityDisplayNames.vdl_dunregroupement,
  },
  vdl_dunefederation: {
    type: "text",
    error: "",
    value: "",
    fillLine: true,
    required: false,
    maxLength: 150,
    label: admissibilityDisplayNames.vdl_dunefederation,
  },
  vdl_produisezvousannuellementdesprevisionsbudgeta: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: true,
    required: false,
    label:
      admissibilityDisplayNames.vdl_produisezvousannuellementdesprevisionsbudgeta,
  },

  vdl_datedefindevotreexercicefinancierannuel: {
    type: "date",
    error: "",
    value: undefined,
    required: true,
    fillLine: true,
    disablePast: true,
    label:
      admissibilityDisplayNames.vdl_datedefindevotreexercicefinancierannuel,
  },
  vdl_vosderniersetatsfinanciersontilsfaitlobjet: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: true,
    required: false,
    label:
      admissibilityDisplayNames.vdl_vosderniersetatsfinanciersontilsfaitlobjet,
  },
  comment_3: {
    type: "comment",
    value: "Si oui, de quel type?",
    fillLine: true,
    removeMarginTop: true,
  },
  vdl_audit: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: true,
    required: false,
    label: admissibilityDisplayNames.vdl_audit,
  },
  vdl_missiondexamen: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: true,
    required: false,
    label: admissibilityDisplayNames.vdl_missiondexamen,
  },
  vdl_verificationcomptable: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: true,
    required: false,
    label: admissibilityDisplayNames.vdl_verificationcomptable,
  },

  file1: {
    type: "file",
    error: "",
    fillLine: true,
    required: false,
    label: "Résolution du conseil d'administration",
    helperText:
      "Résolution du conseil d'administration d'adhésion à la politique d'admissibilité au soutien municipal, incluant les éléments suivants : \n- Que l'organisme souhaite déposer une demande; \n- Qu'il s'engage à respecter les exigences et les obligations énumérées dans la politique",
  },

  file2: {
    type: "file",
    error: "",
    fillLine: true,
    required: false,
    label: "Copie des règlements généraux de l'organisme",
  },
  comment7: {
    type: "comment",
    fillLine: true,
    value:
      "Procès-verbaux des assemblées générales annuelles et des assemblées générales spéciales des deux dernières années s'il y a lieu, qui témoignent du fonctionnement démocratique de l'organisme et qui attestent la présentation et l'adoption du rapport annuel et d'états financiers de celui-ci",
  },
  file3: {
    type: "file",
    error: "",
    fillLine: true,
    required: false,
    label: "Procès-verbal de l'année " + lastYear,
  },
  file4: {
    type: "file",
    error: "",
    fillLine: true,
    required: false,
    label: "Procès-verbal de l'année " + previousLastYear,
  },
  file5: {
    type: "file",
    error: "",
    fillLine: true,
    required: false,
    label: "Lettres patentes ou charte de l'organisme",
  },
  file6: {
    type: "file",
    error: "",
    fillLine: true,
    required: false,
    label: "Rapport annuel d'activités de l'année écoulée",
  },
  comment8: {
    type: "comment",
    fillLine: true,
    value: "",
  },
  file7: {
    type: "file",
    error: "",
    fillLine: true,
    required: false,
    label: "Copie des états financiers de l'année " + lastYear,
  },
  file8: {
    type: "file",
    error: "",
    fillLine: true,
    required: false,
    label: "Copie des états financiers de l'année " + previousLastYear,
  },
};

const editDemandAdmissiblityAdmissibilitySection: FormSection<EditDemandAdmissibilityFields> =
  {
    fields: [
      "comment_6",
      "vdl_dorganismeabutnonlucratif",
      "vdl_dorganismedebienfaisanceenregistre",
      "vdl_etesvousunchapitrelavalloisduneinstancepro",
      "vdl_siouiquelestlenometleneqdelorg",

      "vdl_datedeladeclarationaureq",
      "comment_5",
      "vdl_aucuneassurance",
      "vdl_assurancedesadministrateurs",
      "vdl_assurancedesbiens",
      "vdl_assuranceresponsabilitecivile",
      "vdl_assuranceadministrateursetdirigeants",
      "vdl_nomdelacompagniedassurance",
      "vdl_numerodelapolicedassurance",
      "vdl_datedecheancedelapolicedassurance",
    ],
    title: "Admissiblité de l'organisme",
  };
const editDemandAdmissiblityMissionSection: FormSection<EditDemandAdmissibilityFields> =
  {
    fields: ["vdl_missionetobjectif"],
    title: "Mission de l'organisme",
    description:
      "Quelle est votre mission et vos objectifs tels que décrits dans vos lettres patentes et règlements généraux.",
  };

const editDemandAdmissiblityReasonsSection: FormSection<EditDemandAdmissibilityFields> =
  {
    fields: ["vdl_realitebesoinsoutien"],
    title: "La ou les raisons de la demande",
    description:
      "Préciser votre réalité, vos besoins, le type de soutien que votre organisation souhaite obtenir.",
  };
const editDemandAdmissiblityServiceOfferSection: FormSection<EditDemandAdmissibilityFields> =
  {
    fields: [
      "vdl_pointsdeservicealaval",
      "vdl_siouiaquelendroit",
      "vdl_dansqueldomainedactivitevotreorganismeinter",
      "vdl_siautresprecisez",
      "comment_4",
      "vdl_principauxserviceouactivites",
      "vdl_desregroupementsetoudesorganismes",
      "vdl_territoiresdesservisparlorganisme2",
      "vdl_siailleursprecisez",
    ],
    title: "Offre de service de l'organisme",
  };
const editDemandAdmissiblityClientsSection: FormSection<EditDemandAdmissibilityFields> =
  {
    fields: [
      "vdl_typedeclientele",
      "vdl_nombredeparticipantsauxactivites",
      "comment_individus",
      "vdl_prescolaire0a4ans",
      "vdl_enfants5a12ans",
      "vdl_adolescents13a17ans",
      "vdl_adultes18a64ans",
      "vdl_aines65ansetplus65",

      "comment_cliente",
      "vdl_politiquedeverification",
      "vdl_entente",
      "vdl_codedeconduite",
      "vdl_formationsurladoptiondecomportementsadequats",
      "vdl_codedeconduitedelorganisation",
      "vdl_codeetsignatureduncontratethique",
      "vdl_verificationantecedentjudiciaire",
      "vdl_mecanismedegestionderisqueencasdedoute",
      "vdl_utilisationdelaplateformejebenevoleca",
      "vdl_jeveuxplusdinformationacesujet",
    ],
    title: "Clientèle desservie",
  };

const editDemandAdmissiblityGouvernanceSection: FormSection<EditDemandAdmissibilityFields> =
  {
    fields: [
      "comment_2",
      "vdl_duneconcertation",
      "vdl_dunregroupement",
      "vdl_dunefederation",
      "vdl_nombrederencontresduca",
      "vdl_datedeladerniereassembleegeneraleannuelle",
      "vdl_nombredepresencesalaga",
      "vdl_nombredemembresactifsauseindelorganisme",
    ],
    title: "Vie associative, démocratie et gouvernance",
  };

const editDemandAdmissiblityFinanciarySection: FormSection<EditDemandAdmissibilityFields> =
  {
    fields: [
      "vdl_datedefindevotreexercicefinancierannuel",
      "vdl_produisezvousannuellementdesprevisionsbudgeta",
      "vdl_vosderniersetatsfinanciersontilsfaitlobjet",
      "comment_3",
      "vdl_audit",
      "vdl_missiondexamen",
      "vdl_verificationcomptable",
    ],
    title: "Gestion Financière",
  };

const editDemandAdmissiblityAttachementsSection: FormSection<EditDemandAdmissibilityFields> =
  {
    fields: [
      "file1",
      "file2",
      "comment7",
      "file3",
      "file4",
      "comment8",
      "file5",
      "file6",
      "file7",
      "file8",
    ],
    title: "Documents",
  };

const editDemandAdmissiblitySections: FormSection<EditDemandAdmissibilityFields>[] =
  [
    editDemandAdmissiblityAdmissibilitySection,
    editDemandAdmissiblityMissionSection,
    editDemandAdmissiblityReasonsSection,
    editDemandAdmissiblityServiceOfferSection,
    editDemandAdmissiblityClientsSection,
    editDemandAdmissiblityGouvernanceSection,
    editDemandAdmissiblityFinanciarySection,
  ];

const editDemandAdmissiblityAdmissibilityRenouvellementSection: FormSection<EditDemandAdmissibilityFields> =
  {
    fields: [
      "comment_5",
      "vdl_aucuneassurance",
      "vdl_assurancedesadministrateurs",
      "vdl_assurancedesbiens",
      "vdl_assuranceresponsabilitecivile",
      "vdl_assuranceadministrateursetdirigeants",
      "vdl_nomdelacompagniedassurance",
      "vdl_numerodelapolicedassurance",
      "vdl_datedecheancedelapolicedassurance",
    ],
    title: "Admissiblité de l'organisme",
  };

const editDemandAdmissiblityServiceOfferRenouvellementSection: FormSection<EditDemandAdmissibilityFields> =
  {
    fields: [
      "vdl_principauxserviceouactivites",
    ],
    title: "Offre de service de l'organisme",
  };

const editDemandAdmissiblityRenouvellementSections: FormSection<EditDemandAdmissibilityFields>[] =
  [
    editDemandAdmissiblityAdmissibilityRenouvellementSection,
    editDemandAdmissiblityMissionSection,
    editDemandAdmissiblityReasonsSection,
    editDemandAdmissiblityServiceOfferRenouvellementSection,
    editDemandAdmissiblityClientsSection,
    editDemandAdmissiblityGouvernanceSection,
    editDemandAdmissiblityFinanciarySection,
  ];
export { editDemandAdmissibilityFields, editDemandAdmissiblitySections, editDemandAdmissiblityRenouvellementSections };

