import { Dayjs } from "dayjs";
import {
  CheckBoxInput,
  CommentInput,
  DateInput,
  DateTimeInput,
  DropDownChoice,
  DropDownOptionSetInput,
  FormFields,
  FormSection,
  NumberInput,
  RequiredCondition,
  SectionCheckBoxInput,
  TextInput,
} from "../../../types";
import { publicRoadDisplayNames } from "../displayNames";

export type EditDemandPublicRoadFields = {
  //Fermeture de rue
  vdl_fermeturedelarue2: SectionCheckBoxInput;
  vdl_fermeturedelarue: DropDownOptionSetInput;
  vdl_troncon: CheckBoxInput;
  vdl_nomdelarue: TextInput;
  vdl_nord: CheckBoxInput;
  vdl_sud: CheckBoxInput;
  vdl_est: CheckBoxInput;
  vdl_ouest: CheckBoxInput;
  vdl_entrerueouadresses: TextInput;
  vdl_circuit: CheckBoxInput;
  vdl_trajetrueparrueavecdirection: TextInput;
  vdl_dateetheuredelafermeture: DateTimeInput;
  vdl_dateetheuredelareouverture: DateTimeInput;
  vdl_renseignementadditionnels: TextInput;

  //Fermeture du trottoir
  vdl_fermeturedutrottoircasecocher: SectionCheckBoxInput;
  vdl_fermeturedutrottoirliste: DropDownOptionSetInput;
  vdl_nomdelarue2: TextInput;
  vdl_entrerueouadresses4: TextInput;
  vdl_nord2: CheckBoxInput;
  vdl_sud2: CheckBoxInput;
  vdl_est2: CheckBoxInput;
  vdl_ouest2: CheckBoxInput;
  vdl_dateetheuredelafermeture2: DateTimeInput;
  vdl_dateetheuredelareouverture2: DateTimeInput;
  vdl_renseignementadditionnels2: TextInput;

  //Interdiction de stationnement
  vdl_interdictiondestationnement: SectionCheckBoxInput;
  vdl_raisondelademande: TextInput;
  vdl_nomdelarue3: TextInput;
  vdl_nord3: CheckBoxInput;
  vdl_est3: CheckBoxInput;
  vdl_sud3: CheckBoxInput;
  vdl_ouest3: CheckBoxInput;
  vdl_dateetheuredelafermeture3: DateTimeInput;
  vdl_dateetheuredelareouverture3: DateTimeInput;
  vdl_renseignementadditionnels3: TextInput;

  //Toléramce de stationnement
  vdl_tolerancedestationnement: SectionCheckBoxInput;
  vdl_raisondelademande2: TextInput;
  vdl_nomdelarue4: TextInput;
  vdl_nord4: CheckBoxInput;
  vdl_est4: CheckBoxInput;
  vdl_sud4: CheckBoxInput;
  vdl_ouest4: CheckBoxInput;
  vdl_dateetheuredelafermeture4: DateTimeInput;
  vdl_dateetheuredelareouverture4: DateTimeInput;
  vdl_renseignementadditionnels4: TextInput;

  //Activités
  vdl_redonneeavelo: CheckBoxInput;
  vdl_defile: CheckBoxInput;
  vdl_marche: CheckBoxInput;
  vdl_processionreligieuse: CheckBoxInput;
  vdl_vehiculehippomobilecaleche: CheckBoxInput;
  vdl_rassemblementsansdeplacement: CheckBoxInput;
  vdl_nombredeparticipants: NumberInput;
  vdl_enfants512ans: NumberInput;
  vdl_adultes: NumberInput;
  vdl_trajetrueparrueavecdirection2: TextInput;
  commentUtilisation: CommentInput;
  vdl_rue: CheckBoxInput;
  vdl_trottoir: CheckBoxInput;
  vdl_pistescyclables: CheckBoxInput;
  vdl_autre: CheckBoxInput;
  vdl_siautreprecisez: TextInput;
};

const vdl_fermeturedelarueChoices: DropDownChoice[] = [
  {
    value: "Complète",
  },
  {
    value: "Partielle",
  },
  {
    value: "Par intermittence (3min. )",
  },
];

const sectionRequiredError = "Ce champ est requis pour cette section";

const vdl_fermeturedelarue2Condition: RequiredCondition = {
  validator: (fields: FormFields) => fields.vdl_fermeturedelarue2.value,
  error: sectionRequiredError,
};

const vdl_fermeturedutrottoircasecocherCondition: RequiredCondition = {
  validator: (fields: FormFields) =>
    fields.vdl_fermeturedutrottoircasecocher.value,
  error: sectionRequiredError,
};

const vdl_interdictiondestationnementCondition: RequiredCondition = {
  validator: (fields: FormFields) =>
    fields.vdl_interdictiondestationnement.value,
  error: sectionRequiredError,
};
const vdl_tolerancedestationnementCondition: RequiredCondition = {
  validator: (fields: FormFields) => fields.vdl_tolerancedestationnement.value,
  error: sectionRequiredError,
};

const editDemandPublicRoadFields: EditDemandPublicRoadFields = {
  vdl_fermeturedelarue2: {
    type: "sectioncheckbox",
    value: false,
  },
  vdl_fermeturedelarue: {
    type: "optionset",
    error: "",
    value: undefined,
    fillLine: true,
    choices: vdl_fermeturedelarueChoices,
    //requiredCondition: vdl_fermeturedelarue2Condition,
    label: publicRoadDisplayNames.vdl_fermeturedelarue,
  },
  vdl_troncon: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: publicRoadDisplayNames.vdl_troncon,
  },
  vdl_nomdelarue: {
    type: "text",
    error: "",
    value: "",
    //requiredCondition: vdl_fermeturedelarue2Condition,
    fillLine: true,
    maxLength: 500,
    label: publicRoadDisplayNames.vdl_nomdelarue,
  },
  vdl_nord: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: publicRoadDisplayNames.vdl_nord,
  },
  vdl_sud: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: publicRoadDisplayNames.vdl_sud,
  },
  vdl_est: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: publicRoadDisplayNames.vdl_est,
  },
  vdl_ouest: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: publicRoadDisplayNames.vdl_ouest,
  },
  vdl_entrerueouadresses: {
    type: "text",
    error: "",
    value: "",
    //requiredCondition: vdl_fermeturedelarue2Condition,
    fillLine: true,
    maxLength: 500,
    label: publicRoadDisplayNames.vdl_entrerueouadresses,
  },
  vdl_circuit: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: publicRoadDisplayNames.vdl_circuit,
  },
  vdl_trajetrueparrueavecdirection: {
    type: "text",
    error: "",
    value: "",
    //requiredCondition: vdl_fermeturedelarue2Condition,
    fillLine: true,
    maxLength: 1000,
    label: publicRoadDisplayNames.vdl_trajetrueparrueavecdirection,
  },
  vdl_dateetheuredelafermeture: {
    type: "datetime",
    error: "",
    value: undefined,
    //requiredCondition: vdl_fermeturedelarue2Condition,
    fillLine: false,
    disablePast: true,
    label: publicRoadDisplayNames.vdl_dateetheuredelafermeture,
  },
  vdl_dateetheuredelareouverture: {
    type: "datetime",
    error: "",
    value: undefined,
    //requiredCondition: vdl_fermeturedelarue2Condition,
    fillLine: false,
    disablePast: true,
    label: publicRoadDisplayNames.vdl_dateetheuredelareouverture,
    minDate: (fields: FormFields) =>
      (fields.vdl_dateetheuredelafermeture.value as Dayjs)
        ? (fields.vdl_dateetheuredelafermeture.value as Dayjs)
        : undefined,
  },
  vdl_renseignementadditionnels: {
    type: "text",
    error: "",
    value: "",
    //requiredCondition: vdl_fermeturedelarue2Condition,
    fillLine: true,
    maxLength: 1000,
    label: publicRoadDisplayNames.vdl_renseignementadditionnels,
  },

  vdl_fermeturedutrottoircasecocher: {
    type: "sectioncheckbox",
    value: false,
  },
  vdl_fermeturedutrottoirliste: {
    type: "optionset",
    error: "",
    value: undefined,
    //requiredCondition: vdl_fermeturedutrottoircasecocherCondition,
    fillLine: true,
    choices: vdl_fermeturedelarueChoices,
    label: publicRoadDisplayNames.vdl_fermeturedutrottoirliste,
  },
  vdl_nomdelarue2: {
    type: "text",
    error: "",
    value: "",
    //requiredCondition: vdl_fermeturedutrottoircasecocherCondition,
    fillLine: true,
    maxLength: 500,
    label: publicRoadDisplayNames.vdl_nomdelarue2,
  },
  vdl_entrerueouadresses4: {
    type: "text",
    error: "",
    value: "",
    //requiredCondition: vdl_fermeturedutrottoircasecocherCondition,
    fillLine: true,
    maxLength: 1000,
    label: publicRoadDisplayNames.vdl_entrerueouadresses4,
  },
  vdl_nord2: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: publicRoadDisplayNames.vdl_nord2,
  },
  vdl_sud2: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: publicRoadDisplayNames.vdl_sud2,
  },
  vdl_est2: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: publicRoadDisplayNames.vdl_est2,
  },
  vdl_ouest2: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: publicRoadDisplayNames.vdl_ouest2,
  },
  vdl_dateetheuredelafermeture2: {
    type: "datetime",
    error: "",
    value: undefined,
    //requiredCondition: vdl_fermeturedutrottoircasecocherCondition,
    fillLine: false,
    disablePast: true,
    label: publicRoadDisplayNames.vdl_dateetheuredelafermeture2,
  },
  vdl_dateetheuredelareouverture2: {
    type: "datetime",
    error: "",
    value: undefined,
    //requiredCondition: vdl_fermeturedutrottoircasecocherCondition,
    fillLine: false,
    disablePast: true,
    label: publicRoadDisplayNames.vdl_dateetheuredelareouverture2,
    minDate: (fields: FormFields) =>
      (fields.vdl_dateetheuredelafermeture2.value as Dayjs)
        ? (fields.vdl_dateetheuredelafermeture2.value as Dayjs)
        : undefined,
  },
  vdl_renseignementadditionnels2: {
    type: "text",
    error: "",
    value: "",
    //requiredCondition: vdl_fermeturedutrottoircasecocherCondition,
    fillLine: true,
    maxLength: 1000,
    label: publicRoadDisplayNames.vdl_renseignementadditionnels2,
  },

  vdl_interdictiondestationnement: {
    type: "sectioncheckbox",
    value: false,
  },
  vdl_raisondelademande: {
    type: "text",
    error: "",
    value: "",
    //requiredCondition: vdl_interdictiondestationnementCondition,
    fillLine: true,
    maxLength: 500,
    label: publicRoadDisplayNames.vdl_raisondelademande,
  },
  vdl_nomdelarue3: {
    type: "text",
    error: "",
    value: "",
    //requiredCondition: vdl_interdictiondestationnementCondition,
    fillLine: true,
    maxLength: 500,
    label: publicRoadDisplayNames.vdl_nomdelarue3,
  },
  vdl_nord3: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: publicRoadDisplayNames.vdl_nord3,
  },
  vdl_est3: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: publicRoadDisplayNames.vdl_est3,
  },
  vdl_sud3: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: publicRoadDisplayNames.vdl_sud3,
  },
  vdl_ouest3: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: publicRoadDisplayNames.vdl_ouest3,
  },
  vdl_dateetheuredelafermeture3: {
    type: "datetime",
    error: "",
    value: undefined,
    //requiredCondition: vdl_interdictiondestationnementCondition,
    fillLine: false,
    disablePast: true,
    label: publicRoadDisplayNames.vdl_dateetheuredelafermeture3,
  },
  vdl_dateetheuredelareouverture3: {
    type: "datetime",
    error: "",
    value: undefined,
    requiredCondition: vdl_interdictiondestationnementCondition,
    fillLine: false,
    disablePast: true,
    label: publicRoadDisplayNames.vdl_dateetheuredelareouverture3,
    minDate: (fields: FormFields) =>
      (fields.vdl_dateetheuredelafermeture3.value as Dayjs)
        ? (fields.vdl_dateetheuredelafermeture3.value as Dayjs)
        : undefined,
  },
  vdl_renseignementadditionnels3: {
    type: "text",
    error: "",
    value: "",
    requiredCondition: vdl_interdictiondestationnementCondition,
    fillLine: true,
    maxLength: 1000,
    label: publicRoadDisplayNames.vdl_renseignementadditionnels3,
  },

  vdl_tolerancedestationnement: {
    type: "sectioncheckbox",
    value: false,
  },
  vdl_raisondelademande2: {
    type: "text",
    error: "",
    value: "",
    requiredCondition: vdl_tolerancedestationnementCondition,
    fillLine: true,
    maxLength: 500,
    label: publicRoadDisplayNames.vdl_raisondelademande2,
  },
  vdl_nomdelarue4: {
    type: "text",
    error: "",
    value: "",
    requiredCondition: vdl_tolerancedestationnementCondition,
    fillLine: true,
    maxLength: 500,
    label: publicRoadDisplayNames.vdl_nomdelarue4,
  },
  vdl_nord4: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: publicRoadDisplayNames.vdl_nord4,
  },
  vdl_est4: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: publicRoadDisplayNames.vdl_est4,
  },
  vdl_sud4: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: publicRoadDisplayNames.vdl_sud4,
  },
  vdl_ouest4: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: publicRoadDisplayNames.vdl_ouest4,
  },
  vdl_dateetheuredelafermeture4: {
    type: "datetime",
    error: "",
    value: undefined,
    //requiredCondition: vdl_tolerancedestationnementCondition,
    fillLine: false,
    disablePast: true,
    label: publicRoadDisplayNames.vdl_dateetheuredelafermeture4,
  },
  vdl_dateetheuredelareouverture4: {
    type: "datetime",
    error: "",
    value: undefined,
    requiredCondition: vdl_tolerancedestationnementCondition,
    fillLine: false,
    disablePast: true,
    label: publicRoadDisplayNames.vdl_dateetheuredelareouverture4,
    minDate: (fields: FormFields) =>
      (fields.vdl_dateetheuredelafermeture4.value as Dayjs)
        ? (fields.vdl_dateetheuredelafermeture4.value as Dayjs)
        : undefined,
  },
  vdl_renseignementadditionnels4: {
    type: "text",
    error: "",
    value: "",
    requiredCondition: vdl_tolerancedestationnementCondition,
    fillLine: true,
    maxLength: 1000,
    label: publicRoadDisplayNames.vdl_renseignementadditionnels4,
  },

  vdl_redonneeavelo: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: publicRoadDisplayNames.vdl_redonneeavelo,
  },
  vdl_defile: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: publicRoadDisplayNames.vdl_defile,
  },
  vdl_marche: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: publicRoadDisplayNames.vdl_marche,
  },
  vdl_processionreligieuse: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: publicRoadDisplayNames.vdl_processionreligieuse,
  },
  vdl_vehiculehippomobilecaleche: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: publicRoadDisplayNames.vdl_vehiculehippomobilecaleche,
  },
  vdl_rassemblementsansdeplacement: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: publicRoadDisplayNames.vdl_rassemblementsansdeplacement,
  },
  vdl_nombredeparticipants: {
    type: "number",
    error: "",
    value: undefined,
    required: false,
    fillLine: true,
    label: publicRoadDisplayNames.vdl_nombredeparticipants,
  },
  vdl_enfants512ans: {
    type: "number",
    error: "",
    value: undefined,
    required: false,
    fillLine: false,
    label: publicRoadDisplayNames.vdl_enfants512ans,
  },
  vdl_adultes: {
    type: "number",
    error: "",
    value: undefined,
    required: false,
    fillLine: false,
    label: publicRoadDisplayNames.vdl_adultes,
  },
  vdl_trajetrueparrueavecdirection2: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    maxLength: 1000,
    label: publicRoadDisplayNames.vdl_trajetrueparrueavecdirection2,
  },
  commentUtilisation: {
    type: "comment",
    value: "Utilisation de la voie publique:",
    fillLine: true,
  },
  vdl_rue: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: publicRoadDisplayNames.vdl_rue,
  },
  vdl_trottoir: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: publicRoadDisplayNames.vdl_trottoir,
  },
  vdl_pistescyclables: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: publicRoadDisplayNames.vdl_pistescyclables,
  },
  vdl_autre: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: publicRoadDisplayNames.vdl_autre,
  },
  vdl_siautreprecisez: {
    type: "text",
    error: "",
    value: "",
    requiredCondition: {
      validator: (fields: FormFields) => fields.vdl_autre.value,
      error: "Veuillez préciser",
    },
    fillLine: true,
    label: publicRoadDisplayNames.vdl_siautreprecisez,
  },
};

const editDemandPublicRoadStreetSection: FormSection<EditDemandPublicRoadFields> =
  {
    fields: [
      "vdl_fermeturedelarue",
      "vdl_troncon",
      "vdl_nomdelarue",
      "vdl_nord",
      "vdl_sud",
      "vdl_est",
      "vdl_ouest",
      "vdl_entrerueouadresses",
      "vdl_circuit",
      "vdl_trajetrueparrueavecdirection",
      "vdl_dateetheuredelafermeture",
      "vdl_dateetheuredelareouverture",
      "vdl_renseignementadditionnels",
    ],
    title: publicRoadDisplayNames.vdl_fermeturedelarue2,
    activeRule: {
      field: "vdl_fermeturedelarue2",
    },
  };

const editDemandPublicRoadSideWalkSection: FormSection<EditDemandPublicRoadFields> =
  {
    fields: [
      "vdl_fermeturedutrottoirliste",
      "vdl_nomdelarue2",
      "vdl_entrerueouadresses4",
      "vdl_nord2",
      "vdl_sud2",
      "vdl_est2",
      "vdl_ouest2",
      "vdl_dateetheuredelafermeture2",
      "vdl_dateetheuredelareouverture2",
      "vdl_renseignementadditionnels2",
    ],
    title: publicRoadDisplayNames.vdl_fermeturedutrottoircasecocher,
    activeRule: {
      field: "vdl_fermeturedutrottoircasecocher",
    },
  };

const editDemandPublicRoadNoParkingSection: FormSection<EditDemandPublicRoadFields> =
  {
    fields: [
      "vdl_raisondelademande",
      "vdl_nomdelarue3",
      "vdl_nord3",
      "vdl_est3",
      "vdl_sud3",
      "vdl_ouest3",
      "vdl_dateetheuredelafermeture3",
      "vdl_dateetheuredelareouverture3",
      "vdl_renseignementadditionnels3",
    ],
    title: publicRoadDisplayNames.vdl_interdictiondestationnement,
    activeRule: {
      field: "vdl_interdictiondestationnement",
    },
  };

const editDemandPublicRoadParkingToleranceSection: FormSection<EditDemandPublicRoadFields> =
  {
    fields: [
      "vdl_raisondelademande2",
      "vdl_nomdelarue4",
      "vdl_nord4",
      "vdl_est4",
      "vdl_sud4",
      "vdl_ouest4",
      "vdl_dateetheuredelafermeture4",
      "vdl_dateetheuredelareouverture4",
      "vdl_renseignementadditionnels4",
    ],
    title: "Tolérance de stationnement",
    activeRule: {
      field: "vdl_tolerancedestationnement",
    },
  };

const editDemandPublicRoadActivitySection: FormSection<EditDemandPublicRoadFields> =
  {
    fields: [
      "vdl_redonneeavelo",
      "vdl_defile",
      "vdl_marche",
      "vdl_processionreligieuse",
      "vdl_vehiculehippomobilecaleche",
      "vdl_rassemblementsansdeplacement",
      "vdl_nombredeparticipants",
      "vdl_enfants512ans",
      "vdl_adultes",
      "vdl_trajetrueparrueavecdirection2",
      "commentUtilisation",
      "vdl_rue",
      "vdl_trottoir",
      "vdl_pistescyclables",
      "vdl_autre",
      "vdl_siautreprecisez",
    ],
    title: "Activités",
  };

const editDemandPublicRoadSectionsEvent: FormSection<EditDemandPublicRoadFields>[] =
  [
    editDemandPublicRoadStreetSection,
    editDemandPublicRoadNoParkingSection,
    editDemandPublicRoadParkingToleranceSection,
    editDemandPublicRoadActivitySection,
  ];

const editDemandPublicRoadSectionsFilming: FormSection<EditDemandPublicRoadFields>[] =
  [
    editDemandPublicRoadStreetSection,
    editDemandPublicRoadSideWalkSection,
    editDemandPublicRoadNoParkingSection,
    editDemandPublicRoadParkingToleranceSection,
  ];

export {
  editDemandPublicRoadFields,
  editDemandPublicRoadSectionsEvent,
  editDemandPublicRoadSectionsFilming,
};
