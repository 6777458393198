import { errorHandler } from "../../components/common/error/errorHandler";
import { GetResponse } from "../common/types";
import usefetchWithMsal from "../fetchWithMsal";
import { AccountTypeRow } from "./models";

const getAccounTypes = async (): Promise<GetResponse<AccountTypeRow[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/get_typeorganisation`;

    const result = await usefetchWithMsal("GET", endpoint);
    if (result.StatusCode === 404)
      return { status: "success", data: undefined };
    if (!result.Value) throw new Error("Erreur dans la requête");

    const data = result.Value;
    const tempData: AccountTypeRow[] = [];

    data.forEach(
      (type: {
        vdl_typeorganisationid: string;
        vdl_typedorganisation: string;
      }) => {
        tempData.push({
          value: type.vdl_typeorganisationid,
          label: type.vdl_typedorganisation,
        });
      }
    );

    return { status: "success", data: tempData };
  } catch (e) {
    return errorHandler(
      e,
      "Erreur dans la récupération des types d'organisation"
    );
  }
};

export { getAccounTypes };
