import { Box } from "@mui/material";
import { DemandPublicRoadRow } from "../../../../../data/Demand/models";
import { publicRoadDisplayNames } from "../../../../../data/Demand/displayNames";
import DisplayFieldCard from "../../../../common/cards/DisplayFieldCard";
import { dateTimeStringToString } from "../../../../../data/common/fieldToString";

type Props = {
  demand: DemandPublicRoadRow;
};

const PublicRoad = ({ demand }: Props) => {
  const details = demand.vdl_detailsdelademande;

  const fermetureDeRue = (
    <DisplayFieldCard
      title="Fermeture de rue"
      fields={[
        {
          label: publicRoadDisplayNames.vdl_fermeturedelarue,
          value: details.vdl_fermeturedelarue,
          type: "string",
          span: 2,
        },
        {
          label: publicRoadDisplayNames.vdl_troncon,
          value: details.vdl_troncon,
          type: "check",
          span: 2,
        },
        {
          label: publicRoadDisplayNames.vdl_nomdelarue,
          value: details.vdl_nomdelarue,
          type: "string",
          span: 2,
        },
        {
          label: publicRoadDisplayNames.vdl_nord,
          value: details.vdl_nord,
          type: "check",
        },
        {
          label: publicRoadDisplayNames.vdl_sud,
          value: details.vdl_sud,
          type: "check",
        },
        {
          label: publicRoadDisplayNames.vdl_est,
          value: details.vdl_est,
          type: "check",
        },
        {
          label: publicRoadDisplayNames.vdl_ouest,
          value: details.vdl_ouest,
          type: "check",
        },
        {
          label: publicRoadDisplayNames.vdl_entrerueouadresses,
          value: details.vdl_entrerueouadresses,
          type: "string",
          span: 2,
        },
        {
          label: publicRoadDisplayNames.vdl_circuit,
          value: details.vdl_circuit,
          type: "check",
          span: 2,
        },
        {
          label: publicRoadDisplayNames.vdl_trajetrueparrueavecdirection,
          value: details.vdl_trajetrueparrueavecdirection,
          type: "string",
          span: 2,
        },
        {
          label: publicRoadDisplayNames.vdl_dateetheuredelafermeture,
          value: dateTimeStringToString(details.vdl_dateetheuredelafermeture),
          type: "string",
        },
        {
          label: publicRoadDisplayNames.vdl_dateetheuredelareouverture,
          value: dateTimeStringToString(details.vdl_dateetheuredelareouverture),
          type: "string",
        },
        {
          label: publicRoadDisplayNames.vdl_renseignementadditionnels,
          value: details.vdl_renseignementadditionnels,
          type: "string",
          span: 2,
        },
      ]}
      columns={2}
      minColWidth={275}
      activeRule={{ hide: true, value: details.vdl_fermeturedelarue2 }}
    />
  );

  const interdictionStationnement = (
    <DisplayFieldCard
      title="Interdiction de stationnement"
      fields={[
        {
          label: publicRoadDisplayNames.vdl_raisondelademande,
          value: details.vdl_raisondelademande,
          type: "string",
          span: 2,
        },
        {
          label: publicRoadDisplayNames.vdl_nomdelarue3,
          value: details.vdl_nomdelarue3,
          type: "string",
          span: 2,
        },
        {
          label: publicRoadDisplayNames.vdl_nord3,
          value: details.vdl_nord3,
          type: "check",
        },
        {
          label: publicRoadDisplayNames.vdl_sud3,
          value: details.vdl_sud3,
          type: "check",
        },
        {
          label: publicRoadDisplayNames.vdl_est3,
          value: details.vdl_est3,
          type: "check",
        },
        {
          label: publicRoadDisplayNames.vdl_ouest3,
          value: details.vdl_ouest3,
          type: "check",
        },
        {
          label: publicRoadDisplayNames.vdl_dateetheuredelafermeture3,
          value: dateTimeStringToString(details.vdl_dateetheuredelafermeture3),
          type: "string",
        },
        {
          label: publicRoadDisplayNames.vdl_dateetheuredelareouverture3,
          value: dateTimeStringToString(
            details.vdl_dateetheuredelareouverture3
          ),
          type: "string",
        },
        {
          label: publicRoadDisplayNames.vdl_renseignementadditionnels3,
          value: details.vdl_renseignementadditionnels3,
          type: "string",
          span: 2,
        },
      ]}
      columns={2}
      minColWidth={275}
      activeRule={{
        hide: true,
        value: details.vdl_interdictiondestationnement,
      }}
    />
  );

  const toleranceStationnement = (
    <DisplayFieldCard
      title="Tolérance de stationnement"
      fields={[
        {
          label: publicRoadDisplayNames.vdl_raisondelademande2,
          value: details.vdl_raisondelademande2,
          type: "string",
          span: 2,
        },
        {
          label: publicRoadDisplayNames.vdl_nomdelarue4,
          value: details.vdl_nomdelarue4,
          type: "string",
          span: 2,
        },
        {
          label: publicRoadDisplayNames.vdl_nord4,
          value: details.vdl_nord4,
          type: "check",
        },
        {
          label: publicRoadDisplayNames.vdl_sud4,
          value: details.vdl_sud4,
          type: "check",
        },
        {
          label: publicRoadDisplayNames.vdl_est4,
          value: details.vdl_est4,
          type: "check",
        },
        {
          label: publicRoadDisplayNames.vdl_ouest4,
          value: details.vdl_ouest4,
          type: "check",
        },
        {
          label: publicRoadDisplayNames.vdl_dateetheuredelafermeture4,
          value: dateTimeStringToString(details.vdl_dateetheuredelafermeture4),
          type: "string",
        },
        {
          label: publicRoadDisplayNames.vdl_dateetheuredelareouverture4,
          value: dateTimeStringToString(
            details.vdl_dateetheuredelareouverture4
          ),
          type: "string",
        },
        {
          label: publicRoadDisplayNames.vdl_renseignementadditionnels4,
          value: details.vdl_renseignementadditionnels4,
          type: "string",
          span: 2,
        },
      ]}
      columns={2}
      minColWidth={275}
      activeRule={{ hide: true, value: details.vdl_tolerancedestationnement }}
    />
  );

  return (
    <Box display="grid" gap="16px">
      {details.vdl_typedactivite === "Évènement" ? (
        <>
          {fermetureDeRue}
          {interdictionStationnement}
          {toleranceStationnement}
          <DisplayFieldCard
            title="Activités"
            fields={[
              {
                label: publicRoadDisplayNames.vdl_redonneeavelo,
                value: details.vdl_redonneeavelo,
                type: "check",
              },
              {
                label: publicRoadDisplayNames.vdl_defile,
                value: details.vdl_defile,
                type: "check",
              },
              {
                label: publicRoadDisplayNames.vdl_marche,
                value: details.vdl_marche,
                type: "check",
              },
              {
                label: publicRoadDisplayNames.vdl_processionreligieuse,
                value: details.vdl_processionreligieuse,
                type: "check",
              },
              {
                label: publicRoadDisplayNames.vdl_vehiculehippomobilecaleche,
                value: details.vdl_vehiculehippomobilecaleche,
                type: "check",
              },
              {
                label: publicRoadDisplayNames.vdl_rassemblementsansdeplacement,
                value: details.vdl_rassemblementsansdeplacement,
                type: "check",
              },
              {
                label: publicRoadDisplayNames.vdl_nombredeparticipants,
                value: details.vdl_nombredeparticipants?.toString(),
                type: "string",
                span: 2,
              },
              {
                label: publicRoadDisplayNames.vdl_enfants512ans,
                value: details.vdl_enfants512ans?.toString(),
                type: "string",
              },
              {
                label: publicRoadDisplayNames.vdl_adultes,
                value: details.vdl_adultes?.toString(),
                type: "string",
              },
              {
                label: publicRoadDisplayNames.vdl_trajetrueparrueavecdirection2,
                value: details.vdl_trajetrueparrueavecdirection2,
                type: "string",
                span: 2,
              },
              {
                label: publicRoadDisplayNames.vdl_rue,
                value: details.vdl_rue,
                type: "check",
              },
              {
                label: publicRoadDisplayNames.vdl_trottoir,
                value: details.vdl_trottoir,
                type: "check",
              },
              {
                label: publicRoadDisplayNames.vdl_pistescyclables,
                value: details.vdl_pistescyclables,
                type: "check",
              },
              {
                label: publicRoadDisplayNames.vdl_autre,
                value: details.vdl_autre,
                type: "check",
              },
              {
                label: publicRoadDisplayNames.vdl_siautreprecisez,
                value: details.vdl_siautreprecisez,
                type: "string",
              },
            ]}
            columns={2}
            minColWidth={275}
          />
        </>
      ) : details.vdl_typedactivite === "Tournage" ? (
        <>
          {fermetureDeRue}
          <DisplayFieldCard
            title="Fermeture du trottoir"
            fields={[
              {
                label: publicRoadDisplayNames.vdl_fermeturedutrottoirliste,
                value: details.vdl_fermeturedutrottoirliste,
                type: "string",
                span: 2,
              },
              {
                label: publicRoadDisplayNames.vdl_nomdelarue2,
                value: details.vdl_nomdelarue2,
                type: "string",
                span: 2,
              },
              {
                label: publicRoadDisplayNames.vdl_entrerueouadresses4,
                value: details.vdl_entrerueouadresses4,
                type: "string",
                span: 2,
              },
              {
                label: publicRoadDisplayNames.vdl_nord2,
                value: details.vdl_nord2,
                type: "check",
              },
              {
                label: publicRoadDisplayNames.vdl_sud2,
                value: details.vdl_sud2,
                type: "check",
              },
              {
                label: publicRoadDisplayNames.vdl_est2,
                value: details.vdl_est2,
                type: "check",
              },
              {
                label: publicRoadDisplayNames.vdl_ouest2,
                value: details.vdl_ouest2,
                type: "check",
              },
              {
                label: publicRoadDisplayNames.vdl_dateetheuredelafermeture2,
                value: dateTimeStringToString(
                  details.vdl_dateetheuredelafermeture2
                ),
                type: "string",
              },
              {
                label: publicRoadDisplayNames.vdl_dateetheuredelareouverture2,
                value: dateTimeStringToString(
                  details.vdl_dateetheuredelareouverture2
                ),
                type: "string",
              },
              {
                label: publicRoadDisplayNames.vdl_renseignementadditionnels2,
                value: details.vdl_renseignementadditionnels2,
                type: "string",
                span: 2,
              },
            ]}
            columns={2}
            minColWidth={275}
            activeRule={{
              hide: true,
              value: details.vdl_fermeturedutrottoircasecocher,
            }}
          />
          {interdictionStationnement}
          {toleranceStationnement}
        </>
      ) : null}
    </Box>
  );
};

export default PublicRoad;
