import React from "react";
import { Routes, Route } from "react-router-dom";
import { RouterRoutes, routes } from "./routes";
import {
  AppLayout,
  AppEmptyLayout,
} from "../components/layouts/AppLayouts/AppLayout";
import Demands from "../pages/Demands.page";
import NewAccount from "../pages/NewAccount.page";
import Test from "../pages/Test.page";
import TestPage2 from "../pages/TestPage2";
import PrivateRoute from "./PrivateRoute";
import NotFound from "../pages/NotFound.page";
import AuthRedirect from "../pages/AuthRedirect";

const AppRoutes = () => {
  return (
    <Routes>
      <Route>
        <Route path={RouterRoutes.Home} element={<AppLayout />}>
          <Route index element={<Demands />} />
          {Object.entries(routes).map(([key, value]) => (
            <Route
              key={value.path}
              path={value.path}
              element={
                value.minimumRole ? (
                  <PrivateRoute roleMinimum={value.minimumRole}>
                    {value.component}
                  </PrivateRoute>
                ) : (
                  value.component
                )
              }
            />
          ))}
          <Route
            path={"test"}
            element={
              <PrivateRoute roleMinimum="Administrateur">
                <Test />
              </PrivateRoute>
            }
          />
          <Route path="/auth" element={<AuthRedirect />} />
          <Route path="/invite" element={<AuthRedirect />} />
          <Route path={"test2"} element={<TestPage2 />} />
        </Route>
      </Route>
      <Route path={RouterRoutes.Home} element={<AppEmptyLayout />}>
        <Route path="/test-form" element={<NewAccount />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
