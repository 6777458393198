import { DropDownChoice } from "../../../types";

const vdl_fonctionChoices: DropDownChoice[] = [
  {
    value: "Président",
  },
  {
    value: "Vice-Président",
  },
  {
    value: "Trésorier",
  },
  {
    value: "Administrateur",
  },
  {
    value: "Employé",
  },
  {
    value: "Autre",
  },
];

const vdl_salutationsChoices: DropDownChoice[] = [
  {
    value: "Monsieur",
  },
  {
    value: "Madame",
  },
];

const vdl_accessPortailChoices: DropDownChoice[] = [
  {
    value: "Oui",
  },
  {
    value: "Non",
  },
];

export { vdl_fonctionChoices, vdl_salutationsChoices, vdl_accessPortailChoices };
