import { TimeInput } from "../../../types";
import {
  DateTimePicker,
  DateTimeValidationError,
  PickerChangeHandlerContext,
  TimePicker,
} from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";

type Props = {
  field: TimeInput;
  fieldName: string;
  handleChange: (field: TimeInput) => void;
  disabled: boolean;
};

const FormTimeInput = ({ field, fieldName, handleChange, disabled }: Props) => {
  function handleInputChange(
    value: Dayjs | null,
    context: PickerChangeHandlerContext<DateTimeValidationError>
  ) {
    let _field = { ...field };
    if (value === null) {
      _field.value = undefined;
      _field.error = "Ce champ est requis";
    } else {
      _field.error = "";
      _field.value = value;
    }

    handleChange(_field);
  }

  function handleBlur() {
    let _field = { ...field };
    _field.error = "";
    if (field.required && !field.value) {
      _field.error = "Ce champ est requis";
    }

    handleChange(_field);
  }
  return (
    <TimePicker
      name={fieldName}
      ampm={false}
      disabled={disabled}
      label={field.label}
      value={field.value !== undefined ? field.value : null}
      onChange={handleInputChange}
      onClose={handleBlur}
      slotProps={{
        field: {
          readOnly: true,
        },
        textField: {
          required: field.required,
          fullWidth: true,
          error: !(field.error == ""),
          helperText: field.error ? field.error : field.helperText,
        },
      }}
    />
  );
};

export default FormTimeInput;
