import { Box, Checkbox, Typography, useTheme } from "@mui/material";
import React from "react";
import { CheckBoxInput, FormField, SectionCheckBoxInput } from "../../../types";

type Props = {
  title?: string;
  description?: string;
  enableRule?: {
    field: SectionCheckBoxInput;
    fieldName: string;
    handleChange: (key: string, field: FormField) => void;
  };
};
const SimpleFormSectionTitle = ({ title, description, enableRule }: Props) => {
  return (
    <Box flexBasis="100%" display="flex" flexWrap="wrap">
      <Box display="flex" alignItems="center" gap="8px">
        <Typography color="primary" variant="h5">
          {title}
        </Typography>
        {enableRule && (
          <Box>
            <Checkbox
              sx={{
                color: enableRule.field.error
                  ? "error.main"
                  : "RGBA(0,0,0,0.6)",
              }}
              value={enableRule.field.value}
              checked={enableRule.field.value}
              onChange={() =>
                enableRule.handleChange(enableRule.fieldName, {
                  ...enableRule.field,
                  value: !enableRule.field.value,
                })
              }
              name={enableRule.fieldName}
              required={enableRule.field.required}
            />
          </Box>
        )}
      </Box>

      <Typography flexBasis="100%">{description}</Typography>
    </Box>
  );
};

export default SimpleFormSectionTitle;
