import React, { useEffect, useState } from "react";
import {
  DemandSpecialEventPatch,
  DemandSpecialEventRow,
} from "../../../../../data/Demand/models";
import { NewDemandFields } from "../../../../../data/Demand/forms/dialog";
import { updateDemand } from "../../../../../data/Demand/requests";
import EditDemandLayout from "../../EditDemandLayout";
import SimpleFields from "../../../../form/SimpleForm/SimpleFields";
import handleSimpleFormSubmit from "../../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../../hooks/useTabs";
import {
  EditDemandSpecialEventsGeneralFields,
  editDemandSpecialEventsGeneralFields,
  editDemandSpecialEventsGeneralSections,
} from "../../../../../data/Demand/forms/specialEvents/specialEventsGeneral";
import {
  EditDemandSpecialEventFields,
  editDemandSpecialEventFields,
  editDemandSpecialEventSections,
} from "../../../../../data/Demand/forms/specialEvents/specialEvent/specialEvent";
import { validateDateString } from "../../../../../data/common/validateDateString";
import {
  DemandSpecialEventsDateEditing,
  DemandSpecialEventsDatePatch,
} from "../../../../../data/Demand/types/specialEvents/dates";
import SpecialEvents from "../GeneralFields/SpecialEvents";
import { useSnackbar } from "../../../../../hooks/useSnackbar";

type Props = {
  demand: DemandSpecialEventRow;
  isNewDemand: boolean;
  fieldsGeneral: NewDemandFields;
  onUpdateSuccess: () => void;
  onUpdateFailure: (error?: string) => void;
  setFieldsGeneral: React.Dispatch<React.SetStateAction<NewDemandFields>>;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const SpecialEvent = ({
  demand,
  isNewDemand,
  fieldsGeneral,
  setFieldsGeneral,
  onUpdateSuccess,
  onUpdateFailure,
  setError,
}: Props) => {
  const [specialEventFields, setSpecialEventFields] =
    useState<EditDemandSpecialEventsGeneralFields>(
      editDemandSpecialEventsGeneralFields
    );
  const [fields, setFields] = useState<EditDemandSpecialEventFields>(
    editDemandSpecialEventFields
  );
  const [dates, setDates] = useState<DemandSpecialEventsDateEditing[]>([]);
  const { activeTab, changeTab } = useTabs();
  const {showSnackbar} = useSnackbar();

  useEffect(() => {
    try {
      const _fields = { ...fields };
      const details = demand.vdl_detailsdelademande;

      _fields.vdl_nomdelevenement.value = details.vdl_nomdelevenement ?? "";
      _fields.vdl_naturedelevenement.value =
        details.vdl_naturedelevenement ?? [];

      _fields.vdl_siautreprecisez.value = details.vdl_siautreprecisez ?? "";

      _fields.vdl_descriptiondelevenement.value =
        details.vdl_descriptiondelevenement ?? "";
      _fields.vdl_rayonnementdelevenement.value =
        details.vdl_rayonnementdelevenement ?? [];
      _fields.vdl_nouvelledemande.value = details.vdl_nouvelledemande ?? false;
      _fields.vdl_demanderecurrente.value =
        details.vdl_demanderecurrente ?? false;
      _fields.vdl_sireccurentdatedeladerniereedition.value = validateDateString(
        details.vdl_sireccurentdatedeladerniereedition
      );
      _fields.vdl_nombredeparticipantsetdevisiteursattendus.value =
        details.vdl_nombredeparticipantsetdevisiteursattendus;
      _fields.vdl_clienteleviseeparlevenement.value =
        details.vdl_clienteleviseeparlevenement ?? [];
      _fields.vdl_sispecifiqueprecisez.value =
        details.vdl_sispecifiqueprecisez ?? "";
      _fields.vdl_engagementloisetreglement.value =
        details.vdl_engagementloisetreglement ?? false;
      _fields.vdl_engagementequiteetinclusion.value =
        details.vdl_engagementequiteetinclusion ?? false;
      _fields.vdl_engagementdeveloppementdurable.value =
        details.vdl_engagementdeveloppementdurable ?? false;
      _fields.vdl_responsabilitedumaterielprete.value =
        details.vdl_responsabilitedumaterielprete ?? false;
      _fields.vdl_engagementhabitudessaines.value =
        details.vdl_engagementhabitudessaines ?? false;

      setFields(_fields);
    } catch (e) {
      setError("Erreur dans la récupération des champs de la demande");
    }
  }, []);

  const onSubmit = async () => {
    const errors = handleSimpleFormSubmit(fields, setFields);

    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      return;
    }

    const _demand: DemandSpecialEventPatch = {
      title: fieldsGeneral.title.value,
      vdl_contactprincipal: fieldsGeneral.vdl_contactprincipal.value,
      type: demand.type,
      vdl_detailsdelademande: {
        vdl_lieudelevenement: specialEventFields.vdl_lieudelevenement.value,
        vdl_precisionsurlelieu: specialEventFields.vdl_precisionsurlelieu.value,
        vdl_siautreprecisezlieu:
          specialEventFields.vdl_siautreprecisezlieu.value,
        vdl_encasdintemperieslevenementest:
          specialEventFields.vdl_encasdintemperieslevenementest.value,
        vdl_siremisle: specialEventFields.vdl_siremisle.value?.toISOString(),

        vdl_nomdelevenement: fields.vdl_nomdelevenement.value,
        vdl_naturedelevenement: fields.vdl_naturedelevenement.value,
        vdl_siautreprecisez: fields.vdl_siautreprecisez.value,
        vdl_descriptiondelevenement: fields.vdl_descriptiondelevenement.value,
        vdl_rayonnementdelevenement: fields.vdl_rayonnementdelevenement.value,
        vdl_nouvelledemande: fields.vdl_nouvelledemande.value,
        vdl_demanderecurrente: fields.vdl_demanderecurrente.value,

        vdl_sireccurentdatedeladerniereedition:
          fields.vdl_sireccurentdatedeladerniereedition.value?.toISOString(),

        dates: [],

        vdl_nombredeparticipantsetdevisiteursattendus:
          fields.vdl_nombredeparticipantsetdevisiteursattendus.value,
        vdl_clienteleviseeparlevenement:
          fields.vdl_clienteleviseeparlevenement.value,
        vdl_sispecifiqueprecisez: fields.vdl_sispecifiqueprecisez.value,

        vdl_engagementloisetreglement:
          fields.vdl_engagementloisetreglement.value,
        vdl_engagementequiteetinclusion:
          fields.vdl_engagementequiteetinclusion.value,
        vdl_engagementdeveloppementdurable:
          fields.vdl_engagementdeveloppementdurable.value,
        vdl_responsabilitedumaterielprete:
          fields.vdl_responsabilitedumaterielprete.value,
        vdl_engagementhabitudessaines:
          fields.vdl_engagementhabitudessaines.value,
      },
    };

    const datesUpdate: DemandSpecialEventsDatePatch[] = [];

    let validDatesCount = 0;

    dates.forEach((date) => {
      if ("vdl_datesid" in date) {
        if ("delete" in date) {
          datesUpdate.push({
            vdl_datesid: date.vdl_datesid,
            delete: true,
          });
        } else {
          validDatesCount++;
        }
      } else {
        validDatesCount++;
        datesUpdate.push({
          ...date,
        });
      }
    });

    if(validDatesCount === 0) {
      showSnackbar("Veuillez entrer au moins une date avant d'enregistrer la demande", "error")
      return;
    }

    _demand.vdl_detailsdelademande.dates = datesUpdate;

    if (demand.statuscode === "Inutilisée") {
      _demand.vdl_detailsdelademande.statuscode = "En création";
    }

    const { status, error } = await updateDemand(demand.incidentid, _demand);
    if (status === "success") {
      onUpdateSuccess();
    } else {
      onUpdateFailure(error);
    }
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_lienhautduformulaireportail}
    >
      <SpecialEvents
        fields={specialEventFields}
        setFields={setSpecialEventFields}
        demandDetails={demand.vdl_detailsdelademande}
        dates={dates}
        setDates={setDates}
        setError={setError}
      />
      <SimpleFields
        fields={fields}
        setFields={setFields}
        sections={editDemandSpecialEventSections}
        sectionsBackground="background.paper"
      />
    </EditDemandLayout>
  );
};

export default SpecialEvent;
