import { FormLabel, Typography } from "@mui/material";
import {
  DropDownChoice,
  DropDownOptionSetInput,
  MultilineInput,
  MultipleFilesInput,
  TextInput,
} from "../../../types";
import activityDisplayNames from "../displayNames";

export type NewActivityFields = {
  vdl_typedinformation: DropDownOptionSetInput;
  subject: TextInput;
  description: MultilineInput;
  files: MultipleFilesInput;
};

const vdl_typedinformationChoices: DropDownChoice[] = [
  {
    value: "Information générale",
  },
  {
    value: "Modification de demande",
  },
  {
    value: "Information supplémentaire nécessaire",
  },
  {
    value: "Annulation de la demande",
  },
  {
    value: "Autres",
  },
];

const AttachmentLabel = ({ label }: { label: string }) => {
  return (
    <Typography component="label" variant="h5" color="primary">
      {label}
    </Typography>
  );
};

const newActivityFields: NewActivityFields = {
  vdl_typedinformation: {
    type: "optionset",
    error: "",
    value: undefined,
    choices: vdl_typedinformationChoices,
    required: true,
    fillLine: true,
    label: activityDisplayNames.vdl_typedinformation,
  },
  subject: {
    type: "text",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    label: activityDisplayNames.subject,
  },
  description: {
    type: "multiline",
    error: "",
    value: "",
    required: true,
    rows: {
      min: 4,
      max: 10,
    },
    maxLength: 2000,
    helperText: "2 000 caractères maximum",
    fillLine: true,
    label: activityDisplayNames.description,
  },
  files: {
    type: "multiplefiles",
    error: "",
    value: [],
    required: false,
    maxLength: 5,
    fillLine: true,
    helperText:
      "Votre fichier doit être en format GIF, JPEG, JPG, PDF ou PNG et doit avoir une taille maximale de 100 Mo",
    accept: [".gif", ".png", ".jpg", ".PNG", ".jpeg", ".pdf"],
    labelComponent: AttachmentLabel,
    label: "Pièces jointes",
  },
};

export { newActivityFields };
