import React, { useState } from "react";
import {
  DemandPermitPatch,
  DemandPermitRow,
} from "../../../../data/Demand/models";
import { NewDemandFields } from "../../../../data/Demand/forms/dialog";
import { updateDemand } from "../../../../data/Demand/requests";
import EditDemandLayout from "../EditDemandLayout";
import SimpleFields from "../../../form/SimpleForm/SimpleFields";
import handleSimpleFormSubmit from "../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../hooks/useTabs";
import {
  EditDemandPermitFields,
  editDemandPermitFields,
  editDemandPermitSectionsShooting,
  editDemandPermitSectionsSpecialEvent,
} from "../../../../data/Demand/forms/permit";
import buildForm from "../../../form/SimpleForm/buildForm";

type Props = {
  demand: DemandPermitRow;
  isNewDemand: boolean;
  fieldsGeneral: NewDemandFields;
  onUpdateSuccess: () => void;
  onUpdateFailure: (error?: string) => void;
  setFieldsGeneral: React.Dispatch<React.SetStateAction<NewDemandFields>>;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const Permit = ({
  demand,
  isNewDemand,
  fieldsGeneral,
  setFieldsGeneral,
  onUpdateSuccess,
  onUpdateFailure,
  setError,
}: Props) => {
  const details = demand.vdl_detailsdelademande;
  const [fields, setFields] = useState<EditDemandPermitFields>(
    buildForm(editDemandPermitFields, {
      //Tarification d’accès à un site municipal
      vdl_tarificationdaccesaunsitemunicipal:
        details.vdl_tarificationdaccesaunsitemunicipal ?? false,
      vdl_enfant: details.vdl_enfant,
      vdl_adultes: details.vdl_adultes,
      vdl_autres1: details.vdl_autres1 ?? false,
      vdl_siautreprecisez: details.vdl_siautreprecisez ?? "",
      vdl_revenusdetarificationremisa:
        details.vdl_revenusdetarificationremisa ?? "",

      //Diffusion sonore
      vdl_diffusionsonore: details.vdl_diffusionsonore ?? false,
      vdl_utilisationdunsystemedesonorisationamplifie:
        details.vdl_utilisationdunsystemedesonorisationamplifie ?? false,
      vdl_autreappareilsusceptibledenuireauvoisinage:
        details.vdl_autreappareilsusceptibledenuireauvoisinage ?? false,
      vdl_precisez8: details.vdl_precisez8 ?? "",

      //Éclairage
      vdl_eclairage: details.vdl_eclairage ?? false,
      vdl_utilisationdunsystemdeclairagenuisance:
        details.vdl_utilisationdunsystemdeclairagenuisance ?? false,
      vdl_precisez7: details.vdl_precisez7 ?? "",

      //Affichage temporaire
      vdl_affichagetemporairesignalisationpavoisement:
        details.vdl_affichagetemporairesignalisationpavoisement ?? false,
      vdl_panneauxdesignalisation: details.vdl_panneauxdesignalisation ?? false,
      vdl_precisezdimensionetmateriaux:
        details.vdl_precisezdimensionetmateriaux ?? "",
      vdl_bannieres: details.vdl_bannieres ?? false,
      vdl_precisezdimensionetsupport:
        details.vdl_precisezdimensionetsupport ?? "",
      vdl_fanions: details.vdl_fanions ?? false,
      vdl_autres2: details.vdl_autres2 ?? false,
      vdl_precisez1: details.vdl_precisez1 ?? "",

      //Installation de structure temporaire
      vdl_installationdestructuretemporaire:
        details.vdl_installationdestructuretemporaire ?? false,
      vdl_chapiteaumarquise: details.vdl_chapiteaumarquise ?? false,
      vdl_preciseznombreetdimension:
        details.vdl_preciseznombreetdimension ?? "",
      vdl_structuresgonflables: details.vdl_structuresgonflables ?? false,
      vdl_tyrolienne: details.vdl_tyrolienne ?? false,
      vdl_scenemobile: details.vdl_scenemobile ?? false,
      vdl_autres3: details.vdl_autres3 ?? false,
      vdl_precisez2: details.vdl_precisez2 ?? "",
      vdl_equipementconformeauxnormesetreglements:
        details.vdl_equipementconformeauxnormesetreglements ?? false,
      vdl_installateuretoperateursontqualifies:
        details.vdl_installateuretoperateursontqualifies ?? false,
      vdl_nomdufournisseur: details.vdl_nomdufournisseur ?? "",
      vdl_requerantassurelaresponsabilitecivile:
        details.vdl_requerantassurelaresponsabilitecivile ?? false,

      //Consommation de boissons alcoolisées
      vdl_consommationdeboissonsalcooliques:
        details.vdl_consommationdeboissonsalcooliques ?? false,

      //Vente de boissons alcoolisées
      vdl_ventedeboissonsalcooliques:
        details.vdl_ventedeboissonsalcooliques ?? false,
      vdl_venteestgereeparledemandeur1:
        details.vdl_venteestgereeparledemandeur1 ?? false,
      vdl_ventegereeparuntiers1: details.vdl_ventegereeparuntiers1 ?? false,
      vdl_precisez4: details.vdl_precisez4 ?? "",
      vdl_profitsdelaventeremis1: details.vdl_profitsdelaventeremis1 ?? "",

      //Vente d’aliments et breuvages
      vdl_ventealimentsetboissonsexcomptoirkiosque:
        details.vdl_ventealimentsetboissonsexcomptoirkiosque ?? false,
      vdl_venteestgereeparledemandeur2:
        details.vdl_venteestgereeparledemandeur2 ?? false,
      vdl_ventegereeparuntiers2: details.vdl_ventegereeparuntiers2 ?? false,
      vdl_precisez6: details.vdl_precisez6 ?? "",
      vdl_nombredepointsdeventesurlesite1:
        details.vdl_nombredepointsdeventesurlesite1,
      vdl_profitsdelaventeremis2: details.vdl_profitsdelaventeremis2 ?? "",

      //Vente de produits et/ou services
      vdl_ventedeproduitsservicesobjetsexpovente:
        details.vdl_ventedeproduitsservicesobjetsexpovente ?? false,
      vdl_venteestgereeparledemandeur3:
        details.vdl_venteestgereeparledemandeur3 ?? false,
      vdl_ventegereeparuntiers3: details.vdl_ventegereeparuntiers3 ?? false,
      vdl_precisez5: details.vdl_precisez5 ?? "",
      vdl_nombredepointsdeventesurlesite2:
        details.vdl_nombredepointsdeventesurlesite2,
      vdl_profitsdelaventeremisa3: details.vdl_profitsdelaventeremisa3 ?? "",

      //Sollicitation porte-à-porte
      vdl_sollicitationporteaporte:
        details.vdl_sollicitationporteaporte ?? false,
      vdl_collectedebouteille: details.vdl_collectedebouteille ?? false,
      vdl_ventedecalendrier: details.vdl_ventedecalendrier ?? false,
      vdl_autre: details.vdl_autre ?? false,
      vdl_precisez3: details.vdl_precisez3 ?? "",
      vdl_revenusdelactiviteremisa: details.vdl_revenusdelactiviteremisa ?? "",

      //Camion-restaurant
      vdl_camionrestaurant: details.vdl_camionrestaurant ?? false,
    })
  );
  const { activeTab, changeTab } = useTabs();

  const onSubmit = async () => {
    const errors = handleSimpleFormSubmit(fields, setFields);

    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      const scrollTo = fields[errors[errors.length - 1]].fieldRef;
      if (scrollTo) {
        scrollTo.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
      return;
    }

    const _demand: DemandPermitPatch = {
      title: fieldsGeneral.title.value,
      vdl_contactprincipal: fieldsGeneral.vdl_contactprincipal.value,
      type: demand.type,
      vdl_detailsdelademande: {
        vdl_tarificationdaccesaunsitemunicipal:
          fields.vdl_tarificationdaccesaunsitemunicipal.value,
        vdl_enfant: fields.vdl_enfant.value,
        vdl_adultes: fields.vdl_adultes.value,
        vdl_autres1: fields.vdl_autres1.value,
        vdl_siautreprecisez: fields.vdl_siautreprecisez.value,
        vdl_revenusdetarificationremisa:
          fields.vdl_revenusdetarificationremisa.value,

        vdl_diffusionsonore: fields.vdl_diffusionsonore.value,
        vdl_utilisationdunsystemedesonorisationamplifie:
          fields.vdl_utilisationdunsystemedesonorisationamplifie.value,
        vdl_autreappareilsusceptibledenuireauvoisinage:
          fields.vdl_autreappareilsusceptibledenuireauvoisinage.value,
        vdl_precisez8: fields.vdl_precisez8.value,

        vdl_eclairage: fields.vdl_eclairage.value,
        vdl_utilisationdunsystemdeclairagenuisance:
          fields.vdl_utilisationdunsystemdeclairagenuisance.value,
        vdl_precisez7: fields.vdl_precisez7.value,

        vdl_affichagetemporairesignalisationpavoisement:
          fields.vdl_affichagetemporairesignalisationpavoisement.value,
        vdl_panneauxdesignalisation: fields.vdl_panneauxdesignalisation.value,
        vdl_precisezdimensionetmateriaux:
          fields.vdl_precisezdimensionetmateriaux.value,
        vdl_bannieres: fields.vdl_bannieres.value,
        vdl_precisezdimensionetsupport:
          fields.vdl_precisezdimensionetsupport.value,
        vdl_fanions: fields.vdl_fanions.value,
        vdl_autres2: fields.vdl_autres2.value,
        vdl_precisez1: fields.vdl_precisez1.value,

        vdl_installationdestructuretemporaire:
          fields.vdl_installationdestructuretemporaire.value,
        vdl_chapiteaumarquise: fields.vdl_chapiteaumarquise.value,
        vdl_preciseznombreetdimension:
          fields.vdl_preciseznombreetdimension.value,
        vdl_structuresgonflables: fields.vdl_structuresgonflables.value,
        vdl_tyrolienne: fields.vdl_tyrolienne.value,
        vdl_scenemobile: fields.vdl_scenemobile.value,
        vdl_autres3: fields.vdl_autres3.value,
        vdl_precisez2: fields.vdl_precisez2.value,
        vdl_equipementconformeauxnormesetreglements:
          fields.vdl_equipementconformeauxnormesetreglements.value,
        vdl_installateuretoperateursontqualifies:
          fields.vdl_installateuretoperateursontqualifies.value,
        vdl_nomdufournisseur: fields.vdl_nomdufournisseur.value,
        vdl_requerantassurelaresponsabilitecivile:
          fields.vdl_requerantassurelaresponsabilitecivile.value,

        vdl_consommationdeboissonsalcooliques:
          fields.vdl_consommationdeboissonsalcooliques.value,

        vdl_ventedeboissonsalcooliques:
          fields.vdl_ventedeboissonsalcooliques.value,
        vdl_venteestgereeparledemandeur1:
          fields.vdl_venteestgereeparledemandeur1.value,
        vdl_ventegereeparuntiers1: fields.vdl_ventegereeparuntiers1.value,
        vdl_precisez4: fields.vdl_precisez4.value,
        vdl_profitsdelaventeremis1: fields.vdl_profitsdelaventeremis1.value,

        vdl_ventealimentsetboissonsexcomptoirkiosque:
          fields.vdl_ventealimentsetboissonsexcomptoirkiosque.value,
        vdl_venteestgereeparledemandeur2:
          fields.vdl_venteestgereeparledemandeur2.value,
        vdl_ventegereeparuntiers2: fields.vdl_ventegereeparuntiers2.value,
        vdl_precisez6: fields.vdl_precisez6.value,
        vdl_nombredepointsdeventesurlesite1:
          fields.vdl_nombredepointsdeventesurlesite1.value,
        vdl_profitsdelaventeremis2: fields.vdl_profitsdelaventeremis2.value,

        vdl_ventedeproduitsservicesobjetsexpovente:
          fields.vdl_ventedeproduitsservicesobjetsexpovente.value,
        vdl_venteestgereeparledemandeur3:
          fields.vdl_venteestgereeparledemandeur3.value,
        vdl_ventegereeparuntiers3: fields.vdl_ventegereeparuntiers3.value,
        vdl_precisez5: fields.vdl_precisez5.value,
        vdl_nombredepointsdeventesurlesite2:
          fields.vdl_nombredepointsdeventesurlesite2.value,
        vdl_profitsdelaventeremisa3: fields.vdl_profitsdelaventeremisa3.value,

        vdl_sollicitationporteaporte: fields.vdl_sollicitationporteaporte.value,
        vdl_collectedebouteille: fields.vdl_collectedebouteille.value,
        vdl_ventedecalendrier: fields.vdl_ventedecalendrier.value,
        vdl_autre: fields.vdl_autre.value,
        vdl_precisez3: fields.vdl_precisez3.value,
        vdl_revenusdelactiviteremisa: fields.vdl_revenusdelactiviteremisa.value,

        vdl_camionrestaurant: fields.vdl_camionrestaurant.value,
      },
    };

    if (demand.statuscode === "Inutilisée") {
      _demand.vdl_detailsdelademande.statuscode = "En création";
    }

    const { status, error } = await updateDemand(demand.incidentid, _demand);
    if (status === "success") {
      onUpdateSuccess();
    } else {
      onUpdateFailure(error);
    }
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_lienhautduformulaireportail}
    >
      <SimpleFields
        updateRefs
        fields={fields}
        setFields={setFields}
        sections={
          demand.vdl_detailsdelademande.vdl_typedactivite === "Évènement"
            ? editDemandPermitSectionsSpecialEvent
            : editDemandPermitSectionsShooting
        }
        sectionsBackground="common.white"
        columns={{ count: 2, minWidth: 275 }}
      />
    </EditDemandLayout>
  );
};

export default Permit;
