import React from "react";
import { EmailInput } from "../../../types";
import { TextField } from "@mui/material";

type Props = {
  field: EmailInput;
  fieldName: string;
  handleChange: (field: EmailInput) => void;
  disabled: boolean;
};

const FormEmailInput = ({
  field,
  fieldName,
  handleChange,
  disabled,
}: Props) => {
  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    let _field = { ...field };
    _field.value = value;
    handleChange(_field);
  }

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const isValidEmail = (email: string) => {
    return emailRegex.test(email);
  };

  function handleBlur(
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) {
    let _field = { ...field };
    _field.error = "";
    if (_field.required && (!_field.value || _field.value === "")) {
      _field.error = "Ce champ est requis";
    }
    if (_field.value && _field.value !== "") {
      if (!isValidEmail(_field.value)) {
        _field.error = "Veuillez entrer une adresse courriel valide";
      }
    }
    handleChange(_field);
  }

  return (
    <TextField
      disabled={disabled}
      fullWidth
      label={field.label}
      variant="outlined"
      name={fieldName}
      value={field.value}
      onChange={handleInputChange}
      onBlur={handleBlur}
      required={field.required}
      error={!(field.error == "")}
      helperText={field.error ? field.error : field.helperText}
    />
  );
};

export default FormEmailInput;
