import React from "react";
import { PhoneInput, TextInput } from "../../../types";
import { TextField } from "@mui/material";

type Props = {
  field: PhoneInput;
  fieldName: string;
  handleChange: (field: PhoneInput) => void;
  disabled: boolean;
};

const FormPhoneInput = ({
  field,
  fieldName,
  handleChange,
  disabled,
}: Props) => {
  
  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    const digitsOnly = value.replace(/\D+/g, "");
    let formattedValue = "";
    const mainDigits = digitsOnly.substring(0, 10);
    const extensionDigits = digitsOnly.substring(10, 14);
    if (mainDigits.length <= 3) {
      formattedValue = mainDigits;
    } else if (mainDigits.length <= 6) {
      formattedValue = `${mainDigits.slice(0, 3)} ${mainDigits.slice(3)}`;
    } else {
      formattedValue = `${mainDigits.slice(0, 3)} ${mainDigits.slice(
        3,
        6
      )}-${mainDigits.slice(6)}`;
    }
    if (extensionDigits) {
      formattedValue += `x${extensionDigits}`;
    }

    let _field = { ...field };
    _field.value = formattedValue;
    handleChange(_field);
  }

  const phoneRegex = /^(\d{3} \d{3}-\d{4})(x\d{1,4})?$/;
  const isValidPhoneNumber = (phoneNumber: string) => {
    return phoneRegex.test(phoneNumber);
  };

  function handleBlur(
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) {
    let _field = { ...field };
    _field.error = "";
    if (_field.required && (!_field.value || _field.value === "")) {
      _field.error = "Ce champ est requis";
    }
    if (_field.value && _field.value !== "") {
      if (!isValidPhoneNumber(_field.value)) {
        _field.error = "Veuillez entrer au minimum 10 chiffres";
      }
    }
    handleChange(_field);
  }

  return (
    <TextField
      disabled={disabled}
      fullWidth
      label={field.label}
      variant="outlined"
      name={fieldName}
      value={field.value}
      onChange={handleInputChange}
      onBlur={handleBlur}
      required={field.required}
      error={!(field.error == "")}
      helperText={field.error ? field.error : field.helperText}
    />
  );
};

export default FormPhoneInput;
