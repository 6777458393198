import React, { useEffect } from "react";
import "./App.css";
import ThemeProvider from "./theme/theme";
import AuthenticatedLayout from "./components/layouts/AppLayouts/AuthenticatedLayout";
import { BrowserRouter, useLocation, useParams } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import {
  MsalAuthenticationTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import {
  IPublicClientApplication,
  InteractionStatus,
  InteractionType,
} from "@azure/msal-browser";
import AppLoading from "./components/common/loading/AppLoading";
import { UserProvider } from "./providers/UserProvider";
import MsalProvider from "./providers/MsalProvider";
import NewAccount from "./pages/NewAccount.page";
import { SnackbarProvider } from "./providers/SnackbarProvider";
import { signUpRequestBuilder } from "./auth/authConfig";
import AuthenticationError from "./components/common/auth/AuthenticationError";
import { Location } from "@remix-run/router";
import TabsProvider from "./providers/TabsProvider";
import ConfirmationProvider from "./providers/ConfirmationProvider";

function App() {
  const location = useLocation();
  return (
    <MsalProvider>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={signUpRequestBuilder(inviteEmail(location))}
        loadingComponent={AppLoading}
        errorComponent={AuthenticationError}
      >
        <ThemeProvider>
          <ConfirmationProvider>
            <SnackbarProvider>
              <TabsProvider>
                <UserProvider noInfoComponent={<NewAccount />}>
                  <AppRoutes />
                </UserProvider>
              </TabsProvider>
            </SnackbarProvider>
          </ConfirmationProvider>
        </ThemeProvider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

export default App;

const inviteEmail = (location: Location<any>): string | undefined => {
  const isInvite = location.pathname.startsWith("/invite");
  const params = new URLSearchParams(location.search);
  const emailParam = params.get("emailaddress");
  const emailaddress = emailParam === null ? undefined : emailParam;

  if (isInvite && emailaddress) return emailaddress;
  return undefined;
};
