import React from "react";
import { CurrencyInput } from "../../../types";
import { InputAdornment, TextField } from "@mui/material";
import { isNumber } from "../../../utils/isNumber";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

type Props = {
  field: CurrencyInput;
  fieldName: string;
  handleChange: (field: CurrencyInput) => void;
  disabled: boolean;
};

const FormCurrencyInput = ({
  field,
  fieldName,
  handleChange,
  disabled,
}: Props) => {
  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    let { value } = event.target;
    let _field = { ...field };

    const lastIndex = value.lastIndexOf(",");
    if (lastIndex !== -1) {
      value = value.slice(0, lastIndex) + "." + value.slice(lastIndex + 1);
    }

    value = value.replace(/\s/g, "");
    const dotSplit = value.split(".");
    if (value.trim() === "") {
      _field.error = "";
      _field.value = "";
    } else if (dotSplit.length <= 2) {
      if (value.endsWith(".")) {
        if (dotSplit[0].length === 0) {
          _field.value = "0,";
        } else {
          _field.value =
            parseInt(value.slice(0, -1), 10).toLocaleString("fr-CA") + ",";
        }
      } else if (dotSplit.length === 1 || dotSplit[1].length < 3) {
        if (value === "0.0") {
          _field.value = "0,0";
        } else if (isNumber(value)) {
          const number = parseFloat(value);
          if (field.max && number > field.max) {
            _field.error = `Veuillez entrer un chiffre plus petit que ${
              field.max + 1
            }`;
          } else if (field.min && number < field.min) {
            _field.error = `Veuillez entrer un chiffre plus grand que ${
              field.min - 1
            }`;
          } else {
            _field.error = "";
            _field.value = number.toLocaleString("fr-CA");
          }
        }
      }
    }

    handleChange(_field);
  }

  function handleBlur(
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) {
    let _field = { ...field };
    _field.error = "";
    if (field.required && !field.value) {
      _field.error = "Ce champ est requis";
    }
    handleChange(_field);
  }

  return (
    <TextField
      disabled={disabled}
      type="text"
      fullWidth
      label={field.label}
      variant="outlined"
      name={fieldName}
      value={field.value ? field.value : ""}
      onChange={handleInputChange}
      required={field.required}
      error={!(field.error === "")}
      helperText={field.error ? field.error : field.helperText}
      onBlur={handleBlur}
      InputLabelProps={{
        required: field.required,
      }}

    />
  );
};

export default FormCurrencyInput;
