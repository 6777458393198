import { FormSection, MultilineInput, TextInput } from "../../../types";
import { demandDisplayNames } from "../displayNames";

export type EditDemandInfoRequest = {
  vdl_sujetdelademande: TextInput;
  vdl_descriptiondelademande: MultilineInput;
};

const editDemandInfoRequestFields: EditDemandInfoRequest = {
  vdl_sujetdelademande: {
    type: "text",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    maxLength: 500,
    label: demandDisplayNames.title,
  },
  vdl_descriptiondelademande: {
    type: "multiline",
    error: "",
    value: "",
    rows: {
      min: 4,
      max: 4,
    },
    maxLength: 3000,
    required: true,
    fillLine: true,
    label: demandDisplayNames.vdl_detailsdelademande,
  },
};

export { editDemandInfoRequestFields };
