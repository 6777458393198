import { DropDownChoice } from "../../../types";

const vdl_orgTypeChoices: DropDownChoice[] = [
  { value: "Services municipaux" },
  { value: "Institutions lavalloises" },
  { value: "coopérative à but non lucratif" },
  { value: "Regroupement de partenaires lavallois" },
  { value: "Organisme" },
  { value: "Entente particulière" },
];

const vdl_secteurChoices: DropDownChoice[] = [
  { value: "Sec 1 (Duvernay, Saint-François, Saint-Vincent-de-Paul)" },
  { value: "Sec 2 (Laval-des-Rapides, Pont-Viau, Renaud-Coursol)" },
  { value: "Sec 3 (Chomedey)" },
  {
    value:
      "Sec 4 (Fabreville-Ouest, Laval-Ouest, Laval-sur-le-Lac, Les Îles-Laal, Sainte-Dorothée)",
  },
  { value: "Sec 5 (Fabreville-Est, Sainte-Rose)" },
  { value: "Sec 6 (Auteuil, Vimont)" },
];

export { vdl_orgTypeChoices, vdl_secteurChoices };
