import { errorHandler } from "../../components/common/error/errorHandler";
import { GetResponse, PostResponse } from "../common/types";
import fetchWithMsal from "../fetchWithMsal";
import { ActivityBaseRow, ActivityPost, ActivityRow } from "./models";

export const getActivities = async (
  demandid: string
): Promise<GetResponse<ActivityRow[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/get_history/${demandid}`;
    const result = await fetchWithMsal("GET", endpoint);
    if (result.StatusCode === 404)
      return { status: "success", data: undefined };
    if (!result.Value) throw new Error("Erreur dans la requête");
    console.log(result);
    const data = result.Value;
    const tempData: ActivityRow[] = [];
    data.forEach((event: any) => {
      const historyBase: ActivityBaseRow = {
        createdon: event.createdon,
        subject: event.subject,
        activityid: event.activityid,
      };
      switch (event.type) {
        case "message":
          tempData.push({
            ...historyBase,
            type: "message",
            vdl_typedinformation: event.vdl_typedinformation,
            vdl_viaportail: event.vdl_viaportail ?? false,
            description: event.description,
            vdl_destinataire: event.vdl_destinataire
              ? {
                  value: event.vdl_destinataire.Id,
                  label: event.vdl_destinataire.Name,
                }
              : undefined,
            vdl_expediteur: event.vdl_expediteur
              ? {
                  value: event.vdl_expediteur.Id,
                  label: event.vdl_expediteur.Name,
                }
              : undefined,
          });
          break;
        case "statut":
          tempData.push({
            ...historyBase,
            type: "statut",
            vdl_ancienneraisondestatut: event.vdl_ancienneraisondestatut,
            vdl_nouvelleraisondestatut: event.vdl_nouvelleraisondestatut,
          });
          break;
      }
    });

    return { status: "success", data: tempData };
  } catch (e) {
    return errorHandler(e, "Erreur dans la récupération de l'historique");
  }
};

export const createActivity = async (
  demandid: string,
  messageData: ActivityPost
): Promise<PostResponse> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/send_message/${demandid}`;
    const result = await fetchWithMsal("POST", endpoint, messageData);
    console.log(result);
    if (result.StatusCode === 200) {
      return { status: "success", data: result.Value };
    } else {
      throw new Error("Erreur dans l'exécution");
    }
  } catch (e) {
    return errorHandler(e, "Erreur dans l'ajout de lu message");
  }
};
