import { errorHandler } from "../../components/common/error/errorHandler";
import { GetResponse } from "../common/types";
import fetchWithMsal from "../fetchWithMsal";
import { SiteBaseRow, SiteRow } from "./models.ts";

export const getSites = async (): Promise<GetResponse<SiteRow[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/get_sites`;
    const result = await fetchWithMsal("GET", endpoint);
    if (result.StatusCode === 404)
      return { status: "success", data: undefined };
    if (!result.Value) throw new Error("Erreur dans la requête");
    const data = result.Value;
    const tempData: SiteRow[] = [];
    data.forEach((site: any) => {
      const sous_plateaux: SiteBaseRow[] = [];

      if (site.plateaux) {
        site.plateaux.forEach((plateau: any) => {
          sous_plateaux.push({
            vdl_name: plateau.vdl_name,
            vdl_plateauxid: plateau.vdl_plateauxid,
          });
        });

        tempData.push({
          vdl_name: site.vdl_name,
          vdl_plateauxid: site.vdl_plateauxid,
          plateaux: sous_plateaux,
        });
      }
    });

    return { status: "success", data: tempData };
  } catch (e) {
    return errorHandler(e, "Erreur dans la récupération des sites");
  }
};
