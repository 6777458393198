import React from "react";
import {
  DropDownMultipleOptionSetInput,
  DropDownOptionSetInput,
} from "../../../types";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import clone from "just-clone";

type Props = {
  field: DropDownMultipleOptionSetInput;
  fieldName: string;
  handleChange: (field: DropDownMultipleOptionSetInput) => void;
  disabled: boolean;
};

const FormDropDownMultipleOptionSetInput = ({
  field,
  fieldName,
  handleChange,
  disabled,
}: Props) => {
  const handleSelectChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    let _field = clone(field);
    if (field.required && value !== "") {
      _field.error = "";
    }
    _field.value = typeof value === "string" ? value.split(",") : value;

    handleChange(_field);
  };

  function handleBlur(
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) {
    let _field = { ...field };
    _field.error = "";
    if (field.required && field.value.length === 0) {
      _field.error = "Ce champ est requis";
    }
    handleChange(_field);
  }

  return (
    <FormControl
      variant="outlined"
      fullWidth
      required={field.required}
      disabled={disabled}
      error={!(field.error == "")}
    >
      <InputLabel id={fieldName}>{field.label}</InputLabel>
      <Select
        multiple
        labelId={fieldName}
        name={fieldName}
        value={field.value}
        onChange={handleSelectChange}
        label={field.label}
        onBlur={handleBlur}
        renderValue={(selected) => selected.join(', ')}
      >
        {field.choices.map((choice, idx) => (
          <MenuItem key={idx} value={choice.value}>
            <Checkbox checked={field.value.indexOf(choice.value) > -1} />
            <ListItemText primary={choice.value} />
          </MenuItem>
        ))}
      </Select>
      {field.error || field.helperText ? (
        <FormHelperText>
          {field.error ? field.error : field.helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default FormDropDownMultipleOptionSetInput;
