import React from "react";
import { FormField, FormFields } from "../../../types";

const handleSimpleFormChange = <T extends FormFields>(
  key: string,
  field: FormField,
  fields: T,
  setFields: React.Dispatch<React.SetStateAction<T>>
) => {
  let updatedFields: FormFields | undefined;
  if (field.onChangeEvent) {
    updatedFields = field.onChangeEvent({ ...fields, [key]: field });
  }
  setFields((prevState) => ({
    ...prevState,
    ...updatedFields,
    [key]: field,
  }));
};

export default handleSimpleFormChange;
