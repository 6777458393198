import React, { useEffect, useState } from "react";
import {
  DemandStandardEquipmentPatch,
  DemandStandardEquipmentRow,
} from "../../../../data/Demand/models";
import { NewDemandFields } from "../../../../data/Demand/forms/dialog";
import { updateDemand } from "../../../../data/Demand/requests";
import EditDemandLayout from "../EditDemandLayout";
import {
  EditDemandStandardEquipment,
  editDemandStandardEquipmentFields,
  editDemandStandardEquipmentLivraisonSection,
  editDemandStandardEquipmentRecuperationSection,
} from "../../../../data/Demand/forms/standardEquipment";
import { Box, styled } from "@mui/material";
import ProductsSection from "./Products/ProductsSection";
import { DemandProductRowWithError } from "../../../../data/Demand/types/standardEquipment";
import handleSimpleFormSubmit from "../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../hooks/useTabs";
import { validateDateString } from "../../../../data/common/validateDateString";
import SimpleFields from "../../../form/SimpleForm/SimpleFields";
import buildForm from "../../../form/SimpleForm/buildForm";

const CardsLayout = styled(Box)(({ theme }) => ({
  display: "grid",
  gap: "1rem",
  gridTemplateColumns: "1fr 1fr",
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "1fr",
  },
}));

type Props = {
  demand: DemandStandardEquipmentRow;
  isNewDemand: boolean;
  fieldsGeneral: NewDemandFields;
  onUpdateSuccess: () => void;
  onUpdateFailure: (error?: string) => void;
  setFieldsGeneral: React.Dispatch<React.SetStateAction<NewDemandFields>>;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
};
const StandardEquipment = ({
  demand,
  isNewDemand,
  fieldsGeneral,
  setFieldsGeneral,
  onUpdateSuccess,
  onUpdateFailure,
  setError,
}: Props) => {
  const details = demand.vdl_detailsdelademande;
  const [fields, setFields] = useState<EditDemandStandardEquipment>(
    buildForm(editDemandStandardEquipmentFields, {
      vdl_adressecompletelivraison: details.vdl_adressecompletelivraison
        ? {
            address: details.vdl_adressecompletelivraison,
          }
        : undefined,
      vdl_adressecompleterecuperation: details.vdl_adressecompleterecuperation
        ? {
            address: details.vdl_adressecompleterecuperation,
          }
        : undefined,
      vdl_datedelivraison: validateDateString(details.vdl_datedelivraison),
      vdl_datederecuperation: validateDateString(
        details.vdl_datederecuperation
      ),
      vdl_nomdubatimentlivraison: details.vdl_nomdubatimentlivraison ?? "",
      vdl_nomdubatimentrecuperation:
        details.vdl_nomdubatimentrecuperation ?? "",
      vdl_identiquealadressedelivraison:
        details.vdl_identiquealadressedelivraison ?? false,
      vdl_secteurdeladressedelivraison:
        details.vdl_secteurdeladressedelivraison,
      vdl_secteurdeladressederecuperation:
        details.vdl_secteurdeladressederecuperation,
      vdl_horaire: details.vdl_horaire,
      vdl_horairerecuperation: details.vdl_horairerecuperation,
      vdl_remarqueoucommentaire: details.vdl_remarqueoucommentaire ?? "",
      vdl_remarqueoucommentairerecuperation:
        details.vdl_remarqueoucommentairerecuperation ?? "",
    })
  );
  const [demandProducts, setDemandProducts] = useState<
    DemandProductRowWithError[]
  >([]);
  const { activeTab, changeTab } = useTabs();

  useEffect(() => {
    if (details.products.length > 0) {
      setDemandProducts(details.products);
    }
  }, []);

  const onSubmit = async () => {
    const errors = handleSimpleFormSubmit(fields, setFields);

    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      const scrollTo = fields[errors[errors.length - 1]].fieldRef;
      if (scrollTo) {
        scrollTo.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
      return;
    }

    const _demand: DemandStandardEquipmentPatch = {
      title: fieldsGeneral.title.value,
      vdl_contactprincipal: fieldsGeneral.vdl_contactprincipal.value,
      type: demand.type,
      vdl_detailsdelademande: {
        vdl_identiquealadressedelivraison:
          fields.vdl_identiquealadressedelivraison.value,
        vdl_adressecompletelivraison:
          fields.vdl_adressecompletelivraison.value?.address,
        vdl_adressecompleterecuperation:
          fields.vdl_adressecompleterecuperation.value?.address,
        vdl_nomdubatimentlivraison: fields.vdl_nomdubatimentlivraison.value,
        vdl_datedelivraison: fields.vdl_datedelivraison.value?.toISOString(),
        vdl_datederecuperation:
          fields.vdl_datederecuperation.value?.toISOString(),
        vdl_secteurdeladressedelivraison:
          fields.vdl_secteurdeladressedelivraison.value,
        vdl_remarqueoucommentaire: fields.vdl_remarqueoucommentaire.value,
        vdl_remarqueoucommentairerecuperation:
          fields.vdl_remarqueoucommentairerecuperation.value,
        vdl_horaire: fields.vdl_horaire.value,
        vdl_horairerecuperation: fields.vdl_horairerecuperation.value,
        products: [],
      },
    };

    const sameAddress = fields.vdl_identiquealadressedelivraison.value;

    if (
      fields.vdl_adressecompletelivraison.value &&
      fields.vdl_adressecompletelivraison.value.attributes?.X &&
      fields.vdl_adressecompletelivraison.value.attributes?.Y
    ) {
      if (
        demand.vdl_detailsdelademande.vdl_adressecompletelivraison !==
        fields.vdl_adressecompletelivraison.value.address
      ) {
        _demand.vdl_detailsdelademande.vdl_longitudelivraison =
          fields.vdl_adressecompletelivraison.value.attributes.X;
        _demand.vdl_detailsdelademande.vdl_latitudelivraison =
          fields.vdl_adressecompletelivraison.value.attributes.Y;
      }

      if (
        sameAddress &&
        demand.vdl_detailsdelademande.vdl_adressecompleterecuperation !==
          fields.vdl_adressecompletelivraison.value.address
      ) {
        _demand.vdl_detailsdelademande.vdl_adressecompleterecuperation =
          fields.vdl_adressecompletelivraison.value.address;
        _demand.vdl_detailsdelademande.vdl_longituderecuperation =
          fields.vdl_adressecompletelivraison.value.attributes.X;
        _demand.vdl_detailsdelademande.vdl_latituderecuperation =
          fields.vdl_adressecompletelivraison.value.attributes.Y;
      }
    }

    if (sameAddress) {
      _demand.vdl_detailsdelademande.vdl_nomdubatimentrecuperation =
        fields.vdl_nomdubatimentlivraison.value;
      _demand.vdl_detailsdelademande.vdl_secteurdeladressederecuperation =
        fields.vdl_secteurdeladressedelivraison.value;
    } else {
      _demand.vdl_detailsdelademande.vdl_secteurdeladressederecuperation =
        fields.vdl_secteurdeladressederecuperation.value;

      if (
        fields.vdl_adressecompleterecuperation.value &&
        demand.vdl_detailsdelademande.vdl_adressecompleterecuperation !==
          fields.vdl_adressecompleterecuperation.value.address &&
        fields.vdl_adressecompleterecuperation.value.attributes?.X &&
        fields.vdl_adressecompleterecuperation.value.attributes?.Y
      ) {
        _demand.vdl_detailsdelademande.vdl_longituderecuperation =
          fields.vdl_adressecompleterecuperation.value.attributes.X;
        _demand.vdl_detailsdelademande.vdl_latituderecuperation =
          fields.vdl_adressecompleterecuperation.value.attributes.Y;
      }
    }

    _demand.vdl_detailsdelademande.products = demandProducts.map((product) => ({
      vdl_produit: product.vdl_produit,
      vdl_quantite: product.vdl_quantite,
    }));

    if (demand.statuscode === "Inutilisée") {
      _demand.vdl_detailsdelademande.statuscode = "En création";
    }

    const { status, error } = await updateDemand(demand.incidentid, _demand);

    if (status === "success") {
      onUpdateSuccess();
    } else {
      onUpdateFailure(error);
    }
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_lienhautduformulaireportail}
    >
      <>
        <CardsLayout>
          <SimpleFields
            fields={fields}
            setFields={setFields}
            sections={[editDemandStandardEquipmentLivraisonSection]}
            sectionsBackground="background.paper"
            columns={{ count: 2, minWidth: 260 }}
          />
          <SimpleFields
            fields={fields}
            setFields={setFields}
            sections={[editDemandStandardEquipmentRecuperationSection]}
            sectionsBackground="background.paper"
            columns={{ count: 2, minWidth: 260 }}
          />
          <Box
            sx={{
              gridColumn: {
                lg: "span 2",
              },
            }}
            bgcolor="common.white"
            padding="16px"
          >
            <ProductsSection
              demandProducts={demandProducts}
              setDemandProducts={setDemandProducts}
              productTypes={
                demand.vdl_detailsdelademande.vdl_typedeproduit.value
              }
            />
          </Box>
        </CardsLayout>
      </>
    </EditDemandLayout>
  );
};

export default StandardEquipment;
