import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  useTheme,
  BoxProps,
} from "@mui/material";

type Props = {
  displayName: string;
  value: string;
  emptyPlaceHolder?: string;
  fontSize?: string;
  color?: string;
  disabled?: boolean;
  horizontal?: boolean;
  horizontalLabelWidthPercentage?: number
  boxProps?: BoxProps
};

const DisplayField = ({
  displayName,
  value,
  emptyPlaceHolder,
  fontSize,
  color,
  disabled,
  horizontal,
  horizontalLabelWidthPercentage,
  boxProps
}: Props) => {
  const theme = useTheme();
  return (
    <Box
      color={disabled ? theme.palette.text.disabled : color ? color : undefined}
      display={horizontal ? "flex" : undefined}
      alignItems={horizontal ? "center" : undefined}
      gap={horizontal ? "8px" : undefined}
      width={horizontal ? "100%" : undefined}
      {...boxProps}
    >
      <Typography
        fontWeight={"bold"}
        fontSize={fontSize && fontSize}
        color="inherit"
        width={horizontal && horizontalLabelWidthPercentage ? `${horizontalLabelWidthPercentage}%`: undefined}
        flexShrink="0"
        lineHeight={1.4}
      >
        {displayName ? displayName : "\u00A0"}
      </Typography>
      <Typography fontSize={fontSize && fontSize} color="inherit" lineHeight={1.3} whiteSpace="pre-line" sx={{wordBreak: "break-word"}}>
        {value ? value : emptyPlaceHolder ? emptyPlaceHolder : "\u00A0"}
      </Typography>
    </Box>
  );
};

export default DisplayField;
