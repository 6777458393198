import {
  CheckBoxInput,
  CommentInput,
  DateInput,
  DropDownChoice,
  DropDownMultipleOptionSetInput,
  DropDownOptionSetInput,
  FormFields,
  FormSection,
  MultilineInput,
  TextInput,
  TimeInput,
} from "../../../../../types";
import { specialEventDisplayNames } from "../../../displayNames";

export type EditDemandSpecialEventFields = {
  vdl_nomdelevenement: TextInput;
  vdl_naturedelevenement: DropDownMultipleOptionSetInput;
  vdl_siautreprecisez: TextInput;
  vdl_descriptiondelevenement: MultilineInput;
  vdl_rayonnementdelevenement: DropDownMultipleOptionSetInput;
  vdl_nouvelledemande: CheckBoxInput;
  vdl_demanderecurrente: CheckBoxInput;
  vdl_sireccurentdatedeladerniereedition: DateInput;
  vdl_nombredeparticipantsetdevisiteursattendus: DropDownOptionSetInput;
  vdl_clienteleviseeparlevenement: DropDownMultipleOptionSetInput;
  vdl_sispecifiqueprecisez: TextInput;

  vdl_engagementloisetreglement: CheckBoxInput;
  vdl_engagementequiteetinclusion: CheckBoxInput;
  vdl_engagementdeveloppementdurable: CheckBoxInput;
  vdl_responsabilitedumaterielprete: CheckBoxInput;
  vdl_engagementhabitudessaines: CheckBoxInput;
};

const vdl_naturedelevenementChoices: DropDownChoice[] = [
  {
    value: "Communautaire",
  },
  {
    value: "Sportif",
  },
  {
    value: "Loisir",
  },
  {
    value: "Culturel",
  },
  {
    value: "Commercial",
  },
  {
    value: "Institutionnel",
  },
  {
    value: "Autre",
  },
];
const vdl_rayonnementdelevenementChoices: DropDownChoice[] = [
  {
    value: "Local",
  },
  {
    value: "Régional",
  },
  {
    value: "Provincial",
  },
  {
    value: "National",
  },
];

const vdl_nombredeparticipantsetdevisiteursattendusChoices: DropDownChoice[] = [
  {
    value: "1 à 50",
  },
  {
    value: "51 à 150",
  },
  {
    value: "151 à 300",
  },
  {
    value: "301 à 500",
  },
  {
    value: "501 à 1000",
  },
  {
    value: "1001 à 3000",
  },
  {
    value: "3001 à 5000",
  },
  {
    value: "5001 à 10 000",
  },
  {
    value: "10 000 et plus",
  },
];

const vdl_clienteleviseeparlevenementChoices: DropDownChoice[] = [
  {
    value: "Enfants",
  },
  {
    value: "Adolescents",
  },
  {
    value: "Adultes",
  },
  {
    value: "Aînés",
  },
  {
    value: "Familles",
  },
  {
    value: "Clientèle spécifique",
  },
];
const editDemandSpecialEventFields: EditDemandSpecialEventFields = {
  vdl_nomdelevenement: {
    type: "text",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    label: specialEventDisplayNames.vdl_nomdelevenement,
  },
  vdl_naturedelevenement: {
    type: "multipleoptionset",
    error: "",
    value: [],
    required: true,
    fillLine: true,
    choices: vdl_naturedelevenementChoices,
    label: specialEventDisplayNames.vdl_naturedelevenement,
  },
  vdl_siautreprecisez: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    disabledCondition: (fields: FormFields) =>
      !(fields.vdl_naturedelevenement.value as string[]).includes("Autre"),
    label: specialEventDisplayNames.vdl_siautreprecisez,
  },
  vdl_descriptiondelevenement: {
    type: "multiline",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    rows: { min: 4, max: 6 },
    maxLength: 3000,
    label: specialEventDisplayNames.vdl_descriptiondelevenement,
  },
  vdl_rayonnementdelevenement: {
    type: "multipleoptionset",
    error: "",
    value: [],
    required: true,
    fillLine: true,
    choices: vdl_rayonnementdelevenementChoices,
    label: specialEventDisplayNames.vdl_rayonnementdelevenement,
  },
  vdl_nouvelledemande: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: specialEventDisplayNames.vdl_nouvelledemande,
  },
  vdl_demanderecurrente: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: specialEventDisplayNames.vdl_demanderecurrente,
    onChangeEvent: (fields: FormFields) => {
      try {
        const fields2 = <EditDemandSpecialEventFields>fields;
        if (!fields2.vdl_demanderecurrente.value) {
          return {
            vdl_sireccurentdatedeladerniereedition: {
              ...fields2.vdl_sireccurentdatedeladerniereedition,
              value: undefined,
            },
          };
        }
      } catch (error) {
        return undefined;
      }
    },
  },
  vdl_sireccurentdatedeladerniereedition: {
    type: "date",
    error: "",
    value: undefined,
    required: false,
    fillLine: true,
    disableFuture: true,
    disabledCondition: (fields: FormFields) =>
      !fields.vdl_demanderecurrente.value,
    label: specialEventDisplayNames.vdl_sireccurentdatedeladerniereedition,
  },
  vdl_nombredeparticipantsetdevisiteursattendus: {
    type: "optionset",
    error: "",
    value: undefined,
    required: true,
    fillLine: true,
    choices: vdl_nombredeparticipantsetdevisiteursattendusChoices,
    label:
      specialEventDisplayNames.vdl_nombredeparticipantsetdevisiteursattendus,
  },
  vdl_clienteleviseeparlevenement: {
    type: "multipleoptionset",
    error: "",
    value: [],
    required: true,
    fillLine: true,
    choices: vdl_clienteleviseeparlevenementChoices,
    label: specialEventDisplayNames.vdl_clienteleviseeparlevenement,
  },
  vdl_sispecifiqueprecisez: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    disabledCondition: (fields: FormFields) =>
      !(fields.vdl_clienteleviseeparlevenement.value as string[]).includes(
        "Clientèle spécifique"
      ),
    label: specialEventDisplayNames.vdl_sispecifiqueprecisez,
  },
  vdl_engagementloisetreglement: {
    type: "checkbox",
    error: "",
    value: false,
    required: true,
    fillLine: true,
    label: specialEventDisplayNames.vdl_engagementloisetreglement,
  },
  vdl_engagementequiteetinclusion: {
    type: "checkbox",
    error: "",
    value: false,
    required: true,
    fillLine: true,
    label: specialEventDisplayNames.vdl_engagementequiteetinclusion,
  },
  vdl_engagementdeveloppementdurable: {
    type: "checkbox",
    error: "",
    value: false,
    required: true,
    fillLine: true,
    label: specialEventDisplayNames.vdl_engagementdeveloppementdurable,
  },
  vdl_responsabilitedumaterielprete: {
    type: "checkbox",
    error: "",
    value: false,
    required: true,
    fillLine: true,
    label: specialEventDisplayNames.vdl_responsabilitedumaterielprete,
  },
  vdl_engagementhabitudessaines: {
    type: "checkbox",
    error: "",
    value: false,
    required: true,
    fillLine: true,
    label: specialEventDisplayNames.vdl_engagementhabitudessaines,
  },
};

const editDemandSpecialEventSection: FormSection<EditDemandSpecialEventFields> =
  {
    fields: [
      "vdl_nomdelevenement",
      "vdl_naturedelevenement",
      "vdl_siautreprecisez",
      "vdl_descriptiondelevenement",
      "vdl_rayonnementdelevenement",
      "vdl_nouvelledemande",

      "vdl_demanderecurrente",
      "vdl_sireccurentdatedeladerniereedition",
      "vdl_nombredeparticipantsetdevisiteursattendus",
      "vdl_clienteleviseeparlevenement",
      "vdl_sispecifiqueprecisez",
    ],
    title: "Informations sur l'évènement",
  };

const editDemandSpecialEventEngagementSection: FormSection<EditDemandSpecialEventFields> =
  {
    fields: [
      "vdl_engagementloisetreglement",
      "vdl_engagementequiteetinclusion",
      "vdl_engagementdeveloppementdurable",
      "vdl_responsabilitedumaterielprete",
      "vdl_engagementhabitudessaines",
    ],
    title: "Engagements de l'organisateur",
  };
const editDemandSpecialEventSections: FormSection<EditDemandSpecialEventFields>[] =
  [editDemandSpecialEventSection, editDemandSpecialEventEngagementSection];

export { editDemandSpecialEventFields, editDemandSpecialEventSections };
