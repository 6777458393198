import { useRef } from "react";
import Logo from "../components/common/images/laval-logo.svg";
import SideImage from "../components/common/images/sidelogo.svg";
import { styled } from "@mui/material/styles";
import SignUp from "../components/SignUp/SignUp";
import { Typography } from "@mui/material";

const Container = styled("div")(({ theme }) => ({
  fontFamily: "Roboto, sans-serif",
  margin: 0,
  padding: 0,
  height: "100vh",
  display: "flex",
  overflow: "hidden",
  ["@media (max-width:1200px)"]: {
    flexDirection: "column",
  },
}));

const LeftSection = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  ["@media (min-width:1600px)"]: {
    flex: "1 1 50%",
  },
  ["@media (min-width:1200px) and (max-width:1600px)"]: {
    flex: "1 1 auto",
    maxWidth: "calc(100vw - 700px)",
  },
  ["@media (max-width:1200px)"]: {
    display: "none",
  },
}));

const RightSection = styled("div")(({ theme }) => ({
  flex: "1 1 50%",
  backgroundColor: theme.palette.common.white,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: "50px 0px",
  overflowY: "auto",
  ["@media (min-width:1200px) and (max-width:1600px)"]: {
    flex: "0 0 800px",
  },
  ["@media (max-width:1200px)"]: {
    width: "100%",
    padding: "20px 0px",
    boxSizing: "border-box",
  },
}));

const LavalLogo = styled("img")(({ theme }) => ({
  width: "15vw",
  [theme.breakpoints.down("sm")]: { width: "30vw" },
}));

const Image = styled("img")(() => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
}));

const NewAccount = () => {
  const rightSectionRef = useRef<HTMLDivElement>(null);

  const handleStepChange = () => {
    if (rightSectionRef.current) {
      rightSectionRef.current.scrollTop = 0;
    }
  };
  return (
    <Container>
      <LeftSection>
        <Image src={SideImage} />
      </LeftSection>
      <RightSection ref={rightSectionRef}>
        <LavalLogo src={Logo} />
        <Typography variant="h4" fontWeight="bold" mt={3} textAlign="center">
          Portail des organismes
        </Typography>
        <SignUp onStepChange={handleStepChange} />
      </RightSection>
    </Container>
  );
};

export default NewAccount;
