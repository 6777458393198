import React, { useEffect, useState } from "react";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import { Box, Grid, useTheme } from "@mui/material";
import DataCard from "../components/common/cards/DataCard/DataCard";
import Edit from "@mui/icons-material/Edit";
import DataCardSkeleton from "../components/common/cards/DataCard/DataCardSkeleton";
import { TableFields } from "../types";
import accountDisplayNames from "../data/Account/displayNames";
import LinkIcon from "../components/common/buttons/icons/LinkIcon";
import { getAccount } from "../data/Account/requests";
import useUser from "../hooks/useUserData";
import Add from "@mui/icons-material/Add";
import AccountContactsCard from "../components/pageSpecific/Account/AccountContactsCard/AccountContactsCard";
import AccountContactsCardSkeleton from "../components/pageSpecific/Account/AccountContactsCard/AccountContactsCardSkeleton";
import AccountAdressCard from "../components/pageSpecific/Account/AccountAdressCard/AccountAdressCard";
import { ContactRow } from "../data/Contact/models";
import { AccountRow } from "../data/Account/models";
import PrivateComponent from "../components/common/auth/PrivateComponent";
import { RouterRoutes } from "../routes/routes";
import { getStringFromField } from "../data/common/fieldToString";
import ErrorMessage from "../components/common/error/ErrorMessage";
import BoxResponsivePadding from "../components/common/boxes/BoxResponsivePadding";

type AccountDisplayData = {
  accountInfo: TableFields;
  address1: TableFields;
  sameAddress?: boolean;
  address2: TableFields;
  contacts: ContactRow[];
};

const infoFields: (keyof AccountRow)[] = [
  "name",
  "vdl_autresnomorganisme",
  "vdl_typedorganisation",
  "vdl_neq",
  "telephone1",
  "emailaddress1",
  "websiteurl",
  "vdl_pagefacebook",
];
const adress1Fields: (keyof AccountRow)[] = [
  "vdl_secteursiegesocial",
  "address1_line1",
  "address1_line2",
  "address1_city",
  "address1_country",
  "address1_postalcode",
];
const adress2Fields: (keyof AccountRow)[] = [
  "address2_line1",
  "address2_line2",
  "address2_city",
  "address2_country",
  "address2_postalcode",
];

const AccountPage = () => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [accountData, setAccountData] = useState<AccountDisplayData>();
  const [error, setError] = useState<string>();

  const { userData } = useUser();
  useEffect(() => {
    const querry = async () => {
      const { data, error } = await getAccount(userData.parentcustomerid.value);
      if (data) {
        let _info: TableFields = {};
        let _address1: TableFields = {};
        let _address2: TableFields = {};
        infoFields.forEach((fieldName) => {
          if (!(fieldName === "contacts")) {
            const value = getStringFromField(data[fieldName]);
            _info[fieldName] = {
              displayName: accountDisplayNames[fieldName] || "",
              value: value,
            };
          }
        });

        adress1Fields.forEach((fieldName) => {
          if (!(fieldName === "contacts")) {
            const value = getStringFromField(data[fieldName]);
            _address1[fieldName] = {
              displayName: accountDisplayNames[fieldName] || "",
              value: value,
            };
          }
        });

        adress2Fields.forEach((fieldName) => {
          if (!(fieldName === "contacts")) {
            const value = getStringFromField(data[fieldName]);
            _address2[fieldName] = {
              displayName: accountDisplayNames[fieldName] || "",
              value: value,
            };
          }
        });

        setAccountData({
          accountInfo: _info,
          address1: _address1,
          address2: _address2,
          sameAddress: data.vdl_adresseidentique,
          contacts: data.contacts,
        });
      } else {
        setError("Erreur " + error);
      }
      setIsLoading(false);
    };
    querry();
  }, []);

  if (isLoading) return <AccountPageLoading />;

  if (error || !accountData) return <ErrorMessage message={error} />;

  return (
    <PageTitleLayout title="Mon organisation">
      <Box
        sx={{
          ...theme.custom.pageCardContainer,
        }}
      >
        <Grid container columnGap={"1rem"} rowGap={"1rem"}>
          <Grid
            item
            xs={12}
            height={"100%"}
            xl
            container
            rowGap={"0.5rem"}
            sx={{ backgroundColor: "#ffffff" }}
          >
            <DataCard
              title="Informations générales"
              displayFields={accountData.accountInfo}
              emptyTextPlaceHolder="---"
              icon={
                <PrivateComponent roleMinimum="Employé">
                  <LinkIcon
                    icon={<Edit />}
                    to={RouterRoutes.EditAccount}
                    backgroundColor="background.default"
                  />
                </PrivateComponent>
              }
            />
            <AccountAdressCard
              title1="Adresse du siège social"
              title2="Adresse de correspondance"
              adress1={accountData.address1}
              adress2={accountData.address2}
              sameAddress={
                accountData.sameAddress ? accountData.sameAddress : false
              }
            />
          </Grid>
          <Grid item xs={12} xl={4.5}>
            <AccountContactsCard
              icon={
                <PrivateComponent roleMinimum="Employé">
                  <LinkIcon
                    icon={<Add />}
                    to={RouterRoutes.NewContact}
                    backgroundColor="background.default"
                  />
                </PrivateComponent>
              }
              title={"Contacts"}
              contacts={accountData.contacts}
            />
          </Grid>
        </Grid>
      </Box>
    </PageTitleLayout>
  );
};

export default AccountPage;

const AccountPageLoading = () => {
  return (
    <PageTitleLayout isLoading title="Mon organisation">
      <BoxResponsivePadding>
        <Grid container gap={2}>
          <Grid item xs={12} xl>
            <DataCardSkeleton icon displayFieldsCount={infoFields.length} />
          </Grid>
          <Grid item xs={12} xl={4.5}>
            <AccountContactsCardSkeleton contactsCount={3} />
          </Grid>
        </Grid>
      </BoxResponsivePadding>
    </PageTitleLayout>
  );
};
