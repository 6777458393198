import { Chip, useTheme } from "@mui/material";
import React from "react";

type Props = { text: string } & (
  | { color: string; status?: never }
  | { status: string; color?: never }
);

const CustomChip = ({ color, status, text }: Props) => {
  const theme = useTheme();

  const statusColorMap: Record<
    string,
    { color: string; backgroundColor: string }
  > = {
    "En création": {
      color: "#0B5394",
      backgroundColor: "#BDD7EE",
    },
    Soumise: { color: "#555555", backgroundColor: "#E6E6E6" },
    Incomplète: { color: "#F79872", backgroundColor: "#FEF3E8" },
    "En traitement": { color: "#555555", backgroundColor: "#E6E6E6" },
    Approuvée: {
      color: theme.palette.success.dark,
      backgroundColor: theme.palette.success.light,
    },
    "En cours": {
      color: "#0B5394",
      backgroundColor: "#BDD7EE",
    },
    Inutilisée: { color: "#555555", backgroundColor: "#E6E6E6" },
  };

  const getColor = () => {
    if (color) {
      switch (color) {
        case "orange":
          return "#F79872";
        case "grey":
          return "#555555";
        case "primary":
          return "#0B5394";
        case "green":
          return theme.palette.success.dark;
        default:
          return "#555555";
      }
    } else if (status && statusColorMap[status]) {
      return statusColorMap[status].color;
    } else return "#555555";
  };

  const getBackgroundColor = () => {
    if (color) {
      switch (color) {
        case "orange":
          return "#FEF3E8";
        case "grey":
          return "#E6E6E6";
        case "primary":
          return "#BDD7EE";
        case "green":
          return theme.palette.success.light;
        default:
          return "#E6E6E6";
      }
    } else if (status && statusColorMap[status]) {
      return statusColorMap[status].backgroundColor;
    } else return "#E6E6E6";
  };

  return (
    <Chip
      label={text}
      sx={{
        borderRadius: "3px",
        padding: "3px",
        fontSize: "0.75rem",
        fontWeight: "bold",
        bgcolor: getBackgroundColor(),
        "& .MuiChip-label": {
          color: getColor(),
        },
      }}
    />
  );
};

export default CustomChip;
