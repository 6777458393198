import React from "react";
import {
  Typography,
  Box,
  useTheme,
  BoxProps,
  Link,
  TypographyProps,
} from "@mui/material";
import { Base64File } from "../../../data/common/types";

type Props = {
  displayName: string;
  value?: Base64File;
  emptyPlaceHolder?: string;
  fontSize?: string;
  color?: string;
  disabled?: boolean;
  horizontal?: boolean;
  horizontalLabelWidthPercentage?: number;
  boxProps?: BoxProps;
};
const typoStyles = {
  color: "inherit",
  lineHeight: 1.3,
  sx: { wordBreak: "break-word" },
};

const DisplayFile = ({
  displayName,
  value,
  emptyPlaceHolder,
  fontSize,
  color,
  disabled,
  horizontal,
  horizontalLabelWidthPercentage,
  boxProps,
}: Props) => {
  const theme = useTheme();

  return (
    <Box
      color={disabled ? theme.palette.text.disabled : color ? color : undefined}
      display={horizontal ? "flex" : undefined}
      alignItems={horizontal ? "center" : undefined}
      gap={horizontal ? "8px" : undefined}
      width={horizontal ? "100%" : undefined}
      {...boxProps}
    >
      <Typography
        fontWeight={"bold"}
        fontSize={fontSize && fontSize}
        color="inherit"
        width={
          horizontal && horizontalLabelWidthPercentage
            ? `${horizontalLabelWidthPercentage}%`
            : undefined
        }
        flexShrink="0"
        lineHeight={1.4}
      >
        {displayName ? displayName : "\u00A0"}
      </Typography>
      {value ? (
        <Link
          fontSize={fontSize && fontSize}
          {...typoStyles}
          download={value.filename}
          href={`data:${value.mimetype};base64,${value.content}`}
        >
          {value.filename}
        </Link>
      ) : (
        <Typography {...typoStyles} fontSize={fontSize && fontSize}>
          {emptyPlaceHolder ? emptyPlaceHolder : "\u00A0"}
        </Typography>
      )}
    </Box>
  );
};

export default DisplayFile;
