import { DisplayNames } from "../../types";
import { ContactRow } from "./models";

const contactDisplayNames : DisplayNames<ContactRow> = {
  firstname: "Prénom",
  lastname: "Nom",
  emailaddress1: "Courriel",
  vdl_fonction: "Fonction au sein de l'organisation",
  vdl_salutation: "Titre",
  telephone1: "N° de téléphone principal",
  vdl_roledesecurite: "Rôle de sécurité",
  parentcustomerid: "",
  telephone2: "Autre téléphone",
  contactid: "",
  middlename: "",
  fullname: "Nom complet",
  vdl_postetelprincipal: "",
  vdl_posteautretel: "",
  address1_city: "",
  address1_country: "",
  address1_line1: "",
  address1_line2: "",
  address1_line3: "",
  address1_postalcode: "",
  address1_stateorprovince: "",
  vdl_consentement: "Souhaitez-vous recevoir par courriel des communications de la part de la Ville de Laval concernant vos requêtes?",
  vdl_accesauportail: "Accès au portail",
  createdon: "Date de création",
  modifiedon: "Dernière modification",
  statecode: "Statut"
};
export default contactDisplayNames;
