import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { msalConfig } from "../auth/authConfig";
import { MsalProvider as Provider  } from "@azure/msal-react";
import { useLocation, useNavigate } from "react-router-dom";

export const msalInstance = new PublicClientApplication(msalConfig);

const MsalProvider = ({ children }: IChildrenProps) => {
    msalInstance.initialize().then(() => {
      const accounts =  msalInstance.getAllAccounts();
        if (accounts.length > 0) {
          msalInstance.setActiveAccount(accounts[0]);
        }
      
        msalInstance.addEventCallback((event: EventMessage) => {
          if (
            (event.eventType === EventType.LOGIN_SUCCESS ||
              event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
              event.eventType === EventType.SSO_SILENT_SUCCESS) &&
            event.payload
          ) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;
            
            msalInstance.setActiveAccount(account);
          }
        });
      });

  return (
    <Provider instance={msalInstance}>
        {children}
    </Provider>
  )
}

export default MsalProvider
