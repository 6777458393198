import {
  Box,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Typography,
  styled,
} from "@mui/material";

type Props = {
  icon?: boolean;
};

const CardContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gap: "1rem",
  gridTemplateColumns: "1fr 1fr 18rem",

  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "1fr auto",
    gridTemplateRows: "auto auto",
    gridAutoFlow: "column",
  },
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "1fr",
    gridTemplateRows: "none",
    gridAutoFlow: "row",
  },
}));

const ContactCardSkeleton = (icon: Props) => {
  return (
    <Card elevation={0} sx={{ marginBottom: 2 }}>
      <CardContent>
        <CardContainer>
          <Grid container direction="column">
            <Skeleton
              sx={{ fontSize: "1.8rem", lineHeight: 1.4 }}
              width={"60%"}
            />
            <Skeleton sx={{ fontSize: "0.9rem" }} width={"50%"} />
            <Skeleton sx={{ fontSize: "0.9rem" }} width={"50%"} />
            <Skeleton sx={{ fontSize: "0.9rem" }} width={"50%"} />
            {/* <Skeleton sx={{ fontSize: "0.9rem" }} width={"50%"} /> */}
          </Grid>
          <Grid container direction="column" justifyContent={"center"}>
            <Grid container direction="row" gap={1}>
              <Skeleton
                sx={{ fontSize: "0.9rem", display: "inline" }}
                width={"50%"}
              />
              <Skeleton
                sx={{ fontSize: "0.9rem", display: "inline" }}
                width={"40%"}
              />
            </Grid>
            <Grid container direction="row" gap={1}>
              <Skeleton
                sx={{ fontSize: "0.9rem", display: "inline" }}
                width={"40%"}
              />
              <Skeleton
                sx={{ fontSize: "0.9rem", display: "inline" }}
                width={"40%"}
              />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            direction="row"
            gap="16px"
            gridRow="span 2"
            justifyContent="space-between"
          >
            <Skeleton sx={{ fontSize: "2.2rem" }} width="40%" />
            {icon ? (
              <Grid item>
                <Skeleton
                  variant="circular"
                  width={"calc(1.5rem + 16px)"}
                  height={"calc(1.5rem + 16px)"}
                />
              </Grid>
            ) : null}
          </Grid>
        </CardContainer>
      </CardContent>
    </Card>
  );
};

export default ContactCardSkeleton;
