import React from "react";
import {  FormFields } from "../../../types";
import SimpleFormBase, { BaseFormProps } from "./SimpleFormBase";

type Props<T extends FormFields> = BaseFormProps<T> & {
  handleSuccess: () => Promise<void> | void;
  setFields: React.Dispatch<React.SetStateAction<T>>
};

function SimpleFormWithSectionsWithState<T extends FormFields>({
  fields,
  setFields,
  submitText,
  cancelText,
  handleCancel,
  handleSuccess,
  title,
  maxWidth,
  columns,
  sections,
  isFormLoading,
  sectionsBackground,
  removeFormPadding,
  updateRefs
}: Props<T>) {

  return (
    <SimpleFormBase
      fields={fields}
      setFields={setFields}
      submitText={submitText}
      handleSuccess={handleSuccess}
      cancelText={cancelText}
      handleCancel={handleCancel}
      title={title}
      maxWidth={maxWidth}
      columns={columns}
      sections={sections}
      sectionsBackground={sectionsBackground}
      isFormLoading={isFormLoading}
      removeFormPadding={removeFormPadding}
      updateRefs={updateRefs}
    />
  );
}

export default SimpleFormWithSectionsWithState;
