export const fileToBase64 = async (file: Blob) => {
  const stingContent = await blobToDataUrl(file);
  if (!stingContent) return;
  const regex = /data:.*base64,/;
  return stingContent.replace(regex, "");
};

const blobToDataUrl = (blob: Blob) =>
  new Promise<string | undefined>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result?.toString() ?? undefined);
    reader.onerror = reject;
  });
