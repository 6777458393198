import { Card, CardContent, Grid, Typography } from "@mui/material";
import { FieldNameValue, TableFields } from "../../../../types";
import DisplayField from "../../../common/cards/DisplayField";
import CardTitle from "../../../common/cards/CardTitle";
import AccountAdressCardColumn2 from "./AccountAdressCardColumn2";
import AccountAdressCardColumn1 from "./AccountAdressCardColumn1";
import PrivateComponent from "../../../common/auth/PrivateComponent";

type Props = {
  title1: string;
  title2: string;
  adress1: TableFields;
  adress2: TableFields;
  sameAddress: boolean;
  icon?: JSX.Element;
  emptyTextPlaceHolder?: string;
};

const AccountAdressCard = ({
  title1,
  title2,
  icon,
  adress1,
  adress2,
  sameAddress,
  emptyTextPlaceHolder,
}: Props) => {
  return (
    <Card elevation={0} sx={{ marginBottom: 2 }}>
      <CardContent>
        <Grid container>
          <Grid container item xs rowGap={"0.5rem"}>
            <AccountAdressCardColumn1 title={title1} adress1={adress1} />
            <AccountAdressCardColumn2
              adress1={adress1}
              adress2={adress2}
              sameAddress={sameAddress}
              title={title2}
            />
          </Grid>

          <Grid item width={"40px"} maxHeight={"40px"}>
            <PrivateComponent roleMinimum="Administrateur">
              {icon ? icon : null}
            </PrivateComponent>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AccountAdressCard;
