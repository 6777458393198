import {
  CheckBoxInput,
  FormSection,
  MultilineInput,
  TextInput,
} from "../../../types";
import { eventSupportDisplayNames } from "../displayNames";

export type EditDemandEventSupportFields = {
  vdl_amenagementelectriquetemporaire: CheckBoxInput;

  vdl_typedappareil: TextInput;
  vdl_nombredeprise: TextInput;
  vdl_intensiteetvoltage: TextInput;
  vdl_typedappareil2: TextInput;
  vdl_nombredeprise2: TextInput;
  vdl_intensiteetvoltage2: TextInput;
  vdl_typedappareil3: TextInput;
  vdl_nombredeprise3: TextInput;
  vdl_intensiteetvoltage3: TextInput;

  vdl_descriptionadditionneldamenagementelectrique: MultilineInput;
};

const editDemandEventSupportFields: EditDemandEventSupportFields = {
  vdl_amenagementelectriquetemporaire: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: eventSupportDisplayNames.vdl_amenagementelectriquetemporaire,
  },

  vdl_typedappareil: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: false,
    maxLength: 500,
    label: eventSupportDisplayNames.vdl_typedappareil,
  },
  vdl_nombredeprise: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: false,
    label: eventSupportDisplayNames.vdl_nombredeprise,
  },
  vdl_intensiteetvoltage: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: false,
    label: eventSupportDisplayNames.vdl_intensiteetvoltage,
  },
  vdl_typedappareil2: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: false,
    maxLength: 500,
    label: eventSupportDisplayNames.vdl_typedappareil2,
  },
  vdl_nombredeprise2: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: false,
    label: eventSupportDisplayNames.vdl_nombredeprise2,
  },
  vdl_intensiteetvoltage2: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: false,
    label: eventSupportDisplayNames.vdl_intensiteetvoltage2,
  },
  vdl_typedappareil3: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: false,
    maxLength: 500,
    label: eventSupportDisplayNames.vdl_typedappareil3,
  },
  vdl_nombredeprise3: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: false,
    label: eventSupportDisplayNames.vdl_nombredeprise3,
  },
  vdl_intensiteetvoltage3: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: false,
    label: eventSupportDisplayNames.vdl_intensiteetvoltage3,
  },

  vdl_descriptionadditionneldamenagementelectrique: {
    type: "multiline",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    maxLength: 3000,
    label:
      eventSupportDisplayNames.vdl_descriptionadditionneldamenagementelectrique,
  },
};

const editDemandEventSupport1Section: FormSection<EditDemandEventSupportFields> =
  {
    fields: ["vdl_amenagementelectriquetemporaire"],
    title: "",
  };

const editDemandEventSupport2Section: FormSection<EditDemandEventSupportFields> =
  {
    fields: [
      "vdl_typedappareil",
      "vdl_nombredeprise",
      "vdl_intensiteetvoltage",
      "vdl_typedappareil2",
      "vdl_nombredeprise2",
      "vdl_intensiteetvoltage2",
      "vdl_typedappareil3",
      "vdl_nombredeprise3",
      "vdl_intensiteetvoltage3",
    ],
    title: "",
  };
const editDemandEventSupport3Section: FormSection<EditDemandEventSupportFields> =
  {
    fields: ["vdl_descriptionadditionneldamenagementelectrique"],
    title: "",
  };

const editDemandEventSupportSections: FormSection<EditDemandEventSupportFields>[] =
  [
    editDemandEventSupport1Section,
    editDemandEventSupport2Section,
    editDemandEventSupport3Section,
  ];

export { editDemandEventSupportFields, editDemandEventSupportSections };
