import React from "react";
import { TextInput } from "../../../types";
import { TextField } from "@mui/material";

type Props = {
  field: TextInput;
  fieldName: string;
  handleChange: (field: TextInput) => void;
  disabled: boolean;
};

const FormTextInput = ({ field, fieldName, handleChange, disabled }: Props) => {
  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    let _field = { ...field };
    _field.value = value;
    _field.error = "";
    handleChange(_field);
  }

  function handleBlur(
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) {
    let _field = { ...field };
    _field.error = "";
    if (field.required && (!field.value || field.value === "")) {
      _field.error = "Ce champ est requis";
    }
    if (field.validator && field.value && field.value !== "") {
      const error = field.validator(field.value);
      if (error) {
        _field.error = error;
      }
    }
    handleChange(_field);
  }

  return (
    <TextField
      disabled={disabled}
      fullWidth
      label={field.label}
      variant="outlined"
      name={fieldName}
      value={field.value}
      inputProps={{ maxLength: field.maxLength ? field.maxLength : 100 }}
      onChange={handleInputChange}
      required={field.required}
      error={!(field.error === "")}
      helperText={field.error ? field.error : field.helperText}
      onBlur={handleBlur}
      InputLabelProps={{
        required: field.required,

      }}
    />
  );
};

export default FormTextInput;
