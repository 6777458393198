import dayjs, { Dayjs } from "dayjs";
import {
  AddressInput,
  CheckBoxInput,
  DateInput,
  DateTimeInput,
  DropDownChoice,
  DropDownOptionSetInput,
  FormFields,
  FormSection,
  MultilineInput,
  TextInput,
} from "../../../types";
import { vdl_secteurChoices } from "../../Account/forms/optionSetChoices";
import {
  demandDisplayNames,
  standardEquipmentDisplayNames,
} from "../displayNames";

export type EditDemandStandardEquipment = {
  vdl_adressecompleterecuperation: AddressInput;
  vdl_adressecompletelivraison: AddressInput;
  vdl_identiquealadressedelivraison: CheckBoxInput;
  vdl_datedelivraison: DateInput;
  vdl_horaire: DropDownOptionSetInput;
  vdl_datederecuperation: DateInput;
  vdl_horairerecuperation: DropDownOptionSetInput;
  vdl_secteurdeladressederecuperation: DropDownOptionSetInput;
  vdl_secteurdeladressedelivraison: DropDownOptionSetInput;
  vdl_nomdubatimentrecuperation: TextInput;
  vdl_nomdubatimentlivraison: TextInput;
  vdl_remarqueoucommentaire: MultilineInput;
  vdl_remarqueoucommentairerecuperation: MultilineInput;
};

const vdl_horaireChoices: DropDownChoice[] = [{ value: "AM" }, { value: "PM" }];

const editDemandStandardEquipmentFields: EditDemandStandardEquipment = {
  vdl_adressecompleterecuperation: {
    type: "address",
    error: "",
    value: undefined,
    required: false,
    fillLine: true,
    disabledCondition: (fields: FormFields) =>
      fields.vdl_identiquealadressedelivraison.value,
    requiredCondition: {
      validator: (fields: FormFields) =>
        !fields.vdl_identiquealadressedelivraison.value,
      error: "Veuillez entrer une adress ou sélectionner l'adresse identique",
    },
    label: standardEquipmentDisplayNames.vdl_adressecompleterecuperation,
  },
  vdl_adressecompletelivraison: {
    type: "address",
    error: "",
    value: undefined,
    required: true,
    fillLine: true,
    label: standardEquipmentDisplayNames.vdl_adressecompletelivraison,
  },
  vdl_datederecuperation: {
    type: "date",
    error: "",
    value: undefined,
    required: true,
    fillLine: false,
    disablePast: true,
    label: standardEquipmentDisplayNames.vdl_datederecuperation,
    minDate: (fields: FormFields) =>
      fields.vdl_datedelivraison.value
        ? (fields.vdl_datedelivraison.value as Dayjs)
        : undefined,
  },
  vdl_horairerecuperation: {
    type: "optionset",
    error: "",
    value: undefined,
    required: true,
    fillLine: false,
    choices: vdl_horaireChoices,
    label: standardEquipmentDisplayNames.vdl_horairerecuperation,
  },
  vdl_datedelivraison: {
    type: "date",
    error: "",
    value: undefined,
    required: true,
    fillLine: false,
    disablePast: true,
    label: standardEquipmentDisplayNames.vdl_datedelivraison,
  },
  vdl_horaire: {
    type: "optionset",
    error: "",
    value: undefined,
    required: true,
    fillLine: false,
    choices: vdl_horaireChoices,
    label: standardEquipmentDisplayNames.vdl_horaire,
  },
  vdl_secteurdeladressederecuperation: {
    type: "optionset",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    choices: vdl_secteurChoices,
    disabledCondition: (fields: FormFields) =>
      fields.vdl_identiquealadressedelivraison.value,
    requiredCondition: {
      validator: (fields: FormFields) =>
        !fields.vdl_identiquealadressedelivraison.value,
      error: "Veuillez entrer un secteur ou sélectionner l'adresse identique",
    },
    label: standardEquipmentDisplayNames.vdl_secteurdeladressederecuperation,
  },
  vdl_secteurdeladressedelivraison: {
    type: "optionset",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    choices: vdl_secteurChoices,
    label: standardEquipmentDisplayNames.vdl_secteurdeladressedelivraison,
  },

  vdl_nomdubatimentrecuperation: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    label: standardEquipmentDisplayNames.vdl_nomdubatimentrecuperation,
    disabledCondition: (fields: FormFields) =>
      fields.vdl_identiquealadressedelivraison.value,
    requiredCondition: {
      validator: (fields: FormFields) =>
        !fields.vdl_identiquealadressedelivraison.value,
      error:
        "Veuillez entrer un nom de bâtiment ou sélectionner l'adresse identique",
    },
  },
  vdl_nomdubatimentlivraison: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    label: standardEquipmentDisplayNames.vdl_nomdubatimentlivraison,
  },
  vdl_remarqueoucommentaire: {
    type: "multiline",
    error: "",
    value: "",
    rows: {
      min: 5,
      max: 5,
    },
    maxLength: 3000,
    fillLine: true,
    label: standardEquipmentDisplayNames.vdl_remarqueoucommentaire,
  },
  vdl_remarqueoucommentairerecuperation: {
    type: "multiline",
    error: "",
    value: "",
    rows: {
      min: 4,
      max: 4,
    },
    maxLength: 3000,
    fillLine: true,
    label: standardEquipmentDisplayNames.vdl_remarqueoucommentairerecuperation,
  },
  vdl_identiquealadressedelivraison: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: true,
    onChangeEvent: onAdresseIdentiqueChange,
    label: standardEquipmentDisplayNames.vdl_identiquealadressedelivraison,
  },
};

function onAdresseIdentiqueChange(fields: FormFields): FormFields | undefined {
  try {
    const _fields2 = <EditDemandStandardEquipment>fields;
    let _fields: Partial<EditDemandStandardEquipment> = {};
    _fields.vdl_adressecompleterecuperation = {
      ..._fields2.vdl_adressecompleterecuperation,
      value: undefined,
      error: "",
    };
    _fields.vdl_secteurdeladressederecuperation = {
      ..._fields2.vdl_secteurdeladressederecuperation,
      value: undefined,
      error: "",
    };
    _fields.vdl_nomdubatimentrecuperation = {
      ..._fields2.vdl_nomdubatimentrecuperation,
      value: "",
      error: "",
    };
    return _fields;
  } catch (e) {
    return undefined;
  }
}

const editDemandStandardEquipmentLivraisonSection: FormSection<EditDemandStandardEquipment> =
  {
    fields: [
      "vdl_adressecompletelivraison",
      "vdl_secteurdeladressedelivraison",
      "vdl_nomdubatimentlivraison",
      "vdl_datedelivraison",
      "vdl_horaire",
      "vdl_remarqueoucommentaire",
    ],
    title: "Lieu de livraison",
  };

const editDemandStandardEquipmentRecuperationSection: FormSection<EditDemandStandardEquipment> =
  {
    fields: [
      "vdl_identiquealadressedelivraison",
      "vdl_adressecompleterecuperation",
      "vdl_secteurdeladressederecuperation",
      "vdl_nomdubatimentrecuperation",
      "vdl_datederecuperation",
      "vdl_horairerecuperation",
      "vdl_remarqueoucommentairerecuperation",
    ],
    title: "Lieu de récupération",
  };

export {
  editDemandStandardEquipmentFields,
  editDemandStandardEquipmentLivraisonSection,
  editDemandStandardEquipmentRecuperationSection,
};
