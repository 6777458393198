import { FileInput, TextInput } from "../../../types";
import { websiteDisplayDisplayNames } from "../displayNames";

export type EditDemandWebsiteDisplayFields = {
  vdl_lieninternetdelapageconcernee: TextInput;
  vdl_piecejointe: FileInput;
};

const editDemandWebsiteDisplayFields: EditDemandWebsiteDisplayFields = {
  vdl_lieninternetdelapageconcernee: {
    type: "text",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    label: websiteDisplayDisplayNames.vdl_lieninternetdelapageconcernee,
  },
  vdl_piecejointe: {
    type: "file",
    error: "",
    required: true,
    fillLine: true,
    accept: [".gif", ".png", ".jpg", ".PNG", ".jpeg", ".pdf"],
    label: websiteDisplayDisplayNames.vdl_piecejointe,
    helperText:
      "Veuillez svp ajouter une image qui précise ce que vous souhaitez modifier sur la page.",
  },
};

export { editDemandWebsiteDisplayFields };
