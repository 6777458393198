import React from 'react';
import { Box, Typography } from '@mui/material';


interface StepIndicatorProps {
  currentStep: number;
}

const StepIndicator: React.FC<StepIndicatorProps> = ({ currentStep }) => {
  return (
    <Box display="flex" alignItems="center" mb={3} justifyContent="space-between">
      <StepItem number={1} label="Mes informations" isActive={currentStep >= 1} isCompleted={currentStep > 1} />
      <StepItem number={2} label="Mon organisation" isActive={currentStep >= 2} isCompleted={currentStep > 2} />
      <StepItem number={3} label="Validation" isActive={currentStep >= 3} isCompleted={currentStep > 3} />
    </Box>
  );
};

interface StepItemProps {
  number: number;
  label: string;
  isActive: boolean;
  isCompleted: boolean;
}


const StepItem: React.FC<StepItemProps> = ({ number, label, isActive, isCompleted }) => {
  const stepItemStyle = {
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center', 
    position: 'relative', 
    mx: {
      xs: 1,
      sm: 4
    },
  };

  const circleStyle = {
    width: 30,
    height: 30,
    borderRadius: '50%',
    bgcolor: isActive ? '#0054A6' : 'common.white',
    border: isActive ? 'none' : '1px solid grey',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const labelStyle = {
    mt: 1,
    maxWidth: '120px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '@media (max-width: 1000px)': {
      maxWidth: '120px',
    },
  };

  const lineStyle ={
    position: 'absolute',
    right: {
      xs: '-30%',
      sm: '-50%'
    },
    width: {
      xs: '50px',
      sm: '65px'
    },
    top: '15px',
    height: '2px',
    bgcolor: isCompleted ? '#0054A6' : 'grey.500',
  };

  return (
    <Box sx={stepItemStyle}>
      <Box sx={circleStyle}>
        <Typography variant="h6" color={isActive ? 'common.white' : 'common.black'}>{number}</Typography>
      </Box>
      <Typography variant="body2" sx={labelStyle}>{label}</Typography>

      {number !== 3 && (
        <Box sx={lineStyle} />
      )}
    </Box>
  );
};

export default StepIndicator;
