import React from "react";
import { Card, CardContent, Grid } from "@mui/material";
import CardTitle from "../../../common/cards/CardTitle";
import AccountContactsCardItem from "./AccountContactsCardItem";
import { ContactRow } from "../../../../data/Contact/models";
import useUser from "../../../../hooks/useUserData";

type Props = {
  title: string;
  contacts: ContactRow[];
  icon?: JSX.Element;
};

const AccountContactsCard = ({ title, icon, contacts }: Props) => {
  const { userData } = useUser();
  return (
    <Card elevation={0}>
      <CardContent>
        <Grid container>
          <Grid item container xs={12} minHeight={"45px"}>
            <Grid item xs>
              <CardTitle title={title} />
            </Grid>
            {icon ? (
              <Grid
                item
                width={"50px"}
                maxHeight={"40px"}
                paddingRight={"10px"}
              >
                {icon}
              </Grid>
            ) : null}
          </Grid>
          <Grid item container xs={12} rowGap={1}>
            {contacts.map((contact, idx) => {
              if (contact.contactid !== userData.contactid) {
                return (
                  <AccountContactsCardItem
                    contact={contact}
                    key={contact.contactid}
                  />
                );
              }
            })}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AccountContactsCard;
