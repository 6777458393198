import React from "react";
import { Role } from "../types";
import useUser from "../hooks/useUserData";
import Unauthorized from "../components/common/auth/Unauthorized";

type Props = {
  roleMinimum: Role;
  children: React.ReactNode;
};
const PrivateRoute = ({ roleMinimum, children }: Props) => {
  const { userHasRole } = useUser();
  const hasAccess = userHasRole(roleMinimum);
  return <>{hasAccess ? children : <Unauthorized />}</>;
};

export default PrivateRoute;
