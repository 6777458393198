import React from "react";
import { NumberInput } from "../../../types";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  useTheme,
} from "@mui/material";
import { isNumber } from "../../../utils/isNumber";
import { DraftsOutlined } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

type Props = {
  field: NumberInput;
  fieldName: string;
  handleChange: (field: NumberInput) => void;
  disabled: boolean;
};

const FormNumberInput = ({
  field,
  fieldName,
  handleChange,
  disabled,
}: Props) => {
  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    let _field = { ...field };

    if (isNumber(value)) {
      const number = parseInt(value, 10);
      if (field.max && number > field.max) {
        _field.error = `Veuillez entrer un chiffre plus petit que ${
          field.max + 1
        }`;
      } else if (field.min && number < field.min) {
        _field.error = `Veuillez entrer un chiffre plus grand que ${
          field.min - 1
        }`;
      } else {
        _field.error = "";
        _field.value = number;
      }
    } else if (value.trim() === "") {
      _field.error = "";
      _field.value = undefined;
    }

    handleChange(_field);
  }

  function handleBlur(
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) {
    let _field = { ...field };
    _field.error = "";
    if (field.required && !field.value) {
      _field.error = "Ce champ est requis";
    }
    handleChange(_field);
  }
  const handleArrowClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    direction: "up" | "down"
  ) => {
    if (!field.step) return;
    const toAdd = direction === "up" ? field.step : field.step * -1;
    let _field = { ...field };
    let newNumber = field.value;
    if (_field.value === undefined) {
      if (direction === "up") {
        newNumber = field.min ? field.min : 0 + field.step;
      } else {
        newNumber = field.min ? field.min : 0 - field.step;
      }
    } else {
      newNumber = _field.value + toAdd;
    }

    if (field.max !== undefined && newNumber > field.max) {
      _field.error = `Veuillez entrer un chiffre plus petit que ${
        field.max + 1
      }`;
    } else if (field.min !== undefined && newNumber < field.min) {
      _field.error = `Veuillez entrer un chiffre plus grand que ${
        field.min - 1
      }`;
    } else {
      _field.error = "";
      _field.value = newNumber;
    }
    handleChange(_field);
  };

  return (
    <TextField
      disabled={disabled}
      fullWidth
      label={field.label}
      variant="outlined"
      name={fieldName}
      value={field.value ? field.value : ""}
      onChange={handleInputChange}
      required={field.required}
      error={!(field.error === "")}
      helperText={field.error ? field.error : field.helperText}
      onBlur={handleBlur}
      InputLabelProps={{
        required: field.required,
        sx: {
          paddingRight: field.step ? "24px" : undefined,
          ".MuiInputLabel-asterisk": field.required
            ? { color: "#A81B8D", fontSize: "20px" }
            : {},
        },
      }}
      InputProps={{
        endAdornment: field.step ? (
          <InputAdornment position="end">
            <Box display="flex" flexDirection="column" gap="1px">
              <IconButton sx={style} onClick={(e) => handleArrowClick(e, "up")}>
                <ArrowDropUpIcon />
              </IconButton>
              <IconButton
                sx={style}
                onClick={(e) => handleArrowClick(e, "down")}
              >
                <ArrowDropDownIcon />
              </IconButton>
            </Box>
          </InputAdornment>
        ) : undefined,
      }}
    />
  );
};

const style = {
  padding: 0,
  backgroundColor: "background.default",
  borderRadius: "2px",
  "&:hover": {
    backgroundColor: "#dddddd",
  },
};

export default FormNumberInput;
