import React from "react";
import { RadioInput } from "../../../types";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

type Props = {
  field: RadioInput;
  fieldName: string;
  handleChange: (field: RadioInput) => void;
  disabled: boolean;
};

const FormRadioInput = ({
  field,
  fieldName,
  handleChange,
  disabled,
}: Props) => {
  
  function handleInputChange(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    const value  = event.currentTarget.value;
    let _field = { ...field };
    _field.value = value;
    _field.error = "";
    handleChange(_field);
  }

  return (
    <FormControl error={!(field.error == "")}>
      <FormLabel>{field.label}</FormLabel>
      <RadioGroup name={fieldName}>
        {field.choices.map((choice, idx) => (
          <FormControlLabel
            key={choice.value}
            value={choice.value}
            control={<Radio disabled={disabled} onChange={handleInputChange} checked={choice.value === field.value}/>}
            label={choice.value}
          />
        ))}
      </RadioGroup>
      {field.error || field.helperText ? (
        <FormHelperText>
          {field.error ? field.error : field.helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default FormRadioInput;
