import contactDisplayNames from "../displayNames";
import {
  CheckBoxInput,
  DropDownOptionSetInput,
  FormFields,
  FormSection,
  PhoneInput,
  TextInput,
  DropDownLookUpInput
} from "../../../types";
import { vdl_fonctionChoices, vdl_salutationsChoices } from "./optionSetChoices";

export type EditProfileFields = {
  vdl_salutation: DropDownOptionSetInput;
  firstname: TextInput;
  lastname: TextInput;
  vdl_fonction: DropDownOptionSetInput;
  telephone1: PhoneInput;
  telephone2: PhoneInput;
};

const sectionInfos: FormSection<EditProfileFields> = {
  fields: [
    "firstname",
    "lastname",
    "vdl_fonction",
    "telephone1",
    "vdl_salutation",
    "telephone2",
  ],
  title: "Informations personnelles",
};

const editProfileSections: FormSection<EditProfileFields>[] = [
  sectionInfos
];

const editProfileFields: EditProfileFields = {
  vdl_salutation: {
    type: "optionset",
    error: "",
    value: "",
    choices: vdl_salutationsChoices,
    required: false,
    fillLine: true,
    label: contactDisplayNames.vdl_salutation,
  },
  firstname: {
    type: "text",
    value: "",
    error: "",
    required: true,
    label: contactDisplayNames.firstname,
  },
  lastname: {
    type: "text",
    value: "",
    error: "",
    required: true,
    label: contactDisplayNames.lastname,
  },
  vdl_fonction: {
    type: "optionset",
    value: "",
    error: "",
    choices: vdl_fonctionChoices,
    required: true,
    fillLine: true,
    label: contactDisplayNames.vdl_fonction,
  },
  telephone1: {
    type: "phone",
    value: "",
    error: "",
    required: true,
    label: contactDisplayNames.telephone1,
  },
  telephone2: {
    type: "phone",
    value: "",
    error: "",
    label: contactDisplayNames.telephone2,
  },
};

const editProfileForm = {
  fields: editProfileFields,
  sections: editProfileSections,
};

export { editProfileForm, editProfileFields, editProfileSections};
