import contactDisplayNames from "../displayNames";
import {
  CheckBoxInput,
  DropDownOptionSetInput,
  FormFields,
  FormSection,
  PhoneInput,
  TextInput,
  DropDownLookUpInput,
  RadioInput,
} from "../../../types";
import {
  vdl_accessPortailChoices,
  vdl_fonctionChoices,
  vdl_salutationsChoices,
} from "./optionSetChoices";

export type EditContactFields = {
  vdl_salutation: DropDownOptionSetInput;
  firstname: TextInput;
  lastname: TextInput;
  vdl_fonction: DropDownOptionSetInput;
  telephone1: PhoneInput;
  telephone2: PhoneInput;
  vdl_accesauportail: RadioInput;
  vdl_roledesecurite: DropDownLookUpInput;
};

const sectionInfos: FormSection<EditContactFields> = {
  fields: [
    "vdl_salutation",
    "firstname",
    "lastname",
    "telephone1",
    "telephone2",
    "vdl_fonction",
  ],
  title: "Informations personnelles",
};

const sectionAccess: FormSection<EditContactFields> = {
  fields: ["vdl_accesauportail", "vdl_roledesecurite"],
  title: "Accès au portail",
};

const editContactSections: FormSection<EditContactFields>[] = [
  sectionInfos,
  sectionAccess,
];

const editContactFields: EditContactFields = {
  vdl_salutation: {
    type: "optionset",
    error: "",
    value: "",
    choices: vdl_salutationsChoices,
    required: false,
    fillLine: true,
    label: contactDisplayNames.vdl_salutation,
  },
  firstname: {
    type: "text",
    value: "",
    error: "",
    required: true,
    label: contactDisplayNames.firstname,
  },
  lastname: {
    type: "text",
    value: "",
    error: "",
    required: true,
    label: contactDisplayNames.lastname,
  },
  vdl_fonction: {
    type: "optionset",
    value: "",
    error: "",
    choices: vdl_fonctionChoices,
    required: true,
    fillLine: true,
    label: contactDisplayNames.vdl_fonction,
  },
  telephone1: {
    type: "phone",
    value: "",
    error: "",
    required: true,
    label: contactDisplayNames.telephone1,
  },
  telephone2: {
    type: "phone",
    value: "",
    error: "",
    label: contactDisplayNames.telephone2,
  },
  vdl_accesauportail: {
    type: "radio",
    error: "",
    value: "",
    choices: vdl_accessPortailChoices,
    required: false,
    fillLine: true,
    label: contactDisplayNames.vdl_accesauportail,
    onChangeEvent: onAccessauportailChange,
  },
  vdl_roledesecurite: {
    type: "lookup",
    value: "",
    error: "",
    choices: [],
    label: contactDisplayNames.vdl_roledesecurite,
    requiredCondition : {
      validator: (fields : FormFields) => fields.vdl_accesauportail.value === "Oui",
      error: "Vous devez donner un rôle à au contact s'il a accès au portail"
    },
    disabledCondition: (fields : FormFields) => fields.vdl_accesauportail.value !== "Oui",
  },
};

function onAccessauportailChange(fields2: FormFields): FormFields | undefined {
  try {
    const fields = <EditContactFields>fields2;
    if (fields.vdl_accesauportail.value === "Oui") return undefined;
    let _fields: Partial<EditContactFields> = {};
    _fields.vdl_roledesecurite = {
      ...fields.vdl_roledesecurite,
      value: undefined,
    };
    return _fields;
  } catch (e) {
    return {};
  }
}

const editContactForm = {
  fields: editContactFields,
  sections: editContactSections,
};

export { editContactForm, editContactFields, editContactSections };
