import { Box, Typography } from "@mui/material";
import { DemandPublicSpaceReservationRow } from "../../../../../../data/Demand/types/publicSpace/reservation";
import BoxResponsivePadding from "../../../../../common/boxes/BoxResponsivePadding";
import { dateStringToLongWithDayString } from "../../../../../../data/common/fieldToString";

type Props = {
  reservation: DemandPublicSpaceReservationRow 
};
const ReservationCard = ({ reservation  }: Props) => {
  return (
    <BoxResponsivePadding
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="space-between"
      bgcolor="background.default"
      rowGap="16px"
      columnGap="24px"
    >
      <Typography fontWeight="bold">
        {reservation.vdl_nomdusiteareserver.label}
      </Typography>
      <Typography>{reservation.vdl_sousplateau.label}</Typography>

      <Box>
        <Typography fontWeight="bold">{`${dateStringToLongWithDayString(
          reservation.vdl_debutdelareservation
        )}`}</Typography>
        <Typography fontWeight="bold">{`au ${dateStringToLongWithDayString(
          reservation.vdl_findelareservation
        )}`}</Typography>
      </Box>
      <Box display="flex" gap="4px">
        <Typography fontWeight="bold">Contact:</Typography>
        <Typography >{`${reservation.vdl_prenom} ${reservation.vdl_nom}`}</Typography>
      </Box>
    </BoxResponsivePadding>
  );
};

export default ReservationCard;
