import { Box } from "@mui/material";
import { DemandPublicSpaceRow } from "../../../../../data/Demand/models";
import { publicSpaceDisplayNames } from "../../../../../data/Demand/displayNames";
import DisplayFieldCard from "../../../../common/cards/DisplayFieldCard";
import ReservationsSection from "./Reservatoions/ReservationsSection";
import BoxResponsivePadding from "../../../../common/boxes/BoxResponsivePadding";

type Props = {
  demand: DemandPublicSpaceRow;
};

const PublicSpace = ({ demand }: Props) => {
  const details = demand.vdl_detailsdelademande;
  return (
    <Box display="grid" gap="16px">
      <DisplayFieldCard
        title="Activité"
        fields={[
          {
            label: publicSpaceDisplayNames.vdl_name,
            value: details.vdl_name,
            type: "string",
          },
        ]}
        columns={1}
        minColWidth={300}
      />
        <ReservationsSection
          reservations={demand.vdl_detailsdelademande.reservations}
        />
    </Box>
  );
};

export default PublicSpace;
