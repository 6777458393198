import { Box } from "@mui/material";
import { DemandActivitiesCalendarRow } from "../../../../../data/Demand/models";
import { activitiesCalendarDisplayNames } from "../../../../../data/Demand/displayNames";
import DisplayFieldCard from "../../../../common/cards/DisplayFieldCard";
import { dateTimeStringToString } from "../../../../../data/common/fieldToString";

type Props = {
  demand: DemandActivitiesCalendarRow;
};

const ActivitiesCalendar = ({ demand }: Props) => {
  const details = demand.vdl_detailsdelademande;
  return (
    <Box display="grid" gap="16px">
      <DisplayFieldCard
        title="Informations générales"
        fields={[
          {
            label: activitiesCalendarDisplayNames.vdl_titredelevenement,
            value: details.vdl_titredelevenement,
            type: "string",
          },
          {
            label: activitiesCalendarDisplayNames.vdl_lieudelevenement,
            value: details.vdl_lieudelevenement,
            type: "string",
          },
          {
            label:
              activitiesCalendarDisplayNames.vdl_adressecompletedulieudelevenement,
            value: details.vdl_adressecompletedulieudelevenement,
            type: "string",
          },
        ]}
        columns={1}
        minColWidth={300}
      />
      <DisplayFieldCard
        title="Image"
        fields={[
          {
            label: activitiesCalendarDisplayNames.vdl_veuillezjoindreunephotofile,
            value: details.vdl_veuillezjoindreunephotofile,
            type: "file",
          },
        ]}
        columns={1}
        minColWidth={300}
      />
      <DisplayFieldCard
        title="Date(s) de l'évènement"
        fields={[
          {
            label: activitiesCalendarDisplayNames.vdl_dateetheurededebut,
            value: dateTimeStringToString(details.vdl_dateetheurededebut),
            type: "string",
          },
          {
            label: activitiesCalendarDisplayNames.vdl_dateetheuredefin,
            value: dateTimeStringToString(details.vdl_dateetheuredefin),
            type: "string",
          },
          {
            label:
              activitiesCalendarDisplayNames.vdl_commentairessurleshorairesdelevenement,
            value: details.vdl_commentairessurleshorairesdelevenement,
            type: "string",
          },
        ]}
        columns={1}
        minColWidth={300}
      />
      <DisplayFieldCard
        title="Détail des tarifs offerts"
        fields={[
          {
            label: activitiesCalendarDisplayNames.vdl_clientelesvisees,
            value: details.vdl_clientelesvisees?.join(", "),
            type: "string",
          },
          {
            label: activitiesCalendarDisplayNames.vdl_cout,
            value: details.vdl_cout,
            type: "string",
          },
          {
            label: activitiesCalendarDisplayNames.vdl_adultes,
            value: details.vdl_adultes?.toString() ,
            type: "string",
          },
          {
            label: activitiesCalendarDisplayNames.vdl_retraites,
            value: details.vdl_retraites?.toString(),
            type: "string",
          },
          {
            label: activitiesCalendarDisplayNames.vdl_etudiants,
            value: details.vdl_etudiants?.toString(),
            type: "string",
          },
          {
            label: activitiesCalendarDisplayNames.vdl_enfants,
            value: details.vdl_enfants?.toString(),
            type: "string",
          },
          {
            label: activitiesCalendarDisplayNames.vdl_prixdefamille,
            value: details.vdl_prixdefamille?.toString(),
            type: "string",
          },
          {
            label: activitiesCalendarDisplayNames.vdl_prixdegroupe,
            value: details.vdl_prixdegroupe?.toString() ,
            type: "string",
          },
          {
            label: activitiesCalendarDisplayNames.vdl_autressvpdetaillez2,
            value: details.vdl_autressvpdetaillez2,
            type: "string",
          },
          {
            label: activitiesCalendarDisplayNames.vdl_stationnementpayant,
            value: details.vdl_stationnementpayant??false,
            type: "check",
          },
          {
            label: activitiesCalendarDisplayNames.vdl_coutdestationnement,
            value: details.vdl_coutdestationnement?.toString(),
            type: "string",
          },
        ]}
        columns={1}
        minColWidth={300}
      />
      <DisplayFieldCard
        title="Détail des tarifs offerts"
        fields={[
          {
            label: activitiesCalendarDisplayNames.vdl_siteinternet,
            value: details.vdl_siteinternet,
            type: "string",
          },
          {
            label: activitiesCalendarDisplayNames.vdl_pagefacebook,
            value: details.vdl_pagefacebook,
            type: "string",
          },
          {
            label: activitiesCalendarDisplayNames.vdl_courriel,
            value: details.vdl_courriel,
            type: "string",
          },
          {
            label: activitiesCalendarDisplayNames.vdl_telephonecalendrier,
            value: details.vdl_telephonecalendrier,
            type: "string",
          },
          {
            label: activitiesCalendarDisplayNames.vdl_descriptionsommairedelevenement,
            value: details.vdl_descriptionsommairedelevenement,
            type: "string",
          },
          {
            label: activitiesCalendarDisplayNames.vdl_autreinformationpertinente,
            value: details.vdl_autreinformationpertinente,
            type: "string",
          },
        ]}
        columns={1}
        minColWidth={300}
      />
    </Box>
  );
};

export default ActivitiesCalendar;
