import clone from "just-clone";
import { FieldValues, FormFields } from "../../../types";

export default function buildForm <T extends FormFields>(baseForm: T, values : FieldValues<T>) {
    let _fields = clone(baseForm)
  
    for (const key of Object.keys(values)) {
      if (values.hasOwnProperty(key)) {
        baseForm[key].value = values[key as keyof typeof values];
      }
    }
    return _fields;
  };