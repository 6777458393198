import React, { useEffect, useState } from "react";
import SimpleFormWithSections from "../components/form/SimpleForm/SimpleFormWithSections";
import { Box } from "@mui/material";
import SectionLoading from "../components/common/loading/SectionLoading";
import useUser from "../hooks/useUserData";
import { updateContact } from "../data/Contact/requests";
import { useSnackbar } from "../hooks/useSnackbar";
import { useNavigate } from "react-router-dom";
import { RouterRoutes } from "../routes/routes";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import {
  editProfileForm,
  EditProfileFields,
} from "../data/Contact/forms/editProfile";
import { ContactPatch } from "../data/Contact/models";
import ErrorMessage from "../components/common/error/ErrorMessage";

const EditProfile = () => {
  const { userData, updateUser } = useUser();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const [error, setError] = useState<string>();
  const { showSnackbar } = useSnackbar();
  const [fields, setFields] = useState<EditProfileFields>(
    editProfileForm.fields
  );

  useEffect(() => {
    let _fields = fields;
    _fields.firstname.value = userData.firstname;
    _fields.lastname.value = userData.lastname;
    _fields.telephone1.value = userData.telephone1;
    _fields.telephone2.value = userData.telephone2??"";
    _fields.firstname.value = userData.firstname;
    _fields.vdl_salutation.value = userData.vdl_salutation;
    _fields.vdl_fonction.value = userData.vdl_fonction
    setFields(_fields)
    setIsLoading(false);
  }, []);


  const handleSuccess = async (fields: EditProfileFields) => {
    const contact: ContactPatch = {
      firstname: fields.firstname.value,
      lastname: fields.lastname.value,
      telephone1: fields.telephone1.value,
      telephone2: fields.telephone2.value,
      vdl_salutation: fields.vdl_salutation.value,
      vdl_fonction: fields.vdl_fonction.value
    };

    const { status, error } = await updateContact(userData.contactid, contact);
    if (status === "success") {
      await updateUser();
      showSnackbar("Profil modifié", "success");
      navigate(`/${RouterRoutes.MyProfile}`);
    } else {
      showSnackbar(
        "Erreur dans l'ajout de l'utilisateur. Réessayez ou contactez le support.",
        "error"
      );
    }
  };

  if (isLoading)
    return (
      <PageTitleLayout title="Modifier mon profil">
        <SectionLoading />
      </PageTitleLayout>
    );
  if (error) return <ErrorMessage message={error}/>;
  return (
    <PageTitleLayout title="Modifier mon profil">
      <Box>
        <SimpleFormWithSections
          maxWidth={900}
          fields={fields}
          title="Informations personnelles"
          handleSuccess={handleSuccess}
          submitText={"Enregistrer"}
          columns={{ minWidth: 220, count: 2 }}
        />
      </Box>
    </PageTitleLayout>
  );
};

export default EditProfile;
