import React from "react";
import { useTheme } from "@mui/material";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

type Props = {
  to: string;
  text: string;
  fontSize?: string;
  color?: string;
};
const SimpleLink = ({ color, to, text, fontSize }: Props) => {
  const theme = useTheme();
  const textColor = color;
  const textFontSize = fontSize;

  return (
    <Link
      component={RouterLink}
      to={"/"}
      underline="hover"
      sx={{
        color: textColor ? textColor : (theme) => theme.custom.simpleLink.color,
        fontSize: textFontSize ? textFontSize : 13,
      }}
    >
      {text}
    </Link>
  );
};

export default SimpleLink;
