import { Box, styled } from "@mui/material";

const BoxResponsivePadding = styled(Box)(() => ({
  padding: "20px",
  "@media (max-width:600px)": {
    padding: "15px",
  },
}));

export default BoxResponsivePadding;
