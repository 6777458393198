import {
  CheckBoxInput,
  CommentInput,
  FormSection,
  NumberInput,
  SectionCheckBoxInput,
  TextInput,
} from "../../../types";
import {
  permitDisplayNames,
  televisionDisplayDisplayNames,
} from "../displayNames";

export type EditDemandPermitFields = {
  //Tarification d’accès à un site municipal
  vdl_tarificationdaccesaunsitemunicipal: SectionCheckBoxInput;
  commentTarif: CommentInput;
  vdl_enfant: NumberInput;
  vdl_adultes: NumberInput;
  vdl_autres1: CheckBoxInput;
  vdl_siautreprecisez: TextInput;
  vdl_revenusdetarificationremisa: TextInput;

  //Diffusion sonore
  vdl_diffusionsonore: SectionCheckBoxInput;
  vdl_utilisationdunsystemedesonorisationamplifie: CheckBoxInput;
  vdl_autreappareilsusceptibledenuireauvoisinage: CheckBoxInput;
  vdl_precisez8: TextInput;

  //Éclairage
  vdl_eclairage: SectionCheckBoxInput;
  vdl_utilisationdunsystemdeclairagenuisance: CheckBoxInput;
  vdl_precisez7: TextInput;

  //Affichage temporaire
  vdl_affichagetemporairesignalisationpavoisement: SectionCheckBoxInput;
  vdl_panneauxdesignalisation: CheckBoxInput;
  vdl_precisezdimensionetmateriaux: TextInput;
  vdl_bannieres: CheckBoxInput;
  vdl_precisezdimensionetsupport: TextInput;
  vdl_fanions: CheckBoxInput;
  vdl_autres2: CheckBoxInput;
  vdl_precisez1: TextInput;

  //Installation de structure temporaire
  vdl_installationdestructuretemporaire: SectionCheckBoxInput;
  vdl_chapiteaumarquise: CheckBoxInput;
  vdl_preciseznombreetdimension: TextInput;
  vdl_structuresgonflables: CheckBoxInput;
  vdl_tyrolienne: CheckBoxInput;
  vdl_scenemobile: CheckBoxInput;
  vdl_autres3: CheckBoxInput;
  vdl_precisez2: TextInput;
  vdl_equipementconformeauxnormesetreglements: CheckBoxInput;
  vdl_installateuretoperateursontqualifies: CheckBoxInput;
  vdl_nomdufournisseur: TextInput;
  vdl_requerantassurelaresponsabilitecivile: CheckBoxInput;

  //Consommation de boissons alcoolisées
  vdl_consommationdeboissonsalcooliques: SectionCheckBoxInput;

  //Vente de boissons alcoolisées
  vdl_ventedeboissonsalcooliques: SectionCheckBoxInput;
  vdl_venteestgereeparledemandeur1: CheckBoxInput;
  vdl_ventegereeparuntiers1: CheckBoxInput;
  vdl_precisez4: TextInput;
  vdl_profitsdelaventeremis1: TextInput;

  //Vente d’aliments et breuvages
  vdl_ventealimentsetboissonsexcomptoirkiosque: SectionCheckBoxInput;
  vdl_venteestgereeparledemandeur2: CheckBoxInput;
  vdl_ventegereeparuntiers2: CheckBoxInput;
  vdl_precisez6: TextInput;
  vdl_nombredepointsdeventesurlesite1: NumberInput;
  vdl_profitsdelaventeremis2: TextInput;

  //Vente de produits et/ou services
  vdl_ventedeproduitsservicesobjetsexpovente: SectionCheckBoxInput;
  vdl_venteestgereeparledemandeur3: CheckBoxInput;
  vdl_ventegereeparuntiers3: CheckBoxInput;
  vdl_precisez5: TextInput;
  vdl_nombredepointsdeventesurlesite2: NumberInput;
  vdl_profitsdelaventeremisa3: TextInput;

  //Sollicitation porte-à-porte
  vdl_sollicitationporteaporte: SectionCheckBoxInput;
  vdl_collectedebouteille: CheckBoxInput;
  vdl_ventedecalendrier: CheckBoxInput;
  vdl_autre: CheckBoxInput;
  vdl_precisez3: TextInput;
  vdl_revenusdelactiviteremisa: TextInput;

  //Camion-restaurant
  vdl_camionrestaurant: SectionCheckBoxInput;
};

const editDemandPermitFields: EditDemandPermitFields = {
  vdl_tarificationdaccesaunsitemunicipal: {
    type: "sectioncheckbox",
    value: false,
  },
  commentTarif: {
    type: "comment",
    value: "Tarifs:",
    fillLine: true,
  },
  vdl_enfant: {
    type: "number",
    error: "",
    value: undefined,
    fillLine: false,
    label: permitDisplayNames.vdl_enfant,
  },
  vdl_adultes: {
    type: "number",
    error: "",
    value: undefined,
    fillLine: false,
    label: permitDisplayNames.vdl_adultes,
  },
  vdl_autres1: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: false,
    label: permitDisplayNames.vdl_autres1,
  },
  vdl_siautreprecisez: {
    type: "text",
    error: "",
    value: "",
    fillLine: false,
    label: permitDisplayNames.vdl_siautreprecisez,
  },
  vdl_revenusdetarificationremisa: {
    type: "text",
    error: "",
    value: "",
    fillLine: true,
    maxLength: 500,
    label: permitDisplayNames.vdl_revenusdetarificationremisa,
  },

  vdl_diffusionsonore: {
    type: "sectioncheckbox",
    value: false,
  },
  vdl_utilisationdunsystemedesonorisationamplifie: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: true,
    label: permitDisplayNames.vdl_utilisationdunsystemedesonorisationamplifie,
  },
  vdl_autreappareilsusceptibledenuireauvoisinage: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: false,
    label: permitDisplayNames.vdl_autreappareilsusceptibledenuireauvoisinage,
  },
  vdl_precisez8: {
    type: "text",
    error: "",
    value: "",
    fillLine: false,
    maxLength: 300,
    label: permitDisplayNames.vdl_precisez8,
  },

  vdl_eclairage: {
    type: "sectioncheckbox",
    value: false,
  },
  vdl_utilisationdunsystemdeclairagenuisance: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: false,
    label: permitDisplayNames.vdl_utilisationdunsystemdeclairagenuisance,
  },
  vdl_precisez7: {
    type: "text",
    error: "",
    value: "",
    fillLine: false,
    maxLength: 300,
    label: permitDisplayNames.vdl_precisez7,
  },

  vdl_affichagetemporairesignalisationpavoisement: {
    type: "sectioncheckbox",
    value: false,
  },
  vdl_panneauxdesignalisation: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: false,
    label: permitDisplayNames.vdl_panneauxdesignalisation,
  },
  vdl_precisezdimensionetmateriaux: {
    type: "text",
    error: "",
    value: "",
    fillLine: false,
    maxLength: 300,
    label: permitDisplayNames.vdl_precisezdimensionetmateriaux,
  },
  vdl_bannieres: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: true,
    label: permitDisplayNames.vdl_bannieres,
  },
  vdl_precisezdimensionetsupport: {
    type: "text",
    error: "",
    value: "",
    fillLine: false,
    maxLength: 300,
    label: permitDisplayNames.vdl_precisezdimensionetsupport,
  },
  vdl_fanions: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: false,
    label: permitDisplayNames.vdl_fanions,
  },
  vdl_autres2: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: false,
    label: permitDisplayNames.vdl_autres2,
  },
  vdl_precisez1: {
    type: "text",
    error: "",
    value: "",
    fillLine: false,
    maxLength: 300,
    label: permitDisplayNames.vdl_precisez1,
  },

  vdl_installationdestructuretemporaire: {
    type: "sectioncheckbox",
    value: false,
  },
  vdl_chapiteaumarquise: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: false,
    label: permitDisplayNames.vdl_chapiteaumarquise,
  },
  vdl_preciseznombreetdimension: {
    type: "text",
    error: "",
    value: "",
    fillLine: false,
    maxLength: 500,
    label: permitDisplayNames.vdl_preciseznombreetdimension,
  },
  vdl_structuresgonflables: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: false,
    label: permitDisplayNames.vdl_structuresgonflables,
  },
  vdl_tyrolienne: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: false,
    label: permitDisplayNames.vdl_tyrolienne,
  },
  vdl_scenemobile: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: true,
    label: permitDisplayNames.vdl_scenemobile,
  },
  vdl_autres3: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: true,
    label: permitDisplayNames.vdl_autres3,
  },
  vdl_precisez2: {
    type: "text",
    error: "",
    value: "",
    fillLine: false,
    label: permitDisplayNames.vdl_precisez2,
  },
  vdl_equipementconformeauxnormesetreglements: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: true,
    label: permitDisplayNames.vdl_equipementconformeauxnormesetreglements,
  },
  vdl_installateuretoperateursontqualifies: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: true,
    label: permitDisplayNames.vdl_installateuretoperateursontqualifies,
  },
  vdl_nomdufournisseur: {
    type: "text",
    error: "",
    value: "",
    fillLine: true,
    maxLength: 300,
    label: permitDisplayNames.vdl_nomdufournisseur,
  },
  vdl_requerantassurelaresponsabilitecivile: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: true,
    label: permitDisplayNames.vdl_requerantassurelaresponsabilitecivile,
  },

  vdl_consommationdeboissonsalcooliques: {
    type: "sectioncheckbox",
    value: false,
  },

  vdl_ventedeboissonsalcooliques: {
    type: "sectioncheckbox",
    value: false,
  },
  vdl_venteestgereeparledemandeur1: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: true,
    label: permitDisplayNames.vdl_venteestgereeparledemandeur1,
  },
  vdl_ventegereeparuntiers1: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: false,
    label: permitDisplayNames.vdl_ventegereeparuntiers1,
  },
  vdl_precisez4: {
    type: "text",
    error: "",
    value: "",
    fillLine: false,
    maxLength: 300,
    label: permitDisplayNames.vdl_precisez4,
  },
  vdl_profitsdelaventeremis1: {
    type: "text",
    error: "",
    value: "",
    fillLine: true,
    maxLength: 300,
    label: permitDisplayNames.vdl_profitsdelaventeremis1,
  },

  vdl_ventealimentsetboissonsexcomptoirkiosque: {
    type: "sectioncheckbox",
    value: false,
  },
  vdl_venteestgereeparledemandeur2: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: true,
    label: permitDisplayNames.vdl_venteestgereeparledemandeur2,
  },
  vdl_ventegereeparuntiers2: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: false,
    label: permitDisplayNames.vdl_ventegereeparuntiers2,
  },
  vdl_precisez6: {
    type: "text",
    error: "",
    value: "",
    fillLine: false,
    maxLength: 300,
    label: permitDisplayNames.vdl_precisez6,
  },
  vdl_nombredepointsdeventesurlesite1: {
    type: "number",
    error: "",
    value: undefined,
    fillLine: true,
    label: permitDisplayNames.vdl_nombredepointsdeventesurlesite1,
  },
  vdl_profitsdelaventeremis2: {
    type: "text",
    error: "",
    value: "",
    fillLine: true,
    maxLength: 500,
    label: permitDisplayNames.vdl_profitsdelaventeremis2,
  },

  vdl_ventedeproduitsservicesobjetsexpovente: {
    type: "sectioncheckbox",
    value: false,
  },
  vdl_venteestgereeparledemandeur3: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: true,
    label: permitDisplayNames.vdl_venteestgereeparledemandeur3,
  },
  vdl_ventegereeparuntiers3: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: false,
    label: permitDisplayNames.vdl_ventegereeparuntiers3,
  },
  vdl_precisez5: {
    type: "text",
    error: "",
    value: "",
    fillLine: false,
    maxLength: 300,
    label: permitDisplayNames.vdl_precisez5,
  },
  vdl_nombredepointsdeventesurlesite2: {
    type: "number",
    error: "",
    value: undefined,
    fillLine: true,
    label: permitDisplayNames.vdl_nombredepointsdeventesurlesite1,
  },
  vdl_profitsdelaventeremisa3: {
    type: "text",
    error: "",
    value: "",
    fillLine: true,
    maxLength: 500,
    label: permitDisplayNames.vdl_profitsdelaventeremisa3,
  },

  vdl_sollicitationporteaporte: {
    type: "sectioncheckbox",
    value: false,
  },
  vdl_collectedebouteille: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: false,
    label: permitDisplayNames.vdl_collectedebouteille,
  },
  vdl_ventedecalendrier: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: false,
    label: permitDisplayNames.vdl_ventedecalendrier,
  },
  vdl_autre: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: false,
    label: permitDisplayNames.vdl_autre,
  },
  vdl_precisez3: {
    type: "text",
    error: "",
    value: "",
    fillLine: false,
    maxLength: 300,
    label: permitDisplayNames.vdl_precisez3,
  },
  vdl_revenusdelactiviteremisa: {
    type: "text",
    error: "",
    value: "",
    fillLine: true,
    maxLength: 500,
    label: permitDisplayNames.vdl_profitsdelaventeremisa3,
  },


  vdl_camionrestaurant: {
    type: "sectioncheckbox",
    value: false,
  },
};

const editDemandPermitTarificationSection: FormSection<EditDemandPermitFields> =
  {
    fields: [
      "commentTarif",
      "vdl_enfant",
      "vdl_adultes",
      "vdl_autres1",
      "vdl_siautreprecisez",
      "vdl_revenusdetarificationremisa",
    ],
    title: "Tarification d'accès à un site municipal",
    activeRule: {
      field: "vdl_tarificationdaccesaunsitemunicipal",
    },
  };

const editDemandPermitDiffusionSonoreSection: FormSection<EditDemandPermitFields> =
  {
    fields: [
      "vdl_utilisationdunsystemedesonorisationamplifie",
      "vdl_autreappareilsusceptibledenuireauvoisinage",
      "vdl_precisez8",
    ],
    title: "Diffusion sonore",
    activeRule: {
      field: "vdl_diffusionsonore",
    },
  };

const editDemandPermitEclairageSection: FormSection<EditDemandPermitFields> = {
  fields: ["vdl_utilisationdunsystemdeclairagenuisance", "vdl_precisez7"],
  title: "Éclairage",
  activeRule: {
    field: "vdl_eclairage",
  },
};

const editDemandPermitAffichageTemporaireSection: FormSection<EditDemandPermitFields> =
  {
    fields: [
      "vdl_panneauxdesignalisation",
      "vdl_precisezdimensionetmateriaux",
      "vdl_bannieres",
      "vdl_fanions",      
      "vdl_precisezdimensionetsupport",
      "vdl_autres2",
      "vdl_precisez1",
    ],
    title: "Affichage temporaire",
    activeRule: {
      field: "vdl_affichagetemporairesignalisationpavoisement",
    },
  };
const editDemandPermitStructureTemporaireSection: FormSection<EditDemandPermitFields> =
  {
    fields: [
      "vdl_chapiteaumarquise",
      "vdl_preciseznombreetdimension",
      "vdl_structuresgonflables",
      "vdl_tyrolienne",
      "vdl_scenemobile",
      "vdl_autres3",
      "vdl_precisez2",
      "vdl_equipementconformeauxnormesetreglements",
      "vdl_installateuretoperateursontqualifies",
      "vdl_nomdufournisseur",
      "vdl_requerantassurelaresponsabilitecivile",
    ],
    title: "Installation de structure temporaire",
    activeRule: {
      field: "vdl_installationdestructuretemporaire",
    },
  };

const editDemandPermitConsommationBoissonSection: FormSection<EditDemandPermitFields> =
  {
    fields: [],
    title: "Consommation de boissons alcoolisées",
    activeRule: {
      field: "vdl_consommationdeboissonsalcooliques",
    },
  };

const editDemandPermitVenteBoissonSection: FormSection<EditDemandPermitFields> =
  {
    fields: [
      "vdl_venteestgereeparledemandeur1",
      "vdl_ventegereeparuntiers1",
      "vdl_precisez4",
      "vdl_profitsdelaventeremis1",
    ],
    title: "Vente de boissons alcoolisées",
    activeRule: {
      field: "vdl_ventedeboissonsalcooliques",
    },
  };

const editDemandPermitVenteAlimentsBreuvagesSection: FormSection<EditDemandPermitFields> =
  {
    fields: [
      "vdl_venteestgereeparledemandeur2",
      "vdl_ventegereeparuntiers2",
      "vdl_precisez6",
      "vdl_nombredepointsdeventesurlesite1",
      "vdl_profitsdelaventeremis2",
    ],
    title: "Vente d'aliments et breuvages",
    activeRule: {
      field: "vdl_ventealimentsetboissonsexcomptoirkiosque",
    },
  };

const editDemandPermitVenteProduitsSection: FormSection<EditDemandPermitFields> =
  {
    fields: [
      "vdl_venteestgereeparledemandeur3",
      "vdl_ventegereeparuntiers3",
      "vdl_precisez5",
      "vdl_nombredepointsdeventesurlesite2",
      "vdl_profitsdelaventeremisa3",
    ],
    title: "Vente de produits et/ou services",
    activeRule: {
      field: "vdl_ventedeproduitsservicesobjetsexpovente",
    },
  };

const editDemandPermitSollicitationSection: FormSection<EditDemandPermitFields> =
  {
    fields: [
      "vdl_collectedebouteille",
      "vdl_ventedecalendrier",
      "vdl_autre",
      "vdl_precisez3",
      "vdl_revenusdelactiviteremisa",
    ],
    title: "Sollicitation porte-à-porte",
    activeRule: {
      field: "vdl_sollicitationporteaporte",
    },
  };

  const editDemandPermitCamionSection: FormSection<EditDemandPermitFields> =
  {
    fields: [],
    title: "Camion-restaurant",
    activeRule: {
      field: "vdl_camionrestaurant",
    },
  };

const editDemandPermitSectionsSpecialEvent: FormSection<EditDemandPermitFields>[] =
  [
    editDemandPermitConsommationBoissonSection,
    editDemandPermitVenteBoissonSection,
    editDemandPermitVenteAlimentsBreuvagesSection,
    editDemandPermitCamionSection,
    editDemandPermitVenteProduitsSection,
    editDemandPermitTarificationSection,
    editDemandPermitSollicitationSection,
    editDemandPermitStructureTemporaireSection,
    editDemandPermitAffichageTemporaireSection,
    editDemandPermitDiffusionSonoreSection
  ];

const editDemandPermitSectionsShooting: FormSection<EditDemandPermitFields>[] =
  [editDemandPermitDiffusionSonoreSection, editDemandPermitEclairageSection];

export {
  editDemandPermitFields,
  editDemandPermitSectionsSpecialEvent,
  editDemandPermitSectionsShooting,
};
