import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React from "react";
import {
  DemandFundraiserRow,
  DemandInfoRequestRow,
} from "../../../../../data/Demand/models";
import DisplayField from "../../../../common/cards/DisplayField";
import { fundraiserDisplayNames } from "../../../../../data/Demand/displayNames";
import BoxResponsivePadding from "../../../../common/boxes/BoxResponsivePadding";
import DisplayFieldCard from "../../../../common/cards/DisplayFieldCard";
import {
  dateStringToShortString,
  dateStringToYearString,
} from "../../../../../data/common/fieldToString";

type Props = {
  demand: DemandFundraiserRow;
};

const Fundraiser = ({ demand }: Props) => {
  const details = demand.vdl_detailsdelademande;
  return (
    <Box display="grid" gap="16px">
      <DisplayFieldCard
        fields={[
          {
            label: fundraiserDisplayNames.vdl_projetdefinancement,
            value: details.vdl_projetdefinancement,
            type: "string",
          },
        ]}
        columns={1}
        minColWidth={300}
      />
      <DisplayFieldCard
        title="Lieu de l'intersection"
        fields={[
          {
            label: fundraiserDisplayNames.vdl_stmartinboisdeboulogne,
            value:
              demand.vdl_detailsdelademande.vdl_stmartinboisdeboulogne?.toString(),
            type: "string",
          },
          {
            label: fundraiserDisplayNames.vdl_jeanberauddanieljohnson,
            value:
              demand.vdl_detailsdelademande.vdl_jeanberauddanieljohnson?.toString(),
            type: "string",
          },
          {
            label: fundraiserDisplayNames.vdl_chomedeycartier,
            value:
              demand.vdl_detailsdelademande.vdl_chomedeycartier?.toString(),
            type: "string",
          },
          {
            label: fundraiserDisplayNames.vdl_notredamelavatere,
            value:
              demand.vdl_detailsdelademande.vdl_notredamelavatere?.toString(),
            type: "string",
          },
        ]}
        columns={2}
        minColWidth={300}
      />
      <DisplayFieldCard
        title="Participants"
        fields={[
          {
            label:
              fundraiserDisplayNames.vdl_nombredejeunesparticipants18ansetmoins,
            value:
              demand.vdl_detailsdelademande.vdl_nombredejeunesparticipants18ansetmoins?.toString(),
            type: "string",
          },
          {
            label: fundraiserDisplayNames.vdl_nombredadultesparticipants5min,
            value:
              demand.vdl_detailsdelademande.vdl_nombredadultesparticipants5min?.toString(),
            type: "string",
          },
        ]}
        columns={2}
        minColWidth={300}
      />
      <DisplayFieldCard
        title="Date de l'évènement"
        fields={[
          {
            label: fundraiserDisplayNames.vdl_erjour,
            value: dateStringToShortString(
              demand.vdl_detailsdelademande.vdl_erjour
            ),
            type: "string",
          },
          {
            label: fundraiserDisplayNames.vdl_emejour,
            value: dateStringToShortString(
              demand.vdl_detailsdelademande.vdl_emejour
            ),
            type: "string",
          },
          {
            label: fundraiserDisplayNames.vdl_3emejour,
            value: dateStringToShortString(
              demand.vdl_detailsdelademande.vdl_3emejour
            ),
            type: "string",
          },

          {
            label: fundraiserDisplayNames.vdl_4emejour,
            value: dateStringToShortString(
              demand.vdl_detailsdelademande.vdl_4emejour
            ),
            type: "string",
          },
          {
            label: fundraiserDisplayNames.vdl_5emejour,
            value: dateStringToShortString(
              demand.vdl_detailsdelademande.vdl_5emejour
            ),
            type: "string",
          },
        ]}
        columns={2}
        minColWidth={300}
      />
      <DisplayFieldCard
        title="Date de l'évènement"
        fields={[
          {
            label:
              fundraiserDisplayNames.vdl_engagementaurespectdesproceduresetdirectives,
            value: details.vdl_engagementaurespectdesproceduresetdirectives??false,
            span: 2,
            type: "check",
          },
          {
            label:
              fundraiserDisplayNames.vdl_jemengageasuivrelaformationobligatoire,
            value: details.vdl_jemengageasuivrelaformationobligatoire??false,
            type: "check",
          },
          {
            label:
              fundraiserDisplayNames.vdl_jaidejasuivilaformationobligatoireannee,
            value: dateStringToYearString(
              details.vdl_jaidejasuivilaformationobligatoireannee
            ),
            type: "string",
          },
        ]}
        columns={2}
        minColWidth={300}
      />
    </Box>
  );
};

export default Fundraiser;
