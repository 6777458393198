import React, { useState } from "react";
import BoxResponsivePadding from "../../../common/boxes/BoxResponsivePadding";
import {
  DemandInfoRequestPatch,
  DemandInfoRequestRow,
} from "../../../../data/Demand/models";
import SimpleFields from "../../../form/SimpleForm/SimpleFields";
import {
  EditDemandInfoRequest,
  editDemandInfoRequestFields,
} from "../../../../data/Demand/forms/infoRequest";
import { NewDemandFields } from "../../../../data/Demand/forms/dialog";
import { updateDemand } from "../../../../data/Demand/requests";
import EditDemandLayout from "../EditDemandLayout";
import handleSimpleFormSubmit from "../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../hooks/useTabs";
import buildForm from "../../../form/SimpleForm/buildForm";

type Props = {
  demand: DemandInfoRequestRow;
  isNewDemand: boolean;
  fieldsGeneral: NewDemandFields;
  onUpdateSuccess: () => void;
  onUpdateFailure: (error?: string) => void;
  setFieldsGeneral: React.Dispatch<React.SetStateAction<NewDemandFields>>;
};

const InfoRequest = ({
  demand,
  isNewDemand,
  fieldsGeneral,
  setFieldsGeneral,
  onUpdateSuccess,
  onUpdateFailure,
}: Props) => {
  const details = demand.vdl_detailsdelademande;
  const [fields, setFields] = useState<EditDemandInfoRequest>(
    buildForm(editDemandInfoRequestFields, {
      vdl_sujetdelademande: details.vdl_sujetdelademande ?? "",
      vdl_descriptiondelademande: details.vdl_descriptiondelademande ?? "",
    })
  );
  const { activeTab, changeTab } = useTabs();

  const onSubmit = async () => {
    const errors = handleSimpleFormSubmit(fields, setFields);

    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      const scrollTo = fields[errors[errors.length - 1]].fieldRef;
      if (scrollTo) {
        scrollTo.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
      return;
    }

    const _demand: DemandInfoRequestPatch = {
      title: fieldsGeneral.title.value,
      vdl_contactprincipal: fieldsGeneral.vdl_contactprincipal.value,
      type: demand.type,
      vdl_detailsdelademande: {
        vdl_sujetdelademande: fields.vdl_sujetdelademande.value,
        vdl_descriptiondelademande: fields.vdl_descriptiondelademande.value,
      },
    };

    if (demand.statuscode === "Inutilisée") {
      _demand.vdl_detailsdelademande.statuscode = "En création";
    }

    const { status, error } = await updateDemand(demand.incidentid, _demand);
    if (status === "success") {
      onUpdateSuccess();
    } else {
      onUpdateFailure(error);
    }
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_lienhautduformulaireportail}
    >
      <BoxResponsivePadding bgcolor="common.white">
        <SimpleFields
          updateRefs
          fields={fields}
          setFields={setFields}
          title={demand.type}
        />
      </BoxResponsivePadding>
    </EditDemandLayout>
  );
};

export default InfoRequest;
