export type ErrorResponse = { status: "error"; error: string };

export const errorHandler = (
  error: unknown,
  errorMessage: string
): ErrorResponse => {
  if (error instanceof Error) return { status: "error", error: error.message };
  else if (typeof error === "string") return { status: "error", error: error };
  else return { status: "error", error: errorMessage };
};
