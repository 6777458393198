import { Box, Card, CardContent, Skeleton } from "@mui/material";
import React from "react";

const AttachmentCardSkeleton = () => {
  return (
    <Card elevation={0} sx={{ bgcolor: "white" }}>
      <CardContent>
        <Box display="flex" flexDirection="column" gap="10px">
          <Skeleton
            variant="rectangular"
            style={{
              borderRadius: "4px / 6.7px ",
              fontSize: "1.6rem",
            }}
          />
          <Box display="grid" rowGap="6px">
            <Skeleton
              variant="rectangular"
              style={{
                borderRadius: "4px / 6.7px",
              }}
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AttachmentCardSkeleton;
