import { Box, Skeleton } from "@mui/material";

const ActivityCardSkeletton = () => {
  return (
    <Box
      display="grid"
      gridTemplateColumns="auto 1fr"
      alignItems="center"
      columnGap="16px"
    >
      <Skeleton variant="circular" height="3rem" width="3rem" />
      <Skeleton variant="text" width="80%" sx={{ maxWidth: 320 }} />
      <Box display="flex" justifyContent="center" height="100%">
        <Box height="100%" width="2px" bgcolor="grey.300"></Box>
      </Box>
      <Box paddingBottom="32px">
        <Skeleton variant="text" width="90%" height="4rem" />
      </Box>
    </Box>
  );
};

export default ActivityCardSkeletton;
