import { Box, Grid, Typography, styled } from "@mui/material";
import { DemandStandardEquipmentRow } from "../../../../../data/Demand/models";
import DisplayField from "../../../../common/cards/DisplayField";
import { standardEquipmentDisplayNames } from "../../../../../data/Demand/displayNames";
import DisplayCheckbox from "../../../../common/cards/DisplayCheckbox";
import { dateStringToShortString, dateTimeStringToString } from "../../../../../data/common/fieldToString";
import ProductsSection from "./ProductsSection/ProductsSection";
import BoxResponsivePadding from "../../../../common/boxes/BoxResponsivePadding";
import DisplayFieldCard from "../../../../common/cards/DisplayFieldCard";

const CardsLayout = styled(Box)(({ theme }) => ({
  display: "grid",
  gap: "1rem",
  gridTemplateColumns: "1fr 1fr",
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "1fr",
  },
}));

type Props = {
  demand: DemandStandardEquipmentRow;
};

const StandardEquipment = ({ demand }: Props) => {
  const details = demand.vdl_detailsdelademande;

  return (
    <CardsLayout>
      <DisplayFieldCard
        title="Lieu de livraison"
        fields={[
          {
            label: standardEquipmentDisplayNames.vdl_adressecompletelivraison,
            value: details.vdl_adressecompletelivraison,
            type: "string",
            span:2,
          },
          {
            label: standardEquipmentDisplayNames.vdl_secteurdeladressedelivraison,
            value: details.vdl_secteurdeladressedelivraison,
            type: "string",
            span:2,
          },
          {
            label: standardEquipmentDisplayNames.vdl_nomdubatimentlivraison,
            value: details.vdl_nomdubatimentlivraison,
            type: "string",
            span:2,
          },
          {
            label: standardEquipmentDisplayNames.vdl_datedelivraison,
            value: dateStringToShortString(details.vdl_datedelivraison),
            type: "string",
          },
          {
            label: standardEquipmentDisplayNames.vdl_horaire,
            value: details.vdl_horaire,
            type: "string",
          },   
          {
            label: standardEquipmentDisplayNames.vdl_remarqueoucommentaire,
            value: details.vdl_remarqueoucommentaire,
            type: "string",
            span:2,
          },
        ]}
        minColWidth={260}
        columns={2}
      />
      <DisplayFieldCard
        title="Lieu de livraison"
        fields={[
          {
            label: standardEquipmentDisplayNames.vdl_identiquealadressedelivraison,
            value: details.vdl_identiquealadressedelivraison,
            type: "check",
            span:2,
          },
          {
            label: standardEquipmentDisplayNames.vdl_adressecompleterecuperation,
            value: details.vdl_adressecompleterecuperation,
            type: "string",
            span:2,
          },
          {
            label: standardEquipmentDisplayNames.vdl_secteurdeladressederecuperation,
            value: details.vdl_secteurdeladressederecuperation,
            type: "string",
            span:2,
          },
          {
            label: standardEquipmentDisplayNames.vdl_nomdubatimentrecuperation,
            value: details.vdl_nomdubatimentrecuperation,
            type: "string",
            span:2,
          },
          {
            label: standardEquipmentDisplayNames.vdl_datederecuperation,
            value: dateStringToShortString(details.vdl_datederecuperation),
            type: "string",
          },
          {
            label: standardEquipmentDisplayNames.vdl_horairerecuperation,
            value: details.vdl_horairerecuperation,
            type: "string",
          },
          {
            label: standardEquipmentDisplayNames.vdl_remarqueoucommentairerecuperation,
            value: details.vdl_remarqueoucommentairerecuperation,
            type: "string",
            span:2,
          },
        ]}
        minColWidth={260}
        columns={2}
      />
      <BoxResponsivePadding
        bgcolor="common.white"
        sx={{
          gridColumn: {
            lg: "span 2",
          },
        }}
      >
        <ProductsSection products={demand.vdl_detailsdelademande.products} />
      </BoxResponsivePadding>
    </CardsLayout>
  );
};

export default StandardEquipment;
