import React from "react";
import { useTheme } from "@mui/material";
import LaunchSharpIcon from "@mui/icons-material/LaunchSharp";
import Link from "@mui/material/Link";

type Props = {
  to: string;
  text: string;
  color?: string;
  fontSize?: string;
  externalIcon?: boolean;
  targetBlank?: boolean;
};
const SimpleExternalLink = ({
  color,
  to,
  text,
  externalIcon,
  fontSize,
  targetBlank,
}: Props) => {
  const theme = useTheme();
  const textColor = color;
  const textFontSize = fontSize;

  return (
    <Link
      href={to}
      underline="hover"
      target={targetBlank ? "_blank" : undefined}
      sx={{
        color: textColor ? textColor : (theme) => theme.custom.simpleLink.color,
        fontSize: textFontSize ? textFontSize : 13,
      }}
    >
      {text} {externalIcon ? <LaunchSharpIcon sx={{ fontSize: 12 }} /> : null}
    </Link>
  );
};

export default SimpleExternalLink;
