import React from "react";
import { DocumentFile } from "../../../data/Document/models";
import { Box, Link } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";

type Props = {
  file: DocumentFile;
};

const styles = {
  button: {
    cursor: "pointer",
    background: "#f1f1f1",
    userSelect: "none",
    "&:hover": {
      background: "lightgray",
    },
  },
};

const getExtension = (filename: string) => {
  return filename.split(".").pop();
};

const renderIcon = (file: DocumentFile) => {
  switch (getExtension(file.name)?.toLowerCase()) {
    case "docx":
      return <DescriptionIcon />;
    case "pdf":
      return <DescriptionIcon />;
    case "xlsx":
      return <DescriptionIcon />;
    default:
      return <DescriptionIcon />;
  }
};

const DownloadableFile = ({ file }: Props) => {
  return (
    <Box
      sx={{ ...styles.button }}
      display="flex"
      flexDirection="row"
      gap={"8px"}
      paddingLeft={"2rem"}
    >
      {renderIcon(file)}
      <Link href={file.downloadUrl}>{file.name}</Link>
    </Box>
  );
};

export default DownloadableFile;
